<script>
  import { onMount } from "svelte";
  import Button from "../../components/commons/Button.svelte";
  import { request } from "../../services/network";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Dialog, { Content, Header, Title, Actions } from "@smui/dialog";
  import DateTime from "../commons/DateTime.svelte";
  import { navigateTo } from "svelte-router-spa";
  import dayjs from "dayjs";
  import Bullets from "../../components/commons/Bullets.svelte";
  import Snackbar, { Label } from "@smui/snackbar";
  let snackbarOpen;
  let snackbarMessage = "";
  let slotId = "";
  let filteredTimeSlots = [];
  let bookSlotOpen = false;
  let notifySlotOpen = false;
  let weblink;
  let showModal = false;
  let isVkyc = false;
  let showSpan = false;

  export let currentRoute;

  let vkycProcess = [
    [
      "You will be required to read out a verification code on your screen (not an OTP) during the video call for KYC verification.",
      "You will be asked to show your PAN card and your live photo will be captured",
    ],
    [
      "Stable Internet connection",
      "To enable camera / microphone / location access for your device",
      "To keep your original PAN card handy",
      "You are sitting in a quiet place with no person around",
      "To keep a blank white paper and a pen handy",
    ],
  ];

  let setSlotId = (id) => {
    if (id) {
      slotId = id;
    }
  };

  onMount(async () => {
    //userCheck(currentRoute.name);
    window.loading(true);
    let response = await request("/api/kyc/vkyc/setup", "GET");
    window.loading(false);
    if (response.type === "danger") {
      isVkyc = true;
      snackbarMessage =
        "Apologies, VKYC service is currently unavailable. Please try again later.";
      snackbarOpen.forceOpen();
    }

    setTimeout(() => {
      showSpan = true;
    }, 6000);
  });

  function formatTime(date) {
    // let time = new Date(date).toLocaleTimeString();
    // return time.slice(0, -6) + " " + time.slice(-2);

    return dayjs(date).format("hh:mm A");
  }

  let startCall = () => {
    window.loading(true);
    request("/api/kyc/vkyc/weblink", "GET").then((response) => {
      weblink = response.result.data.webLink;
      showModal = true;
      window.loading(false);

      /* if (Device.isMobile) {
        window.open(url, "_blank", "noopener");
      } else {
        window.open(link, "_blank");
      } */
    });
  };

  let slotDetails = async () => {
    window.loading(true);
    const response = await request("/api/kyc/vkyc/slot", "GET");

    const currentDateTime = dayjs(); // Get the current date and time

    filteredTimeSlots = response.result.data.reduce((acc, timeslot) => {
      const slotStartDateTime = dayjs(timeslot.slotStart); // Convert slotStart to a dayjs object

      // Calculate the difference in minutes
      const timeDifference = slotStartDateTime.diff(currentDateTime, "minute");

      // Check if the time difference is 15 minutes or more
      if (timeslot.isSlotAvailable && timeDifference >= 15) {
        const timeSlotIndex = acc.findIndex(
          (slot) => slot.date === slotStartDateTime.format("YYYY-MM-DD")
        );

        if (timeSlotIndex === -1) {
          acc = [
            ...acc,
            {
              date: slotStartDateTime.format("YYYY-MM-DD"),
              timeslots: [timeslot],
            },
          ];
        } else {
          const foundTimeSlot = acc[timeSlotIndex];
          let temp = [...acc];
          temp[timeSlotIndex] = {
            ...foundTimeSlot,
            timeslots: [...foundTimeSlot.timeslots, timeslot],
          };
          acc = temp;
        }
      }

      return acc;
    }, []);
    window.loading(false);
    //  [{date: 31, timeslots: [{},{},{}]}, {date: 1, timeslots: [{},{},{}]}, {date: 2, timeslots: [{},{},{}]}]

    bookSlotOpen = true;
  };

  // let notifyCustomer = () => {
  //   request("/api/kyc/vkyc/notify", "GET").then((response) => {
  //     if (response.result[0].data.linkSent) {
  //       notifySlotOpen = true;
  //     }
  //   });

  //   navigateTo("/onboarding/vkyc-confirm/notification-sent");
  // };

  let handleBookingConfirmation = async () => {
    if (!slotId) {
      snackbarMessage = "Please select a slot before confirming your booking.";
      snackbarOpen.forceOpen();
      return;
    } else {
      window.loading(true);
      const response = await request("/api/kyc/vkyc/book-slot", "POST", {
        slotId: slotId,
      });
      window.loading(false);
      if (response && response?.result?.data?.isBooked) {
        navigateTo("/onboarding/vkyc-status");
      } else {
        snackbarMessage = response?.result?.reason;
        snackbarOpen.forceOpen();
        return;
      }
    }
  };
</script>

<Dialog
  fullscreen
  bind:open={showModal}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <Title id="over-fullscreen-title" class="modal-header-top"
        ><span class="modal-header-top modal-title-left-margin">Confirm</span
        ></Title
      >
      <!-- <div style="position: relative; bottom: -6px; ">
        <IconButton
          on:click={() => (modalOpen = false)}
          class="material-icons"
          ripple={false}>close</IconButton
        >
      </div> -->
    </div>
  </Header>
  <Content id="sheet-content">
    <div class="modal">
      <div class="modal-body">
        <div
          class="d-flex"
          style="width: 100%; margin-bottom: 20px; flex-direction: column"
        >
          <span
            style="margin-bottom: 10px; color: #505869; line-height: 20px; text-align: justify;"
          >
            You will have an Audio-Video interaction with a Bank Official/Agent
            to conduct Video KYC and complete the onboarding process.
          </span>
        </div>
      </div>
    </div>
  </Content>
  <Actions class="modal-button">
    <div
      class="modal-button"
      style=" margin-left: 14px; margin-right: 14px; margin-bottom: 14px; justify-content: end;"
    >
      {#if weblink && weblink.length > 5}
        <a href={weblink} rel="noreferrer">
          <Button
            title="Confirm & Start"
            onclick={() => {
              showModal = false;
              //navigateTo("/onboarding/vkyc-status");
            }}
            style="display: flex; justify-content: center; border: 2px solid #13afe9"
          />
        </a>
      {/if}
    </div>
  </Actions>
</Dialog>

<Dialog bind:open={notifySlotOpen}>
  <Content>
    <div class="text-center">
      <h2>Thank you!</h2>
      <p>
        Link has been created!. Message has been sent to the registered phone
        number with the link. Please do not share this message with anyone.
      </p>
    </div>
  </Content>
</Dialog>

<Dialog fullscreen bind:open={bookSlotOpen}>
  <Content>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="close-button"
      on:click={() => {
        bookSlotOpen = false;
      }}
    >
      X
    </div>

    {#each filteredTimeSlots as slots}
      <h2>
        {dayjs(slots.date).format("DD/MM/YYYY")}
      </h2>
      <div class="row gap-1">
        {#each slots.timeslots as slot}
          <DateTime
            startTime={formatTime(slot.slotStart)}
            endTime={formatTime(slot.slotEnd)}
            slotId={slot.slotId}
            {setSlotId}
          />
        {/each}
      </div>
    {/each}
    <div class="text-center mt-4">
      <Button title="Confirm Booking" onclick={handleBookingConfirmation} />
    </div>
  </Content>
</Dialog>

<WizardBody title="Let’s complete your video KYC" back="">
  <span>Video KYC process</span>
  <div class="vkyc-process"><Bullets points={vkycProcess[0]} /></div>

  <span>Kindly ensure</span>
  <div class="vkyc-process"><Bullets points={vkycProcess[1]} /></div>
</WizardBody>

<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  {#if isVkyc && showSpan}
    <span class="vkyc-error"
      >Apologies, VKYC service is currently unavailable. Please try again later.</span
    >
  {/if}
  <div class="button">
    <Button title="Start a Video call" onclick={startCall} disabled={isVkyc} />
    <Button title="Book a Slot" onclick={slotDetails} disabled={isVkyc} />
    <!-- <Button title="Send a Notification" onclick={notifyCustomer} /> -->
  </div>
</WizardActionArea>

<style>
  .button {
    display: flex;
    margin-top: 10px;
    width: 100%;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .vkyc-process {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .vkyc-error {
    color: red;
    font-weight: 400;
    font-size: 14px;
    margin-left: 0.1rem;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 30px; /* Adjust this value to create the desired space */
    width: 25px;
    height: 25px;
    color: black;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 50%;
  }
</style>
