<script>
  export let icon;
  export let color = "#999";
  export let size = "18px";
  export let variant = "material-icons";
</script>

<i
  class={variant}
  style="vertical-align:middle; font-size:{size}; color:{color}">{icon}</i
>

<style>
</style>
