<script>
  import { onMount } from "svelte";
  import SveltyPicker from "svelty-picker";

  export let date;
  export let placeholder;
  export let minDate = null;
  export let maxDate = null;
  // export let initialDate = new Date();
  onMount(() => {
    //date = "";
  });
</script>

<div class="datePicker">
  <SveltyPicker
    inputClasses="formControl"
    format="dd-mm-yyyy"
    {placeholder}
    bind:value={date}
    startDate={minDate}
    endDate={maxDate}
    startView={0}
    on:change
  />
</div>

<style>
  .formControl {
    border: 1px solid #eaf0f9 !important;
    border-radius: 8px !important;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08) !important;
    padding: 18px !important;
  }
  @media only screen and (min-width: 768px) {
    .datePicker {
      --date-input-width: 85%;
    }
  }
  @media only screen and (max-width: 768px) {
    .datePicker {
      --date-input-width: 100%;
    }
  }
</style>
