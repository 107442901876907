<script>
  export let value;
  export let isSelected = false;
  export let onClick;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="option"
  style={isSelected ? "border-color: #1E3868; background-color: #EBF0F9" : ""}
  on:click={onClick}
>
  <div class="input" style={isSelected ? "color: #464448" : ""}>{value}</div>
</div>

<style>
  .option {
    width: 130px;
    border: 1px solid #979797;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    cursor: pointer;
  }

  .input {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    border: none;
    display: block;
    margin: 0px;
    padding: 0px;
    outline: none;
    text-align: center;
  }
</style>
