import { request } from "./network";
import { session } from "./store";
import { navigateTo } from "svelte-router-spa";
import { decrypt } from "./function";

export const userCheck = async (
  refresh = false,
  userCurrentRoute = null,
  forceRefresh = false
) => {
  const url = refresh ? "/api/user/session?refresh=1" : "/api/user/session";
  let userSession = JSON.parse(decrypt((await request(url, "GET")).data));
  session.set(userSession);
  let { data } = await request("/api/next", "GET");
  if (userCurrentRoute && userCurrentRoute.length > 0) {
    if (data !== userCurrentRoute) {
      if (forceRefresh) location.href = data;
      else navigateTo(data);
    }
  } else {
    if (forceRefresh) location.href = data;
    else navigateTo(data);
  }
  /*
  const url = refresh ? "/api/user/session?refresh=1" : "/api/user/session";
  let data = JSON.parse(decrypt((await request(url, "GET")).data));
  session.set(data);

  if (
    data.hasOwnProperty("user") &&
    data.user.hasOwnProperty("user_id") &&
    data.user.hasOwnProperty("user_id") > 0
  ) {
    const kyc = data.user.kyc;
    const phone = data.user.phone;
    const email = data.user.email;

    const nextType = localStorage.getItem("flow");

    if (!phone || phone.toString().length < 10) {
      navigateTo("/onboarding/phone");
    } else if (!data.user.attributes.flow && !data.flow) {
      navigateTo(`/onboarding/select-type`);
    } else if (!email || email.length < 3) {
      navigateTo("/onboarding/email-add");
    } else if (!kyc.PAN) {
      navigateTo("/onboarding/journey");
      //navigateTo("/onboarding/pan-verify");
    } else if (kyc.PAN && kyc.PAN === "PENDING") {
      // for pan exists but not confirmed
      navigateTo("/onboarding/pan-confirm");
    } else if (kyc.PAN && kyc.PAN === "CONFIRMED" && data.user.next) {
      if (
        data.user.attributes.error &&
        data.user.attributes.error.type === "CKYC"
      ) {
        navigateTo("/onboarding/dropoff");
      } else if (!kyc.VKYC && data.user.next === "VKYC") {
        if (kyc.AADHAAR) {
          navigateTo("/onboarding/vkyc");
        } else {
          navigateTo("/onboarding/aadhaar");
        }
      } else if (!kyc.SELFIE && data.user.next === "SELFIE") {
        navigateTo("/onboarding/selfie");
      } else if (!kyc.AADHAAR && data.user.next === "AADHAAR") {
        navigateTo("/onboarding/aadhaar");
      } else if (kyc.AADHAAR && kyc.AADHAAR !== "CONFIRMED") {
        navigateTo("/onboarding/aadhaar-otp");
      } else if (
        kyc.AADHAAR &&
        kyc.AADHAAR === "CONFIRMED" &&
        (!kyc.SELFIE || kyc.SELFIE !== "CONFIRMED")
      ) {
        if (
          data.user.attributes.error &&
          data.user.attributes.error.type === "AADHAAR"
        ) {
          navigateTo("/onboarding/dropoff");
        } else {
          if (data.user.kyc.VKYC && data.user.kyc.VKYC === "PENDING") {
            navigateTo("/onboarding/vkyc-pending");
          } else if (data.user.kyc.VKYC && data.user.kyc.VKYC === "SUCCESS") {
            afterKycNav(data);
          } else {
            navigateTo("/onboarding/selfie");
          }
        }
      } else {
        afterKycNav(data);
      }
    } else {
      afterKycNav(data);
    }
  } else {
    navigateTo("/onboarding/phone");
  }*/
};
