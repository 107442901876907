<script>
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../services/network";
  import { session } from "../../services/store";

  request("/api/user/logout").then((response) => {
    localStorage.setItem("csrfkey", "");
    session.set("{}");

    navigateTo("/");
  });
</script>
