<script>
  export let itemLabel;
  export let highlighted;
</script>

<li class="autocomplete-item" class:autocomplete-active={highlighted} on:click>
  {@html itemLabel}
</li>

<style>
  li.autocomplete-item {
    list-style: none;
    border-bottom: 1px solid #d4d4d4;
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
  }

  li.autocomplete-item:hover {
    /*when hovering an item:*/
    background-color: #a6a6a6;
    color: white;
  }

  li.autocomplete-item:active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }

  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
  }
</style>
