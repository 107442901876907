<script>
  import Button from "../../components/commons/Button.svelte";
  import FdCardItem from "./../../components/FDCC/FdCardItem.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import { makeFirstCap, toCurrency } from "../../services/function";

  let fdList = [];
  let totalAmount = 0;
  let user_name = "";

  onMount(async () => {
    const fdListReq = await request("/api/fd/success", "GET");

    fdList = fdListReq.data;

    fdList.map((item) => (totalAmount = totalAmount + item.amount));

    const userData = await request("/api/user", "GET");

    user_name = `Hi ${makeFirstCap(userData.data.name)}`;
  });
</script>

<WizardBody title={user_name} back="/">
  <div class="m-auto" style="max-width:420px;">
    <div class="responsive-scroll">
      <div style="display: flex; flex-direction: column; ">
        <div style="position: relative; min-width:320px;">
          <div class="top-deposit-box">
            <div class="top-deposit-box-top">Total Amount in FD</div>
            <div class="top-deposit-box-mid">{toCurrency(totalAmount)}</div>
            <div class="top-deposit-box-bottom">
              Total Interest <b>{toCurrency(totalAmount)}</b> (Earned)
            </div>
          </div>
          <div class="bottom-deposit-box">
            <div class="bottom-deposit-box-child">
              <div
                style="display: flex; justify-content: center; align-items: center; margin-bottom: -1px;"
              >
                <i
                  style="background-image: url('/assets/images/bell.svg');background-size: 14px;width: 18px;height: 18px;display: block;background-repeat: no-repeat;"
                />
              </div>
              <div
                style="font-style: normal; font-weight: 500; font-size: 12px; line-height: 12px; color: #fff; width: 100%;"
              >
                Monthly interest credited of ₹240
              </div>
              <div style="margin-bottom: -3px;">
                <i
                  style="background-image: url('/assets/images/right_arrow.svg');background-size: 10px;width: 18px;height: 18px;display: block;background-repeat: no-repeat;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="margin-top: 24px; display: flex; flex-direction: column; ">
        <div
          style="font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; color: #1E3868; margin-top: 4px"
        >
          Your Fixed Deposits
        </div>
        <div style="display: flex; flex-direction: column; margin-top: 16px;">
          {#each fdList as fdData, index}
            <FdCardItem {fdData} />
          {/each}
        </div>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          style="display: flex; margin-top: 4px; gap: 8px; align-items: center; cursor: pointer;"
          on:click={() => navigateTo("/onboarding/presetup")}
        >
          <i
            style="background-image: url('/assets/images/add_circle_outline.svg');background-size: 24px;width: 24px;height: 24px;display: block;background-repeat: no-repeat;"
          />
          <span
            style="font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; color: #1E3868;"
          >
            Start New FD
          </span>
        </div>
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea
  ><Button
    onclick={() => navigateTo("/logout")}
    title="Logout"
  /></WizardActionArea
>

<style>
  .top-deposit-box {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      66.27deg,
      #13afe9 -10.45%,
      #0f89d0 12.51%,
      #0a54ac 39.13%,
      #083a9a 63.11%
    );
    border-radius: 24px;
    padding: 24px;
    z-index: 10;
  }
  .top-deposit-box-top {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    color: #ffffff;
    opacity: 0.72;
    margin-bottom: 10px;
  }

  .top-deposit-box-mid {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: #ffffff;
    margin-bottom: 8px;
  }

  .top-deposit-box-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }

  .bottom-deposit-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fda404;
    border-radius: 24px;
    padding: 24px;
    margin-top: 58px;
    min-height: 112px;
  }
  .bottom-deposit-box-child {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    gap: 10px;
  }
</style>
