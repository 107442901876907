<script>
  import { navigateTo } from "svelte-router-spa";
  import { get } from "svelte/store";
  import { session } from "../../services/store";

  export let back;

  const isLoggedIn = () => {
    let userSession = get(session);

    if (
      userSession.user &&
      userSession.user.user_id &&
      userSession.user.user_id > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
</script>

<div class="navbar pt-1">
  {#if back.length > 0}
    <a
      href={back === "/landing/fd" ? "/landing/fd" : ``}
      on:click={() => {
        if (back === "disabled") {
          alert("Back is disabled");
        } else if (back !== "/landing/fd") {
          navigateTo(back);
        }
      }}
      style="background: rgba(0, 0, 0, 0);border: none;display: flex;justify-content: center;align-items: center;margin: 0px;"
    >
      <i
        style="background-image: url('/assets/images/back-button.svg');background-size: 20px; width: 20px; height: 20px;display: block; background-repeat: no-repeat;"
      />
    </a>
  {/if}

  <div class="logo">
    <i
      style="background-image: url('/assets/images/sbm-logo.png');background-size: 133px; width: 133px; height: 40px;display: block;background-repeat: no-repeat;"
    />
  </div>

  <div style="display: flex; width: 100%" />

  <!-- {#if isLoggedIn()}
    <a href="/logout">
      <span style="font-size: 14px; color: #1e3868">Logout</span>
    </a>
  {/if} -->
</div>

<style>
  .navbar {
    position: relative;
    width: 100%;
    display: flex;
    justify-items: start;
    align-items: center;
    gap: 12px;
    order: -2;
  }
</style>
