<script>
  import { onMount } from "svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Input from "../../components/commons/Input.svelte";
  import SegmentedButton from "../../components/commons/SegmentedButton.svelte";
  import SubHeader from "../../components/Wizard/SubHeader.svelte";
  import { request } from "../../services/network";
  import { navigateTo } from "svelte-router-spa";
  import Snackbar, { Label } from "@smui/snackbar";
  import Dialog, { Content, Header, Title, Actions } from "@smui/dialog";
  import Buttonlnv from "../../components/commons/Buttonlnv.svelte";
  import DatePicker from "../../components/commons/DatePicker.svelte";
  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import Checkbox from "../../components/commons/Checkbox.svelte";
  import { routeCheck } from "../../services/routeCheck";
  import { getStateCode } from "../../services/function";
  import WizardHeader from "../../components/Wizard/Header.svelte";
  import InputAutocomplete from "../../components/commons/InputAutocomplete.svelte";
  dayjs.extend(customParseFormat);
  import { cityOptions } from "../caf/cityArray.js";
  export let currentRoute;
  let isValid = false;
  let snackbarOpen;
  let snackbarMessage = "";
  let modalOpen = false;
  let inputWidth = "100%";
  let inputType = "text";
  const relationships = [
    "Father",
    "Mother",
    "Son",
    "Daughter",
    "Brother",
    "Sister",
    "Husband",
    "Wife",
  ];
  let nomineeName = "";
  let nomineeDob = "";
  let nomineePhone = "";
  let relationShipValue = "";
  const relationshipsG = [
    "Father",
    "Mother",
    "Court Appointed",
    "Defacto Guardian",
    "Others",
  ];
  let nomineeGName = "";
  let nomineeGContact = "";
  let relationShipGValue = "";
  let inputValue = "";
  let showGuardian = false;
  let checkbox = false;
  let declarationAccepted = false;
  let checked = false;
  let nomineeAddressSameChecked = false;

  let communication_address_id;
  let nominee_address_db;
  let nominee_landmark_db;
  let nominee_locality_db;
  let nominee_pincode_db;
  let nominee_city_db;
  let nominee_state_db;
  let nominee_address;
  let nominee_landmark;
  let nominee_locality;
  let nominee_pincode;
  let nominee_city;
  let nominee_state;

  onMount(async () => {
    routeCheck(currentRoute.name);
    try {
      const { user_account_pending } = JSON.parse(
        localStorage.getItem("session")
      );

      communication_address_id =
        user_account_pending[0].data.communication_address_id;

      if (!communication_address_id) {
        return;
      }
      const { data } = await request(
        `/api/address/${communication_address_id}`,
        "GET"
      );

      //"address":"#01 1st Cross, 1st Main, Majestic, Bengaluru, Bengaluru, KA, 560001","landmark":"1st Main","pincode":"560001","city":"Bengaluru","state":"KA"

      nominee_address_db = data.address;
      nominee_landmark_db = data.landmark;
      nominee_pincode_db = data.pincode;
      nominee_city_db = data.district;
      nominee_state_db = data.state;
      nominee_locality_db = data.locality;
    } catch (e) {
      //console.log(e);
    }
  });

  $: if (nomineeAddressSameChecked) {
    nominee_address = nominee_address_db;
    nominee_landmark = nominee_landmark_db;
    nominee_locality = nominee_locality_db;
    nominee_pincode = nominee_pincode_db;
    nominee_city = nominee_city_db;
    nominee_state = nominee_state_db;
  } else {
    nominee_address = "";
    nominee_landmark = "";
    nominee_locality = "";
    nominee_pincode = "";
    nominee_city = "";
    nominee_state = "";
  }

  const nomineeDateChanged = () => {
    if (nomineeDob.length > 0) {
      const currentDate = new Date();
      const dobDate = dayjs(nomineeDob, "DD-MM-YYYY", true); // strict parsing
      const years =
        new Date(currentDate - new Date(dobDate)).getFullYear() - 1970;

      if (years < 18) {
        showGuardian = true;
      } else {
        showGuardian = false;
        nomineeGName = "";
        nomineeGContact = "";
        relationShipGValue = "";
      }
    } else {
      nomineeGName = "";
      nomineeGContact = "";
      relationShipGValue = "";
    }
  };

  const skipNominee = async () => {
    declarationAccepted = true;
    modalOpen = false;
    window.loading(true);
    const nomineeUpdate = await request("/api/user_account/nominee", "POST", {
      nominee_id: "0",
    });
    window.loading(false);
    navigateTo("/");
  };

  const submitForm = async () => {
    if (nomineeName.length < 1) {
      /* window.snackbar("Invalid nominee name", "danger", null); */
      snackbarMessage = "Please enter nominee name";
      snackbarOpen.forceOpen();
      return;
    }

    if (!nomineeDob || nomineeDob.length < 1) {
      //window.snackbar("Invalid nominee date", "danger", null);
      snackbarMessage = "Please enter nominee Date of Birth";
      snackbarOpen.forceOpen();
      return;
    }

    if (nomineePhone.toString().length !== 10) {
      snackbarMessage = "Please enter nominee phone number";
      snackbarOpen.forceOpen();
      return;
    }

    if (relationShipValue.length < 1) {
      //window.snackbar("Invalid nominee relationship", "danger", null);
      snackbarMessage = "Please select the nominee relationship";
      snackbarOpen.forceOpen();
      return;
    }

    if (nomineeGName.length < 1 && showGuardian === true) {
      //window.snackbar("Invalid nominee guardain name", "danger", null);
      snackbarMessage = "Invalid nominee guardian name";
      snackbarOpen.forceOpen();
      return;
    }

    if (nomineeGContact.length < 1 && showGuardian === true) {
      //window.snackbar("Invalid nominee guardain contact", "danger", null);
      snackbarMessage = "Invalid nominee guardian contact";
      snackbarOpen.forceOpen();
      return;
    }

    if (relationShipGValue.length < 1 && showGuardian === true) {
      //window.snackbar("Invalid nominee gurdain relationship", "danger", null);
      snackbarMessage = "Invalid nominee guardian relationship";
      snackbarOpen.forceOpen();
      return;
    }

    if (!nomineeAddressSameChecked) {
      if (nominee_address.length < 1) {
        snackbarMessage = "Please make sure to add the nominee address";
        snackbarOpen.forceOpen();
        return;
      }

      if (nominee_landmark.length < 1) {
        snackbarMessage = "Please make sure to add the nominee landmark";
        snackbarOpen.forceOpen();
        return;
      }

      if (nominee_locality.length < 1) {
        snackbarMessage = "Please make sure to add the nominee locality";
        snackbarOpen.forceOpen();
        return;
      }

      if (nominee_pincode.toString().length !== 6) {
        snackbarMessage = "Please make sure to add the nominee pincode";
        snackbarOpen.forceOpen();
        return;
      }

      if (nominee_city.length < 1) {
        snackbarMessage = "Please make sure to add the nominee city";
        snackbarOpen.forceOpen();
        return;
      }

      if (!cityOptions.includes(nominee_city)) {
        snackbarMessage = "City provided is not in the specified list";
        snackbarOpen.forceOpen();
        return;
      }

      if (nominee_state.length < 1) {
        snackbarMessage = "Please make sure to add the nominee state";
        snackbarOpen.forceOpen();
        return;
      }
    }

    window.loading(true);

    const nomineeReq = await request("/api/nominee", "POST", {
      nominee_name: nomineeName,
      dob: nomineeDob,
      phone: nomineePhone,
      relationship: relationShipValue,
      guardian_name: nomineeGName,
      guardian_phone: nomineeGContact,
      guardian_relationship: relationShipGValue,
      address: {
        address: nominee_address,
        landmark: nominee_landmark,
        locality: nominee_locality,
        pincode: nominee_pincode,
        city: nominee_city,
        state: nominee_state,
      },
    });

    const nomineeId = nomineeReq.data;

    await request("/api/user_account/nominee", "POST", {
      nominee_id: nomineeId.toString(),
    });

    window.loading(false);

    navigateTo("/");
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };

  function beforeunload(event) {
    event.preventDefault();
    return (event.returnValue = "");
  }

  $: if (checked) {
    if (declarationAccepted === false) {
      modalOpen = true;
      checked = false;
    }
  } else {
    declarationAccepted = false;
  }
</script>

<svelte:window on:beforeunload={beforeunload} />

<Dialog
  fullscreen
  bind:open={modalOpen}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <!--<div style="display: flex; justify-content: space-between; width: 100%">
      <Title id="over-fullscreen-title" class="modal-header-top"
        ><span class="modal-header-top modal-title-left-margin" /></Title
      >
       <div style="position: relative; bottom: -6px; ">
        <IconButton on:click={() => (modalOpen = false)} class="material-icons"
          >close</IconButton
        >
      </div> 
    </div>-->
  </Header>
  <Content id="sheet-content">
    <div class="modal">
      <div class="modal-body">
        <div
          class="d-flex"
          style="width: 100%; margin-bottom: 20px; flex-direction: column"
        >
          <Title id="over-fullscreen-title" class="modal-header-top"
            ><span class="modal-header-top">Who Is A Nominee?</span></Title
          >

          <span
            style="margin-bottom: 10px; margin-top: 12px; color: #505869; line-height: 20px; text-align: justify;"
          >
            If an customer dies, the individual or individuals who will receive
            the proceeds are called a nominee or nominees. The nominee is a
            beneficiary or the recipient of the assets, funds, and investments.
          </span>
          <Title id="over-fullscreen-title" class="modal-header-top"
            ><span class="modal-header-top">Importance of Nomination</span
            ></Title
          >
          <span
            style="margin-bottom: 10px; margin-top: 12px; color: #505869; line-height: 20px; text-align: justify;"
          >
            There is a key reason behind nominations and assigning nominees. If
            a person dies, their investments can be transferred to their
            nominee. In case there is no nominee assigned, the family or legal
            heirs will have to go through a long and tedious process to receive
            the investments and assets of the demised person.
          </span>

          <span
            style="margin-bottom: 10px;color: #505869; line-height: 20px; text-align: justify;"
          >
            here could be a long wait and delay in receiving the process in case
            there is no nomination. They would have to produce a will,
            certificate, or other documents proving their position. Such a
            situation can cause delays and strain situations for the unassigned
            nominee or next of kin.
          </span>
          <span
            style="margin-bottom: 10px;color: #505869; line-height: 20px; text-align: justify;"
            >If a nominee is assigned, they would just need to provide their
            address proof, bank details, and ID at the time of claim to have the
            investments transferred to their name.</span
          >
          <span
            style="margin-bottom: 10px;color: #505869; line-height: 20px; text-align: justify;"
            >Therefore, the investment transfer becomes very smooth and
            seamless.
          </span>
          <Title id="over-fullscreen-title" class="modal-header-top"
            ><span class="modal-header-top">Declaration</span></Title
          >
          <span
            style="margin-bottom: 10px; margin-top: 12px; color: #505869; line-height: 20px; text-align: justify;"
          >
            As per RBI guidelines, I confirm that I have been explained about
            the benefits of nomination facility to my SBM bank account. However,
            I state that in spite of the explanation of the said benefits, I do
            not wish to nominate any person to the above mentioned Bank Account.
            I request you to kindly process my account opening form without the
            nomination facility.
          </span>
        </div>
      </div>
    </div>
  </Content>
  <Actions class="modal-button">
    <div
      class="modal-button"
      style=" margin-left: 14px; margin-right: 14px; margin-bottom: 14px; "
    >
      <Buttonlnv
        title="reject"
        onclick={() => {
          modalOpen = false;
        }}
        style="display: flex; justify-content: center;  border: 2px solid #13afe9;  background: #fff; color: #13afe9"
      />
      <Button
        title="i agree"
        onclick={skipNominee}
        style="display: flex; width: 100%; justify-content: center; border: 2px solid #13afe9"
      />
    </div>
  </Actions>
</Dialog>

<WizardBody title="" back="">
  <div class="d-flex align-items-center justify-content-between">
    <WizardHeader style="margin-top:0px;" title="Add Nominee" />
    <div class="flex-shrink-1">
      <Buttonlnv
        title="skip"
        onclick={() => {
          checked = true;
          modalOpen = true;
        }}
      />
    </div>
  </div>
  <div class="nominee-container">
    <div class="input-container">
      <div class="label">Nominee Name</div>
      <div class="input-field">
        <Input
          onchange={(e) => {
            inputValue = e.target.value;
          }}
          type={inputType}
          placeholder="Enter Nominee Name"
          bind:value={nomineeName}
          validate={(value) => {
            return "";
          }}
        />
      </div>
    </div>
    <div class="date-container">
      <div class="label">Date of Birth</div>
      <div class="date-field">
        <DatePicker
          bind:date={nomineeDob}
          placeholder="Enter Date"
          on:change={nomineeDateChanged}
          maxDate={Date()}
        />
      </div>

      <!-- <div class="input-container">
        <input
          class="dob-input-field"
          type="date"
          placeholder="DD/MM/YYYY"
          bind:value={nomineeDob}
          on:change={nomineeDateChanged}
        />
      </div> -->
    </div>
  </div>

  <div class="nominee-container" style="margin-top: 10px;">
    <div class="input-container">
      <div class="label">Phone Number</div>
      <div class="input-field">
        <Input
          onchange={(e) => {
            inputValue = e.target.value;
            nomineePhone = inputValue.replace(/[^0-9]+/g, "");
          }}
          type={"number"}
          placeholder="Enter Nominee Phone"
          bind:value={nomineePhone}
          maxLength="10"
          validate={(value) => {
            return "";
          }}
        />
      </div>
    </div>
  </div>

  <!-- style="display: flex; gap: 20px; width: 100%; margin-top: 24px;" -->
  <div>
    <div class="label my-2">Relationship</div>
    <SegmentedButton
      choices={relationships}
      bind:selected={relationShipValue}
    />
  </div>

  <div>
    <div class="label my-2" style="margin-top: 32px !important">
      Nominee Address
    </div>
    <div style="margin-left: -12px; margin-bottom: 6px;">
      <Checkbox bind:checked={nomineeAddressSameChecked}
        ><div
          style="color: #464448; margin-left: -8px; font-weight: 400; font-size: 12px; line-height: 16px;"
        >
          Same as communication address
        </div></Checkbox
      >
    </div>

    <div class="nominee-container {nomineeAddressSameChecked ? 'hide' : ''}">
      <div class="input-container select-width" style="width: 94%">
        <textarea
          placeholder="Address"
          class="address-textarea"
          rows="4"
          bind:value={nominee_address}
        />
      </div>
    </div>

    <div class="nominee-container {nomineeAddressSameChecked ? 'hide' : ''}">
      <div class="input-container-address">
        <div class="input-field">
          <Input
            onchange={(e) => {
              inputValue = e.target.value;
            }}
            type={inputType}
            placeholder="Landmark"
            bind:value={nominee_landmark}
            validate={(value) => {
              return "";
            }}
          />
        </div>
      </div>

      <div class="input-container-address">
        <div class="input-field">
          <Input
            onchange={(e) => {
              inputValue = e.target.value;
            }}
            type={inputType}
            placeholder="Locality"
            bind:value={nominee_locality}
            validate={(value) => {
              return "";
            }}
          />
        </div>
      </div>
    </div>

    <div class="nominee-container {nomineeAddressSameChecked ? 'hide' : ''}">
      <div class="input-container-address">
        <div class="input-field">
          <Input
            onchange={(e) => {
              inputValue = e.target.value;
            }}
            type={"number"}
            maxLength={6}
            bind:value={nominee_pincode}
            placeholder="Pincode"
            validate={(value) => {
              return "";
            }}
          />
        </div>
      </div>

      <div class="input-container-address">
        <!-- <div class="input-field"> -->
        <!-- <Input
            onchange={(e) => {
              inputValue = e.target.value;
            }}
            type={inputType}
            placeholder="City"
            bind:value={nominee_city}
            validate={(value) => {
              return "";
            }}
          /> -->
        <InputAutocomplete
          bind:inputValue={nominee_city}
          optionsList={cityOptions}
          placeholder="City"
        />
        <!-- </div> -->
      </div>
    </div>

    <div
      class="input-container-address {nomineeAddressSameChecked ? 'hide' : ''}"
    >
      <div
        class="select-width"
        style="width: 100%; background-color: #fff; border-radius: 8px;"
      >
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={nominee_state}
        >
          <option value="" disabled selected>Select your state</option>
          <option value="AD">Andhra Pradesh</option>
          <option value="BR">Bihar</option>
          <option value="CG">Chhattisgarh</option>
          <option value="GA">Goa</option>
          <option value="GJ">Gujarat</option>
          <option value="HR">Haryana</option>
          <option value="HP">Himachal Pradesh</option>
          <option value="JK">Jharkhand</option>
          <option value="KA">Karnataka</option>
          <option value="KL">Kerala</option>
          <option value="MP">Madhya Pradesh</option>
          <option value="MH">Maharashtra</option>
          <option value="OD">Odisha</option>
          <option value="PB">Punjab</option>
          <option value="RJ">Rajasthan</option>
          <option value="TN">Tamil Nadu</option>
          <option value="TS">Telangana</option>
          <option value="UP">Uttar Pradesh</option>
          <option value="UK">Uttarakhand</option>
          <option value="WB">West Bengal</option>
          <option value="AN">Andaman and Nicobar Islands</option>
          <option value="CH">Chandigarh</option>
          <option value="DH">Dadra & Nagar Haveli</option>
          <option value="DD">Daman & Diu</option>
          <option value="DL">Delhi</option>
          <option value="LD">Lakshadweep</option>
          <option value="PY">Puducherry</option>
        </select>
      </div>
    </div>

    <div
      class="mt-5"
      style={showGuardian === true ? `display: block` : `display: none`}
    >
      <div style="margin-bottom:8px;">
        <SubHeader title="Guardian details" />
      </div>
      <div class="text-muted">
        Since you are adding a minor nominee, we will need the guardian details
      </div>
      <div class="nominee-container">
        <div class="input-container">
          <div class="label">Guardian Name</div>
          <div class="input-field">
            <Input
              type={inputType}
              width={inputWidth}
              placeholder="Enter Nominee Guardian Name"
              bind:value={nomineeGName}
            />
          </div>
        </div>
        <div class="input-container">
          <div class="label">Contact</div>
          <div class="input-field">
            <Input
              type={inputType}
              width={inputWidth}
              maxLength={10}
              placeholder="Enter Contact"
              bind:value={nomineeGContact}
            />
          </div>
        </div>
      </div>
      <div>
        <div class="label my-2">Relationship</div>
        <SegmentedButton
          choices={relationshipsG}
          bind:selected={relationShipGValue}
        />
      </div>
    </div>
  </div></WizardBody
>
<WizardActionArea>
  <div style="position: absolute; bottom: 40px; left: 20px; right: 20px;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <div class="button">
    <div class="submit-button">
      <Button
        onclick={() => submitForm()}
        title="Add Nominee"
        disabled={inputValue.length <= 0 ||
          nomineeName.length < 1 ||
          !nomineeDob ||
          nomineeDob.length < 1 ||
          nomineePhone.length < 1}
      />
    </div>
  </div>
</WizardActionArea>

<!-- <Dialog fullscreen bind:open={modalOpen}>
  <Content>
    <h2 class="nominee-modal-heading">Who Is A Nominee?</h2>
    <p class="nominee-modal-desc">
      If an investor dies, the individual or individuals who will receive the
      proceeds are called a nominee or nominees. The nominee is a beneficiary or
      the recipient of the assets, funds, and investments.
    </p>

    <h2 class="nominee-modal-heading">Importance of Nomination</h2>

    <p class="nominee-modal-desc">
      There is a key reason behind nominations and assigning nominees. If a
      person dies, their investments can be transferre-d to their nominee. In
      case there is no nominee assigned, the family or legal heirs will have to
      go through a long and tedious process to receive the investments and
      assets of the demised person.
    </p>
    <p class="nominee-modal-desc">
      here could be a long wait and delay in receiving the process in case there
      is no nomination. They would have to produce a will, certificate, or other
      documents proving their position. Such a situation can cause delays and
      strain situations for the unassigned nominee or next of kin.
    </p>
    <p class="nominee-modal-desc">
      If a nominee is assigned, they would just need to provide their address
      proof, bank details, and ID at the time of claim to have the investments
      transferred to their name.
    </p>
    <p class="nominee-modal-desc">
      Therefore, the investment transfer becomes very smooth and seamless.
    </p>

    <div class="checkbox">
      <Checkbox bind:checked={checkbox} />
      <p class="nominee-modal-checkbox">
        I confirm that I have been explained about the benefits of nomination
        facility to my SBM bank account. However, I state that in spite of the
        explanation of the said benefits, I do not wish to nominate any person
        to the above mentioned Bank Account. I request you to kindly process my
        account opening form without the nomination facility.
      </p>
    </div>
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
    <Button title="I agree" onclick={skipNominee} />
  </Content>
</Dialog> -->
<style>
  .modal-header-top {
    font-family: "Urbanist", sans-serif !important;
  }
  /* i {
    position: absolute;
    padding: 18px;
    text-align: center;
    right: 0%;
  } */

  /* .dob-input-field {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
  } */

  .address-textarea {
    background: #ffffff;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    resize: none;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    padding: 20px;
    display: flex;
    height: 100%;
    row: 4;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
  }

  .state-select {
    position: relative;

    padding: 20px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/images/select-caret-rx.png") no-repeat 100%;
    background-attachment: cover;
    border: 0;
    padding-right: 30px !important;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  }

  .label {
    height: 16px;
    margin-bottom: 16px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
  }

  * :global(.mdc-card) {
    background: #f8f8f9;
    border: 1px solid #d1d5dc;
    border-radius: 4px;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 16px 8px 16px;
    color: #090a0c;
  }

  .button {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  /* .checkbox {
    display: flex;
    align-items: flex-start;
  } */

  @media only screen and (min-width: 768px) {
    .state-select {
      width: 185%;
    }
    .nominee-container {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
    }

    .submit-button {
      width: 200%;
    }

    .date-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-top: 22px;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-start;
      margin-top: 14px;
    }

    .input-field {
      margin-left: -12px;
    }

    .button {
      margin-left: 10px;
    }

    /* .checkbox {
      margin-left: -8px;
    } */

    .modal-button {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .state-select {
      width: 98%;
    }
    .submit-button {
      width: 100%;
    }

    .select-width {
      width: 100%;
    }

    .button {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin-left: 1px;
    }

    .date-container {
      margin-top: 26px;
    }

    .input-container {
      width: 100%;
      margin-bottom: 20px;
    }

    .input-field {
      margin-left: -12px;
      width: 107%;
    }

    .text-muted {
      margin-bottom: 30px;
    }

    /* .nominee-modal-heading {
      font-size: 16px;
      line-height: 16px;
    }

    .nominee-modal-desc {
      font-size: 12px;
      line-height: 16px;
    }

    .nominee-modal-checkbox {
      font-size: 11px;
      line-height: 14px;
    }

    .checkbox {
      margin-left: -14px;
    } */

    .modal-button {
      margin-top: 0.125rem;
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .input-container-address {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-top: 26px;
    }

    .input-field {
      width: 106%;
      margin-left: -12px;
    }

    .select-width {
      width: 100% !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .input-container-address {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-start;
    }

    .input-field {
      width: 108%;
      margin-left: -10px;
    }
  }
  .hide {
    display: none;
  }
</style>
