<script>
  export let title = "";
  export let style = "";
</script>

{#if title !== ""}
  <span class="main-header py-3" {style}>{title}</span>
{/if}

<style>
  /* .main-header {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 48px;
    color: #1e3868;
    order: -1;
  } */

  @media only screen and (min-width: 768px) {
    .main-header {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      color: #1e3868;
      order: -1;
      margin-top: 6px;
    }
  }

  @media only screen and (max-width: 768px) {
    .main-header {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #1e3868;
      order: -1;
      /* width: 21ch; */
      margin-bottom: 0.2rem;
      margin-top: 24px;
    }
  }
</style>
