<script>
  import { request } from "../../services/network";
  import { onMount } from "svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  let error_message;
  let error_type;

  onMount(async () => {
    request("/api/user/error", "GET").then((response) => {
      error_message = response.error_message;
      error_type = response.error_type;
    });
  });
</script>

<WizardBody title="" back="">
  <div class="text-container">
    <div class="text">
      <h3>
        {error_message}
        {#if error_type === "CUSTOMER_DUPLICATE"}
          by
          <a
            href="https://www.sbmbank.co.in/aboutus/login.php"
            target="_blank"
            rel="noreferrer"
            style="text-decoration: underline; color: #007bff;"
          >
            clicking here
          </a>
        {/if}
      </h3>

      {#if error_type === "CUSTOMER_DUPLICATE"}
        <p>
          Kindly call our toll free number 1800 1033 817 or write to us at
          customercare@sbmbank.co.in for any further assistance.
        </p>
      {:else}
        <p>
          Kindly call our toll-free number 1800 1033 817 or write to us at
          customercare@sbmbank.co.in so that we may assist with your application
          for your account.
        </p>
        <p>
          You may also visit the nearest SBM Bank Branch with Officially Valid
          Documents (OVD's), as prescribed by RBI for your application for your
          account.
        </p>
      {/if}
    </div>
  </div></WizardBody
>

<style>
  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .text {
    text-align: center;
  }
</style>
