<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
</script>

<WizardBody title="Savings Account" back="/terms-conditions">
  <p>Last updated on 27<sup>th</sup> December 2022.</p>
  <h2>Introduction and Terms of Use</h2>
  <p>
    The terms and conditions contained hereinafter ("<strong
      >TERMS OF USE</strong
    >") shall apply to the use of the website&nbsp;<a
      href="https://www.sbmbank.co.in/"
      target="_blank"
      rel="noreferrer">https://www.sbmbank.co.in/</a
    >
    and any other linked pages, products, software(s), API keys, features, content
    or application services (including but without limitation to any mobile application
    services) in connection therewith, offered from time to time by SBM Bank (India)
    Limited ("<strong>SBM BANK (INDIA) LIMITED</strong>" or "<strong>WE</strong
    >" or "<strong>OUR</strong>" or "<strong>US</strong>") (collectively, "<strong
      >WEBSITE</strong
    >").
  </p>
  <p>
    Any person logging on to or using the Website (even when such person does
    not avail of any services provided in the Website ("<strong>SERVICES</strong
    >")) (hereinafter referred to as a "<strong>USER</strong>", "<strong
      >SELLER</strong
    >", "<strong>YOU</strong>" or "<strong>CLIENT</strong>") shall be presumed
    to have read these Terms of Use (which includes the Privacy Policy,
    separately put up on the Website) and unconditionally and irrevocably
    accepted the terms and conditions set out herein (these Terms of Use). These
    Terms of Use, together with the rest of the Policies (defined below),
    constitute a binding and enforceable agreement between the User and SBM Bank
    (India) Limited. These Terms of Use do not alter in any way the terms or
    conditions of any other written agreement you may have with SBM Bank (India)
    Limited for other services.
  </p>
  <p>
    THE USER UNDERSTANDS AND UNCONDITIONALLY ACKNOWLEDGES THAT EVEN THOUGH THE
    USER MAY BE ALLOWED TO EXECUTE TRANSACTIONS ON THE PLATFORM OF SBM BANK
    (INDIA) LIMITED, THE FUNDS SHALL NOT BE SETTLED TO THE ACCOUNT OF SUCH USER
    PENDING COMPLETION OF KYC OBLIGATIONS ON THE PART OF THE USER IN ACCORDANCE
    WITH THE RBI'S KYC GUIDELINES (DEFINED BELOW). FURTHER, UPON NON-COMPLETION
    OF KYC OBLIGATIONS ON THE PART OF THE USER, AS MENTIONED ABOVE, TO THE
    SATISFACTION OF SBM BANK (INDIA) LIMITED, WE RESERVE THE RIGHT TO NOT
    RELEASE THE SETTLEMENT AMOUNTS TO THE USER AND MAY EVENTUALLY REVERSE THE
    FUNDS TO THE ACCOUNT FROM WHERE SUCH PAYMENT ORIGINATED.
  </p>
  <p>
    Please read and understand the terms set out hereunder carefully before
    agreeing to the same. If you do not agree to these Terms of Use (including
    any referenced policies or guidelines), please immediately terminate your
    use of the Website. You can accept the Terms of Use by:
  </p>
  <ul>
    <li>
      Clicking to accept or agree to the Terms of Use, where this option is made
      available to you by SBM Bank (India) Limited in the User interface for any
      particular Service; or
    </li>
    <li>
      Accessing, testing or actually using the Services. In this case, you
      understand and agree that SBM Bank (India) Limited will treat your use of
      the Services as acceptance of the Terms of Use from that point onwards.
    </li>
  </ul>
  <p>
    For the purpose of these Terms of Use, wherever the context so requires, the
    term "User" shall mean and include any natural or legal person who has
    agreed to these Terms of Use on behalf of itself or any other legal entity.
  </p>
  <p>
    It is clarified that the Privacy Policy (that is provided separately), form
    an integral part of these Terms of Use and should be read contemporaneously
    with the Terms of Use. Illegality, invalidity or unenforceability of one or
    more provisions of these Terms of Use shall not affect the legality,
    validity or enforceability of the other terms of the Terms of Use. For
    avoidance of doubt, if any of the provisions becomes void or unenforceable,
    the rest of the provisions of these Terms of Use shall be binding upon the
    User.
  </p>
  <p>
    The Terms of Use may be revised or altered by us at our sole discretion at
    any time without any prior intimation to the User. The latest Terms of Use
    will be posted on Bank&rsquo;s Website. Any such changes by SBM Bank (India)
    Limited will be effective immediately. By continuing to use this Website or
    to access the Services / usage of our Services after changes are made, you
    agree to be bound by the revised/ amended Terms of Use and such amendments
    shall supersede all other terms of use previously accepted by the User. You
    are solely responsible for understanding and complying with all applicable
    laws of your specific jurisdiction, including but not limited to the
    provisions of the RBI Guidelines on Regulation of Payment Aggregators and
    Payment Gateways, Payment and Settlement Systems Act, 2007, Prevention of
    Money Laundering Act, 2002, Know Your Customer (KYC) / Anti-Money Laundering
    (AML) / Combating Financing of Terrorism (CFT) guidelines issued by the
    Department of Regulation, RBI (the "<strong>KYC GUIDELINES</strong>") etc.,
    that may be applicable to you in connection with your business and use of
    our Services.
  </p>
  <h2>Provision of the Services being offered by SBM Bank (India) Limited</h2>
  <p>
    Subject to these Terms of Use, Merchant Onboarding Policy, KYC Policy,
    Disputes and Grievance Redressal Policy and Privacy Policy (collectively, "<strong
      >POLICIES</strong
    >"), SBM Bank (India) Limited offers the Services set forth in Schedule I
    herein.
  </p>
  <p>
    Schedule I hereof also contains descriptions and process flows of all
    Services that are offered to Users. These descriptions and process flows are
    set out in Part A to Part G in Schedule I.
  </p>
  <p>
    SBM Bank (India) Limited is constantly evolving in order to provide the best
    possible experience and information to its Users. You, understand,
    acknowledge and agree that the form and nature of the Services which SBM
    Bank (India) Limited provides may change from time to time without any prior
    notice to you.
  </p>
  <p>
    As part of this continuing process, you, understand, acknowledge and agree
    that SBM Bank (India) Limited may stop (permanently or temporarily)
    providing the Services (or any features within the Services) to you or to
    Users generally at SBM Bank (India) Limited's sole discretion, without any
    prior notice. You may stop using the Services at any point of time. You do
    not need to specifically inform SBM Bank (India) Limited when you stop using
    the Services.
  </p>
  <p>
    You agree, understand and acknowledge that the opening and maintenance of
    any account with SBM Bank (India) Limited is subject to rules and
    regulations introduced or amended from time to time by the Reserve Bank of
    India and other applicable laws.
  </p>
  <p>
    You agree, understand and acknowledge that SBM Bank (India) Limited may, at
    its discretion, engage the services of business correspondents (hereinafter
    referred to as "<strong>BC</strong>") for extension of banking and financial
    services so as to ensure greater financial inclusion and increasing the
    outreach of the banking sector.
  </p>
  <p>
    You agree to preserve your cheque book/ATM card carefully. In case of
    loss/theft of the same You shall immediately inform SBM Bank (India) Limited
    in writing.
  </p>
  <p>
    You acknowledge and agree that if SBM Bank (India) Limited disables access
    to your account, you may be prevented from accessing the Services, your
    account details or any files or other content which is contained in your
    account, and SBM Bank (India) Limited shall intimate you regarding the same.
  </p>
  <p>
    SBM Bank (India) Limited reserves the right to delete your User information
    stored in your account including but not limited to all or any personal
    information or any sensitive personal data or information ("<strong
      >SPDI</strong
    >") stored in your User account. Alternately, a User may notify us if they
    do not wish that we retain or use the personal information or SPDI by
    contacting the Grievance Officer (as provided below). However, in such a
    case, we may not be able to provide you some or all of our Services.
  </p>
  <p>
    You acknowledge and agree that while SBM Bank (India) Limited may not
    currently have set a fixed upper limit on the number of transmissions you
    may send or receive through the Services, such fixed upper limits may be set
    by SBM Bank (India) Limited at any time, solely at SBM Bank (India)
    Limited's discretion.
  </p>
  <p>
    By using our Services, you agree that SBM Bank (India) Limited disclaims any
    liability or authenticity of any information that may have become outdated
    since the last time that particular piece of information was updated. SBM
    Bank (India) Limited reserves the right to make changes and corrections to
    any part of the content of this Website at any time without any prior notice
    to you. Unless stated otherwise, all pictures and information contained on
    this Website are believed to be in the public domain as either promotional
    materials, publicity photos, photoshoot rejects or press media stock. Please
    contact the Grievance Officer by an e-mail if you are the copyright owner of
    any content on this Website and you think the use of the above material
    violates the terms of the applicable Copyright law in any manner. In your
    request, please indicate the exact URL of the webpage to enable us to locate
    the same. We will endeavour to address your concerns and take necessary
    steps, if required. Please note that all images displayed on the Website
    have been digitised by SBM Bank (India) Limited. No other party is
    authorised to reproduce or republish these digital versions in any format
    whatsoever without the prior written permission of SBM Bank (India) Limited.
  </p>
  <p>
    SBM Bank (India) Limited acknowledges and represents that it is and shall
    remain Payment Card Industry Data Security Standard ("<strong
      >PCI DSS</strong
    >") certified. Further, SBM Bank (India) Limited shall maintain compliance
    with PCI DSS Standard or other comparable industry standards governing
    physical/ logical security cardholder data across SBM Bank (India) Limited
    environment and ensure that the card holder's data is secured in accordance
    with the standards.
  </p>
  <h2>Use of the Services by User</h2>
  <p>
    In order to access certain Services, you may be required to open a User
    account with SBM Bank (India) Limited by providing information about
    yourself (such as identification or contact details) as part of the
    registration process ("<strong>REGISTRATION DATA</strong>") for the
    Services, or as part of your continued use of the Services. You agree that
    any Registration Data you give to SBM Bank (India) Limited will always be
    accurate, correct, complete and up to date. If you provide any information
    that is untrue, inaccurate, incomplete, or not current or if we have
    reasonable grounds to suspect that such information is in violation of
    applicable law or not in accordance with the Terms of Use (whether wholly or
    in part), we reserve the right to reject your registration and/ or
    indefinitely suspend or terminate your User account and refuse to provide
    you access to the Website. Further, you agree to indemnify and keep us
    indemnified from and against all claims resulting from the use of any
    detail/ information/ Registration Data that you post and/ or supply to us.
    We shall be entitled to remove any such detail/ information/ Registration
    Data posted by you without any prior intimation to you.
  </p>
  <p>
    Notwithstanding anything else contained in any other agreement involving you
    and SBM Bank (India) Limited and/ or any other third party, in order to
    ensure that we are not violating any right that you might have in your
    Registration Data, you hereby grant to us a non-exclusive, worldwide,
    perpetual, irrevocable, royalty-free, sub-licensable right to exercise the
    copyright, publicity, and database rights (but no other rights) that you
    have in the Registration Data, in any media now or in future known, with
    respect to your Registration Data solely to enable us to use such
    Registration Data that you have supplied to us.
  </p>
  <p>
    Any amendment or rectification of your Registration Data in the User account
    can be carried out by accessing the "User account" section on the Website.
    You may choose to delete any or all of your User content/ information or
    even the User account at any time. Processing such deletion may take some
    time, but the same shall be done by SBM Bank (India) Limited. We may
    maintain backup of all User content for such time as may be required under
    applicable laws and for operational purposes of SBM Bank (India) Limited.
    You are solely responsible for maintaining the confidentiality of your
    account and password and for any activity that occurs in or through your
    account. We will not be liable to any person for any loss or damage which
    may arise as a result of any failure on your part to protect your login ID
    or password or any other credential pertaining to your account. You should
    take all necessary steps to ensure that the password is kept confidential
    and secure. In case you have any reason to believe that your password has
    become known to anyone else, or if the password is being, or is likely to
    be, used in an unauthorised manner, you should inform us immediately
    at&nbsp;<a href="mailto:customercare@sbmbank.co.in" target="_blank"
      >customercare@sbmbank.co.in</a
    > (Help Line / Customer Care Number 1800 1033 817). In the event of any dispute
    between two or more parties as to ownership of any particular account with SBM
    Bank (India) Limited, you agree that SBM Bank (India) Limited shall be the sole
    arbitrator for such dispute and that SBM Bank (India) Limited's decision in this
    regard will be final and binding on you.
  </p>
  <p>
    You understand and undertake that you shall be solely responsible for your
    Registration Data and User content and undertake to, neither by yourself nor
    by permitting any third party to host, display, upload, modify, publish,
    transmit, update or share any information that:
  </p>
  <ul>
    <li>
      Belongs to another person and to which you do not have any right to;
    </li>
    <li>
      Is grossly harmful, harassing, blasphemous, defamatory, obscene,
      pornographic, paedophilic, seditious, libellous, invasive of another's
      privacy, hateful, or racially, ethnically objectionable, disparaging,
      relating or encouraging money laundering or gambling, or otherwise
      unlawful in any manner whatsoever;
    </li>
    <li>Harms minors in any way;</li>
    <li>
      Infringes any patent, trademark, copyright or other proprietary rights of
      any person or entity anywhere in the world;
    </li>
    <li>Violates any law for the time being in force;</li>
    <li>
      Deceives or misleads the addressee about the origin of such messages or
      communicates any information which is grossly offensive or menacing in
      nature;
    </li>
    <li>Impersonates another person;</li>
    <li>
      Contains software viruses or any other computer code, files or programs
      designed to interrupt, destroy or limit the functionality of any computer
      resource;
    </li>
    <li>
      Threatens the unity, integrity, defence, security or sovereignty of India,
      friendly relations with foreign states, or public order or causes
      incitement to the commission of any cognizable offence or prevents
      investigation of any offence or is insulting to any other nation; or
    </li>
    <li>Is illegal in any other way.</li>
  </ul>
  <p>
    You agree and understand that SBM Bank (India) Limited reserves the right to
    remove and/or edit such detail/ information. If you come across any
    information as mentioned above on the Website, you are requested to
    immediately contact our Grievance officer.
  </p>
  <p>
    You agree to use the Services only for purposes that are permitted by (a)
    these Terms of Use and (b) any applicable law, regulation or generally
    accepted practices or guidelines in the relevant jurisdictions.
  </p>
  <p>
    You agree to use the data owned by SBM Bank (India) Limited (as available on
    the Website or through any other means like API(s) etc.) only for personal
    purposes and not for any commercial use unless agreed to by SBM Bank (India)
    Limited in writing.
  </p>
  <p>
    You agree not to access (or attempt to access) any of the Services by any
    means other than through the interface that is provided by SBM Bank (India)
    Limited, unless you have been specifically allowed to do so in a separate
    agreement with SBM Bank (India) Limited. You specifically agree not to
    access (or attempt to access) any of the Services through any automated
    means (including use of scripts or web crawlers) and shall ensure that you
    comply with the instructions set out in any robots.txt file present on the
    Services.
  </p>
  <p>
    You agree that you will not engage in any activity that interferes with or
    disrupts the Services (or the servers and networks which are connected to
    the Services) on this Website.
  </p>
  <p>
    Unless you have been specifically permitted to do so in a separate agreement
    with SBM Bank (India) Limited, you agree that you will not reproduce,
    duplicate, copy, sell, trade or resell the Services for any purpose.
  </p>
  <p>
    You agree that you are solely responsible for (and that SBM Bank (India)
    Limited has no responsibility to you or to any third party for) any breach
    of your obligations under the Terms of Use and for the consequences
    (including any loss or damage which SBM Bank (India) Limited may suffer) of
    any such breach. You further agree to the use of your data by us in
    accordance with the Privacy Policy.
  </p>
  <p>
    SBM Bank (India) Limited may share any Content (<em>defined hereinafter</em
    >) generated by the User or their Registration Data with governmental and
    regulatory agencies who are lawfully authorised for investigative,
    protective and cyber security activities. Such information may be
    transferred for the purposes of verification of identity, or for prevention,
    detection, investigation, prosecution pertaining to cyber security incidents
    and punishment of offences under any law for the time being in force.
  </p>
  <p>
    If you have opted for use of SBM Bank (India) Limited's 'subscriptions'
    product by virtue of which your customers have set up a standing instruction
    ("<strong>RECURRING PAYMENT INSTRUCTION</strong>") to charge his/ her chosen
    payment method (such as credit card, debit card or bank account) as per the
    billing cycle communicated by you to SBM Bank (India) Limited, then you
    consent that the relevant amount will be charged to such payment method as
    per the billing cycle communicated to SBM Bank (India) Limited. You agree
    that SBM Bank (India) Limited shall continue to charge the relevant amount
    to the relevant customer's chosen payment method as per such billing cycle
    until you or the customer terminates the Recurring Payment Instruction.
  </p>
  <p>
    You further agree that if the customer revokes his/ her consent to the
    Recurring Payment Instruction then you shall inform us of the revocation in
    writing for us to cease processing the Recurring Payment Instruction
    forthwith upon the revocation but no later than 7 (seven) days prior to the
    next instance of charge to the customer. If such intimation of revocation of
    Recurring Payment Instruction is made less than 7 (seven) days prior to the
    next instance of charge to the customer or is not made at all, SBM Bank
    (India) Limited shall not be liable for any charge applied to the customer
    for that month pursuant to the revocation or not be liable at all, as
    applicable. You agree to make good any losses suffered by us on account of
    demands or claims from customers arising as a consequence of your failure in
    notifying us about a customer's revocation of the Recurring Payment
    Instruction.
  </p>
  <h2>Eligibility</h2>
  <p>
    Any person who is above eighteen (18) years of age and competent to contract
    under the applicable laws is eligible to access or visit the Website or
    avail the Services displayed therein. Your use or access of the Website
    shall be treated as your representation that you are eligible, competent to
    contract and if you are registering as a business entity, then you represent
    and warrant that you have the authority to bind such business entity to the
    Terms of Use. Without generality of the foregoing, use of the Website is
    available only to persons who can form a legally binding contract under the
    Indian Contract Act, 1872 and any amendments or replacements thereto.
  </p>
  <p>
    The User represents and warrants that it will be financially responsible for
    all of User's usage (including the purchase of any Service) and access of
    the Website. The User shall also be responsible for use of User's account by
    others. The Terms of Use shall be void where prohibited by applicable laws,
    and the right to access the Website shall automatically stand revoked in
    such cases.
  </p>
  <h2>Content in the Services</h2>
  <p>
    For the purposes of these Terms of Use, the term "<strong>CONTENT</strong>"
    includes, without limitation, information, data, text, logos, photographs,
    videos, audio clips, animations, written posts, articles, comments,
    software, scripts, graphics, themes and interactive features generated,
    provided or otherwise made accessible on or through the Services.
  </p>
  <p>
    You should be aware that Content presented to you as part of the Services,
    including but not limited to advertisements in the Services and sponsored
    Content within the Services may be protected by intellectual property rights
    which are owned by the sponsors or advertisers who provide that Content to
    SBM Bank (India) Limited (or by other persons or companies on their behalf).
    You may not modify, rent, lease, loan, sell, distribute or create derivative
    works based on this Content (either in whole or in part) unless you have
    been specifically told that you may do so by SBM Bank (India) Limited or by
    the owners of that Content, in writing and in a separate agreement.
  </p>
  <p>
    SBM Bank (India) Limited reserves the right (but shall have no obligation)
    to pre-screen, review, flag, filter, modify, refuse or remove any or all
    Content from any Service.
  </p>
  <p>
    SBM Bank (India) Limited reserves the right to moderate, publish,
    re-publish, and use all User generated contributions and comments (including
    but not limited to reviews, profile pictures, comments, likes, favourites,
    votes) posted on the Website as it deems appropriate (whether in whole or in
    part) for its product(s), whether owned or affiliated. SBM Bank (India)
    Limited is not liable to pay royalty to any User for re-publishing any
    content across any of its platforms.
  </p>
  <p>
    If you submit any material on the Website, you agree thereby to grant SBM
    Bank (India) Limited the right to use, moderate, publish any such work
    worldwide for any of its product(s), whether owned or affiliated.
  </p>
  <p>
    You understand that by using the Services you may be exposed to Content that
    you may find offensive, indecent or objectionable and that, in this respect,
    your use of the Services will be at your own risk.
  </p>
  <p>
    You agree that you are solely responsible for (and that SBM Bank (India)
    Limited has no responsibility to you or to any third party for) any Content
    that you create, transmit or display while using the Services and for the
    consequences of your actions (including any loss or damage which SBM Bank
    (India) Limited may suffer) by doing so.
  </p>
  <h2>Proprietary Rights</h2>
  <p>
    You acknowledge and agree that SBM Bank (India) Limited (or SBM Bank (India)
    Limited's licensors) owns all legal and proprietary right, title and
    interest in and to the Services, including any intellectual property rights
    which subsist in the Services (whether those rights happen to be registered
    or not, and wherever in the world those rights may exist). You further
    acknowledge that the Services may contain information which is designated
    confidential by SBM Bank (India) Limited and that you shall not disclose
    such information without SBM Bank (India) Limited's prior written consent.
  </p>
  <p>
    Unless you have agreed otherwise in writing with SBM Bank (India) Limited,
    nothing in the Terms of Use gives you a right to use any of SBM Bank (India)
    Limited's trade names, trademarks, service marks, logos, domain names, and
    other distinctive brand features.
  </p>
  <p>
    Unless you have been expressly authorized to do so in writing by SBM Bank
    (India) Limited, you agree that in using the Services, you will not use any
    trade mark, service mark, trade name, logo of any company or organization in
    a way that is likely or intended to cause confusion about the owner or
    authorized User of such marks, names or logos.
  </p>
  <h2>Compliance with anti-bribery and anti-corruption laws</h2>
  <p>
    User agrees and unconditionally undertakes to comply with all applicable
    commercial and public anti-bribery and anti-corruption laws (including but
    not limited to the provisions of Foreign Corrupt Practices Act, 1977, UK
    Bribery Act, 2010, Prevention of Corruption Act, 1988, Prevention of Money
    Laundering Act, 2002, Foreign Contribution (Regulation) Act, 2010, and any
    amendments thereto) which prohibit the User, its/ his/ her officials,
    representatives, agents or any other person associated with or acting on
    behalf of such User from giving, offering, promising to offer, receiving/
    accepting or acting in any other manner so as to induce a payment, gift,
    hospitality or anything of value (either directly or indirectly) whether
    from within the country or from abroad to government officials, publics
    servants, regulatory bodies, judicial authorities, persons in positions of
    authority, elected or contesting electoral candidates, political parties or
    office bearers thereof or any other third party or person in order to obtain
    an improper commercial/business advantage of any kind. Government Officials
    include any government employee, candidate for public office, an employee of
    government - owned or government -- controlled companies, public
    international organisation and political parties. User also agrees not to
    give, offer, pay, promise or authorise to give or pay, directly or through
    any other person, anything of value to anybody for the purpose of inducing
    or rewarding any favourable action or influencing a decision in favour of
    the User. The User also unconditionally agrees and undertakes that it is
    compliant with and shall do/ undertake all acts necessary to continue to be
    compliant with the provisions of 'The Know Your Customer (KYC) / Anti-Money
    Laundering (AML) / Combating Financing of Terrorism (CFT) guidelines issued
    by the Department of Regulation', RBI, as amended from time to time.
  </p>
  <h2>Compliance with mandates of the OFAC</h2>
  <p>
    User undertakes that it shall, during the use of the Services, be in
    compliance with the mandates of OFAC and acknowledges that the User has not
    directly or indirectly lent, contributed or otherwise made available funds
    to any of its affiliates, joint venture partners or any other person or
    entity for the purpose of financing the activities of any person currently
    subject to the OFAC Specially Designated Nationals List (SDN), Consolidated
    Sanctions List and the Additional OFAC Sanctions List, as amended from time
    to time. For the purpose of this paragraph 7, OFAC means the Office of
    Foreign Assets Control constituted under the laws of the United States of
    America.
  </p>
  <h2>Exclusion of Warranties</h2>
  <p>
    Nothing in these Terms of Use, including this paragraph, shall exclude or
    limit your warranty or liability for losses unless they are excluded or
    limited by applicable law. Some jurisdictions do not allow the exclusion of
    certain warranties or conditions or the limitation or exclusion of liability
    for loss or damage caused by negligence, breach of contract or breach of
    implied terms, or incidental or consequential damages. Accordingly, only the
    limitations which are lawful in your jurisdiction will apply to you and our
    liability will be limited to the maximum extent permitted by law. SBM Bank
    (India) Limited disclaims any implied warranty for Services and any use
    thereof.
  </p>
  <p>
    You expressly understand and agree that your use of the Services is at your
    sole risk and that the Services are provided on an "as is" and "as
    available" basis.
  </p>
  <p>
    In particular, SBM Bank (India) Limited, its subsidiaries and affiliates,
    and its licensors do not represent or warrant to you that:
  </p>
  <ul>
    <li>
      The information or Contents provided on the Website are accurate, complete
      and updated;
    </li>
    <li>Your use of the Services will meet your requirements;</li>
    <li>
      Your use of the Services will be uninterrupted, timely, secure or free
      from error;
    </li>
    <li>
      Any information obtained by you as a result of your use of the Services
      will be accurate or reliable; and
    </li>
    <li>
      That defects in the operation or functionality of any software provided to
      you as part of the Services will be corrected.
    </li>
  </ul>
  <p>
    Any material downloaded or otherwise obtained through the use of the
    Services is done at your own discretion and risk and that you will be solely
    responsible for any damage to your computer system or other device or loss
    of data that results from the download of any such material.
  </p>
  <p>
    No advice or information, whether oral or written, obtained by you from SBM
    Bank (India) Limited or from the use of Services shall create any warranty
    not expressly stated in these Terms of Use.
  </p>
  <p>
    SBM Bank (India) Limited further expressly disclaims all warranties and
    conditions of any kind, whether express or implied, including, but not
    limited to the implied warranties and conditions of merchantability, fitness
    for a particular purpose and non-infringement.
  </p>
  <h2>Representations and Warranties of User/ seller</h2>
  <p>The User/ seller hereby represents and warrants:</p>
  <ul>
    <li>
      That User/ seller, in case of a natural person, is at least 18 years old
      with a conscious mind fit and proper to enter into this agreement (the
      "Policies"), is a resident of India with valid credentials and is an
      entity who is legally eligible to carry out or operate a business in
      India;
    </li>
    <li>
      That the all the information and documents pertaining to his/ her identity
      and address proof, as submitted for the purpose of know your client (KYC)
      verification with SBM Bank (India) Limited are true and genuine and are
      not fabricated or doctored in any way whatsoever; That the User shall hold
      and keep SBM Bank (India) Limited, its promoters, directors, employees,
      officials, agents, subsidiaries, affiliates and representatives harmless
      from any liabilities arising in connection with any incidental or
      intentional discrepancy that is found to be there in the documents
      submitted by such User for the purpose of KYC formalities;
    </li>
    <li>
      That any incidental or upfront liability arising in connection with
      User's/ seller's KYC formalities for the purpose of availing the services
      of SBM Bank (India) Limited shall be the absolute responsibility and
      repercussion of the User and neither SBM Bank (India) Limited nor any of
      its affiliates or office bearers shall be responsible in any way for any
      reason including for ascertaining the veracity of the KYC documents
      submitted by such User with SBM Bank (India) Limited;
    </li>
    <li>
      That User/ seller shall be solely responsible for understanding and
      complying with any and all applicable laws relevant to the User and their
      business, and any liability, whether pecuniary or otherwise, arising from
      any non-compliance of such applicable laws shall be at the sole cost and
      risk of such User;
    </li>
    <li>
      That the User shall ensure that its IT systems and infrastructure are
      compliant with the mandates of PCI-DSS and Payment Application-Data
      Security Standard, as applicable to it;
    </li>
    <li>
      That the User/ seller shall operate and conduct his/ her business as per
      declaration provided by such User to SBM Bank (India) Limited at the time
      of onboarding of such User by SBM Bank (India) Limited and shall promptly
      report any change/ deviation/ addition/ deletion in the scope of business
      activities of such User to SBM Bank (India) Limited;
    </li>
    <li>
      That all data, information, inventions, intellectual properties (including
      patents, trademarks, copyrights, design and trade secrets), know-how, new
      uses and processes, and any other intellectual property right, asset or
      form, including, but not limited to, analytical methods, procedures and
      techniques, research, procedure manuals, financial information, computer
      technical expertise, software for the purpose of availing of services of
      SBM Bank (India) Limited and any updates or amendments thereto is and
      shall be the sole intellectual property of SBM Bank (India) Limited and
      should in no way be construed to grant any rights and/ or title to the
      User in such intellectual property of SBM Bank (India) Limited;
    </li>
    <li>
      That User/ seller shall not store any customer card and such related date
      in any form or manner whatsoever on their websites/ servers;
    </li>
    <li>
      That it shall be the exclusive responsibility of the User to ensure that
      the correct line of business of the User is declared under merchant
      category code (MCC) pertaining to the User and that SBM Bank (India)
      Limited reserves the right to withhold settlements and/ or suspend
      transactions of the User in case of any mismatch or violation in its MCC
      declaration; and
    </li>
    <li>
      That the User/ seller shall accord adequate and timely co-operation in
      allowing SBM Bank (India) Limited or
    </li>
  </ul>
  <p>
    it's appointed agencies or regulators to conduct audits, including for
    compliance with the Policies and provisions of applicable laws.
  </p>
  <h2>Indemnity</h2>
  <p>
    The User shall indemnify and hold SBM Bank (India) Limited, its
    subsidiaries, affiliates, promoters, directors, employees, contractors,
    licensors and agents and any other related or third parties involved with
    SBM Bank (India) Limited in any manner whatsoever, harmless from and against
    all losses arising from claims, demands, actions or other proceedings as a
    result of:
  </p>
  <ul>
    <li>
      Fraud, negligence and wilful misconduct by the User in the use of the
      Services;
    </li>
    <li>
      Violation of applicable laws in the use of the Services and/ or in the
      conduct of the business of the User, including but not limited to the
      legal provisions mentioned under paragraphs 6 and 7 hereinabove;
    </li>
    <li>
      Breach of the User's confidentiality obligations under these Terms of Use;
    </li>
    <li>
      Disputes raised by a User's customer in relation to a transaction where
      such dispute is not attributable to the Services;
    </li>
    <li>
      Penalties, fines, charges, or any other actions as a result of breach or
      violation of any the User's representations and warranties; and
    </li>
    <li>
      Fines, penalties and charges imposed by the Acquiring Bank, Card Payment
      Networks or any Governmental Authority on account of transactions on the
      User's website or platform that are in violation of applicable law.
    </li>
  </ul>
  <h2>Limitation of Liability</h2>
  <p>
    Subject to the overall provisions stated above, you expressly understand and
    agree that SBM Bank (India) Limited, its subsidiaries, affiliates,
    promoters, directors, employees, agents and licensors shall not be liable to
    you for:
  </p>
  <ul>
    <li>
      Any direct, indirect, incidental, special, consequential, punitive or
      exemplary damages which may be incurred by you, however caused and under
      any theory of liability. This shall include, but not be limited to, any
      loss of profit (whether incurred directly or indirectly), any loss of
      goodwill or business reputation, any loss of data suffered, cost of
      procurement of substitute goods or Services, or other intangible loss;
    </li>
    <li>
      Any loss or damage which may be incurred by you, including but not limited
      to loss or damage as a result of any reliance placed by you on the
      completeness, accuracy or existence of any advertising, or as a result of
      any relationship or transaction between you and any advertiser or sponsor
      whose advertisement appears on the Services;
    </li>
    <li>
      The deletion of, corruption of, or failure to store, any content and other
      communications data maintained or transmitted by or through your use of
      the Services;
    </li>
    <li>
      Your failure to provide SBM Bank (India) Limited with accurate
      Registration Data; or
    </li>
    <li>
      Your failure to keep your password or account details secure and
      confidential.
    </li>
  </ul>
  <p>
    The limitations on SBM Bank (India) Limited's liability to you shall apply
    whether or not SBM Bank (India) Limited has/ had been advised of or should
    have been aware of the possibility of any losses to you.
  </p>
  <h2>Right of Lien/Set off:</h2>
  <p>
    You hereby grant and confirm the existence of the right of lien and set-off
    with SBM Bank (India) Limited, which SBM Bank (India) Limited may at any
    time without prejudice to any of its specific rights under any other
    agreements with me, at its sole discretion and without notice to me utilize
    to appropriate any moneys belonging to me and lying/deposited with SBM Bank
    (India) Limited or due by SBM Bank (India) Limited to me, towards any of SBM
    Bank (India) Limited&rsquo;s dues and outstandings under or in respect of a
    loan facility, including any charges/fees/dues payable under these Terms of
    Use.
  </p>
  <h2>Force Majeure</h2>
  <p>
    SBM Bank (India) Limited shall not be in breach of its obligation hereunder
    if it is delayed in the performance of, or is unable to perform (whether
    partially or fully) its obligations (provide the Services) as a result of
    the occurrence of a Force Majeure Event (defined below).
  </p>
  <p>
    Force Majeure Event means any event, whatever be the origin, not within the
    reasonable control of SBM Bank (India) Limited, which SBM Bank (India)
    Limited is unable to prevent, avoid or remove or circumvent by the use of
    reasonable diligence. Force Majeure event shall include, but shall not be
    limited to, acts of god, acts, orders, directions of governmental/
    regulatory/ judicial/ quasi-judicial/ law enforcement authorities/ agencies
    which hinders SBM Bank (India) Limited from performing its obligations under
    any agreement, including these Terms of Use, with you, war, hostilities,
    invasion, armed conflict, act of foreign enemy, embargoes, riot,
    insurrection, labour stoppages, outages and downtimes systems failures
    experienced by a facility provider, revolution or usurped power, acts of
    terrorism, sabotage, nuclear explosion, earthquake, pandemic, epidemic,
    hacking or man in the middle attack or similar attacks/ intrusions, fires,
    typhoons, storms and other natural catastrophes.
  </p>
  <p>
    Any payment obligations of SBM Bank (India) Limited, in case of a Force
    Majeure event, shall be limited by and be subject to the fulfilment of the
    payment obligations of the partners banks/ financial institutions,
    counterparties and any other parties involved in or intrinsically linked to
    the provision of the Services of SBM Bank (India) Limited.
  </p>
  <h2>Confidentiality</h2>
  <p>
    The User may receive or have access to certain confidential and proprietary
    information, including without limitation, information belonging and/or
    relating to SBM Bank (India) Limited and its affiliates, marketing
    prospects, contractors, officers, directors or shareholders, personal data
    of customers of the User, financial and operational information, billing
    records, business model and reports, computer systems and modules, secure
    websites, reporting systems, marketing strategies, operational plans,
    proprietary systems and procedures, trade secrets and other similar
    proprietary information, including technical "know-how", methods of
    operation, business methodologies, software, software and technology
    architecture, networks, any other information not generally available to the
    public, and any items in any form in writing or oral, clearly identified as
    confidential ("<strong>CONFIDENTIAL INFORMATION</strong>").
  </p>
  <p>
    The User shall keep Confidential Information in confidence. The User shall
    use commercial, reasonable and necessary safety measures and steps to
    maintain the confidentiality and secrecy of the Confidential Information
    from public disclosure, and the User shall at all times maintain appropriate
    measures to protect the security and integrity of the Confidential
    Information. The User shall not, without SBM Bank (India) Limited's prior
    written consent, divulge any of the Confidential Information to any third
    party other than the User's officers, employees, agents or representatives
    who have a need to know for the purposes of these Terms of Use. The User
    shall take all reasonable steps to ensure that all of its directors,
    managers, officers, employees, agents, independent contractors or other
    representatives comply with this paragraph whenever they are in possession
    of Confidential Information as part of this Agreement. The User shall use
    the Confidential Information solely in furtherance of and in connection with
    the Services contemplated under these Terms of Use. The User further agrees
    that the Confidential Information will not be used by him/ her and his/ her
    representatives, in any way detrimental to the interests of SBM Bank (India)
    Limited.
  </p>
  <p>
    The User hereby unconditionally and irrevocably agrees and undertakes to
    take all necessary measures to ensure that the User's website or any other
    computer system that is being used to effect Transactions by the usage of
    Services, do not store/ save any customer card or any other such related
    data. You further undertake that you shall be responsible for ensuring
    complete and absolutely privacy, anonymity and security of all customer data
    flowing through your systems during the usage of the Services by you.
  </p>
  <p>
    SBM Bank (India) LimitedSBM Bank (India) LimitedSBM Bank (India) LimitedSBM
    Bank (India) LimitedThe User shall notify SBM Bank (India) Limited
    immediately upon discovery of any unauthorized use or disclosure of
    Confidential Information or any other breach of this paragraph. The User
    will cooperate with SBM Bank (India) Limited in every reasonable way to help
    SBM Bank (India) Limited regain possession of such Confidential Information
    and prevent its further unauthorized use.
  </p>
  <p>
    Remedies: Parties acknowledge that irreparable damage may occur on breach of
    the terms and provisions set out in this paragraph. Accordingly, if the User
    breaches or threatens to breach any of the provisions set out in this
    paragraph, then SBM Bank (India) Limited shall be entitled, without
    prejudice, to seek all the rights and remedies available to it under
    applicable law, including a temporary restraining order and an injunction
    restraining any breach of the provisions set out in this paragraph. Such
    remedies shall not be deemed to be exclusive but shall be in addition to all
    other remedies available under applicable law or in equity.
  </p>
  <h2>Fraudulent Transactions</h2>
  <p>
    Subject to paragraphs 14.2 and 14.3 below, if SBM Bank (India) Limited is
    intimated, by the Acquiring Bank or a Card Payment Network, that a customer
    has reported an unauthorised debit of the customer's payment instrument ("<strong
      >FRAUDULENT TRANSACTION</strong
    >"), then SBM Bank (India) Limited shall be entitled to suspend the
    settlement of the amount associated with the Fraudulent Transaction during
    the pendency of inquiries, investigations and resolution thereof by the
    Acquiring Bank or the Card Payment Network.
  </p>
  <p>
    Subject to paragraph 14.3 below, if the Fraudulent Transaction results in a
    Chargeback, then the Chargeback shall be resolved in accordance with the
    provisions relating to Chargeback as set out under these Terms of Use.
  </p>
  <p>
    If the amount in respect of a Fraudulent Transaction has already been
    settled to the User pursuant to these Terms of Use, any dispute arising in
    relation to the said Fraudulent Transaction, following settlement, shall be
    resolved in accordance with the RBI's notification RBI/DPSS/2019-20/174
    DPSS.CO.PD.No.1810/02.14.008/2019-20, dated March 17, 2020,
    DBR.No.Leg.BC.78/09.07.005/2017-18, dated July 6, 2017 read with RBI's
    notification DBOD. LEG. BC 86/09.07.007/2001-02 dated April 8, 2002 and
    other notifications, circulars and guidelines issued by the RBI in this
    regard from time to time.
  </p>
  <h2>Advertising</h2>
  <p>
    Some of the Services are supported by advertising revenue and may display
    advertisements and promotions. These advertisements may be targeted to the
    content of information stored on the Services, queries made through the
    Services or other information.
  </p>
  <p>
    The manner, mode and extent of advertising by SBM Bank (India) Limited on
    the Services are subject to change without any specific notice to you.
  </p>
  <p>
    In consideration for SBM Bank (India) Limited granting you access to and use
    of the Services, you agree that SBM Bank (India) Limited may place such
    advertising on the Services.
  </p>
  <p>Authorisation</p>
  <p>
    By accepting these Terms of Use, you authorise us to hold, receive, disburse
    and settle funds on your behalf. Your authorisation permits us to generate
    an electronic funds transfer between the payment system providers and the
    escrow account to process each payment transaction that you authorise.
  </p>
  <p>
    Thereafter, you authorise us to transfer the payments received from your
    buyers to the bank account designated by you for this purpose at the time of
    registration ("<strong>ACQUIRING BANK</strong>"). Your authorisation will
    remain in full force and effect until your SBM Bank (India) Limited account
    is closed or terminated.
  </p>
  <h2>Card Association Rules</h2>
  <p>
    "<strong>CARD PAYMENT NETWORK RULES</strong>" refer to the written rules,
    regulations, releases, guidelines, processes, interpretations and other
    requirements (whether contractual or otherwise) imposed and adopted by the
    Card Payment Networks. These Card Payment Networks have infrastructure and
    processes to enable transaction authorisation. The Card Payment Networks
    require you to comply with all applicable guidelines, rules, and regulations
    formulated by them.
  </p>
  <p>
    The Card Payment Networks reserve the right to amend their guidelines, rules
    and regulations. We may be required to amend modify or change these Terms of
    Use pursuant to amendments to the Card Payment Network Rules and such
    amendments, if any, shall be deemed to be binding on the Users with
    immediate effect.
  </p>
  <p>
    You agree to fully comply with all programs, guidelines, requirements that
    may be published and/ or mandated by the Card Payment Networks.
    Notwithstanding our assistance in understanding the Card Payment Network
    Rules, you expressly acknowledge and agree that you are assuming the risk of
    compliance with all provisions of the Card Payment Network Rules, regardless
    of whether you are aware of or have access to those provisions. MasterCard,
    Visa and American Express make excerpts of their respective rules available
    on their internet sites.
  </p>
  <p>
    In the event that your non-compliance of Card Payment Network Rules, results
    in any fines, penalties or other amounts being levied on or demanded of us
    by a Card Payment Network, then without prejudice to our other rights
    hereunder, you shall forthwith reimburse us in an amount equal to the fines,
    penalties or other amount so levied or demanded or spent by us in any manner
    in relation to such fines, penalties and levies. If you fail to comply with
    your obligations towards the Card Payment Networks, SBM Bank (India) Limited
    may suspend settlement or suspend/ terminate the Services forthwith.
  </p>
  <h2>Settlements</h2>
  <p>
    In consideration of the Services rendered by us, you shall pay SBM Bank
    (India) Limited a fee ("<strong>FEE</strong>"). SBM Bank (India) Limited
    reserves the right to revise the Fee periodically and will intimate you of
    any such change within reasonable time. SBM Bank (India) Limited reserves
    the right to levy additional fees on transactions undertaken through
    corporate cards. This additional fee shall be over and above applicable
    credit card fees. On receipt of the payments in the nodal account, we will
    endeavour to instruct the nodal bank to transmit the payments payable to the
    seller, after deducting our Fee, from the nodal account to the seller's
    designated bank account, within 3 (three) bank working days (or such other
    period as may be prescribed by the Reserve Bank of India from time to time)
    from completion of transaction. Subject to any other provisions of these
    Terms of Use and completion of transaction, the seller acknowledges that we
    will settle the payments only upon actual receipt of payments in the nodal
    account and upon reconciliation of the payments by the Acquiring Banks, our
    payment gateway and the nodal bank. The seller will bear and be responsible
    and liable for the payment of all relevant taxes in relation to the payments
    made under these Terms of Use.
  </p>
  <p>
    Sellers receive the amount (minus SBM Bank (India) Limited's Fee) in their
    bank account within T+3 bank working days where T is defined as the date of
    intimation of the completion of the transaction. SBM Bank (India) Limited
    shall be entitled to charge on the Fee, taxes applicable from time to time
    ("<strong>APPLICABLE TAXES</strong>"). It is agreed that any statutory
    variations in Applicable Taxes during the subsistence of this Agreement
    shall be borne by the User. Monthly invoices shall be raised by us in
    respect of the Fees charged for transactions processed during such month.
    Any reasonable dispute in respect of an amount (or a portion thereof)
    mentioned in an invoice must be communicated by the User via notice ("<strong
      >INVOICE DISPUTE NOTICE</strong
    >") within a reasonable period of time but no later than thirty (30)
    calendar days from the date of the invoice. SBM Bank (India) Limited shall
    use good faith efforts to reconcile any reasonably disputed amounts within
    reasonable time from the receipt of the Invoice Dispute Notice.
  </p>
  <p>
    In respect of invoices received by the Client, it is agreed that if the User
    pays over applicable taxes under the Indian Income Tax laws and furnishes to
    us the TRACES certificate in respect of such taxes paid, then we shall
    reimburse to the User, on a quarterly basis, the amount in respect of such
    taxes paid.
  </p>
  <p>
    Once a payment is authenticated by payment service providers, money shall be
    moved to the escrow account and the first settlement shall be initiated only
    after all required documents (in hard copies) are received by SBM Bank
    (India) Limited.
  </p>
  <p>
    All risks associated with the delivery of the goods or service will solely
    be that of the seller and not of SBM Bank (India) Limited. Also, all
    disputes regarding quality, merchantability, non-delivery, delay in delivery
    or otherwise will be directly between the seller and the buyer without
    making SBM Bank (India) Limited and/ or the payment service providers, a
    party to such disputes.
  </p>
  <p>
    Further, SBM Bank (India) Limited also reserves the right to close, suspend,
    limit or put on hold the User's access to the account with SBM Bank (India)
    Limited and/ or the funds available therein, including settlements
    pertaining to the User under&nbsp;<em>inter alia</em>&nbsp;the following
    scenarios:
  </p>
  <ul>
    <li>If such User's KYC credentials are found to be ingenuine or fake;</li>
    <li>
      If the User makes incorrect or untrue disclosure of the nature of its
      business, resulting in a merchant category code violation;
    </li>
    <li>For violation of any of the provisions of these "Terms of Use";</li>
    <li>
      For violation of any of the provisions of any other agreement that the
      User has entered into or might enter into with SBM Bank (India) Limited;
      and
    </li>
    <li>For violation of any of the applicable laws by such User.</li>
  </ul>
  <p>
    Such right to close, suspend, limit or put on hold the User's access to the
    account with SBM Bank (India) Limited shall continue till such time that
    such User submits genuine KYC documents or credentials to the satisfaction
    of the relevant authorities as per the extant rules, regulations or
    guidelines with regard to KYC, as well as to the satisfaction of SBM Bank
    (India) Limited without prejudice to any other legal remedy that SBM Bank
    (India) Limited is entitled to prefer as per applicable law.
  </p>
  <h2>ELIGIBILITY FOR DISCOUNTS & OFFERS:</h2>
  <ul>
    <li>
      Any offers or discounts related to free credits are not valid for
      unregistered businesses who accept payments via Credit Cards. However, the
      same offers can be utilised through payments accepted via other payment
      modes such as Debit Cards, wallets, UPI, NetBanking, etc.
    </li>
    <li>
      Terms &amp; Conditions may vary basis the ongoing offer, product, or other
      factors. For detailed Terms &amp; Conditions on specific offers and
      discounts, kindly refer to the dedicated T&amp;C page shared along with
      the offer communication.
    </li>
    <li>
      All signups will be governed by the overall Terms &amp; Conditions of SBM
      Bank (India) Limited. SBM Bank (India) Limited reserves the right to
      change these terms and conditions at any time without any prior notice.
    </li>
  </ul>
  <h2>Prohibited Services</h2>
  <p>
    You agree that you will not accept payments in connection with businesses,
    business activities or business practices, including but limited to the
    following:
  </p>
  <ul>
    <li>
      Adult goods and services which include pornography and other sexually
      suggestive materials (including literature, imagery and other media),
      escort or prostitution services, website access and/or website memberships
      of pornography or illegal sites;
    </li>
    <li>
      Alcohol which includes alcohol or alcoholic beverages such as beer,
      liquor, wine, or champagne etc.;
    </li>
    <li>Body parts which include organs or other body parts;</li>
    <li>
      Bulk marketing tools which include email lists, software, or other
      products enabling unsolicited email messages (spam);
    </li>
    <li>
      Cable descramblers and black boxes which include devices intended to
      obtain cable and satellite signals for free;
    </li>
    <li>
      Child pornography which includes pornographic materials involving minors;
    </li>
    <li>
      Copyright unlocking devices which include mod chips or other devices
      designed to circumvent copyright protection;
    </li>
    <li>
      Copyrighted media which includes unauthorized copies of books, music,
      movies, and other licensed or protected materials;
    </li>
    <li>
      Copyrighted software which includes unauthorized copies of software, video
      games and other licensed or protected materials, including OEM or bundled
      software;
    </li>
    <ul>
      <li>
        Counterfeit and unauthorized goods which include replicas or imitations
        of designer goods, items without a celebrity endorsement that would
        normally require such an association, fake autographs, counterfeit
        stamps, and other potentially unauthorized goods;
      </li>
      <li>
        Drugs and drug paraphernalia which include illegal drugs and drug
        accessories, including herbal drugs like marijuana, salvia and magic
        mushrooms etc.;
      </li>
      <li>
        Drug test circumvention aids which include drug cleansing shakes, urine
        test additives, and related items;
      </li>
      <li>
        Endangered species which include plants, animals or other organisms
        (including product derivatives) in danger of extinction;
      </li>
      <li>
        Gaming/ gambling which include lottery tickets, sports bets,
        memberships/ enrolment in online gambling sites, and related content;
      </li>
      <li>
        Government IDs or documents which include fake IDs, passports, diplomas,
        and noble titles;
      </li>
      <li>
        Hacking and cracking materials which include manuals, how-to guides,
        information, or equipment enabling illegal access to software, servers,
        website, or other protected property;
      </li>
      <li>
        Illegal goods which include materials, products, or information
        promoting illegal goods or enabling illegal acts;
      </li>
      <li>
        Miracle cures which include unsubstantiated cures, remedies or other
        items marketed as quick health fixes;
      </li>
      <li>
        Offensive goods which include literature, products or other materials
        that inter alia :
      </li>
      <ul>
        <li>
          Defame or slander any person or groups of people based on race,
          ethnicity, national origin, religion, sex, or other factors;
        </li>
        <li>Encourage or incite violent acts; or</li>
        <li>Promote intolerance or hatred.</li>
        <li>
          Offensive goods which include crime scene photos or items, such as
          personal belongings, associated with criminals;
        </li>
        <li>
          Pyrotechnic devices, combustibles, corrosives and hazardous materials
          which include explosives and related goods, toxic, flammable, and
          radioactive materials and substances;
        </li>
        <li>
          Regulated goods which include air bags, batteries containing mercury,
          freon or similar substances/ refrigerants, chemical/ industrial
          solvents, government uniforms, car titles, license plates, police
          badges and law enforcement equipment, lock-picking devices,
          pesticides, postage meters, recalled items, slot machines,
          surveillance equipment, goods regulated by government or other agency
          specifications;
        </li>
        <li>
          Securities which include government and/ or public sector unit bonds,
          stocks, debentures or related financial products;
        </li>
        <li>
          Tobacco and cigarettes which include cigarettes, cigars, chewing
          tobacco, and related products;
        </li>
        <li>
          Traffic devices which include radar detectors/ jammers, license plate
          covers, traffic signal changers, and related products;
        </li>
        <li>
          Weapons which include firearms, ammunition, knives, brass knuckles,
          gun parts, gun powder or explosive mixtures and other armaments;
        </li>
        <li>
          Wholesale currency which includes discounted currencies or currency
          exchanges;
        </li>
        <li>
          Live animals or hides/ skins/ teeth, nails and other parts etc. of
          animals;
        </li>
        <li>Multi-level marketing collection fees;</li>
        <li>Matrix sites or sites using a matrix scheme approach;</li>
        <li>Work-at-home approach and/ or work-at-home information;</li>
        <li>Drop-shipped merchandise;</li>
        <li>
          Any product or service which is not in compliance with all applicable
          laws and regulations whether federal, state, local or international,
          including the laws of India;
        </li>
        <li>
          The User providing services that have the potential of casting the
          payment gateway facilitators in a poor light and/ or that may be prone
          to buy and deny attitude of the cardholders when billed (e.g. adult
          material/ mature content/ escort services/ friend finders) and thus
          leading to chargeback and fraud losses;
        </li>
        <li>
          Businesses or website that operate within the scope of laws which are
          not absolutely clear or are ambiguous in nature (e.g. web-based
          telephony, website supplying medicines or controlled substances,
          website that promise online match-making);
        </li>
        <li>
          Businesses out rightly banned by law (e.g. betting &amp; gambling/
          publications or content that is likely to be interpreted by the
          authorities as leading to moral turpitude or decadence or incite
          caste/ communal tensions, lotteries/ sweepstakes &amp; games of
          chance;
        </li>
        <li>
          Businesses dealing in intangible goods/ services (e.g. software
          download/ health/ beauty Products), and involved in pyramid marketing
          schemes or get-rich-quick schemes;
        </li>
        <li>
          Any other product or service, which in the sole opinion of either the
          Acquiring Bank, is detrimental to the image and interests of either of
          them/ both of them, as communicated by either of them/ both of them to
          the User from time to time. This shall be without prejudice to any
          other terms &amp; conditions mentioned in these Terms of Use;
        </li>
        <li>Mailing lists;</li>
        <li>
          Virtual currency, cryptocurrency, prohibited investments for
          commercial gain or credits that can be monetized, re-sold or converted
          to physical or digital goods or services or otherwise exit the virtual
          world;
        </li>
        <li>Money laundering services;</li>
        <li>Database providers (for tele-callers);</li>
        <li>Bidding/ auction houses;</li>
        <li>
          Activities prohibited by the Telecom Regulatory Authority of India;
          and
        </li>
        <li>Any other activities prohibited by applicable law.</li>
      </ul>
    </ul>
  </ul>
  <p>
    The above list is subject to additions/ amendments (basis changes/
    amendments to applicable laws) by SBM Bank (India) Limited without prior
    intimation to you.
  </p>
  <h2>Transaction Disputes</h2>
  <p>
    Transactions may be disputed anytime within up to 120 (one hundred twenty)
    days, from the date of transaction by a buyer, as per the Card Payment
    Network Rules. Disputes resolved in favour of a buyer may result in reversal
    of payment to such buyer ("<strong>CHARGEBACK</strong>"). In the event of
    rejection/ suspension of payments to the seller, chargebacks, refunds and/or
    any other dispute relating to the transactions contemplated under these
    Terms of Use ("<strong>DISPUTED TRANSACTION</strong>"), on any grounds
    whatsoever, we will forthwith notify the seller of the same.
  </p>
  <p>
    On such notification the seller will conduct an internal review of such
    matter and will, within 5 (five) working days from receipt of notification,
    respond to us in writing either:
  </p>
  <ul>
    <li>
      Requesting us to refund request the payment received by the seller in
      respect of such Disputed Transaction Refund Monies; or
    </li>
    <li>
      Providing us with a statement explaining how the Disputed Transaction is
      not warranted, together with all documentary evidence in support of
      contesting such Disputed Transaction.
    </li>
  </ul>
  <p>
    All refunds shall be made to the original method of payment. In the event
    that the seller provides a Refund Request to us or fails to contest such
    Disputed Transaction within the aforesaid 5 (five) working days or contests
    Disputed Transaction without providing supporting documentation to us,
    payment service providers, Card Payment Network and/ or issuing
    institution's satisfaction, we will be entitled to recover the Refund Monies
    from credits subsequently made to the escrow account with respect to
    payments made by the seller's buyers.
  </p>
  <p>
    In the event that we are unable to recover the Refund Monies as aforesaid,
    due to the amounts credited to the escrow account being lower than the
    Refund Monies, SBM Bank (India) Limited shall be entitled to recover such
    Refund Monies (or any part thereof) from the User by (i) raising a debit
    note in respect of such monies; and/ or (ii) setting-off the remaining
    Refund Monies against the future payables to the seller and refund the same
    to the respective buyers. The seller will be liable to make payment of the
    Refund Monies or part thereof which has not been recovered by us forthwith.
    It is hereby agreed and acknowledged by the parties that the Fees charged by
    us in respect of the Disputed Transaction will not be refunded or repaid by
    us to the seller, buyer or any other person. Further, the Chargeback will be
    provided within 1 (one) week of the transaction and maximum amount of the
    Chargeback payable by SBM Bank (India) Limited to the buyer will be the
    value of the transaction only.
  </p>
  <h2>Technical Issues & Delivery Policy</h2>
  <p>
    In case of any technical issues, please raise a support ticket from your
    service dashboard or by emailing us at <a
      href="mailto:customercare@sbmbank.co.in"
      target="_blank">customercare@sbmbank.co.in</a
    > (Help Line / Customer Care Number 1800 1033 817) to let us know of the same.
    We endeavour to deliver Service to you within 15 (fifteen) working days of bank
    approval, failing which you can terminate a transaction related to Service at
    any time and get a full refund.
  </p>
  <h2>Governing Law, Settlement of Disputes and Jurisdiction</h2>
  <p>
    These Terms of Use and any dispute or claim arising under it will be
    governed by and construed in accordance with the laws of India. The Users
    agree that any legal action or proceedings arising out of these Terms of Use
    or in connection with these Terms of Use may be brought&nbsp;<strong
      >EXCLUSIVELY</strong
    >&nbsp;in the competent courts/ tribunals having jurisdiction in Mumbai,
    India and the Users irrevocably submit themselves to the jurisdiction of
    such courts/ tribunals.
  </p>
  <h2>
    Website of SBM Bank (India) Limited covering Terms and Conditions for
    Internet Banking and Other Digital banking Services and its applicability to
    all users not restricted to only Customers / Partners / Agents
  </h2>
  <p>
    These Terms of Use and any dispute or claim arising under it will be
    governed by and construed in accordance with the laws of India. The Users
    agree that any legal action or proceedings arising out.
  </p>
  <p>
    <a
      href="https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/Generic-Terms-and-Conditions.pdf"
      target="_blank"
      rel="noreferrer"
      >https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/Generic-Terms-and-Conditions.pdf</a
    >
  </p>
  <p>
    <a
      href="https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/Terms-and-Conditions-UPI.pdf"
      target="_blank"
      rel="noreferrer"
      >https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/Terms-and-Conditions-UPI.pdf</a
    >
  </p>
  <p>
    <a
      href="https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/SBM-Bank-Internet-Banking-and-Mobile-Banking-Terms-Conditions.pdf"
      target="_blank"
      rel="noreferrer"
      >https://www.sbmbank.co.in/aboutus/sbmassest/pdf/tnc/SBM-Bank-Internet-Banking-and-Mobile-Banking-Terms-Conditions.pdf</a
    >
  </p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong>&nbsp;</strong></p>
  <h2>TERMS OF USE FOR SBM BANK (INDIA) LIMITED&rsquo;S SERVICES</h2>
  <p>Last updated on 27<sup>th</sup> Dec 2022.</p>
  <h2>1. Introduction and Terms of Use for Current Account</h2>
  <p>
    These Terms of Use for SBM Bank (India) Limited&rsquo;s Services contained
    hereinafter ("<strong>TERMS OF USE</strong>") shall be in addition to the
    Terms &amp; Conditions available on the website&nbsp;<a
      href="https://www.sbmbank.co.in/">https://www.sbmbank.co.in/</a
    >&nbsp;and any other linked pages, products, software(s), API keys,
    features, content or application services (including but without limitation
    to any mobile application services) in connection therewith, offered from
    time to time by SBM Bank (India) Limited Software Private Limited ("<strong
      >SBM BANK (INDIA) LIMITED</strong
    >" or "<strong>WE</strong>" or "<strong>OUR</strong>" or "<strong>US</strong
    >") (collectively, "<strong>WEBSITE</strong>").
  </p>
  <p>
    Any person availing the Services of SBM Bank (India) Limited&rsquo;s ("<strong
      >SERVICES</strong
    >") shall be presumed to have read these Terms of Use (which includes the
    Privacy Policy, separately provided on the Website) and unconditionally
    accepted the terms and conditions set out herein. These Terms of Use
    constitute a binding and enforceable agreement between the User and SBM Bank
    (India) Limited. These Terms of Use do not alter in any way the terms or
    conditions of any other written agreement you may have with SBM Bank (India)
    Limited for other services.
  </p>
  <p>
    Please read the terms set out hereunder carefully before agreeing to the
    same. If you do not agree to these Terms of Use (including any referenced
    policies or guidelines), please immediately terminate your use of the
    Website. You can accept the Terms of Use by:
  </p>
  <p>
    Clicking to accept or agree to the Terms of Use, where this option is made
    available to you by SBM Bank (India) Limited in the User interface for any
    particular Service; or
  </p>
  <p>
    Accessing, testing or actually using the Services. In this case, you
    understand and agree that SBM Bank (India) Limited will treat your use of
    the Services as acceptance of the Terms of Use from that point onwards.
  </p>
  <p>
    For the purpose of these Terms of Use, wherever the context so requires, the
    term "<strong>USER</strong>" shall mean and include any natural or legal
    person who has agreed to these Terms of Use on behalf of itself or any other
    legal entity.
  </p>
  <p>
    It is clarified that the Privacy Policy (that is provided separately), form
    an integral part of these Terms of Use and should be read contemporaneously
    with the Terms of Use. Illegality or unenforceability of one or more
    provisions of these Terms of Use shall not affect the legality and
    enforceability of the other terms of the Terms of Use. For avoidance of
    doubt, if any of the provisions becomes void or unenforceable, the rest of
    the provisions of these Terms of Use shall be binding upon the User.
  </p>
  <p>
    The Terms of Use may be revised or altered by us at our sole discretion at
    any time without any prior intimation to the User. The latest Terms of Use
    will be posted here. Any such changes by SBM Bank (India) Limited will be
    effective immediately. By continuing to use this Website or to access the
    Services / usage of our Services after changes are made, you agree to be
    bound by the revised/ amended Terms of Use and such amendments shall
    supersede all other terms of use previously accepted by the User. You are
    solely responsible for understanding and complying with all applicable laws
    of your specific jurisdiction that may be applicable to you in connection
    with your business and use of our Services.
  </p>
  <h2>
    2. PROVISION OF THE SERVICES BEING OFFERED BY SBM BANK (INDIA) LIMITED
  </h2>
  <p>
    Subject to these Terms of Use, SBM Bank (India) Limited offers the Services
    set forth in Schedule I herein.
  </p>
  <p>Schedule I also contains descriptions of the Services.</p>
  <p>
    SBM Bank (India) Limited is constantly evolving in order to provide the best
    possible experience and information to its Users. You acknowledge and agree
    that the form and nature of the Services which SBM Bank (India) Limited
    provides may change from time to time without any prior notice to you.
  </p>
  <p>
    As part of this continuing process, you acknowledge and agree that SBM Bank
    (India) Limited may stop (temporarily or permanently) providing the Services
    (or any features within the Services) to you or to Users generally at SBM
    Bank (India) Limited's sole discretion, without any prior notice. You may
    stop using the Services at any point of time. SBM Bank (India) Limited
  </p>
  <p>
    You acknowledge and agree that if SBM Bank (India) Limited disables access
    to your account, you may be prevented from accessing the Services, your
    account details or any files or other content which are contained in your
    account, and SBM Bank (India) Limited shall intimate you regarding the same
  </p>
  <p>
    You shall declare details of any credit facilities enjoyed with any bank or
    branch of SBM Bank (India) Limited. You undertake to obtain the requisite no
    objection certificate/s from such bank/s and hand it/them over to SBM Bank
    (India) Limited before the account gets opened.
  </p>
  <p>
    SBM Bank (India) Limited reserves the right to delete your User information
    stored in your account including but not limited to any or all personal
    information or any sensitive personal data or information ("<strong
      >SPDI</strong
    >") stored in your User account. Alternately, a User may notify us if they
    do not wish that we retain or use the personal information or SPDI by
    contacting the Grievance Officer (as provided below). However, in such a
    case, we may not be able to provide you some or all of our Services.
  </p>
  <p>
    You acknowledge and agree that while SBM Bank (India) Limited may not have
    currently set a fixed upper limit on the number of transmissions you may
    send or receive through the Services, such fixed upper limits may be set by
    SBM Bank (India) Limited at any time, solely at SBM Bank (India) Limited's
    discretion.
  </p>
  <p>
    By using our Services, you agree that SBM Bank (India) Limited disclaims any
    liability or authenticity of any information that may have become outdated
    since the last time that particular piece of information was updated. SBM
    Bank (India) Limited reserves the right to make changes and corrections to
    any part of the content of this Website at any time without any prior notice
    to you. Unless stated otherwise, all pictures and information contained on
    this Website are believed to be in the public domain as either promotional
    materials, publicity photos, photoshoot rejects or press media stock. Please
    contact the Grievance Officer by an e-mail if you are the copyright owner of
    any content on this Website and you think the use of the above material
    violates the terms of the applicable Copyright law in any manner. In your
    request, please indicate the exact URL of the webpage to enable us to locate
    the same. We will endeavour to address your concerns and take necessary
    steps, if required. Please note that all images displayed in the Website
    have been digitised by SBM Bank (India) Limited. No other party is
    authorised to reproduce or republish these digital versions in any format
    whatsoever without the prior written permission of SBM Bank (India) Limited.
  </p>
  <h2>
    3. FOR THE PURPOSE OF THIS TERMS OF USE, THE BELOW DEFINTIONS SHALL BE
    CONSTRUED TO MEAN AND INCLUDE AS STATED BELOW &ndash;
  </h2>
  <ul>
    <li>
      (a) "<strong>API</strong>" means application programming interface which
      refers to electronic communication protocols used by the software of the
      various entities / intermediaries in a payment system established to
      initiate, implement and effect electronic payments;
    </li>
    <li>
      (b) "<strong>BANK ACCOUNT</strong>" means a banking account maintained by
      the User.
    </li>
    <li>
      (c) "<strong>NPCI</strong>" means the National Payments Corporation of
      India constituted pursuant to&nbsp;<em>inter alia</em>the provisions of
      the Payment and Settlement Systems Act, 2007;
    </li>
    <li>
      (d) "<strong>PERSONAL DATA</strong>" means the 'personal information' and
      'sensitive personal data or information' as defined under the Information
      Technology Act, 2000 and the rules and regulations thereunder;
    </li>
    <li>
      (e) "<strong>FINTECH PARTNER<strong>S</strong></strong>" mean the banks /
      entities with whom SBM Bank (India) Limited has partnered to provide
      payment solutions to the User by establishing software protocols and
      integrations via API
    </li>
  </ul>
  <h2>4. AUTHORISATIONS BY THE USER</h2>
  <ul>
    <li>
      (a) The User has authorised SBM Bank (India) Limited to generate LDAP,
      User &amp; Secret ID and subscribe to the API's on Fintech Partners web
      portal or software platform on behalf of the User to facilitate the
      Services.
    </li>
  </ul>
  <ol>
    <li>
      (b) The User has further authorised SBM Bank (India) Limited to service
      the User's current account on their behalf with regards to queries and
      complaints raised by the User and/or third party for facilitation of the
      Services including authorisation for raising of queries with the banks on
      their behalf.
    </li>
  </ol>
  <h2>5. INDEMNIFICATION</h2>
  <p>
    You shall indemnify and hold SBM Bank (India) Limited, its directors,
    officers, employees, agents representatives, vendors and any related parties
    whatsoever, harmless from and against any and all losses, damages, costs or
    expenses (including reasonable attorney's fees and any other pecuniary
    liability or any other obligations of any kind whatsoever) on account of
    claims, judgments, awards, decrees, settlements, fines, penalties arising
    from, including but not limited to the following:
  </p>
  <ul>
    <li>
      (a) In respect of communications and customer information which have been
      furnished by Client or appears to have been furnished by or on behalf of
      Client;
    </li>
    <li>
      (b) In respect of the said arrangement for API integration for providing
      the Services;
    </li>
    <li>
      (c) In respect of any debit to the current account of the User maintained
      with SBM Bank (India) Limited for the purpose of payments to customers/
      vendors/ employee of User based on the instructions sent by User;
    </li>
    <li>
      (d) In respect of breach of security measures/ standards/ regulations/
      requirements/ guidelines which would be applicable for API Services
      directly attributable to breach by User;
    </li>
    <li>
      (e) Any wrongful, incorrect, dishonest, criminal, fraudulent or negligent
      work, default, failure, misrepresentation, misfeasance, bad faith, and/or
      misconduct (including perpetration of, or aiding and abetting fraud), any
      act or omission directly attributable to default of or by User; and
    </li>
    <li>
      (f) Any legal actions brought against SBM Bank (India) Limited arising out
      of or directly attributable to User's management of API integration with
      the Fintech partners and/or the customer, including arising from any
      system, dashboard, or platform created by the User based on the API
      integration for its customer.
    </li>
  </ul>
  <h2>&nbsp;</h2>
  <h2>SCHEDULE I</h2>
  <h3>DESCRIPTION OF THE SERVICES</h3>
  <p>&nbsp;</p>
  <ol>
    <ol>
      <li>
        SBM Bank (India) Limited in association with its Fintech Partners plans
        to offer a current account solution via the use of API banking through
        which the User will have access to a dashboard created by SBM Bank
        (India) Limited whereby a larger set of features on the accounts
        payables side like vendor payments, salary disbursements, bill payments,
        etc., will be provided to the User through the current account.
      </li>
      <li>
        Debit Card facility will be available. However, cheque book facility is
        not available.
      </li>
    </ol>
  </ol>
  <ol>
    <ol>
      <li>
        Besides the above features, the conventional features of checking
        balances, fetching account statement etc. will also be available.
      </li>
      <li>
        Hence SBM Bank (India) Limited will provide a technical solution that
        may be utilised by the User to manage their current account. SBM Bank
        (India) Limited will integrate the following APIs (including but not
        limited) to create this dashboard solution:
      </li>
    </ol>
  </ol>
  <table>
    <thead>
      <tr>
        <td>
          <p><strong>Name of API's</strong></p>
        </td>
        <td>
          <p><strong>Used for:</strong></p>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <p>Single Payment</p>
        </td>
        <td>
          <p>Processing NEFT, RTGS, IMPS &amp; FT Transactions</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Multipayment</p>
        </td>
        <td>
          <p>Bulk Processing of NEFT, RTGS, IMPS &amp; FT Transaction</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Balance Inquiry</p>
        </td>
        <td>
          <p>Providing the latest available balance to the Client</p>
        </td>
      </tr>
      <tr>
        <td>
          <p>Account Statement</p>
        </td>
        <td>
          <p>
            Generating a statement comprising deposits and withdrawals for a
            specific period.
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <h3>&nbsp;</h3>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <h3>KYC AND ACCOUNT LEVEL DECLARATION ON THE SERVICES TO BE AVAILED</h3>
  <p>&nbsp;</p>
  <p>
    I hereby authorise SBM Bank (India) Limited to proceed with the Account
    opening subject to successful CRILC verification as per Bank&rsquo;s laid
    down process. I also understand that my requests for account opening may be
    declined based on information found by the Bank during CRILC check.
  </p>
  <p>
    In connection with the same, I declare and confirm that: - I hereby submit
    voluntarily at my own discretion, the physical copy of Aadhaar card/physical
    e-Aadhaar / masked Aadhaar / offline electronic Aadhaar xml as issued by
    UIDAI (Aadhaar), to SBM Bank India for the purpose of establishing my
    identity / address proof/Verification of Aadhaar related information for the
    KYC and additional due-diligence purposes.
  </p>
  <p>
    I hereby confirm to SBM Bank (India) Limited, that the basic customer
    details (Customer Profiling Information) and other KYC documents submitted /
    furnished with the bank, are the updated ones and updated till date. I also
    undertake and ensure on the correctness of the data as per the best of my
    knowledge and the account can be closed or frozen in case of any
    discrepancy.
  </p>
  <p>
    I hereby agree on the Digital Account offering by SBM Bank where my
    signature will not be uploaded in this Digital Account as this is a
    non-issuable account that means I will not be issued any cheque book for the
    purpose of executing financial Transactions (No Payments through Cheques)
    but I accept the fact that account can be operated seamlessly through
    various digital channels where Financial Transactions through various modes
    will be accepted like Fund transfer using NEFT, RTGS, IMPS and between SBM
    Bank Accounts can be done on the Fintech Partner&rsquo;s platform and also
    through Mobile banking and internet banking channels of SBM Bank India.
  </p>
  <p>
    I hereby declare that the details furnished above are true and correct to
    the best of my/our knowledge and belief and I undertake to inform you of any
    changes therein, immediately. In case any of the above information is found
    to be false or untrue or misleading or misrepresenting, I/we am/are aware
    that I/we may be held liable for it.
  </p>
  <p>
    My personal / KYC details may be shared with Central KYC Registry, tax
    authorities / regulators both local and foreign. I hereby consent to
    receiving information from Central KYC Registry through SMS/Email on the
    registered mobile number / email address.
  </p>
  <p>I/We also agree to the following terms &amp; conditions</p>
  <ul>
    <li>
      I/We agree to maintain minimum/average quarterly balance, which SBM Bank
      may prescribe as the minimum/average quarterly balance to be maintained to
      avail the facilities and agree to pay the charges if minimum/average
      quarterly balance is not maintained and any other charges stipulated by
      SBM Bank. I/We understand that any change in this respect will be notified
      by SBM Bank on its website www.sbmbank.co.in and also will be displayed on
      the notice board of the branches one month in advance.
    </li>
  </ul>
  <p>
    I/We agree to comply with SBM Bank&rsquo;s rules in force from time to time
    regarding conduct of the account and agree to be bound by them.
  </p>
  <ul>
    <li>
      I/We confirm that the Company or any of its authorised signatories will
      abide by foreign exchange rules &amp; guidelines as per FEMA being issued
      by RBI from time to time, for any foreign exchange transactions.
    </li>
    <li>
      I/We understand that the Bank will check CRILC site before opening of the
      account. Based on CRILC information Bank may reject or decline an
      application.
    </li>
    <li>
      I/We hereby declare that, I/We am/are enjoying borrowing limit of less
      than `5 crores from another Bank(s) and undertake to inform SBM Bank
      immediately when the limit reaches Rs. 5 crores or above (fund and/or
      non-fund based).
    </li>
    <li>
      I/We confirm having read SBM Bank&rsquo;s tariff and agree to abide by the
      same.
    </li>
    <li>
      I/We also undertake to provide any further information/updated KYC
      documents that SBM Bank may require from time to time. I/We agree to
      indemnify SBM Bank against any fraud, loss or damage suffered by SBM Bank
      due to my/our providing of any incorrect information or failure to
      communicate any change in such particulars/information or provide true and
      updated documents.
    </li>
    <li>
      I/We agree, undertake and authorize SBM Bank to exchange, share or part
      with all the information, data or documents relating to my/our application
      to other SBM Financial Institutions/Credit Bureaus/Agencies/Statutory
      Bodies/such other persons as deemed necessary or appropriate or as may be
      required for use or processing of the said information / data by such
      person(s) or furnishing of the processed information / data / products
      thereof to other Banks/Financial Institutions / Credit Providers / Users
      registered with such persons as permitted by law and shall not hold its
      Group companies liable for use of this information.
    </li>
  </ul>
  <p>
    I/We also understand that SBM Bank would not use my/our KYC information for
    cross-selling of products.
  </p>
  <ul>
    <li>
      I/We agree and understand that SBM Bank reserves the right to reject any
      application without providing any reason and retain the application forms,
      and the documents provided therewith, including photographs and will not
      return the same to me/us.
    </li>
    <li>
      I/We have read and understood the rules and regulations of the
      product(s)/service(s) opted for and agree to abide by the terms and
      conditions relating to the conduct thereof as also any changes brought
      about therein from time to time. Further, I/we have read and understood
      the provisions contained in the &lsquo;Terms of Service document&rsquo;
      displayed on SBM Bank&rsquo;s website www.sbmbank.co.in and accept them.
      SBM Bank is entitled to amend the &lsquo;Terms of Service document&rsquo;
      displayed on their web site from time to time.
    </li>
    <li>
      I/We understand, if we have applied for single-user access for Corporate
      Internet Banking, we indemnify SBM Bank India Ltd from any loss arising
      out or may occur because of dilution of two-level checks provided to 2
      (two) users processing of a transaction under the maker / checker rule.
    </li>
  </ul>
  <p>FAX &amp; EMAIL INDEMNITY AND DECLARATION thereof</p>
  <p>I/We hereby request and authorize you as follows:</p>
  <ol>
    <li>
      Notwithstanding anything to the contrary contained in any other
      document/agreement, we hereby request and authorize SBM Bank to act and
      rely on any instructions or communications for any purpose (including but
      not limited to the instructions/communications pertaining to the operation
      of any and all of my/our account(s) and/or to any other facilities or
      services that may be provided by SBM Bank from time to time) which may
      from time to time be or purport to be given by facsimile or E-mail by
      me/us (including such instructions/communications as may be or purported
      to be given by those authorized to operate my/our account(s) with SBM
      Bank) (&ldquo;Instructions&rdquo;).
    </li>
    <li>
      I/We understand and acknowledge that there are inherent risks involved in
      sending the Instructions to SBM Bank via facsimile or E-mail and hereby
      agree and confirm that all risks shall be fully borne by me/us and I/we
      assume full responsibility for the same, and SBM Bank will not be liable
      for any losses or damages including legal fees arising upon SBM
      Bank&rsquo;s acting, or upon SBM Bank&rsquo;s failure to act, wholly or in
      part in accordance with the Instructions so received.
    </li>
    <li>
      In consideration of SBM Bank agreeing to act upon the Instructions as
      aforesaid, I/we hereby irrevocably agree and undertake as follows:
    </li>
    <li>
      I/We shall be liable for any and all charges, complaints, costs, damages,
      demands, expenses, liabilities, and losses which SBM Bank may incur,
      sustain or suffer arising from or by reason of SBM Bank&rsquo;s acting,
      delaying in acting or declining to act upon any message received from
      me/us including without limitation legal fees and disbursements reasonably
      incurred by SBM Bank.
    </li>
    <li>
      If we wish to withdraw this authority, then we shall give to SBM Bank a
      notice in writing delivered to SBM Bank and such notice shall be effective
      no later than five business days after delivery to SBM Bank.
    </li>
    <li>
      That SBM Bank shall be authorized to disclose all Instructions as SBM Bank
      may deem fit, to SBM Bank&rsquo;s affiliates, counterparties, service
      providers, regulators and other authorities or where SBM Bank is required
      by law to do so.
    </li>
    <li>
      That SBM Bank shall be entitled to require any Instructions in any form to
      be authenticated as may be specified by SBM Bank from time to time and
      I/we shall ensure the secrecy and security of such password, code or test
      and I/we shall be solely responsible for any improper use of the same.
    </li>
    <li>
      That notwithstanding the above, SBM Bank may, under circumstances
      determined by SBM Bank in its absolute discretion, require from me/us
      confirmation of any Instructions in such form as SBM Bank may specify
      before acting on the same; and I/we shall submit such confirmations to SBM
      Bank immediately upon receipt of their request. Pursuant to receipt of
      Instructions, SBM Bank shall have the right but not obligation to act upon
      such Instructions.
    </li>
    <li>
      SBM Bank, it&rsquo;s correspondents and other financial institutions
      involved in processing remittances, may rely on any account or
      identification numbers provided by me/us and will not seek to confirm
      whether the number specified corresponds with the name of the beneficiary
      or the beneficiary&rsquo;s bank provided in the payment order.
    </li>
    <li>
      Payment instructions executed by SBM Bank are irrevocable. Under certain
      circumstances to be exclusively determined by SBM Bank and without it
      being in any way liable however for any refusal, SBM Bank will on a
      &lsquo;best effort basis&rsquo; attempt to recall an electronic payment
      upon my/our instructions. However, SBM Bank cannot guarantee return of
      funds to me/us if SBM Bank is unable to obtain a return of funds. SBM Bank
      will credit my/our account at SBM Bank&rsquo;s quoted rate of exchange
      (where foreign exchange is requested by me/us on the date of such credit
      is made).
    </li>
    <li>
      I/We agree to pay SBM Bank&rsquo;s fees and to reimburse any deductions
      and for any withholding or other taxes and for any interest and penalties
      that may be paid by SBM Bank in connection with any remittances. I/We
      acknowledge that other financial institutions may deduct a fee for
      processing remittances. I/We irrevocably authorize SBM Bank to deduct from
      any of my/our account whether in India or elsewhere such amount paid.
    </li>
    <li>
      I/We acknowledge that international remittances are subject to cut off
      times, time zone differences and local regulations of the destination
      country.
    </li>
    <li>
      The undersigned hereby jointly and severally agree that SBM Bank may act
      on any message provided by either one of the undersigned and such message
      will be binding on the others without confirmation by SBM Bank. The
      undersigned jointly and severally agree to all the conditions stated in
      this communication. The death or winding up or insolvency of any one of
      the undersigned will not invalidate this communication which shall remain
      in effect until such time as notice of termination has been given as
      stated hereinabove (Strike out if inapplicable).
    </li>
  </ol>
  <p>
    I / We unconditionally agree to following terms &amp; conditions for
    operating the Account(s)/Existing accounts &amp; newly opened Savings,
    Current &amp; Fixed Deposit Accounts held with SBM Bank (India) Limited
    through Fax / Email instruction:
  </p>
  <ol>
    <li>
      The facility is available only for (a) Transfer of funds within the
      accounts held by me/us with SBM Bank India (b) Creation of Term Deposit by
      debit to my/our savings account with SBM Bank India (c) Statement request
      for accounts held by me/us with SBM Bank India (d) Hot listing of Cards
      for accounts held by me/us (e) Stop Payment requests for accounts held by
      me/us with SBM Bank India. (f) Mobile Number and E mail ID updation for
      my/our accounts with SBM Bank India.
    </li>
    <li>
      The instructions sent through my/our E-Mail ID / Fax as registered with
      the Bank, will be processed only on the basis of a Customer Request Form
      (CRF) duly signed by me/us and sent as a scan copy through my/our
      registered E-Mail ID/Fax. I/We agree that the Bank will not process the
      instructions provided by me in such a manner as above, in case my/our
      signature on the Customer Request Form does not match with the
      signature(s) available in the Bank&rsquo;s records
    </li>
    <li>
      Details of Fax No. and E-Mail ID given in this Form will constitute our
      registered Fax No. and E- Mail Id for SBM Bank India unless changed
      through my / our mandate and acknowledged by SBM Bank India.
    </li>
    <li>
      Operation of the Account through Fax / E-Mail shall be strictly confined
      to instructions sent through registered Fax No. / E-Mail Id and received
      by SBM Bank India in their Fax No. / E-Mail Id provided to me / us and SBM
      Bank India shall not be responsible for ensuring the validity and
      authorization for such instructions.
    </li>
    <li>
      Any change in the Fax No. / E-Mail Id has to be intimated by me / us to
      SBM Bank India in their Fax No. / E-Mail Id and acknowledged by it.
    </li>
    <li>
      SBM Bank India shall act on faxes / emails received from me / us only on
      working days and during the business hours of the aforesaid Branch of the
      Bank for aforesaid transactions and SBM Bank India shall not be
      responsible for any failure / rejection of the instruction due to lack of
      availability of time for execution of such instructions.
    </li>
    <li>
      SBM Bank India shall not be bound to act upon instructions received by Fax
      / E-Mail, which are illegible or multiple and not unambiguous and SBM Bank
      India opinion and decision shall be treated as final. SBM Bank India shall
      not be responsible for any losses or damages which I/We may suffer as a
      consequence thereof.
    </li>
    <li>
      SBM Bank India acting upon the instructions through Fax / E-Mail shall not
      be responsible for any losses / damages incurred out of transactions
      undertaking based on such instructions. If subsequently it was found by me
      / us that the instruction sent through Fax / E-Mail was not authorized or
      fraud or hacking has occurred at my / our Fax / E-Mail Id. SBM Bank India
      shall also not be responsible for the consequences.
    </li>
    <li>
      I/ We shall responsible for all the instructions given by Fax / E-Mail as
      to compliance of all laws or regulations of all statutory, regulatory and
      enforcement bodies and will bear all claims, losses, damages, costs
      liabilities and expenses incurred, suffered or paid by SBM Bank India
      acting upon the instructions received through Fax / E-Mail.
    </li>
  </ol>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p><strong><u>&nbsp;</u></strong></p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
  <p>&nbsp;</p>
</WizardBody>

<style>
  h2 {
    color: #1e3868;
    font-weight: 700;
  }
</style>
