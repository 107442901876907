<script context="module">
  export function setLoading(isLoading) {
    document.getElementById("loader").style.visibility = isLoading
      ? "visible"
      : "collapse";
  }
</script>

<div
  id="loader"
  style="width:100%;height:100%;backdrop-filter: blur(6px); position:fixed;visibility:collapse; display:flex; align-items: center; z-index:1000"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: rgb(255, 255, 255,0); display: block; shape-rendering: auto;"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#083a9a"
      stroke-width="4"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
</div>
