<script context="module">
  import Device from "svelte-device-info";
</script>

<script>
  import Header from "./Header.svelte";
  import Navbar from "./NavBar.svelte";
  export let title;
  export let back;

  let focus = false;

  function setFocus(hasFocus) {
    focus = hasFocus;
  }
  window.setFocus = setFocus;
</script>

<Navbar {back} />
<Header {title} />
<div
  style={`width:100%; ${
    focus === true && Device.isMobile
      ? "flex-grow: 0;"
      : Device.isMobile
      ? "flex-grow: 0.84;"
      : "flex-grow: 0.98;"
  }`}
>
  <slot />
</div>
