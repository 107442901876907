<script>
  export let points = [];
  export let listFontStyle = "";
</script>

<div style="display: flex; flex-direction: column; width: 100%; gap: 10px;">
  {#each points as point}
    <div style="display: flex; width: 100%; gap: 2px;">
      <i
        style="margin-top: 4px; width: 10px; height: 10px; background: #A0B0CE; border-radius: 50%;"
      />

      <span class="list-font" style={listFontStyle}>{point}</span>
    </div>
  {/each}
</div>

<style>
  .list-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #505869;
    margin-left: 12px;
    display: flex;
    width: 100%;
  }
</style>
