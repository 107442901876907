export const consent = {
  en: {
    name: "English",
    value:
      "<p>I hereby authorize SBM Bank (India) Ltd. (“Bank”) to fetch/retrieve my Know Your Customer (“KYC”) records online from the Central KYC Registry (“CKYCR”) for the purpose of opening my account with the Bank. </p>" +
      "<p>I further authorize and give my consent to the Bank (and its service providers) to obtain for the purpose of opening my account with the Bank, any additional identification documents, data or details and carry out additional check with respect to my information from CKYCR, in the event:</p>" +
      "<ol>" +
      "<li>there is a change in the information as existing in the records of CKYCR;</li>" +
      "<li>the current address is required to be verified;</li>" +
      "<li>the Bank considers it necessary to verify the identity or address;</li>" +
      "<li>to perform enhanced due diligence or to build an appropriate risk profile;</li>" +
      "<li>KYC and periodic KYC process as per the Prevention of Money Laundering Act, 2002 and its rules thereunder and RBI guidelines, or for establishing my identity, carrying out my identification, offline verification demographic or other authentication/verification/identification as may be permitted as per applicable Law, for all accounts, facilities, services and relationships of/through the Bank, existing and future;</li>" +
      "<li>collecting, sharing, storing, preserving Information, maintaining records and using the information and authentication/verification/identification records: (a) for the informed purposes above; (b) as well as for regulatory and legal reporting and filings; and/or (c) where required under applicable Law;</li>" +
      "<li>Producing records and logs of consent, information or of authentication, identification, verification etc, for evidentiary purposes including before a court of law, or any authority or in arbitration.</li>" +
      "</ol>" +
      "<p>I hereby consent to receiving information from CKYCR through SMS/Email on the above registered number/email address.</p>" +
      "<p>I hereby confirm that the above consent, purpose of collecting my consent and the nature of information to be collected pursuant to my consent has been explained to me to my satisfaction in the local language as known and understood by me.</p>",
  },
  hi: {
    name: "हिंदी (Hindi)",
    value:
      "<p>मैं बैंक के साथ मेरा खाता खोलने के उद्देश्य के लिये एसबीएम बैंक (इंडिया) लिमिटेड (‘‘बैंक’’) को सेंट्रल केवाइसी रेजिस्ट्री (‘‘CKYCR’’) से मेरे नो योर कस्टमर (“KYC”) रेकॉर्ड्स प्राप्त करने का अधिकार देता/देती हूं।</p>" +
      "<p>साथ ही मैं निम्नलिखित स्थितियों में बैंक (और उसके सेवा प्रदाताओं) को बैंक के साथ मेरा खाता खोलने के उद्देश्य के लिये CKYCR से कोई भी अतिरिक्त दस्तावेज़, डाटा या विवरण प्राप्त करने और मेरी जानकारी की अतिरिक्त जाँच करने का भी अधिकार देता/देती हूं:</p>" +
      "<ol>" +
      "<li>CKYCR के रेकॉर्ड्स में मौजूद जानकारी में कोई बदलाव हुआ है;</li>" +
      "<li>मौजूदा पते का सत्यापन आवश्यक है;</li>" +
      "<li>बैंक को पहचान या पते की जाँच आवश्यक लगती है;</li>" +
      "<li>विस्तृत जाँच करने के लिये या उचित जोखिम प्रोफाइल तैयार करने के लिये;</li>" +
      "<li>हवाला विरोधी कानून, 2002 और उसके नियमों तथा आरबीआइ के दिशानिर्देशों के अनुसार या मेरी पहचान की पुष्टि के लिये, मेरी पहचान के लिये, ऑफलाइन जनांकिक सत्यापन के लिये KYC और आवधिक KYC प्रक्रिया या लागू कानूनों के अनुसार बैंक के साथ सभी वर्तमान और भविष्य के खातों, सेवाओं, सुविधाओं और संबंधों के लिये आवश्यक अन्य सत्यापन/जाँच/पहचान</li>" +
      "<li>जानकारी लेना, साझा करना, भंडारण करना, सुरक्षित रखना, रेकॉर्ड्स रखना और जानकारी का इस्तेमाल करना और रेकॉर्ड्स का सत्यापन/जाँच/पहचान करना: (a) ऊपर दिये गये सूचित उद्देश्यों के लिये; (b) साथ ही नियामक और कानूनी रिपोर्टिंग और फाइलिंग के लिये; और/या लागू कानून के अधीन आवश्यक होने पर;</li>" +
      "<li> न्यायालय के समक्ष, किसी वैधानिक, प्रशासनिक प्राधिकरण या किसी अन्य प्राधिकरण, किसी नियामक या मध्यस्थ के समक्ष प्रमाण के रूप में रेकॉर्ड्स, अनुमति, जानकारी या सत्यापन, पहचान, जाँच इत्यादि के लॉग प्रस्तुत करना।</li>" +
      "<p>मैं उपर्युक्त रेजिस्टर्ड नंबर/ईमेल अड्रेस पर CKYCR से एसएमएस/ईमेल द्वारा जानकारी प्राप्त करने की अनुमति देता/देती हूं।</p>" +
      "<p>मैं पुष्टि करता/करती हूं कि उपर्युक्त अनुमति, मेरी अनुमति लेने का उद्देश्य और मेरी अनुमति के अनुसार ली जाने वाली जानकारी का प्रकार मुझे समझ में आने वाली प्रादेशिक भाषा में संतोषजनक रूप से स्पष्ट किया गया है।</p>" +
      "</ol>",
  },
  be: {
    name: "বাংলা (Bengali)",
    value:
      "<p>আমি এতদ্বারা SBM ব্যাঙ্ক (ইন্ডিয়া) লিমিটেড (“ব্যাঙ্ক”) কে ব্যাঙ্কের সঙ্গে আমার অ্যাকাউন্ট খোলার উদ্দেশ্যে, সেন্ট্রাল KYC রেজিস্ট্রি (“CKYCR”) থেকে অনলাইনে আমার নো ইওর কাস্টোমার (“KYC”) রেকর্ডগুলি আনার/পুনরুদ্ধার করার জন্য অনুমোদিত করি|</p>" +
      "<p> আমি, ব্যাঙ্কের সঙ্গে আমার অ্যাকাউন্ট খোলার উদ্দেশ্যে, যেকোনো অতিরিক্ত নথিপত্রাদি, পরিসংখ্যান অথবা বিবরণসমূহ প্রাপ্ত করার এবং CKYCR থেকে প্রাপ্ত আমার তথ্য সম্পর্কে অতিরিক্ত যাচাই পরিচালিত করার জন্য ব্যাঙ্কের (এবং তাদের পরিষেবা প্রদানকারীদের) নিকট আরও অনুমোদিত করি এবং আমার সম্মতি প্রদান করি:</p>" +
      "<ol>" +
      "<li>CKYCR এর রেকর্ডগুলিতে বিদ্যমান অনুসারে তথ্যের ক্ষেত্রে যেকোনো পরিবর্তন;</li>" +
      "<li>বর্তমান ঠিকানাটি যাচাই করার প্রয়োজনীয়তা আছে;</li>" +
      "<li>ব্যাঙ্ক, পরিচয় অথবা ঠিকানা যাচাই করার প্রয়োজনীয় বলে বিবেচনা করে;</li>" +
      "<li>বর্ধিত ন্যায়সংগত নিষ্ঠার অনুশীলনের অথবা একটি উপযুক্ত ঝুঁকির রূপরেখা গঠনের উদ্দেশ্যে;</li>" +
      "<li>ব্যাঙ্কের সঙ্গে/মাধ্যমে, সকল বর্তমানে বিদ্যমান অথবা ভবিষ্যৎ অ্যাকাউন্ট, সুবিধাসমূহ, পরিষেবা এবং সম্পর্কসমূহের জন্য প্রিভেনশন অফ মানি লন্ডারিং অ্যাক্ট, 2002 এবং তদোধীন সেটির রুলস এবং RBI এর নির্দেশিকা অনুসারে KYC এবং পর্যায়ক্রমিক KYC প্রক্রিয়া অথবা অফলাইনে আমার শনাক্তকরণ, জন-তাত্ত্বিক অথবা অন্যান্য প্রত্যয়ন/যাচাইকরণ/শনাক্তকরণ, যে প্রকার প্রযোজ্য আইন অনুসারে অনুমোদিত হবে, যাচাই করার দ্বারা আমার পরিচয় প্রতিষ্ঠা করার উদ্দেশ্যে;</li>" +
      "<li>তথ্য সংগ্রহ করা, শেয়ার করা, মজুত করা, সংরক্ষণ করা, রেকর্ডগুলি রক্ষণাবেক্ষণ করা এবং তথ্য এবং রেকর্ড প্রত্যয়ন/যাচাইকরণ/শনাক্তকরণ:(a) উপরে অবহিত উদ্দেশ্যগুলির জন্য; (b) এছাড়াও নিয়মিততা এবং আইনি প্রতিবেদন এবং ফাইলিং; এবং/অথবা (c) যেখানে প্রযোজ্য আইনের অধীনে প্রয়োজনীয়;</li>" +
      "<li>একটি আইনি আদালতের, যেকোনো বিধির, প্রশাসনিক, আইন ভিত্তিক কর্তৃপক্ষ অথবা অন্য যেকোনো কর্তৃপক্ষ, অন্য যেকোনো নিয়ন্ত্রক অথবা শালিসির সম্মুখে প্রমাণমূলক উদ্দেশ্যে সম্মতি, প্রত্যয়ন, শনাক্তকরণ, যাচাইকরণ, ইত্যাদির তথ্য অথবা রেকর্ড এবং লগগুলি উপস্থাপিত করা|</li>" +
      "<p>আমি এতদ্বারা CKYCR এর থেকে উপরের রেজিস্টার্ড নম্বরে/ইমেল ঠিকানায় SMS/ইমেল এর মাধ্যমে তথ্য প্রাপ্ত করার জন্য সম্মতি প্রদান করি|</p>" +
      "<p>আমি এতদ্বারা নিশ্চিত করি যে উপরের সম্মতি, আমার সম্মতি সংগ্রহ করার উদ্দেশ্যে এবং আমার সম্মতির অনুসরণে সংগ্রহণীয় তথ্যের প্রকার আমার নিকট, আমার জানা এবং আমি ৱুঝি এমন একটি ভাষায়, আমার সন্তুষ্টি মত ব্যাখ্যা করা হয়েছে|</p>" +
      "</ol>",
  },
  gu: {
    name: "ગુજરાતી (Gujarati)",
    value:
      "<p>હું આથી એસબીએમ બેંક (ઇન્ડિયા) લિમિટેડ (બેંક” બેંકમા‘ મારુ‘ ખાતુ‘ ખોલાવવાના હેતુસર સેન્ટ્રલ કેવાયસી રજિસ્ટ્રી (“CKYCR”) માંથી મારા નો યોર કસ્ટમર (“KYC”) રેકોર્ડ્સ ઑ‘લાઇ‘ મેળવવા/પ્રાપ્ત કરવા માટે અધિકૃત કરું છું.</p> " +
      "<p>જો ઘટનાઓ સંભવે તો, હું વધુમાં બેંક (અને તેના સેવા પ્રદાતાઓ)ને બેંકમાં મારું ખાતું ખોલાવવાના હેતુસર, કોઈપણ વધારાના ઓળખ દસ્તાવેજો, ડેટા અથવા વિગતો મેળવવા અધિકૃત કરું છું અને CKYCR પાસેથી મારી માહિતીના સંદર્ભમાં વધારાની તપાસ કરવા માટે મારી સંમતિ આપું છું :</p>" +
      "<ol>" +
      "<li>CKYCR રેકોર્ડમાં હાલની માહિતીમાં ફેરફાર હોય;</li>" +
      "<li>વર્તમાન સરનામું ચકાસવું જરૂરી હોય;</li>" +
      "<li>બેંક ઓળખ અથવા સરનામાની ચકાસણી કરવી જરૂરી માને;</li>" +
      "<li>એન્હૅન્સ્ડ ડ્યૂ ડિલિજન્સ પ્રક્રિયા કરવા અથવા યોગ્ય જોખમ (રિસ્ક) પ્રોફાઇલ બનાવવા માટે;</li>" +
      "<li>પ્રિવેન્શન ઑફ મની લોન્ડરિંગ ઍક્ટ, 2002 અને તેના હેઠળના નિયમો અને આરબીઆઈની માર્ગદર્શિકા અનુસાર KYC અને સામયિક KYC પ્રક્રિયા, અથવા મારી ઓળખ સ્થાપિત કરવા, મારી ઓળખ, ઑફલાઇન ચકાસણી વસ્તી વિષયક અથવા અન્ય પ્રમાણીકરણ/ચકાસણી/ઓળખ શક્ય હોય તેમ વર્તમાન અને ભવિષ્યના તમામ ખાતાઓ, સવલતો, સેવાઓ અને બેંકના/માર્ગેના સંબંધો માટે લાગુ કાયદા મુજબ પરવાનગી;</li>" +
      "<li>માહિતી એકત્ર કરવી, વહેંચવી, સંગ્રહ કરવી, સાચવવી, રેકોર્ડ જાળવવો અને માહિતી અને પ્રમાણીકરણ/ચકાસણી/ ઓળખના રેકોર્ડનો ઉપયોગ કરવો: (ક) ઉપર જણાવેલ હેતુઓ માટે; (ખ) તેમજ નિયમનકારી અને કાનૂની રિપોર્ટિંગ અને ફાઇલિંગ માટે; અને/અથવા (ગ) જ્યાં લાગુ પડતા કાયદાઓ અંતર્ગત જરૂરી હોય;</li>" +
      "<li>કાયદાની અદાલત, કોઈપણ વૈધાનિક, વહીવટી, કાયદાકીય સત્તા અથવા અન્ય કોઈ સત્તા, કોઈપણ નિયમનકાર અથવા આર્બિટ્રેશનમાં સહિતના પુરાવાના હેતુઓ માટે સંમતિ, માહિતી અથવા પ્રમાણીકરણ, ઓળખ, ચકાસણી વગેરેના રેકોર્ડ્સ અને લોગ્સનું નિર્માણ કરવા.</li>" +
      "<p>હું ઉપરોક્ત રજિસ્ટર્ડ નંબર/ઈમેલ એડ્રેસ પર એસએમએસ/ઈમેલ દ્વારા CKYCR પાસેથી માહિતી મેળવવા માટે આથી સંમતિ આપું છું.</p>" +
      "<p>હું આથી પુષ્ટિ કરું છું કે ઉપરોક્ત સંમતિ, મારી સંમતિ એકત્ર કરવાનો હેતુ અને મારી સંમતિને અનુરૂપ માહિતી એકત્રિત કરવાની પ્રકૃતિ મને મારા દ્વારા જાણીતી અને સમજાયેલી સ્થાનિક ભાષામાં મારા સંતોષ માટે સમજાવવામાં આવી છે.</p>" +
      "</ol>",
  },
  ka: {
    name: "ಕನ್ನಡ (Kannada)",
    value:
      "<p>ಬ್ಯಾಂಕ್‌ನಲ್ಲಿ ನನ್ನ ಖಾತೆಯನ್ನು ತೆರೆಯುವ ಉದ್ದೇಶಕ್ಕಾಗಿ ಕೇಂದ್ರೀಯ ಕೆವೈಸಿ ರಿಜಿಸ್ಟ್ರಿಯಿಂದ (“ಸಿ ಕೆ ವೈ ಸಿ ಆರ್‍”) ನನ್ನ ನಿಮ್ಮ ಗ್ರಾಹಕರ ಬಗ್ಗೆ ತಿಳಿಯಿರಿ (“ಕೆವೈಸಿ”) ದಾಖಲೆಗಳನ್ನು ಆನ್‌ಲೈನ್‌ನಲ್ಲಿ ಪಡೆಯಲು/ಹಿಂಪಡೆಯಲು ಎಸ್ ಬಿ ಎಂ ಬ್ಯಾಂಕ್ (ಇಂಡಿಯಾ) ಲಿಮಿಟೆಡ್ (“ಬ್ಯಾಂಕ್”) ಗೆ ನಾನು ಈ ಮೂಲಕ ಅಧಿಕಾರ ನೀಡುತ್ತೇನೆ. </p>" +
      "<p>ಬ್ಯಾಂಕ್‌ನಲ್ಲಿ ನನ್ನ ಖಾತೆಯನ್ನು ತೆರೆಯುವ ಉದ್ದೇಶಕ್ಕಾಗಿ, ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ಗುರುತಿನ ದಾಖಲೆಗಳು, ಡೇಟಾ ಅಥವಾ ವಿವರಗಳನ್ನು ಪಡೆಯಲು ಮತ್ತು ಸಿಕೆವೈಸಿಆರ್ ನಿಂದ ನನ್ನ ಮಾಹಿತಿಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ಹೆಚ್ಚುವರಿ ಪರಿಶೀಲನೆ ನಡೆಸಲು ನಾನು ಬ್ಯಾಂಕ್‌ಗೆ (ಮತ್ತು ಅದರ ಸೇವಾ ಪೂರೈಕೆದಾರರಿಗೆ) ಈ ಕೆಳಗಿನ ಸಂದರ್ಭಗಳಲ್ಲಿ ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ನೀಡುತ್ತಿದ್ದೇನೆ:</p>" +
      "<ol>" +
      "<li>ಸಿಕೆವೈಸಿಆರ್ ದಾಖಲೆಗಳಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಮಾಹಿತಿಯಲ್ಲಿ ಬದಲಾವಣೆ ಇದ್ದರೆ;</li>" +
      "<li>ಪ್ರಸ್ತುತ ವಿಳಾಸವನ್ನು ಪರಿಶೀಲಿಸುವ ಅಗತ್ಯವಿದ್ದರೆ;</li>" +
      "<li>ಗುರುತು ಅಥವಾ ವಿಳಾಸವನ್ನು ಪರಿಶೀಲಿಸುವುದು ಅಗತ್ಯವೆಂದು ಬ್ಯಾಂಕ್ ಪರಿಗಣಿಸಿದರೆ;</li>" +
      "<li>ಬದ್ಧತೆ ನಿರ್ವಹಿಸಲು ಅಥವಾ ಸೂಕ್ತವಾದ ಅಪಾಯದ ಪ್ರೊಫೈಲ್ ನಿರ್ಮಿಸಬೇಕಾದಲ್ಲಿ;</li>" +
      "<li>2002 ರ ಹಣ ವಂಚನೆ ತಡೆಗಟ್ಟುವಿಕೆ ಕಾಯ್ದೆ, ಮತ್ತು ಅದರ ನಿಯಮಗಳು ಹಾಗೂ ಆರ್ ಬಿ ಐ ಮಾರ್ಗಸೂಚಿಗಳ ಪ್ರಕಾರ ಎಲ್ಲಾ ಖಾತೆಗಳು, ಸೌಲಭ್ಯಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಬ್ಯಾಂಕ್‌ನ/ಮೂಲಕ, ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಮತ್ತು ಭವಿಷ್ಯದ ಕೆವೈಸಿ ಮತ್ತು ಆವರ್ತಕ ಕೆವೈಸಿ ಪ್ರಕ್ರಿಯೆ, ಅಥವಾ ನನ್ನ ಗುರುತನ್ನು ಸ್ಥಾಪಿಸಲು, ನನ್ನ ಗುರುತನ್ನು ಪರಿಶೀಲನೆ ನಡೆಸಲು, ಆಫ್‌ಲೈನ್ ಪರಿಶೀಲನೆ ಜನಸಂಖ್ಯಾಶಾಸ್ತ್ರ ಅಥವಾ ಇತರ ದೃಢೀಕರಣ/ಪರಿಶೀಲನೆ/ಗುರುತಿಸುವಿಕೆ ಅನ್ವಯವಾಗುವ ಕಾನೂನಿನ ಪ್ರಕಾರ ಅನುಮತಿಸಿದ್ದರೆ</li>" +
      "<li>ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುವುದು, ಹಂಚಿಕೊಳ್ಳುವುದು, ಸಂಗ್ರಹಿಸುವುದು, ಸಂರಕ್ಷಿಸುವುದು, ದಾಖಲೆಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು ಮತ್ತು ಮಾಹಿತಿ ಮತ್ತು ದೃಢೀಕರಣ/ಪರಿಶೀಲನೆ/ಗುರುತಿನ ದಾಖಲೆಗಳನ್ನು ಬಳಸುವುದು: (ಎ) ಮೇಲಿನ ಮಾಹಿತಿ ಉದ್ದೇಶಗಳಿಗಾಗಿ; (ಬಿ) ಹಾಗೆಯೇ ನಿಯಂತ್ರಕ ಮತ್ತು ಕಾನೂನು ವರದಿ ಮತ್ತು ಫೈಲಿಂಗ್‌ಗಳಿಗಾಗಿ; ಮತ್ತು/ಅಥವಾ (ಸಿ) ಅನ್ವಯವಾಗುವ ಕಾನೂನಿನ ಅಡಿಯಲ್ಲಿ ಅಗತ್ಯವಿದ್ದಲ್ಲಿ;</li>" +
      "<li>ನ್ಯಾಯಾಲಯದ ಮುಂದೆ, ಯಾವುದೇ ಶಾಸನಬದ್ಧ, ಆಡಳಿತಾತ್ಮಕ, ಶಾಸಕಾಂಗ ಪ್ರಾಧಿಕಾರ ಅಥವಾ ಯಾವುದೇ ಇತರ ಪ್ರಾಧಿಕಾರ, ಯಾವುದೇ ನಿಯಂತ್ರಕ ಅಥವಾ ಮಧ್ಯಸ್ಥಿಕೆಯಲ್ಲಿ ಸೇರಿದಂತೆ ಸಾಕ್ಷಿ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಸಮ್ಮತಿ, ಮಾಹಿತಿ ಅಥವಾ ದೃಢೀಕರಣ, ಗುರುತಿಸುವಿಕೆ, ಪರಿಶೀಲನೆ ಇತ್ಯಾದಿಗಳ ದಾಖಲೆಗಳು ಮತ್ತು ದಾಖಲೆಗಳನ್ನು ಉತ್ಪಾದಿಸುವುದು.</li>" +
      "</ol>" +
      "<p>ಮೇಲಿನ ನೋಂದಾಯಿತ ಸಂಖ್ಯೆ/ಇಮೇಲ್ ವಿಳಾಸದಲ್ಲಿ ಎಸ್ ಎಂ ಎಸ್/ಇಮೇಲ್ ಮೂಲಕ ಸಿಕೆವೈಸಿಆರ್ ನಿಂದ ಮಾಹಿತಿಯನ್ನು ಸ್ವೀಕರಿಸಲು ನಾನು ಈ ಮೂಲಕ ಸಮ್ಮತಿಸುತ್ತೇನೆ.</p>" +
      "<p>ಮೇಲಿನ ಒಪ್ಪಿಗೆ, ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ಸಂಗ್ರಹಿಸುವ ಉದ್ದೇಶ ಮತ್ತು ನನ್ನ ಒಪ್ಪಿಗೆಗೆ ಅನುಗುಣವಾಗಿ ಸಂಗ್ರಹಿಸಬೇಕಾದ ಮಾಹಿತಿಯ ಸ್ವರೂಪವನ್ನು ಸ್ಥಳೀಯ ಭಾಷೆಯಲ್ಲಿ ನನಗೆ ತಿಳಿದಿರುವಂತೆ ಮತ್ತು ಅರ್ಥಮಾಡಿಕೊಂಡಂತೆ ನನಗೆ ತೃಪ್ತಿಯಾಗುವಂತೆ ವಿವರಿಸಲಾಗಿದೆ ಎಂದು ನಾನು ಈ ಮೂಲಕ ದೃಢೀಕರಿಸುತ್ತೇನೆ.</p>",
  },
  mar: {
    name: "मराठी (Marathi)",
    value:
      "<p>मी ह्याद्वारे एसबीएम बँक (इंडिया) लि. (‘‘बँक’’) ला बँकेकडे माझे खाते उघडण्याच्या हेतुसाठी माझ्या नो युअर कस्टमर (‘‘केवायसी’’) नोंदी केंद्रीय केवायसी नोंदणी शाखेमधून (‘‘सीकेवायसीआर’’) मागवण्यास / मिळवण्यास अधिकृत करत आहे.</p>" +
      "<p>मी त्याबरोबरच बँकेकडे माझे खाते उघडण्याच्या हेतुसाठी, बँकेला (आणि तिच्या सेवा पुरवठादारांना) कोणतीही अतिरिक्त परिचयाची कागदपत्रे, आधारसामग्री किंवा तपशील मिळवण्यास आणि सीकेवायसीआर कडे माझ्या माहितीसंदर्भात अतिरिक्त चौकशी करण्यास अधिकृत करत आहे आणि माझी संमती देत आहे, सबब :</p>" +
      "<ol>" +
      "<li>सीकेवायसीआरच्या नोंदींमध्ये उपलब्ध माहितीमध्ये कोणताही बदल झाला असेल;</li>" +
      "<li>विद्यमान पत्त्याची पडताळणी आवश्यक असेल;</li>" +
      "<li>बँकेला ओळख किंवा पत्ता पडताळून पाहणे आवश्यक वाटत असेल तर;</li>" +
      "<li>वाढीव यथायोग्य तत्परतेने कार्य करण्यासाठी किंवा जोखमीची रूपरेषा व्यवस्थित उभारण्यासाठी;</li>" +
      "<li>आर्थिक गैरव्यवहार प्रतिबंधक कायदा, 2002 आणि त्यांअतर्गत नियमांन्वये व आरबीआयच्या मार्गदर्शक तत्त्वांनुसार, किंवा माझी ओळख प्रस्थापित करण्यासाठी, माझा परिचय स्थापित करण्यासाठी, माझ्या माहितीच्या ऑफलाइन पडताळणीसाठी किंवा लागू कायद्यांनुसार अनुमत असणार्‍या इतर कोणत्याही अधिकृतीसाठी / पडताळणीसाठी / परिचयासाठी, बँकेच्या / बँकेमार्फत विद्यमान आणि भावी सर्व खात्यांकरिता, सुविधांकरिता, सेवांकरिता आणि संबंधांकरिता, केवायसी आणि कालौघिक केवायसी प्रक्रिया;</li>" +
      "<li>माहिती गोळा करणे, वितरित करणे, साठवणे, जतन करणे, नोंदी बाळगणे आणि माहिती व अधिकृती / पडताळणी / परिचय नोंदी वापर : (अ) वरील माहितीप्राप्त हेतुंकरिता; (ब) तसेच नियामकीय आणि कायदेशीर अहवालांकरिता आणि दाखल्यांकरिता; आणि / किंवा (क) जिथे लागू कायद्यांतर्गत आवश्यक असेल तिथे;</li>" +
      "<li>न्यायालयासमोर, कोणत्याही सांविधिक, प्रशासकीय, वैधानिक प्राधिकरणासमोर किंवा इतर कोणत्याही प्राधिकरणासमोर, कोणत्याही नियामकासमोर किंवा लवादामध्ये पुराव्याच्या हेतुकरिता संमती, माहिती किंवा अधिकृती, परिचय, पडताळणी, इ. च्या नोंदी आणि कृती नोंदवही सादर करण्याकरिता.</li>" +
      "<p>मी ह्याद्वारे सीकेवायसीआरकडून एसएमएस / ईमेल मार्फत वरील नोंदणीकृत क्रमांकावर / ईमेल पत्त्यावर माहिती प्राप्त करण्यास संमती देत आहे.</p>" +
      "<p>मी ह्याद्वारे पुष्टी देत आहे की वरील संमती, माझी संमती घेण्याचा हेतु आणि मी संमती दिल्यावर गोळा करण्यात येणार्‍या माहितीचे स्वरूप मला समाधानकारकरीत्या मला माहीत असणार्‍या आणि समजणार्‍या स्थानिक भाषेमध्ये समजावून देण्यात आले आहे.</p>" +
      "</ol>",
  },
  pu: {
    name: "ਪੰਜਾਬੀ (Punjabi)",
    value:
      "<p>ਮੈਂ ਇਸ ਦੁਆਰਾ ਐਸਬੀਐਮ ਬੈਂਕ (ਇੰਡੀਆ) ਲਿਮਿਟੇਡ (“ਬੈਂਕ”) ਨੂੰ ਬੈਂਕ ਵਿੱਚ ਮੇਰਾ ਖਾਤਾ ਖੋਲ੍ਹਣ ਦੇ ਉਦੇਸ਼ ਲਈ ਕੇਂਦਰੀ ਕੇਵਾਈਸੀ ਰਜਿਸਟਰੀ (“ਸੀਕੇਵਾਈਸੀਆਰ”) ਤੋਂ ਮੇਰੇ ਆਪਣੇ ਗ੍ਰਾਹਕ ਨੂੰ ਜਾਣੋ (“ਕੇਵਾਈਸੀ”) ਰਿਕਾਰਡਾਂ ਨੂੰ ਔਨਲਾਈਨ ਕੱਢਣ/ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਅਧਿਕਾਰਤ ਕਰਦਾ/ਕਰਦੀ ਹਾਂ।</p>" +
      "<p>ਮੈਂ ਹੇਠ ਦਿੱਤੀ ਸਥਿਤੀ ਵਿੱਚ ਬੈਂਕ ਵਿੱਚ ਆਪਣਾ ਖਾਤਾ ਖੋਲ੍ਹਣ, ਕੋਈ ਵੀ ਵਾਧੂ ਪਛਾਣ ਦਸਤਾਵੇਜ਼, ਡੇਟਾ ਜਾਂ ਵੇਰਵੇ ਅਤੇ ਸੀਕੇਵਾਈਸੀਆਰ ਤੋਂ ਮੇਰੀ ਜਾਣਕਾਰੀ ਦੇ ਸਬੰਧ ਵਿੱਚ ਵਧੀਕ ਜਾਂਚ ਕਰਵਾਉਣ ਦੇ ਉਦੇਸ਼ ਲਈ ਬੈਂਕ (ਅਤੇ ਇਸਦੇ ਸੇਵਾ ਪ੍ਰਦਾਤਾਵਾਂ) ਨੂੰ ਅਧਿਕਾਰਤ ਕਰਦਾ ਹਾਂ ਅਤੇ ਆਪਣੀ ਸਹਿਮਤੀ ਦਿੰਦਾ/ਦਿੰਦੀ ਹਾਂ:</p>" +
      "<ol>" +
      "<li>ਜੇਕਰ ਸੀਕੇਵਾਈਸੀਆਰ ਦੇ ਰਿਕਾਰਡਾਂ ਵਿੱਚ ਮੌਜੂਦ ਜਾਣਕਾਰੀ ਵਿੱਚ ਕੋਈ ਬਦਲਾਅ ਹੁੰਦਾ ਹੈ;</li>" +
      "<li>ਜੇਕਰ ਮੌਜੂਦਾ ਪਤੇ ਨੂੰ ਤਸਦੀਕ ਕਰਨ ਦੀ ਲੋੜ ਹੈ;</li>" +
      "<li>ਜੇਕਰ ਬੈਂਕ ਪਛਾਣ ਜਾਂ ਪਤੇ ਦੀ ਤਸਦੀਕ ਕਰਨਾ ਜ਼ਰੂਰੀ ਸਮਝਦਾ ਹੈ;</li>" +
      "<li>ਜੇਕਰ ਵਧੀ ਹੋਈ ਢੁੱਕਵੀਂ ਜਾੰਚ ਕਰਨ ਲਈ ਜਾਂ ਢੁਕਵੀਂ ਜੋਖਮ ਪ੍ਰੋਫਾਈਲ ਬਣਾਉਣ ਲਈ;</li>" +
      "<li>ਜੇਕਰ ਮਨੀ ਲਾਂਡਰਿੰਗ ਦੀ ਰੋਕਥਾਮ ਐਕਟ, 2002 ਅਤੇ ਇਸ ਦੇ ਨਿਯਮਾਂ ਅਤੇ ਆਰਬੀਆਈ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ਾਂ ਦੇ ਅਨੁਸਾਰ ਕੇਵਾਈਸੀ ਅਤੇ ਸਮੇਂ-ਸਮੇਂ ਤੇ ਕੇਵਾਈਸੀ ਪ੍ਰਕਿਰਿਆ, ਜਾਂ ਮੌਜੂਦਾ ਅਤੇ ਭਵਿੱਖ ਦੇ ਸਾਰੇ ਖਾਤਿਆਂ, ਸਹੂਲਤਾਂ, ਸੇਵਾਵਾਂ ਅਤੇ ਬੈਂਕ ਦੇ/ਰਾਹੀਂ ਸਬੰਧਾਂ ਲਈ ਮੇਰੀ ਪਛਾਣ ਸਥਾਪਤ ਕਰਨ ਲਈ, ਮੇਰੀ ਪਛਾਣ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ, ਔਫਲਾਈਨ ਤਸਦੀਕ ਜਨਸੰਖਿਆ ਸੰਬੰਧੀ ਜਾਂ ਹੋਰ ਪ੍ਰਮਾਣਿਕਤਾ/ਤਸਦੀਕ/ਪਛਾਣ ਨੂੰ ਲਾਗੂ ਕਰਨ ਵਾਲੇ ਕਾਨੂੰਨ ਦੇ ਅਨੁਸਾਰ ਆਗਿਆ ਦਿੱਤੀ ਜਾ ਸਕਦੀ ਹੈ;</li>" +
      "<li>ਜੇਕਰ ਜਾਣਕਾਰੀ ਨੂੰ ਇਕੱਠਾ ਕਰਨਾ, ਸਾਂਝਾ ਕਰਨਾ, ਸਟੋਰ ਕਰਨਾ, ਸੰਭਾਲਣਾ, ਰਿਕਾਰਡ ਬਣਾਏ ਰੱਖਣਾ ਅਤੇ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਕਰਨਾ ਅਤੇ ਰਿਕਾਰਡਾਂ ਦੀ ਪ੍ਰਮਾਣਿਕਤਾ/ਤਸਦੀਕ/ਪਛਾਣ ਕਰਨਾ: (a) ਉਪਰੋਕਤ ਸੂਚਿਤ ਉਦੇਸ਼ਾਂ ਲਈ; (b) ਅਤੇ ਨਾਲ ਹੀ ਰੈਗੂਲੇਟਰੀ ਅਤੇ ਕਾਨੂੰਨੀ ਰਿਪੋਰਟਿੰਗ ਅਤੇ ਫਾਈਲਿੰਗ ਲਈ; ਅਤੇ/ਜਾਂ (c) ਜਿੱਥੇ ਲਾਗੂ ਕਾਨੂੰਨ ਦੇ ਅਧੀਨ ਲੋੜੀਂਦਾ ਹੋਵੇ;</li>" +
      "<li>ਜੇਕਰ ਕਨੂੰਨ ਦੀ ਅਦਾਲਤ ਦੇ ਸਾਹਮਣੇ, ਕੋਈ ਵਿਧਾਨਕ, ਪ੍ਰਸ਼ਾਸਕੀ, ਵਿਧਾਨਿਕ ਅਥਾਰਟੀ ਜਾਂ ਕੋਈ ਹੋਰ ਅਥਾਰਟੀ, ਕੋਈ ਰੈਗੂਲੇਟਰ ਜਾਂ ਆਰਬਿਟਰੇਸ਼ਨ ਸਮੇਤ ਪ੍ਰਮਾਣਿਕ ਉਦੇਸ਼ਾਂ ਲਈ ਸਹਿਮਤੀ, ਜਾਣਕਾਰੀ ਜਾਂ ਪ੍ਰਮਾਣਿਕਤਾ, ਪਛਾਣ, ਤਸਦੀਕ ਆਦਿ ਦੇ ਰਿਕਾਰਡ ਅਤੇ ਲੌਗ ਤਿਆਰ ਕਰਨਾ।</li>" +
      "<p>ਮੈਂ ਇਸ ਦੁਆਰਾ ਸੀਕੇਵਾਈਸੀਆਰ ਤੋਂ ਉਪਰੋਕਤ ਰਜਿਸਟਰਡ ਨੰਬਰ/ਈਮੇਲ ਪਤੇ ਤੇ ਐਸਐਮਐਸ/ਈਮੇਲ ਰਾਹੀਂ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਸਹਿਮਤੀ ਦਿੰਦਾ/ਦਿੰਦੀ ਹਾਂ।</p>" +
      "<p>ਮੈਂ ਇਸ ਦੁਆਰਾ ਪੁਸ਼ਟੀ ਕਰਦਾ/ਕਰਦੀ ਹਾਂ ਕਿ ਉਪਰੋਕਤ ਸਹਿਮਤੀ, ਮੇਰੀ ਸਹਿਮਤੀ ਇਕੱਤਰ ਕਰਨ ਦਾ ਉਦੇਸ਼ ਅਤੇ ਮੇਰੀ ਸਹਿਮਤੀ ਦੇ ਅਨੁਸਾਰ ਇਕੱਤਰ ਕੀਤੀ ਜਾਣ ਵਾਲੀ ਜਾਣਕਾਰੀ ਦੀ ਪ੍ਰਕਿਰਤੀ ਬਾਰੇ ਮੈਨੂੰ ਸਥਾਨਕ ਭਾਸ਼ਾ ,ਜਿਸ ਤੋਂ ਮੈਂ ਜਾਣੂ ਹਾਂ ਅਤੇ ਸਮਝਦਾ ਹਾਂ, ਵਿੱਚ ਮੇਰੀ ਸੰਤੁਸ਼ਟੀ ਹੋਣ ਤੱਕ ਸਮਝਿਆ ਗਿਆ ਹੈ।</p>" +
      "</ol>",
  },
  ta: {
    name: "தமிழ் (Tamil)",
    value:
      "<p>நான் இதன் மூலம் வங்கியுடன் எனது கணக்கை திறக்கும் நோக்கத்திற்காக மத்திய கேஒய்சி பதிவேட்டிலிருந்து (“CKYCR”) ஆன்லைன் மூலம் எனது உமது வாடிக்கையாளரை அறிந்திரு (‘KYC‘) பதிவுகளை பெற்றுக்கொள்ள/மீட்டெடுக்க எஸ்பிஎம் பேங்க் (இந்தியா) லிட், (“வங்கி”)க்கு அங்கீகாரம் அளிக்கிறேன்</p>" +
      "<p>நான் மேலும் வங்கியுடன் எனது கணக்கை திறக்கும் நோக்கத்திற்காக, ஏதேனும் கூடுதல் அடையாள ஆவணங்கள், தரவுகள் வேண்டும் என்னும் நிகழ்வில், அல்லது விவரங்கள் பெறுவதற்கு, மற்றும் CKYCR இடமிருந்து எனது தகவலை பெறுவதுடன் கூடுதல் சரிபார்ப்பு மேற்கொள்ள வங்கிக்கு (மற்றும் அதன் சேவை வழங்குநர்க்கு) அங்கீகரித்து எனது ஒப்புதலை வழங்குகிறேன்</p>" +
      "<ol>" +
      "<li>CKYCR பதிவேடுகளில் உள்ள தகவலில் மாற்றம் உள்ளது;</li>" +
      "<li>தற்போதைய முகவரி சரிபார்க்க வேண்டியுள்ளது;</li>" +
      "<li>அடையாளம் அல்லது முகவரியை சரிபார்க்க வேண்டியது அவசியம் என்று வங்கி கருதுகிறது</li>" +
      "<li>மேம்படுத்தப்பட்ட உரிய விடாமுயற்சியை செயல்படுத்த அல்லது பொருத்தமான ஆபத்து சுயவிவரத்தை உருவாக்குவது.</li>" +
      "<li>பண மோசடி தடுப்புச் சட்டம், 2002 படி மற்றும் அதன் விதிகள் கீழ் மற்றும் இந்திய ரிசர்வ் வங்கி வழிகாட்டுதல்களின் படிகேஒய்சி மற்றும் காலக்கிரம கேஒய்சி செய்முறை, அல்லது அனைத்து கணக்குகள், வசதிகள், சேவைகள் மற்றும் வங்கி மூலமான தொடர்புகள் தற்போது உள்ளவை மற்றும் எதிர்காலத்திற்காகவும், பொருந்தும் சட்டத்தின் படி அனுமதிக்கப்பட்டவாறு இதரஅங்கீகரிப்பு/ சரிபார்ப்பு/ அடையாளம் காண்பதற்கு;</li>" +
      "<li>சேகரித்தல், பகிர்தல், சேமித்தல், தகவலை பாதுகாத்தல், பதிவேடுகளை பராமரித்தல், மற்றும் தகவலை பயன்படுத்துதல், மற்றும் பதிவுகளை அங்கீகரித்தல்/ சரிபார்த்தல்/ அடையாளம் காணல்; (a) மேலே தெரிவிக்கப்பட்ட நோக்கங்களுக்காக, (b) அதேபோல் ஒழுங்குமுறை மற்றும் சட்டபூர்வ அறிவித்தல் மற்றும் தாக்கல் செய்ய; மற்றும்/அல்லது (c) பொருந்தும் சட்டங்களின் கீழ் தேவைப்படும் இடத்தில்; 7) ஒரு நீதிமன்றம், ஏதேனும் சட்டரீதியான, நிர்வாக, சட்டமியற்றும் அதிகாரம் அல்லது வேறு ஏதேனும் அதிகாரம், ஏதேனும் ஒழுங்கமைப்பாளர் அல்லது நடுவர் மன்றம் முன்பு சாட்சிப்படுத்தும் நோக்கத்திற்காக ஒப்புதல், தகவல் அல்லது அங்கீகரித்தல், அடையாளம் காணல், சரிபார்த்தல் ஆகியவறின் பதிவுகளை மற்றும் குறிப்பேட்டை வழங்குதல்</li>" +
      "<p>நான் இதன் மூலம் CKYCR இடமிருந்து எஸ்எம்எஸ்/ மின் அஞ்சல் மூலம் மேற்கண்ட பதிவுஎண்/மின் அஞ்சல் முகவரியில் தகவலைப் பெறுவதற்கு ஒப்புதல் அளிக்கிறேன்.</p>" +
      "<p>நான் இதன் மூலம் மேற்கண்ட ஒப்புதல், எனது ஒப்புதலை சேகரிப்பதன் நோக்கம் மற்றும் எனது ஒப்புதலைத் தொடர்ந்து சேகரிக்கப்படவேண்டிய தகவலின் தன்மையை, நான் அறிந்த மற்றும் புரிந்துகொள்ளும் மொழியில் என்னுடைய திருப்திக்கு எனக்கு விளக்கிக் கூறப்பட்டது என்று உறுதி அளிக்கிறேன்</p>" +
      "</ol>",
  },
  te: {
    name: "తెలుగు (Telegu)",
    value:
      "<p>నా నోయవర్ కస్టమర్ (“KYC”) రికార్డులను ఆన్‌లైనులో సెంట్రల్ కెవైసి రిజిస్ట్రీ (“CKYCR”) నుండి బ్యాంకుతో నా ఖాతాను తెరిచే ఉద్దేశ్యానికి తీసుకోమని /సమీకరించమని ఎస్‌బిఎమ్ బ్యాంక్ (ఇండియూ) లి. (“బ్యాంక్‌”)ను నేను ఇందుమూలంగా అధీకతం చేస్తున్నాను.</p>" +
      "<p> బ్యాంకుతో నా ఖాతాను తెరిచే ఉద్దేశ్యానికి అదనపు గుర్తింపు డాక్యుమెంట్లు, డేటా లేదా వివరాలను వేటినైనా లభ్యం చేసుకోవడానికి మరియ CKYCR నుండి ఈ క్రింది సందర్భాలలో నా సమాచారానికి సంబంధించి అదనపు తనిఖీలు చేబట్టమని బ్యాంకుకు (మరియ దాని సర్వీసు ప్రొవైడర్లకు) తదుపరి అధీకతం చేస్తున్నాను మరియ నా సమ్మతిని ఇస్తున్నాను.</p>" +
      "<ol>" +
      "<li>CKYCR రికార్డులలో ప్రస్తుతం ఉన్న సమాచారంలో మార్పు ఉంటే</li>" +
      "<li>ప్రస్తుత చిరునామా తనిఖీ చేయువలసిన అవసరం ఉంటే</li>" +
      "<li>గుర్తింపు లేదా చిరునామాను తనిఖీ చేయుడం అవసరమని బ్యాంకు పరిగణిస్తే</li>" +
      "<li>హెచ్చుచేయుబడిన శ్రద్ధతో చేబట్టడానికి లేదా సబబైన రిస్క్ ప్రొఫైలును ఏర్పాటుచేయుడానికి</li>" +
      "<li>KYC మరియ స్థిరమైన సమయూలలో KYC ప్రక్రియను ప్రివెన్షన్ ఆఫ్ మని లాండరింగ్ చట్టం, 2022 మరియ దాని క్రింద నియుమాల ప్రకారం మరియ ఆర్‌బిఐ మార్గదర్శకాల ప్రకారం లేదా నా గుర్తింపును నిరూపించడానికి, నా గుర్తింపును చేబట్టడానికి, వర్తించే చట్టం ప్రకారం జనన సంబంధిత లేదా ఇతర అధీకత / తనిఖీ / గుర్తింపును ఆఫ్‌లైన్ లోతనిఖీకి, ఇవన్నీ అన్ని ఖాతాలకు, సౌకర్యాలకు, సర్వీసులకు మరియ బ్యాంకు యెక్క సంబంధాల / ద్వారా , ప్రస్త్తుతం ఉన్నవి మరియ భవిష్యత్తువి</li>" +
      "<li>సమాచారాన్ని సేకరించడం, పంచుకోవడం, నిల్వచేయుడం, భద్రపరచడం, రికార్డులను అట్టేపెట్టడం మరియ సమాచారాన్ని ఉపయోగించడం మరియ రికార్డులను అధీకతం / తనిఖీ / గుర్తింపు చేయుడం(ఎ) పైన పేర్కొనబడిన అవగాహనాపూర్వక ఉద్దేశ్యాల కొరకు (బి) అలాగే క్రమబద్ధీకరణ మరియ చట్టపరంగా నివేదించడానికి మరియ సమర్పించడానికి మరియ / లేదా (సి)వర్తించే చట్టం క్రింద అవసరమరన చోట</li>" +
      "<li>రికార్డులు మరియ సమ్మతి యెక్క లాగ్స్‌ను సమర్పించడం, సమాచారం లేదా అధీకతం, గుర్తింపు, తనిఖీ వెదలరన వాటి సాక్ష్యం ఇచ్చే ఉద్దేశ్యాల కొరకు, న్యాయుస్థానం, ఏదైనాచట్టపరమైన ( స్టేట్యూటరి), పాలనాపరమైన, చట్టపరమైన అధీకారవర్గం లేదా మరే ఇతర అధీకారవర్గం, క్రమబద్ధీకరించే వారు ఎవరైనా లేదా ఆర్బిట్రేషన్‌లో</li>" +
      "<p>ఎస్‌ఎమ్‌ఎస్ / ఇమెరల్ ద్వారా పైన రిజిష్టరు చేయుబడిన నంబరు / ఇమెరల్ చిరునామాపై CKYCR నుండి సమాచారాన్ని అందుకోవడానికి నేను ఇందుమూలంగా సమ్మతిస్తున్నాను.</p>" +
      "<p>పై సమ్మతి, నా సమ్మతిని సేకరించే ఉద్దేశ్యం మరియ నా సమ్మతి అనుసరించి సేకరించబడే సమాచార స్వభావం గురించి నా సంతప్తి మేరకు నాకు తెలిసిన మరియ అర్థం చేసుకోగల ప్రాంతీయు భాషలో నాకు వివరించబడినది అని నేను ఇందుమూలంగా ధృవీకరిస్తున్నాను.</p>" +
      "</ol>",
  },
  // mal: {
  //   name: "ഇംഗ്ലീഷിൽ (Malayalam)",
  //   value:
  //     "<p>I hereby authorize SBM Bank (India) Ltd. (“Bank”) to fetch/retrieve my Know Your Customer (“KYC”) records online from the Central KYC Registry (“CKYCR”) for the purpose of opening my account with the Bank.</p> " +
  //     "<p> I further authorize and give my consent to the Bank (and its service providers) to obtain for the purpose of opening my account with the Bank, any additional identification documents, data or details and carry out additional check with respect to my information from CKYCR, in the event:</p>" +
  //     "<ol>" +
  //     "<li>there is a change in the information as existing in the records of CKYCR;</li>" +
  //     "<li>the current address is required to be verified.</li>" +
  //     "<li>to perform enhanced due diligence or to build an appropriate risk profile;</li>" +
  //     "<li>KYC and periodic KYC process as per the Prevention of Money Laundering Act, 2002 and its rules thereunder and RBI guidelines, or for establishing my identity, carrying out my identification, offline verification demographic or other authentication/verification/identification as may be permitted as per applicable Law, for all accounts, facilities, services and relationships of/through the Bank, existing and future;</li>" +
  //     "<li>collecting, sharing, storing, preserving Information, maintaining records and using the information and authentication/verification/identification records: (a) for the informed purposes above; (b) as well as for regulatory and legal reporting and filings; and/or (c) where required under applicable Law;</li>" +
  //     "<li>Producing records and logs of consent, information or of authentication, identification, verification etc, for evidentiary purposes including before a court of law, or any authority or in arbitration.</li>" +
  //     "<li></li>" +
  //     "<p></p>" +
  //     "<p></p>" +
  //     "</ol>",
  // },
};
