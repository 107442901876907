<script>
  import dayjs from "dayjs";
  import { bankToJsDate, toCurrency } from "../../services/function";
  export let transactionListItem;
</script>

<div
  style="width: 100%; display: flex; padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid #EAF0F9"
>
  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; color: #000000; justify-content: start;"
  >
    {dayjs(bankToJsDate(transactionListItem.created_at)).format("DD MMM YYYY")}
  </div>

  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 700; font-size: 16px; line-height: 14px; display: flex; align-items: center; letter-spacing: 0.32px; color: #65BF73; justify-content: center;"
  >
    Success
  </div>

  <div
    style="display: flex; width: 100%; font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; letter-spacing: 0.26px; color: #000000; justify-content: end;"
  >
    {toCurrency(transactionListItem.amount)}
  </div>
</div>

<!-- <div
  style="width: 100%; display: flex; padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid #EAF0F9"
>
  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; color: #000000; justify-content: start;"
  >
    28 Sept ‘22
  </div>

  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 700; font-size: 16px; line-height: 14px; display: flex; align-items: center; letter-spacing: 0.32px; color: #F17F22;; justify-content: center;"
  >
    Pending
  </div>

  <div
    style="display: flex; width: 100%; font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; letter-spacing: 0.26px; color: #000000; justify-content: end;"
  >
    ₹240
  </div>
</div>

<div
  style="width: 100%; display: flex; padding-top: 20px; padding-bottom: 20px; border-bottom: 1px solid #EAF0F9"
>
  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; color: #000000; justify-content: start;"
  >
    28 Sept ‘22
  </div>

  <div
    style="display: flex; width: 100%;font-style: normal; font-weight: 700; font-size: 16px; line-height: 14px; display: flex; align-items: center; letter-spacing: 0.32px; color: #65BF73; justify-content: center;"
  >
    Success
  </div>

  <div
    style="display: flex; width: 100%; font-style: normal; font-weight: 600; font-size: 16px; line-height: 14px; letter-spacing: 0.26px; color: #000000; justify-content: end;"
  >
    ₹240
  </div>
</div>
 -->
