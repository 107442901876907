<script>
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Note from "../../../components/commons/Note.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../../services/network";
  import { userCheck } from "../../../services/userCheck";
  import dayjs from "dayjs";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import Snackbar, { Label } from "@smui/snackbar";

  let name = "";
  let pan = "";
  let dob = "";
  let snackbarOpen;
  let snackbarMessage = "";

  onMount(async () => {
    const req = await request("/api/kyc/pan", "GET");

    //{"message":"PAN Details Fetched Successfully","type":"success","pan":[{"user_kyc_id":7,"user_id":134,"type":"PAN","status":"VERIFIED","data":{"req":{"pan":"BNZAA2318J"},"res":{"data":[{"dob":"01/09/2022","panNo":"BNZAA2318J","filler1":null,"filler2":null,"filler3":null,"errorMsg":"Success","lastName":"SHAH","panTitle":"Shri","firstName":"SAGAR","panStatus":"Valid","middleName":"RAMKUMAR","nameOnCard":"SAGAR RAMKUMAR SHAH","panLastUpdate":"17/08/2017","aadharSeedingStatus":"Y"}],"message":"Success.","responseCode":"200"}},"created_at":"2022-12-13T00:34:30.000Z","modified_at":"2022-12-13T00:34:30.000Z"}]}

    const [panDetails] = req.pan;
    const pan_res_data = panDetails.data.res.data[0];

    pan = pan_res_data.panNo;
    dob = panDetails.data.req.dob;
    name = `${pan_res_data.firstName} ${pan_res_data.middleName} ${pan_res_data.lastName}`;
  });

  const buttonClick = async () => {
    window.loading(true);
    const res = await request("/api/kyc/pan/verify", "POST");
    snackbarMessage = "PAN is verified";
    snackbarOpen.forceOpen();

    if (res.user_kyc_id > 0) {
      //TODO: we need to put these conditions also in userCheck
      setTimeout(async () => {
        window.loading(false);
        navigateTo("/");
      }, 2000);
    }
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };

  const changePAN = async () => {
    window.loading(true);

    await request("/api/kyc/pan/change", "POST");

    localStorage.setItem("pan", JSON.stringify({ pan, dob }));

    window.loading(false);

    navigateTo("/onboarding/pan");
  };

  dayjs.extend(customParseFormat);
</script>

<WizardBody title="Confirm if these details are correct" back=""
  ><div class="row mb-3">
    <div class="info" style="margin-top: 5px; margin-bottom: 25px">
      We have fetched these details from NSDL Registry
    </div>
    <!-- <div class="row responsive-scroll">
      
    </div> -->
    <div class="pan-card">
      <div class="pan-details" style="position: relative;top: 3%;left: -12%;">
        {pan}
      </div>

      <div class="pan-details pan-name">
        {name}
      </div>

      <div class="pan-details pan-number">
        {dayjs(dob, "DD-MM-YYYY").format("DD/MM/YYYY")}
      </div>
    </div>
  </div>
  <div class="note">
    <Note note="PAN Image is for visual reference only." />
  </div>

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="change-pan">
    Incorrect PAN details? <span on:click={changePAN}>
      &nbsp;Change PAN details</span
    >
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative; z-index: 9999">
    <Snackbar leading bind:this={snackbarOpen} class="success">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>

  <div style="position: relative;">
    <div class="button">
      <Button onclick={buttonClick} title="Confirm" />

      <!-- <Buttonlnv title="Change PAN" onclick={changePAN} /> -->
      <!-- <Button
    title="Confirm & Start CKYC"
    onclick={buttonClick}
  /> -->
    </div>
  </div></WizardActionArea
>

<style>
  .info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17161b;
  }

  .pan-card {
    /* width: 414px;
    height: 274px;
    min-width: 414px;
    min-height: 274px; */
    width: 414px;
    height: 224px;
    background-image: url(/assets/images/pan-template.png);
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .pan-details {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    color: #242424;
  }

  .pan-name {
    position: relative;
    bottom: -15%;
    left: -26%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 196px;
  }

  .pan-number {
    position: relative;
    bottom: -36%;
    left: -40%;
  }

  @media only screen and (min-width: 768px) {
    .change-pan {
      margin-top: 20px;
      font-size: 16px;
      line-height: 20px;
      color: #1e3868;
      font-weight: 500;
    }

    .change-pan > span {
      font-weight: 700;
      cursor: pointer;
    }
    .pan-card {
      margin-left: 12px;
    }

    .info {
      margin-left: 5px;
    }

    .button {
      margin-left: 2px;
    }
  }

  @media only screen and (max-width: 768px) {
    .change-pan {
      margin-top: 10px;
      font-size: 14px;
      line-height: 16px;
      color: #1e3868;
      font-weight: 500;
    }

    .change-pan > span {
      font-weight: 700;
      cursor: pointer;
    }
    .info {
      font-size: 12px;
      line-height: 16px;
      margin-top: -12px;
      word-spacing: 2px;
    }

    .pan-card {
      width: 340px;
      height: 202px;
      margin-left: 28px;
    }

    .pan-name {
      bottom: -14%;
      left: -20%;
    }

    .pan-number {
      bottom: -34%;
      left: -40%;
    }

    .button {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin-left: 1px;
    }
  }

  .button {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
</style>
