<script>
  export let optionsList;
  export let placeholder;
  import InputAutocompleteOption from "./InputAutocompleteOption.svelte";
  export let inputValue = "";
  export let onchange;

  /* FILTERING options DATA BASED ON INPUT */
  let filteredOptions = [];

  const filterOptions = () => {
    let storageArr = [];
    if (inputValue) {
      optionsList.forEach((option) => {
        if (option.toLowerCase().startsWith(inputValue.toLowerCase())) {
          storageArr = [...storageArr, makeMatchBold(option)];
        }
      });
    }
    filteredOptions = storageArr;
  };

  /* HANDLING THE INPUT */
  let searchInput; // use with bind:this to focus element
  // let inputValue = "";

  $: if (!inputValue) {
    filteredOptions = [];
    hiLiteIndex = null;
  }

  const clearInput = () => {
    inputValue = "";
    searchInput.focus();
  };

  const setInputVal = (optionName) => {
    inputValue = removeBold(optionName);
    filteredOptions = [];
    hiLiteIndex = null;
    document.querySelector("#option-input").focus();
  };

  //   const submitValue = () => {
  //     if (inputValue) {
  //       console.log(`${inputValue} is submitted!`);
  //       setTimeout(clearInput, 1000);
  //     } else {
  //       alert("You didn't type anything.");
  //     }
  //   };

  const makeMatchBold = (str) => {
    // replace part of (option name === inputValue) with strong tags
    let matched = str.substring(0, inputValue.length);
    let makeBold = `<strong>${matched}</strong>`;
    let boldedMatch = str.replace(matched, makeBold);
    return boldedMatch;
  };

  const removeBold = (str) => {
    //replace < and > all characters between
    return str.replace(/<(.)*?>/g, "");
    // return str.replace(/<(strong)>/g, "").replace(/<\/(strong)>/g, "");
  };

  /* NAVIGATING OVER THE LIST OF OPTIONS W HIGHLIGHTING */
  let hiLiteIndex = null;
  //$: console.log(hiLiteIndex);
  $: hiLitedOption = filteredOptions[hiLiteIndex];

  const navigateList = (e) => {
    if (e.key === "ArrowDown" && hiLiteIndex <= filteredOptions.length - 1) {
      hiLiteIndex === null ? (hiLiteIndex = 0) : (hiLiteIndex += 1);
    } else if (e.key === "ArrowUp" && hiLiteIndex !== null) {
      hiLiteIndex === 0
        ? (hiLiteIndex = filteredOptions.length - 1)
        : (hiLiteIndex -= 1);
    } else if (e.key === "Enter") {
      setInputVal(filteredOptions[hiLiteIndex]);
    } else {
      return;
    }
  };
</script>

<svelte:window on:keydown={navigateList} />

<div class="autocomplete">
  <input
    id="option-input"
    class="input"
    type="text"
    {placeholder}
    bind:this={searchInput}
    bind:value={inputValue}
    on:input={filterOptions}
    on:change={onchange}
  />

  <!-- FILTERED LIST OF OPTIONS -->
  {#if filteredOptions.length > 0}
    <ul id="autocomplete-items-list" class="autocomplete-items-list">
      {#each filteredOptions as option, i}
        <InputAutocompleteOption
          itemLabel={option}
          highlighted={i === hiLiteIndex}
          on:click={() => setInputVal(option)}
        />
      {/each}
    </ul>
  {/if}
</div>

<style>
  .autocomplete {
    position: relative;
  }
  .input {
    display: block;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
    width: 100% !important;
    margin: 0px !important;
  }
  .autocomplete-items-list {
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 200px;
    overflow-y: auto;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .autocomplete {
      width: 100% !important;
    }
  }
</style>
