<script>
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import SAPayment from "./SAPayment.svelte";
  import FdPayment from "./FDPayment.svelte";
  import { routeCheck } from "../../services/routeCheck.js";
  export let currentRoute;

  let open = false;

  setTimeout(function () {
    open = true;
  }, 5000);

  let flow;
  onMount(async () => {
    await routeCheck(currentRoute.name);
    window.loading(true);
    await request("/api/user_account/confirm", "GET");
    const response = await request("/api/payment/success", "GET");
    flow = response.flow;
    window.loading(false);
  });

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

{#if flow === "sa"}
  <SAPayment />
{:else if flow === "fd" || flow === "sc"}
  <FdPayment />
{/if}
