//import jwt from "jsonwebtoken";
//const jwt = require('jsonwebtoken');

const { sha256 } = require("js-sha256");
const { toBase64, encrypt } = require("./function");

async function request(
  url,
  method = "GET",
  body = null,
  contentType = "application/json",
  headers = {}
) {
  headers["Content-Type"] = contentType + ";charset=utf-8";
  const csrfkey = localStorage.getItem("csrfkey");

  if (csrfkey && csrfkey.length > 0 && method !== "GET") {
    const payload = JSON.stringify({
      key: csrfkey,
      timestamp: new Date().getTime(),
    });
    const hash = sha256.hmac("JbV7Y06MJ6l9gX@U", payload);
    headers = { ...headers, token: toBase64(payload) + "." + toBase64(hash) };
  }
  let payload = null;
  if (body != null)
    payload = JSON.stringify({ encrypt: encrypt(JSON.stringify(body)) });
  return window
    .fetch(url, {
      cache: "no-cache",
      method,
      body: payload,
      headers,
    })
    .then((res) => res.json())
    .then((data) => {
      try {
        if (data.message === "User not logged in" && data.type === "danger") {
          snackbar("User is logged out. Redirecting", "danger", null);
          window.location.href = "/";
        } else if (
          data.message === "Invalid CSRF Token" &&
          data.type === "danger"
        ) {
          snackbar("Invalid CSRF Token", "danger", null);
          window.location.href = "/";
        }
      } catch (err) {}
      return data;
    })
    .catch((err) => {
      //throw err;
    });
}
function handleSubmit(e, callback) {
  const method = e.target.getAttribute("method") || e.target.method;
  const encType = e.target.getAttribute("encType") || e.target.enctype;
  const body = {};
  for (let field of new FormData(e.target)) {
    body[field[0]] = field[1];
  }
  request(e.target.action, method, body, encType)
    .then((res) => {
      callback(res, null);
    })
    .catch((err) => {
      callback(null, err);
    });
}
//function uploadFile(url, method, body, headers = {}) {}
module.exports = {
  request,
  handleSubmit,
};
