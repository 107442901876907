<script>
  import { onMount } from "svelte";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Input from "../../../components/commons/Input.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../../services/network";
  import Snackbar, { Label } from "@smui/snackbar";
  import { isEmail } from "../../../services/function";
  import { routeCheck } from "../../../services/routeCheck";
  import { get } from "svelte/store";
  import { prefill } from "../../../services/store";
  let emailValue = "";
  let inputValue = "";

  let snackbarOpen;
  let snackbarMessage = "";
  export let currentRoute = "";
  get(prefill);
  onMount(async () => {
    routeCheck(currentRoute.name);
  });

  const sendOtp = async () => {
    prefill.set({ ...$prefill, email: emailValue }); // set value
    window.loading(true);
    const result = await request("/api/verify/email", "POST", {
      email: emailValue,
    });

    window.loading(false);

    if (result.type === "success" && result.verify_id) {
      navigateTo(`/onboarding/email-verify/${result.verify_id}`);
    } else {
      snackbarMessage = result.message
        ? result.message
        : "Something went wrong";
      snackbarOpen.forceOpen();
    }
  };

  const buttonClick = () => {
    if (emailValue.length < 6 || !isEmail(emailValue)) {
      snackbarMessage = "Invalid email";
      snackbarOpen.forceOpen();
      return;
    }

    sendOtp();
  };

  onMount(() => {
    if ($prefill.hasOwnProperty("email")) {
      emailValue = $prefill.email;
    }
    prefill.set({});
  });
</script>

<WizardBody title="Enter your email id" back="">
  <div class="row">
    <div class="text-muted span">We'll send an OTP to the mail ID</div>

    <Input
      id={"emailInput"}
      onchange={(event) => {
        inputValue = event.target.value;
      }}
      type="text"
      style={"width:340px"}
      placeholder="Enter Email Id"
      bind:value={emailValue}
      onKeyPress={(e) => {
        if (!e) e = window.event;
        const keyCode = e.code || e.key;
        if (keyCode == "Enter") {
          buttonClick();
          return false;
        }
      }}
      validate={(value) => {
        if (!isEmail(value)) {
          return "Invalid Email";
        }
        return "";
      }}
    />
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button
    title="Get OTP"
    onclick={buttonClick}
    disabled={inputValue.length <= 0 || !isEmail(emailValue)}
  />
</WizardActionArea>

<style>
  .span {
    margin-bottom: 10px;
  }
</style>
