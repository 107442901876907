<script>
  import InputCurrency from "./../../components/commons/InputCurrency.svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import SubHeader from "../../components/Wizard/SubHeader.svelte";
  import OptionGroup from "../../components/FDCC/OptionGroup.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../services/network";
  import Dialog, { Content } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import dayjs from "dayjs";
  import { onMount } from "svelte";
  import {
    getInterst,
    orderedInterestList,
    decrypt,
    toCurrency,
    parseDaysUsingDayjs,
  } from "../../services/function";
  import Snackbar, { Label } from "@smui/snackbar";
  import TenureGroupNew from "../../components/FDCC/TenureGroupNew.svelte";
  import OptionGroupSelector from "../../components/FDCC/OptionGroupSelector.svelte";
  import DatePicker from "../../components/commons/DatePicker.svelte";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import _ from "lodash";
  import InputAutocomplete from "../../components/commons/InputAutocomplete.svelte";
  import BanksList from "../commons/BanksList.svelte";
  import {banksListWithPaymentMethods} from '../commons/BanksListWithPaymentMethods';


  dayjs.extend(customParseFormat);

  export let currentRoute;
  const type = currentRoute.namedParams.type;

  let selectedTenureIndex = -1;
  let amount = "₹50,000";
  let open = false;
  let showBankList = false;
  let maturityDate = "";
  let maturityDateTemp = "";
  let customMaturityDate = "";
  let customMaturityInterest = "";
  let payoutDisabled = false;
  let snackbarOpen;
  let snackbarMessage = "";

  //later these will be used to send in presetup post api
  let daysSelected = "";
  let interest_rate = "";

  let header = "Create your Fixed Deposit";
  let cardHeader = "Fixed Deposit Amount";

  //let interestList = [];
  let interests = [];

  let optionGroup = [];
  let tenureList = [];

  let interestPayoutList = ["On Maturity", "Every Quarter", "Every Month"];
  let interestPayout = interestPayoutList[0];

  let maturityInstructionList = ["Close and credit to SBM A/c", "Auto Renew"];
  let maturityInstruction = maturityInstructionList[0];
  $: maturityInstruction, (interestPayout = interestPayoutList[0]);

  let maturityAmount = "";

  onMount(async () => {
    if (type !== "fd" && type !== "sc") {
      navigateTo("/");
    }

    await request("/api/user_account/fd/prefill", "GET").then((response) => {
      if (response.type === "success") {
        amount = toCurrency(_.get(response, "fd_details.amount", ""));
        maturityInstruction = _.get(response, "fd_details.maturity_inst", "");
        interestPayout = _.get(response, "fd_details.interest_payout", "");
        interest_rate = _.get(response, "fd_details.interest_rate", "");
        let duration = _.get(response, "fd_details.duration", "");

        tenureList[0] = {
          value: duration.toString(),
          default: true,
          recommended: true,
        };
        selectedTenureIndex = 0;
      }
    });

    try {
      if (localStorage.getItem("fd_data").length > 0) {
        let fd_data = localStorage.getItem("fd_data");

        fd_data = JSON.parse(fd_data);
        amount = fd_data.amount;
        //duration = fd_data.duration;
        interest_rate = Number(fd_data.interest_rate);
        interestPayout = fd_data.interest_payout;
        maturityInstruction = fd_data.maturity_inst;
      }
    } catch (e) {
      //console.log(e);
    }

    localStorage.setItem("fd_data", "");

    const typeRes = JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );

    if (
      typeRes.presetup &&
      typeRes.presetup.cc &&
      typeRes.presetup.cc === true
    ) {
      header = "Choose your Credit Card limit";
      cardHeader = "Credit Card Limit";
    }

    let templateRes = {};
    templateRes = await request(
      "/api/global_template/PREFILL_DATA", //dont change this
      "GET"
    );
    console.log(dayjs().add(1, "year").diff(dayjs(), "day"));
    if (templateRes.hasOwnProperty("type") && templateRes.type === "danger") {
      templateRes = {
        data: {
          global_template_id: 1,
          name: "PREFILL_DAT1A",
          data: {
            amount: [
              {
                value: 10000,
                default: true,
                recommended: false,
              },
              {
                value: 50000,
                default: false,
                recommended: true,
              },
              {
                value: 100000,
                default: false,
                recommended: false,
              },
            ],
            tenure: [
              {
                value: dayjs().add(1, "year").diff(dayjs(), "day"),
                default: true,
                recommended: false,
              },
              {
                value: dayjs().add(2, "year").diff(dayjs(), "day"),
                default: false,
                recommended: false,
              },
              {
                value: dayjs().add(5, "year").diff(dayjs(), "day"),
                default: false,
                recommended: false,
              },
            ],
            cc_landing: [
              "No credit score required",
              "No Income proof required",
              "Earn upto 6% interest on FD",
              "Complete online process",
            ],
            fd_landing: [
              "Minimum Investment of Rs. 1000",
              "Flexible Tenure",
              "Hassle-Free Opening",
              "Complete online process",
            ],
          },
          created_at: "2023-03-15T09:32:05.000Z",
          modified_at: "2023-03-15T09:33:07.000Z",
        },
      };
    }
    optionGroup = templateRes.data.data.amount;
    tenureList = templateRes.data.data.tenure;

    interests = await request("/api/global_template/INTEREST_DATA", "GET");
    interests = interests.data.data.DATA;
    interests = orderedInterestList(interests);
  });

  $: if (maturityDate) {
    if (maturityDate.length > 0) {
      //open = false;
      const currentDate = new Date();
      //const selectDate = new Date(maturityDate);
      const selectDate = dayjs(maturityDate, "DD-MM-YYYY", true).toDate();
      const diffTime = Math.abs(selectDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 3653) {
        snackbarMessage = "You cannot select date more than 10 years";
        snackbarOpen.forceOpen();
      } else if (
        diffDays < 183 ||
        selectDate.getTime() < currentDate.getTime()
      ) {
        snackbarMessage = "Minimum tenure must be 6 months";
        snackbarOpen.forceOpen();
        customMaturityDate = "";
        daysSelected = "";
        interest_rate = "";
      } else {
        /* customMaturityInterest = getDurationAndRate(
          interestList,
          diffDays
        ).interest_rate; */

        customMaturityInterest = getInterst(
          interests,
          diffDays,
          Number(amount.replace(/[^0-9\.]/, "").replace(/,/g, "")),
          false
        );

        customMaturityDate =
          "Selected maturity date: " +
          customMaturityDateInDateFormat.format("DD-MM-YYYY") +
          " (" +
          parseDaysUsingDayjs(
            dayjs()
              .add(customSelectedYears, "year")
              .add(customSelectedMonths, "month")
              .add(customSelectedDays, "day")
              .diff(dayjs(), "day")
          ) +
          /*daysIntoYearsMonthsDays(
            customSelectedYears,
            customSelectedMonths,
            customSelectedDays
          )*/ " at " +
          customMaturityInterest +
          "% )";

        daysSelected = diffDays.toString();
        interest_rate = customMaturityInterest;
        selectedTenureIndex = -1;
      }
    } else {
      customMaturityDate = "";
    }
  }

  const buttonClick = async () => {
    const amountVal = amount.replace(/[^0-9]/g, "").toString();

    if (Number(amountVal) < 5000) {
      snackbarMessage = "Minimum amount must be ₹5,000";
      snackbarOpen.forceOpen();
      return;
    }

    if (Number(amountVal) > 10000000) {
      snackbarMessage = "Maximum amount can be ₹1 Crore(Cr)";
      snackbarOpen.forceOpen();
      return;
    }

    if (selectedTenureIndex > -1 && selectedTenureIndex <= 2) {
      daysSelected = tenureList[selectedTenureIndex].value;
      //interest_rate = getRateFromDay(daysSelected); //TODO

      interest_rate = getInterst(
        interests,
        Number(daysSelected),
        Number(amount.replace(/[^0-9\.]/, "").replace(/,/g, "")),
        false
      ); //TODO
    }

    if (daysSelected.length < 1 || interest_rate < 1) {
      snackbarMessage = "Please select a tenure";
      snackbarOpen.forceOpen();
      return;
    }

    if (interestPayout.length < 1) {
      snackbarMessage = "Please select a interest payout";
      snackbarOpen.forceOpen();
      return;
    }

    if (maturityInstruction.length < 1) {
      snackbarMessage = "Please select a maturity instruction";
      snackbarOpen.forceOpen();
      return;
    }

    const fdData = {
      amount: amountVal.toString(),
      duration: daysSelected.toString(),
      type: type.toString(),
      interest_rate: interest_rate.toString(),
      interest_payout: interestPayout,
      maturity_inst: maturityInstruction,
    };

    localStorage.setItem("fd_data", JSON.stringify(fdData));

    navigateTo("/fd/confirm");
  };

  const selectTenureCustom = () => {
    customMaturityDate = "";
    open = true;
  };

  let customYears = Array.from(Array(11).keys());
  let customSelectedYears = 0;

  let customMonths = Array.from(Array(12).keys());
  let customSelectedMonths = 0;

  let customDays = Array.from(Array(31).keys());
  let customSelectedDays = 0;

  let customMaturityDateInDateFormat;

  let customTenureButton = () => {
    customMaturityDateInDateFormat = dayjs()
      .add(customSelectedYears, "year")
      .add(customSelectedMonths, "month")
      .add(customSelectedDays, "day");

    let diffMonths = customMaturityDateInDateFormat.diff(dayjs(), "month");

    let diffDays = customMaturityDateInDateFormat
      .subtract(diffMonths, "month")
      .diff(dayjs(), "day");

    console.log(diffMonths, "diffMonths", diffDays, "diffDays");

    if (diffMonths < 6) {
      snackbarMessage = "Minimum tenure must be 6 months";
      snackbarOpen.forceOpen();
      return;
    }

    maturityDate = customMaturityDateInDateFormat
      .format("DD-MM-YYYY")
      .toString();

    open = false;
  };
</script>

<Dialog bind:open aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">Select Maturity Date</div>
        <div class="modal-header-icon">
          <IconButton on:click={() => (open = false)} class="material-icons"
            >close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <!-- <span style=" align-items: center; display: flex; font-size: 12px"
            >Select Maturity Date:</span
          > -->
        <!-- <div class="col-xs-12 col-md-6 my-3">
            <div class="input-container">
              <DatePicker
                bind:date={maturityDateTemp}
                placeholder="Enter Date"
                minDate={dayjs(new Date()).add(6, "month").format("DD-MM-YYYY")}
                maxDate={dayjs(new Date()).add(10, "year").format("DD-MM-YYYY")}
              />
            </div>
          </div> -->
        <div
          class="d-flex justify-content-between align-items-center gap-3 my-4"
        >
          <div class="select-container">
            <div class="label">Years:</div>
            <div
              style="width: 100%; background-color: #fff; border-radius: 8px;"
            >
              <select class="tenure-select" bind:value={customSelectedYears}>
                <!-- <option value="0" disabled selected>Years</option> -->
                {#each customYears as year}
                  <option value={year}>{year}</option>
                {/each}
              </select>
            </div>
          </div>

          <div class="select-container">
            <div class="label">Months:</div>
            <div
              style="width: 100%; background-color: #fff; border-radius: 8px;"
            >
              <select class="tenure-select" bind:value={customSelectedMonths}>
                <!-- <option value="0" disabled selected>Months</option> -->
                {#each customMonths as month}
                  <option value={month}>{month}</option>
                {/each}
              </select>
            </div>
          </div>
          <div class="select-container">
            <div class="label">Days:</div>
            <div
              style="width: 100%; background-color: #fff; border-radius: 8px;"
            >
              <select class="tenure-select" bind:value={customSelectedDays}>
                <!-- <option value="0" disabled selected>Days</option> -->
                {#each customDays as day}
                  <option value={day}>{day}</option>
                {/each}
              </select>
            </div>
          </div>
        </div>
        <Button title="Save" onclick={customTenureButton} />
      </div>
    </div>
  </Content>
</Dialog>



<Dialog fullscreen bind:open={showBankList} aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text-banklist">Payments are available for the following banks:</div>
        <div class="modal-header-icon">
          <IconButton on:click={() => (showBankList = false)} class="material-icons"
            >close</IconButton
          >
        </div>
      </div>
      <BanksList banks={banksListWithPaymentMethods} />
    </div>
  </Content>
</Dialog>
<WizardBody title={header} back="">
  <div class="responsive-scroll p-3" style="display: none;">
    <div class="card">
      <div class="top-blue" />

      <div class="card-content">
        <div
          style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
        >
          <i
            style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
          />
          <i
            style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
          />
        </div>
        <div
          style="margin-top: 18px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          {cardHeader}
        </div>
        <div
          style="margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
        >
          {amount.replace("₹", "₹ ")}
        </div>
        <div
          style="margin-top: 36px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          FD Amount
        </div>
        <div
          style="margin-top: 4px;font-style: normal; font-weight: 700; font-size: 24px; line-height: 24px;color: #FFFFFF;"
        >
          {amount.replace("₹", "₹ ")}
        </div>
      </div>
    </div>
  </div>
  <SubHeader
    title="Enter FD Amount"
    style="color: #5A5A5A; margin-bottom: 24px;"
  />
  <InputCurrency
    width="320px"
    placeholder="₹ 10,000"
    type="text"
    bind:value={amount}
  />

  {#if Number(amount.replace(/[^0-9\.]/, "").replace(/,/g, "")) < 5000}
    <div class="minimum-amount-note">Minimum amount must be ₹5,000</div>
  {/if}

  {#if Number(amount.replace(/[^0-9\.]/, "").replace(/,/g, "")) > 100000}
  <div class="info">
    <div class="minimum-amount-note">
      Note that UPI transaction will not be available for deposits more than
      ₹1,00,000
    </div>
     <!-- svelte-ignore a11y-click-events-have-key-events -->
    <img style="cursor: pointer;"
        src="/assets/images/info.svg"
        alt="info"
        on:click={() => {
         showBankList = true; 
         console.log(showBankList, "showBankList");
         
        }}
      />
  </div>
    
  {/if}

  {#if optionGroup.length > 0}
    <OptionGroup bind:amount bind:optionGroup style="margin-top: 24px" />
  {/if}

  <SubHeader
    title="Select Tenure"
    style="color: #5A5A5A; margin-top: 44px; margin-bottom: 24px;"
  />

  {#if tenureList.length > 0}
    <TenureGroupNew
      bind:selectedTenureIndex
      onclick={selectTenureCustom}
      bind:tenureList
      bind:interestList={interests}
      bind:maturityDate
      bind:customMaturityDate
      bind:amount
    />
  {/if}

  {#if customMaturityDate.length > 0}
    <h2 style="color: #505869; font-size: 16px">{customMaturityDate}</h2>
  {/if}

  <SubHeader
    title="Maturity Instruction"
    style="color: #5A5A5A; margin-top: 44px; margin-bottom: 24px;"
  />

  <!-- <SegmentedButton
    choices={maturityInstructionList}
    bind:selected={maturityInstruction}
  /> -->

  <OptionGroupSelector
    list={maturityInstructionList}
    bind:value={maturityInstruction}
  />

  <SubHeader
    title="Interest Payout"
    style="color: #5A5A5A; margin-top: 44px; margin-bottom: 24px;"
  />

  <!-- <SegmentedButton
    choices={interestPayoutList}
    bind:selected={interestPayout}
  /> -->
  {#if maturityInstruction.includes("Close and credit to SBM A/c")}
    <OptionGroupSelector
      list={interestPayoutList}
      bind:value={interestPayout}
    />
  {:else}
    <OptionGroupSelector
      list={[interestPayoutList[0]]}
      bind:value={interestPayout}
    />
  {/if}
  {#if maturityAmount.length > 0}
    <div style="font-size: 14px; margin-top: 16px">{maturityAmount}</div>
  {/if}
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button title="Next" onclick={buttonClick} />
</WizardActionArea>

<style>

  .info {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  .select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    gap: 0.25rem;
  }
  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
    margin-bottom: 14px;
  }
  .tenure-select {
    position: relative;
    width: 100%;
    padding: 12px 10px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/images/select-caret-rx.png") no-repeat 100%;
    background-attachment: cover;
    border: 0;
    padding-right: 30px !important;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
    cursor: pointer;
  }
  .minimum-amount-note {
    color: red;
    font-weight: 400;
    font-size: 14px;
    margin-left: 0.1rem;
  }
  .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 380px;
  }
  .top-blue {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 70%;
    background: rgba(20, 20, 20, 0.39);
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    border-radius: 24px 24px 0px 0px;
  }
  .card-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 99;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .modal {
    padding: 0px;
  }

  .modal-header {
    width: 100%;
    color: #000;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-header-text {
    width: 100%;
    height: 28px;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .modal-header-text-banklist {
    width: 100%;
    height: 28px;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 24px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    position: relative;
    display: flex;
    width: 100%;
  }

  .dob-input-field {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
  }
</style>
