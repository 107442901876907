<script>
  import {
    currencyToNum,
    getDurationAndRate,
    getMaturityAmount,
    parseDays,
    toCurrency,
    decrypt,
  } from "../../services/function.js";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Checkbox from "../../components/commons/Checkbox.svelte";
  import Button from "../../components/commons/Button.svelte";
  import Dialog, { Content } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import InputCurrency from "../../components/commons/InputCurrency.svelte";
  import Switch from "@smui/switch";
  import dayjs from "dayjs";
  import Snackbar, { Label } from "@smui/snackbar";

  let checked = false;
  let open = false;
  let amountEditOpen = false;
  let customTenureOpen = false;

  let snackbarOpen;
  let snackbarMessage = "";

  let confirmAmount = "";
  let confirmInterestRate = "";
  let confirmFdDuration = "";
  let confirmFDId = "";

  let maturityDate = "";
  let maturityDateTemp = "";
  let customMaturityDate = "";
  let customMaturityInterest = "";

  const items = [
    { years: "5 Years", title: "6.25%" },
    { years: "2 Years", title: "5.75%" },
    { years: "1 Years", title: "5.25%" },
  ];

  let ccEnabled = false;

  let addCreditCard = false;

  let interestList = [];
  let tenureList = [];

  onMount(async () => {
    /* const pendingRes = await request("/api/fd/pending", "GET");

    const pendingData = pendingRes.data[0]; */

    const presetupDataRes = JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );

    const presetupData = presetupDataRes.user.attributes.presetup;

    /* const pendingData = pendingRes.data[0];

    ccEnabled = pendingData.cc_enabled === 1 ? true : false; */

    confirmAmount = toCurrency(presetupData.amount.toString());
    confirmInterestRate = presetupData.interest_rate;
    confirmFdDuration = presetupData.duration;
    //confirmFDId = pendingData.user_fd_id;

    const getInterestRatesRes = await request("/api/fd/interest", "GET");
    interestList = getInterestRatesRes.data;
    const templateRes = await request(
      "/api/global_template/PREFILL_DATA", //dont change this
      "GET"
    );
    tenureList = templateRes.data.data.tenure;
  });

  const tenureSelect = () => {
    open = true;
  };

  const buttonClick = async () => {
    if (!checked) {
      snackbarMessage = "Please accept the terms & conditions to move ahead";
      snackbarOpen.forceOpen();
      return;
    }

    return;

    window.loading(true);

    const confirmFd = await request("/api/fd/confirm", "POST", {
      fd_id: confirmFDId.toString(),
      amount: currencyToNum(confirmAmount).toString(),
      interest_rate: confirmInterestRate.toString(),
      duration: confirmFdDuration.toString(),
      addCreditCard: addCreditCard.toString(),
    });

    window.loading(false);

    navigateTo("/onboarding/nominee");
    // open = true;
  };

  const saveEditAmount = () => {
    amountEditOpen = false;
  };

  const customTenureSelect = () => {
    if (maturityDate.length > 0) {
      //open = false;
      const currentDate = new Date();
      const selectDate = new Date(maturityDate);
      const diffTime = Math.abs(selectDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 3560) {
        /* window.snackbar(
          "You cannot select date more than 10 years",
          "danger",
          null
        ); */
        snackbarMessage = "You cannot select date more than 10 years";
        snackbarOpen.forceOpen();
      } else if (
        diffDays < 14 ||
        selectDate.getTime() < currentDate.getTime()
      ) {
        /*  window.snackbar(
          "Please select a date more than 14 days",
          "danger",
          null
        ); */
        snackbarMessage = "Please select a date more than 14 days";
        snackbarOpen.forceOpen();
        customMaturityDate = "";
        daysSelected = "";
        interest_rate = "";
      } else {
        confirmInterestRate = getDurationAndRate(
          interestList,
          diffDays
        ).interest_rate;

        confirmFdDuration = diffDays;

        /* customMaturityDate =
          "Selected maturity date: " +
          dayjs(maturityDate).format("DD/MM/YYYY") +
          " (" +
          diffDays +
          " days) at " +
          customMaturityInterest +
          "%"; */

        //daysSelected = diffDays.toString();
        //interest_rate = customMaturityInterest;
      }
    }
  };
</script>

<Dialog bind:open={customTenureOpen} aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">Select Custom Tenure</div>
        <div class="modal-header-icon">
          <IconButton
            on:click={() => (customTenureOpen = false)}
            class="material-icons">close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <span style=" align-items: center; display: flex; ">Select Date:</span
          >
          <div class="col-xs-12 col-md-6 my-3">
            <div class="input-container">
              <input
                class="dob-input-field"
                type="date"
                placeholder="DD/MM/YYYY"
                min={dayjs(new Date()).add(14, "day").format("YYYY-MM-DD")}
                bind:value={maturityDateTemp}
              />
            </div>
          </div>
        </div>
        <Button
          title="Save"
          onclick={() => {
            maturityDate = "";
            maturityDate = maturityDateTemp;
            customTenureOpen = false;
            customTenureSelect();
          }}
        />
        <!-- <div class="d-flex justify-content-between">
          <span>Year: 10</span>
          <span>Month: 10</span>
          <span>Day: 10</span>
        </div> -->
      </div>
    </div>
  </Content>
</Dialog>

<Dialog bind:open={amountEditOpen} aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">Select Amount</div>
        <div class="modal-header-icon">
          <IconButton
            on:click={() => (amountEditOpen = false)}
            class="material-icons">close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <InputCurrency
          name="amount_edit"
          type="number"
          placeholder="Enter amount"
          style="width: 100%; margin-bottom: 34px !important;"
          bind:value={confirmAmount}
        />

        <Button title="Save" onclick={saveEditAmount} />
      </div>
    </div>
  </Content>
</Dialog>

<Dialog bind:open aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">Select Tenure</div>
        <div class="modal-header-icon">
          <IconButton on:click={() => (open = false)} class="material-icons"
            >close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        {#each tenureList as item, index}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <label
            on:click={() => {
              confirmFdDuration = item.value;
              confirmInterestRate = getDurationAndRate(
                interestList,
                item.value
              ).interest_rate.toFixed(2);
            }}
          >
            <div class="modal-body-item radio_label">
              <div class="item-container">
                <div class="item-header">{parseDays(item.value)}</div>
                <div class="item-sub-header">Maturity Amount</div>
                <div class="item-amount">
                  {toCurrency(
                    getMaturityAmount(
                      confirmAmount,
                      getDurationAndRate(interestList, item.value).duration,
                      getDurationAndRate(interestList, item.value).interest_rate
                    )
                  )}
                </div>
              </div>
              <div>
                <input
                  type="radio"
                  id={`tenure_dialog_${item.value}`}
                  name="select_tenure"
                />
              </div>
            </div>
          </label>
        {/each}

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <label
          on:click={() => {
            open = false;
            customTenureOpen = true;
          }}
        >
          <div class="modal-body-item">
            <div class="item-container">
              <div class="item-header">Custom</div>
              <div style="color:white;">Maturity Amount</div>
            </div>
            <div>
              <input type="radio" id="custom" name="select_tenure" />
            </div>
          </div>
        </label>
      </div>
    </div>
  </Content>
</Dialog>
<WizardBody
  title={ccEnabled ? "Confirm your credit card" : "Confirm your fixed deposit"}
  back="/onboarding/terms"
>
  <div class="responsive-scroll p-3">
    <div class="card">
      <div class="top-blue" />
      <div class="mid-blue" />
      <div class="card-content">
        <div
          style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
        >
          <i
            style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
          />
          <i
            style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
          />
        </div>

        <div
          style="margin-top: 18px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          {ccEnabled ? "Credit Card Limit" : "Fixed Deposit Amount"}
        </div>
        <div
          style="margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
        >
          {confirmAmount}
        </div>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          style="cursor: pointer; margin-top: 16px; padding-left: 16px; padding-right: 16px; padding-top: 8px; padding-bottom: 8px; background: #13AFE9; border-radius: 6px; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
          on:click={() => {
            amountEditOpen = true;
          }}
        >
          CHANGE AMOUNT
        </div>

        <div
          style="width: 100%; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF; margin-top: 8px;"
        >
          Fixed Deposit
        </div>

        <div
          style="margin-top: 16px; display: flex; width: 100%; position: relative;"
        >
          <div
            style="position: relative; width: 100%;display: flex; flex-direction: column; align-items: center;"
          >
            <div
              style="font-weight: 500; font-size: 10px; line-height: 13px; text-align: right; letter-spacing: -0.5px; color: #FFFFFF;"
            >
              FD Amount
            </div>
            <div
              style="margin-top: 2px;font-weight: 700; font-size: 16px; line-height: 21px;  text-align: right; letter-spacing: -0.9px; color: #FFFFFF; text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);"
            >
              {confirmAmount}
            </div>
          </div>

          <div
            style="position: relative; width: 100%;display: flex; flex-direction: column; align-items: center;"
          >
            <div
              style="font-weight: 500; font-size: 10px; line-height: 13px; text-align: right; letter-spacing: -0.5px; color: #FFFFFF;"
            >
              Interest Rate
            </div>
            <div
              style="margin-top: 2px;font-weight: 700; font-size: 16px; line-height: 21px;  text-align: right; letter-spacing: -0.9px; color: #FFFFFF; text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);"
            >
              {confirmInterestRate} %
            </div>
          </div>

          <div
            style="position: relative; width: 100%;display: flex; flex-direction: column; align-items: center;"
          >
            <div
              style="font-weight: 500; font-size: 10px; line-height: 13px; text-align: right; letter-spacing: -0.5px; color: #FFFFFF;"
            >
              FD Duration
            </div>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div on:click={tenureSelect} style="cursor: pointer;">
              <span
                class="me-1"
                style="margin-top: 2px;font-weight: 700; font-size: 16px; line-height: 21px;  text-align: right; letter-spacing: -0.9px; color: #FFFFFF; text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);"
                >{parseDays(confirmFdDuration)}
              </span>
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        <div
          style="margin-top: 14px; font-style: normal; font-weight: 400; font-size: 9px; line-height: 12px; display: flex; align-items: center; text-align: center; color: #FFFFFF; opacity: 0.72;"
        >
          Interest will be paid on maturity and FD will be renewed automatically
          every year
        </div>

        <div
          style="width: 100%; margin-top: 30px; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
        >
          SBM Savings account
        </div>

        <div
          style="margin-top: 12px; font-style: normal; font-weight: 400; font-size: 12px; line-height: 14px; color: #FFFFFF;"
        >
          We’re opening this account for hassle free interest deposit and
          withdrawals
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="ms-3">
    <Icon icon="info" class="note-text" color="#505869" size="18px" />
    <span class="note-text">
      Note: You will get one Credit Card, Saving account and a Fixed Deposit(FD)
    </span>
  </div> -->

  {#if ccEnabled === false}
    <div
      class="m-3"
      style="position: relative; overflow: hidden; max-width: 480px; display: flex;"
    >
      <div
        style="width: 100%; display: flex; max-width: 480px; align-items: center; position: relative; background: linear-gradient(90.22deg, #00A58C 3.38%, #083999 130.68%); border-radius: 12px; padding: 12px 8px 12px 12px;"
      >
        <img src="/assets/images/sbm_card_cc_confirm.png" alt="" />

        <div style="width: 100%; display: flex; flex-direction: column;">
          <div style="font-weight: 600; font-size: 12px;color: white;">
            Get a free Credit Card
          </div>
          <div style="font-weight: 600; font-size: 12px; color: white;">
            You just unlocked a Credit Card against your FD. Avail now !
          </div>
        </div>

        <!-- <input
          type="checkbox"
          style="margin: 10px; scale: 1.3; cursor: pointer;"
        /> -->
        <Switch bind:checked={addCreditCard} icons={false} />
      </div>
    </div>
  {/if}
</WizardBody>
<WizardActionArea>
  <!-- <div style="position: relative; margin-bottom: -110px">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>

  <div style="margin-left: -12px; margin-bottom: 18px; position: relative;">
    <Checkbox bind:checked
      >By Confirming, I agree to SBM bank T&C and Policies</Checkbox
    >
  </div>
  <Button title="Confirm & Deposit Money" onclick={buttonClick} /> -->

  <div class="row" style="position: relative;">
    <div style="position: absolute; bottom: -10px; left: 8px;">
      <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
        <Label>{snackbarMessage}</Label>
      </Snackbar>
    </div>
    <div class="col-12" style="margin-bottom: 10px; margin-left: -8px;">
      <Checkbox bind:checked
        >By Confirming, I agree to SBM bank T&C and Policies</Checkbox
      >
    </div>
    <div class="col-12">
      <Button title="Confirm & Deposit Money" onclick={buttonClick} />
    </div>
  </div>
</WizardActionArea>

<style>
  .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 480px;
    max-width: 100%;
    background: #051838;
  }
  .top-blue {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 44%;
    background: linear-gradient(
      66.27deg,
      #13afe9 -10.45%,
      #0f89d0 12.51%,
      #0a54ac 39.13%,
      #083a9a 63.11%
    );
    border-radius: 24px;
    z-index: 2;
  }
  .mid-blue {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 78%;
    background: #062869;
    mix-blend-mode: normal;
    border-radius: 0px 0px 24px 24px;
    z-index: 1;
  }
  .card-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 4;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .note-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #505869;
    display: inline;
    font-family: Urbanist;
    font-weight: 500;
  }

  .modal {
  }

  .modal-header {
    width: 100%;
    color: #000;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-header-text {
    width: 100%;
    height: 28px;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 34px;
  }

  .modal-body-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 16px;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
  }
  .item-container {
    width: 100%;
  }
  .item-header {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #282828;
  }

  .item-sub-header {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 10px;
    letter-spacing: 0.32px;
    color: #464448;
    text-transform: uppercase;
    margin-top: 18px;
  }

  .item-amount {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.26px;
    color: #464448;
    margin-top: 4px;
  }
  .input-container {
    position: relative;
    display: flex;
    width: 100%;
  }

  .dob-input-field {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
  }
</style>
