<script>
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Button from "../../components/commons/Button.svelte";
  import Checkbox from "../../components/commons/Checkbox.svelte";
  import Snackbar, { Label } from "@smui/snackbar";
  import { request } from "../../services/network";
  import { navigateTo } from "svelte-router-spa";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import { onMount } from "svelte";
  import MainHeader from "../../components/welcome/MainHeader.svelte";
  import { routeCheck } from "../../services/routeCheck";

  export let currentRoute;

  let type;
  let sa_checked = false;
  let fd_checked = false;
  let debit_card_checked = false;
  let snackbarOpen;
  let snackbarMessage = "";
  let maturity_inst;

  onMount(async () => {
    routeCheck(currentRoute.name);
    request("/api/terms/flow", "GET").then((response) => {
      type = response.flow;
      maturity_inst = response.maturity_inst;
    });
  });

  let data = {};

  const acceptTerms = async () => {
    data = {
      fixed_account: fd_checked,
      savings_account: sa_checked,
      schedule_of_charges: sa_checked,
      debit_card: debit_card_checked,
    };

    if (
      type === "fd" &&
      maturity_inst.includes("Close and credit to SBM A/c")
    ) {
      if (!fd_checked || !sa_checked) {
        snackbarMessage = "Please accept the terms and conditions";
        snackbarOpen.forceOpen();
        return;
      }
    }

    if (type === "sa") {
      if (!sa_checked) {
        snackbarMessage = "Please accept the terms and conditions";
        snackbarOpen.forceOpen();
        return;
      }
    }

    if (
      type === "fd" &&
      !maturity_inst.includes("Close and credit to SBM A/c")
    ) {
      if (!fd_checked) {
        snackbarMessage = "Please accept the terms and conditions";
        snackbarOpen.forceOpen();
        return;
      }
    }

    const response = await request("/api/terms", "POST", { data });

    if (response.type === "success") {
      navigateTo("/");
    } else {
      snackbarMessage = "Something went wrong.";
      snackbarOpen.forceOpen();
    }
  };
</script>

<WizardBody title="Terms & Conditions" back="">
  {#if type == "sa" || (type === "fd" && maturity_inst.includes("Close and credit to SBM A/c"))}
    <div style="margin-left:-12px;">
      <Checkbox bind:checked={sa_checked}>
        <p>
          I agree to Saving Account <a href="/terms-conditions/sa"
            >Terms & Conditions</a
          >
          and
          <a href="/assets/documents/SOC.pdf" target="_blank"
            >Schedule of Charges</a
          >
        </p>
      </Checkbox>
    </div>
  {/if}
  {#if type === "sa"}
    <div style="margin-left:-12px;">
      <Checkbox bind:checked={debit_card_checked}>
        <p>
          I agree to SBM Debit card <a href="/terms-conditions/dc"
            >Terms & Conditions</a
          >
        </p>
      </Checkbox>
    </div>
  {/if}
  {#if type == "fd" || type == "sc"}
    <div style="margin-left:-12px;">
      <Checkbox bind:checked={fd_checked}>
        <p>
          I agree to SBM Fixed Deposit <a href="/terms-conditions/fd"
            >Terms & Conditions</a
          >
        </p>
      </Checkbox>
    </div>
  {/if}
</WizardBody>

<WizardActionArea>
  <Snackbar bind:this={snackbarOpen} class="snackbar-warning">
    <Label>{snackbarMessage}</Label>
  </Snackbar>
  <Button title="confirm" onclick={acceptTerms} />
</WizardActionArea>

<style>
  @media only screen and (max-width: 768px) {
    .checkbox-note {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* Add some padding inside the card container */
  .card-body {
    padding: 16px 32px;
  }
</style>
