<script>
  import OptionMultiple from "./OptionMultiple.svelte";

  export let optionSelected;
  export let optionGroup;

  export let style = "";

  const selectValue = (item, index) => {
    if (optionSelected.includes(item)) {
      optionSelected = optionSelected.filter((value) => value !== item);
    } else {
      optionSelected = [...optionSelected, item];
    }
  };
</script>

<div style={`display: flex; flex-wrap: wrap; gap: 12px; ${style}`}>
  {#each optionGroup as item, index}
    <OptionMultiple
      isSelected={optionSelected.includes(item) ? true : false}
      value={item}
      onClick={() => selectValue(item, index)}
    />
  {/each}
</div>
