<script>
  export let title;
  export let style;
</script>

<div {style}>
  <span class="sub-header">{title}</span>
</div>

<style>
  .sub-header {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #1e3868;
  }

  @media only screen and (max-width: 768px) {
    .sub-header {
      font-size: 20px;
      line-height: 32px;
    }
  }
</style>
