<script>
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import Snackbar, { Label } from "@smui/snackbar";
  import { userCheck } from "../../services/userCheck";
  import Tooltip, { Wrapper, Content } from "@smui/tooltip";
  import Bullets from "../../components/commons/Bullets.svelte";
  import Buttonlnv from "../../components/commons/Buttonlnv.svelte";
  import { routeCheck } from "../../services/routeCheck";
  export let currentRoute;

  let stream;
  let srcObject;
  let video;
  let photoURL;
  let screenState = "INFO"; //
  let show = false;
  let snackbarOpen;
  let snackbarMessage = "";
  let snackbarType;
  let latitude = 0;
  let longitude = 0;

  let locationPermissionDisabled = false;

  let locationApiFetched = false;
  let locationIsIndia = false;
  let location_data;
  let confirm = false;

  let captureDisabled = true;

  let info = [
    "There should be enough light in the room",
    "Clear picture, no shake or blurr",
    "  You should not be wearing anything on your face like face mask, sun glasess, spectacles, hat or any headgear",
  ];
  let terms = [
    "There should be enough light in the room.",
    " You should not be wearing anything on your face like face mask, sun glasses, spectacles, hat or any headgear.",
    "Your picture isn’t blurred",
  ];

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const reportLocation = async (state) => {
    if (state === "prompt" || state === "granted") {
      navigator.geolocation.getCurrentPosition(async (position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        request("/api/location", "POST", {
          lat: latitude.toString(),
          lng: longitude.toString(),
        }).then((countryRes) => {
          window.loading(false);
          locationApiFetched = true;
          location_data = countryRes;
          if (countryRes && countryRes.name && countryRes.name === "India") {
            locationIsIndia = true;
          }
        });
      });
    } else if (state === "denied") {
      locationPermissionDisabled = true;
      window.loading(false);
      alert(
        "You have disabled the location permission, please enable it in site settings"
      );
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          reportLocation(result.state);
        } else if (result.state === "prompt") {
          reportLocation(result.state);
        } else if (result.state === "denied") {
          reportLocation(result.state);
        }
        result.addEventListener("change", () => {
          reportLocation(result.state);
        });
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  onMount(() => {
    routeCheck(currentRoute.name);
    window.loading(true);
    setTimeout(async () => {
      getLocation();
    }, 1000);
  });

  const capture = async () => {
    screenState = "CONFIRM_SELFIE";
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);
    const dataURL = canvas.toDataURL();
    photoURL = dataURL;
    confirm = true;

    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      stream = null;
    }
    if (video) {
      video.srcObject = null;
    }
  };

  const takePhoto = async () => {
    captureDisabled = true;

    if (
      "mediaDevices" in navigator &&
      "getUserMedia" in navigator.mediaDevices
    ) {
      navigator.mediaDevices.getUserMedia({ audio: false, video: true }).then(
        (stream) => {
          screenState = "TAKE_SELFIE";
          setTimeout(() => {
            video.srcObject = stream;
            video.play();

            captureDisabled = false;
          }, 2000);
        },
        (e) => {
          // microphone not available
          alert("Please allow camera permission");
        }
      );
    } else {
      alert("Your browser doesn't support camera");
    }
  };

  const faceMatchCheck = async () => {
    window.loading(true);
    const matchingImageRes = await request("/api/kyc/selfie/upload", "POST", {
      image: photoURL.split(",")[1],
      location: JSON.stringify({
        location: {
          latitude,
          longitude,
          country_info: {
            code: location_data.code,
            name: location_data.name,
            isoA2Code: location_data.isoA2Code,
            isoA3Code: location_data.isoA3Code,
          },
        },
        ip: location_data.ip,
      }),
    });
    //window.loading(false);
    screenState = "NAV";
    ///window.loading(true);

    if (matchingImageRes.type === "success") {
      //navigate to next page with a success message to the user.
      if (matchingImageRes.liveliness === true) {
        snackbarType = "success";
        snackbarMessage = "Picture verified successfully";
        snackbarOpen.forceOpen();
        setTimeout(async () => {
          window.loading(false);
          navigateTo("/");
        }, 2000);
      } else {
        window.loading(false);
        navigateTo("/");
      }
    } else {
      //stay at the same page, inform that the present image is not accepted and disable the confirm page until a new image is taken

      window.loading(false);
      confirm = false;
      snackbarType = "snackbar-warning";
      snackbarMessage = "Picture could not be verified. Please try again.";
      snackbarOpen.forceOpen();
      navigateTo("/");
    }
  };
</script>

{#if screenState === "INFO"}
  <WizardBody title="Take a selfie" back="">
    <div
      style="margin-bottom: 30px; font-weight: 600; font-size: 14px; line-height: 30px;  color: #979797; margin-top: -2px;"
    >
      Ensure following things to avoid rejection
    </div>
    <Bullets points={terms} />
  </WizardBody>
  <WizardActionArea>
    {#if !locationPermissionDisabled && locationApiFetched}
      {#if !locationIsIndia}
        <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px">
          Looks like you're not in India. Account opening is only allowed for
          Indian citizen residing in India.
        </div>
      {/if}
    {/if}

    {#if locationPermissionDisabled}
      <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px">
        You have disabled the location permission, please enable it in site
        settings
      </div>
    {/if}

    <Button
      title="Take photo"
      onclick={takePhoto}
      disabled={!locationIsIndia}
    />
  </WizardActionArea>
{:else if screenState === "TAKE_SELFIE"}
  <WizardBody title="" back="/">
    <p
      style="margin-top: 0px; font-style: normal; font-weight: 500; line-height: 24px;  text-align: center; color: #505869;"
    >
      You should not be wearing anything on your face like face mask, sun
      glasses, spectacles, hat or any headgear.
    </p>

    <div style={captureDisabled === true ? "display: block" : "display: none"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; background: rgb(255, 255, 255,0); display: block; shape-rendering: auto;"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#083a9a"
          stroke-width="4"
          r="35"
          stroke-dasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          />
        </circle>
      </svg>
    </div>

    <!-- svelte-ignore a11y-media-has-caption -->
    <div
      style={`width: 100%; height: 100%; position: relative; ${
        captureDisabled === true ? "display: none" : "display: block"
      }`}
    >
      <div
        style="position: absolute;width: 100%;display: flex;justify-content: center;height: 100%; align-items: center; overflow:hidden;"
      >
        <div class="border-top">
          <span
            style="margin-bottom: 12px; font-style: normal; font-weight: 700; font-size: 18px; line-height: 16px; text-align: center; color: #C9C9C9; opacity: 0.64;"
            >Your face should fit into the box</span
          >
        </div>
      </div>
      <video
        id="video"
        bind:this={video}
        style="width: 100%; height: 100%"
        autoPlay={true}
        playsInline={true}
        muted={true}
      />
    </div>
  </WizardBody>
  <WizardActionArea>
    <Button onclick={capture} title="Capture" disabled={captureDisabled} />
  </WizardActionArea>
  <!-- svelte-ignore empty-block -->
{:else if screenState === "CONFIRM_SELFIE"}
  <WizardBody title="Looks good!" back="/">
    <div class="d-flex align-items-center gap-2">
      <p>Ensure the selfie is clear to avoid rejection.</p>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="info">
        <Wrapper rich>
          <Label><img src="/assets/images/info.svg" alt="info" /></Label>
          <Tooltip>
            <Content>
              <Bullets points={info} />
            </Content>
          </Tooltip>
        </Wrapper>
      </div>
    </div>
    <div class="selfie">
      <!-- svelte-ignore a11y-img-redundant-alt -->
      <img class="selfie-img" src={photoURL} alt="picture" />
    </div>
  </WizardBody>
  <WizardActionArea>
    <div style="position: relative; z-index: 9999">
      <Snackbar leading bind:this={snackbarOpen} class={snackbarType}>
        <Label>{snackbarMessage}</Label>
      </Snackbar>
    </div>
    <div class="button">
      <div style="position: relative;">
        <Buttonlnv
          title="retake"
          onclick={takePhoto}
          style="margin-right: 5px !important;"
        />
      </div>
      <div style="position: relative;">
        <Button title="confirm" onclick={faceMatchCheck} disabled={!confirm} />
      </div>
    </div>
  </WizardActionArea>
{/if}

<style>
  video {
    width: 100%;
  }
  .selfie-img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
  }

  .selfie {
    max-width: 30rem;
    min-width: 300px;
    margin-top: 20px;
    width: 100%;
    height: auto;
    left: 88px;
    top: 218px;
    background-color: black;
  }

  .info:hover {
    cursor: pointer;
  }

  .border-top {
    width: 100%;
    height: 80%;
    border: 2px dashed #65bf73;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 16%;
    box-shadow: 1px 1px 1px 200px rgb(0 0 0 / 50%);
  }

  @media only screen and (min-width: 768px) {
    .button {
      display: flex;
      align-items: center;
    }
  }
</style>
