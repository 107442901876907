import dayjs from "dayjs";
import { bubble, listen } from "svelte/internal";
import CryptoJS from "crypto-js";
let defaulString = "rve80wmL6hHd1B3j";
export const valueArray = (items, attribute) => {
  const result = [];
  for (let i in items) {
    result.push(items[i][attribute]);
  }
  return result;
};
export const toCurrency = (number) => {
  return Number(number.toString().replace(/[^\d.]/g, "")).toLocaleString(
    "en-IN",
    {
      maximumFractionDigits: 0,
      style: "currency",
      currency: "INR",
    }
  );
};

export const parseDaysUsingDayjs = (numberOfDays) => {
  let maturityDate = dayjs().add(numberOfDays, "day");
  let years = maturityDate.diff(dayjs(), "year", true);
  if (years % 1 >= 0.99) {
    years = Math.ceil(years);
  } else {
    years = Math.floor(years);
  }
  maturityDate = maturityDate.subtract(years, "year");
  let months = maturityDate.diff(dayjs(), "month");
  if (months == 12) {
    years = years + 1;
    months = 0;
  } else {
    maturityDate = maturityDate.subtract(months, "month");
  }
  let days = maturityDate.diff(dayjs(), "day");
  const yearsDisplay =
    years > 0 ? years + (years == 1 ? " year " : " years ") : "";
  const monthsDisplay =
    months > 0 ? months + (months == 1 ? " month " : " months ") : "";
  const daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
  return (yearsDisplay + monthsDisplay + daysDisplay).trim();
};
export const parseDaysUsingDayjsObject = (numberOfDays) => {
  let maturityDate = dayjs().add(numberOfDays, "day");
  const years = Math.round(maturityDate.diff(dayjs(), "year"), true);
  maturityDate = maturityDate.subtract(years, "year");
  const months = Math.round(maturityDate.diff(dayjs(), "month", true));
  maturityDate = maturityDate.subtract(months, "month");
  const days = Math.round(maturityDate.diff(dayjs(), "day"), true);
  return { years, months, days };
};
/*
export const parseDays = (numberOfDays) => {
  let maturityDate = dayjs().add(numberOfDays, "day");
  const years = maturityDate.diff(dayjs(), "year");
  maturityDate = maturityDate.subtract(years, "year");
  const months = maturityDate.diff(dayjs(), "month");
  maturityDate = maturityDate.subtract(months, "month");
  const days = maturityDate.diff(dayjs(), "day");
  return { year: yearsDisplay, month: monthsDisplay, day: daysDisplay };
};
export const formatDays = (numberOfDays) => {
  let {year,month,day} = parseDays(numberOfDays);
  const yearsDisplay =
  year > 0 ? year + (year == 1 ? " year " : " years ") : "";
  const monthsDisplay =
  month > 0 ? month + (month == 1 ? " month " : " months ") : "";
  const daysDisplay = day > 0 ? day + (day == 1 ? " day" : " days") : "";
  return (yearsDisplay + monthsDisplay + daysDisplay).trim();
};*/

export const parseDays = (numberOfDays) => {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  const yearsDisplay =
    years > 0 ? years + (years == 1 ? " year " : " years ") : "";
  const monthsDisplay =
    months > 0 ? months + (months == 1 ? " month " : " months ") : "";
  const daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
  return (yearsDisplay + monthsDisplay + daysDisplay).trim();
};

export const bankToJsDate = (date) => dayjs(date).toDate();

export const dateDiff = (largeDate, smallDate) => {
  const currentDate = largeDate;
  const selectDate = new Date(smallDate);
  const diffTime = Math.abs(selectDate - currentDate);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const getDurationAndRate = (
  interestList,
  selectedDays,
  dob = new Date()
) => {
  const age = Math.ceil(Math.abs(new Date() - dob) / (1000 * 60 * 60 * 24));

  let isSenior = false;
  if (age >= 60) {
    isSenior = true;
  }

  /* const selectedDays = Math.ceil(
    Math.abs(dayjs(selectedDate).toDate() - new Date()) / (1000 * 60 * 60 * 24)
  ); */

  for (const interestItem of interestList) {
    if (
      selectedDays >= interestItem.duration_start &&
      selectedDays <= interestItem.duration_end
    ) {
      if (isSenior) {
        //for senior
        return {
          interest_rate: interestItem.interest_senior,
          duration: selectedDays,
        };
      }

      return { interest_rate: interestItem.interest, duration: selectedDays };
    }
  }

  return { interest_rate: 0, duration: selectedDays };
};

export const getMaturityAmount = (principle, duration, interest_rate) => {
  principle = currencyToNum(principle);
  const compounding = 4; //365 daily,12 is monthly,4 quarter,2 bi-annual,1 yearly
  return (
    principle *
    Math.pow(
      1 + interest_rate / (100 * compounding),
      (duration / 365) * compounding
    )
  );
};
export const currencyToNum = (amount) => {
  amount = amount.toString().replaceAll(",", "");

  return parseFloat(amount.replace(/[^0-9.]/, ""));
};

export const makeFirstCap = (value) => {
  var splitStr = value.toLowerCase().split(" ");

  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};

export const convertDaysIntoMonthdays = (duration) => {
  const monthResult = duration / 30;
  const dayResult = duration % 30;

  return [monthResult, dayResult];
};

export const orderedInterestList = (interests) => {
  let newInterestList = interests.map((interest) => {
    const { DEPOSIT_DAYS, INTEREST_RATE, DEPOSIT_AMOUNT, DEPOSIT_MONTHS } =
      interest;
    const today = dayjs();

    let maturityDate = today
      .add(Number(DEPOSIT_MONTHS), "month")
      .add(Number(DEPOSIT_DAYS), "day");
    const numDaysInterest = maturityDate.diff(today, "d");
    return {
      duration: numDaysInterest,
      amount: Number(DEPOSIT_AMOUNT),
      interest_rate: Number(INTEREST_RATE),
    };
  });
  newInterestList = newInterestList.sort((a, b) => {
    if (Math.abs(a.amount - b.amount) > 0.1) {
      return a.amount - b.amount;
    } else {
      return b.duration - a.duration;
    }
  });
  let interestObj = {};
  let interestArr = [];
  for (const interest of newInterestList) {
    if (!interestObj.hasOwnProperty(interest["amount"]))
      interestObj[interest["amount"]] = [];
    interestObj[interest["amount"]].push(interest);
  }
  for (const [i, interest] of Object.entries(interestObj)) {
    interestArr.push(interest);
  }
  interestArr.sort((a, b) => {
    return a[0].amount - b[0].amount;
  });
  return interestArr;
};

export const getInterst = (interests, duration, amount, isSenior = false) => {
  //console.log(duration, amount);
  let durationInterest = 0;
  for (const amountArr of interests) {
    if (amount <= Number(amountArr[0].amount)) {
      for (const interest of amountArr) {
        if (duration <= interest.duration) {
          durationInterest = interest.interest_rate;
        } else {
          break;
        }
      }
      break;
    }
  }
  return durationInterest;
};

export const isEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
};

export const isPan = (pan) => {
  return pan.match(/[A-Z]{3}P[A-Z][0-9]{4}[A-Z]{1}/) && pan.length == 10;
};

export function getEventsAction(component) {
  return (node) => {
    const events = Object.keys(component.$$.callbacks);
    const listeners = [];
    events.forEach((event) =>
      listeners.push(listen(node, event, (e) => bubble(component, e)))
    );
    return {
      destroy: () => {
        listeners.forEach((listener) => listener());
      },
    };
  };
}

export const toBase64 = (payload) => {
  return window.btoa(payload);
  //return Buffer.from(payload, 'utf8').toString('base64')
};

export const decodeBase64 = (data) => {
  return window.atob(data);
  //return Buffer.from(data, "base64").toString("ascii");
};
export const encrypt = (plaintext, key = defaulString) => {
  let ciphertext = CryptoJS.AES.encrypt(plaintext, key);
  return ciphertext.toString();
};
export const decrypt = (ciphertext, key = defaulString) => {
  let bytes = CryptoJS.AES.decrypt(ciphertext, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const getStateCode = (state) => {
  const json = {
    "andhra pradesh": "AD",
    "arunachal pradesh": "AR",
    assam: "AS",
    bihar: "BR",
    chattisgarh: "CG",
    delhi: "DL",
    goa: "GA",
    gujarat: "GJ",
    haryana: "HR",
    "himachal pradesh": "HP",
    "jammu and kashmir": "JK",
    jharkhand: "JH",
    karnataka: "KA",
    kerala: "KL",
    "lakshadweep islands": "LD",
    "madhya pradesh": "MP",
    maharashtra: "MH",
    manipur: "MN",
    meghalaya: "ML",
    mizoram: "MZ",
    nagaland: "NL",
    odisha: "OD",
    pondicherry: "PY",
    puducherry: "PY",
    punjab: "PB",
    rajasthan: "RJ",
    sikkim: "SK",
    "tamil nadu": "TN",
    telangana: "TS",
    tripura: "TR",
    "uttar pradesh": "UP",
    uttarakhand: "UK",
    "west bengal": "WB",
    "andaman and nicobar islands": "AN",
    chandigarh: "CH",
    "dadra & nagar haveli": "DH",
    "daman & diu": "DD",
    ladakh: "LA",
    "other territory": "OT",
    ad: "AD",
    ar: "AR",
    as: "AS",
    br: "BR",
    cg: "CG",
    dl: "DL",
    ga: "GA",
    gj: "GJ",
    hr: "HR",
    hp: "HP",
    jk: "JK",
    jh: "JH",
    ka: "KA",
    kl: "KL",
    ld: "LD",
    mp: "MP",
    mh: "MH",
    mn: "MN",
    ml: "ML",
    mz: "MZ",
    nl: "NL",
    od: "OD",
    py: "PY",
    pb: "PB",
    rj: "RJ",
    sk: "SK",
    tn: "TN",
    ts: "TS",
    tr: "TR",
    up: "UP",
    uk: "UK",
    wb: "WB",
    an: "AN",
    ch: "CH",
    dh: "DH",
    dd: "DD",
    la: "LA",
    ot: "OT",
  };
  return json[state.toSting().toLowerCase()];
};
