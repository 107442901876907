<script>
  export let value;
  export let recommended = false;
  export let isSelected = false;
  export let amount;
  export let badgeSelected = false;

  const selected = () => {
    amount = value;
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="option"
  style={isSelected ? "border-color: #1E3868; background-color: #EBF0F9" : ""}
  on:click={selected}
>
  {#if recommended}
    <div
      class="recommended"
      style={badgeSelected === false ? "background: #979797; color: #fff" : ""}
    >
      Recommended
    </div>
  {/if}

  <div class="input" style={isSelected ? "color: #464448" : ""}>{value}</div>
</div>

<style>
  .option {
    width: 130px;
    border: 1px solid #979797;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;
    cursor: pointer;
  }
  .recommended {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 8px;
    color: #ffffff;
    background: #1e3868;
    background: #eb3c23;
    border-radius: 2px;
    z-index: 2;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    top: -7px;
  }
  .input {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    border: none;
    display: block;
    margin: 0px;
    padding: 0px;
    outline: none;
    text-align: center;
  }
</style>
