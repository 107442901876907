<script>
  import {
    getDurationAndRate,
    getInterst,
    parseDays,
    parseDaysUsingDayjs,
  } from "../../services/function";
  import Icon from "../commons/Icon.svelte";
  import TenureNew from "./TenureNew.svelte";

  export let selectedTenureIndex;
  export let onclick;
  export let tenureList;
  export let interestList = [];
  export let maturityDate;
  export let customMaturityDate;
  export let amount;

  tenureList.map((item, index) => {
    if (item.default === true) {
      selectedTenureIndex = index;
    }
  });
</script>

<div style=" gap: 24px; display: flex; flex-wrap: wrap; ">
  {#each tenureList as item, index}
    <TenureNew
      smallTitle={parseDaysUsingDayjs(item.value)}
      bigTitle={`${getInterst(
        interestList,
        item.value,
        Number(amount.replace(/[^0-9\.]/, "").replace(/,/g, "")),
        false
      ).toFixed(2)}%`}
      attractive={item.recommended}
      bind:selectedTenureIndex
      selected={selectedTenureIndex === index ? true : false}
      {index}
      bind:maturityDate
      bind:customMaturityDate
    />
  {/each}

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="tenure-item"
    style={customMaturityDate && customMaturityDate.length > 0
      ? "border: 1px solid #083A9A"
      : ""}
    on:click={() => {
      selectedTenureIndex = -1;
      onclick();
    }}
  >
    <div class="small-title">
      <Icon icon="add_circle" class="note-text" color="#979797" size="20px" />
    </div>
    <div class="big-title">Custom</div>
  </div>
</div>

<style>
  .tenure-item {
    width: 40%;
    height: 100%;
    margin-bottom: 24px;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      #e2eaf5 24.91%,
      rgba(166, 180, 202, 0) 97.17%
    );
    border: 1px solid #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 18px;
    justify-content: end;
    cursor: pointer;
  }

  .tenure-item:hover {
    background: linear-gradient(
      180deg,
      #d8e3f3 24.91%,
      rgba(157, 171, 194, 0) 97.17%
    );
  }

  .small-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #979797;
  }

  .big-title {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #505869;
  }
</style>
