<script>
  import {
    currencyToNum,
    getMaturityAmount,
    parseDays,
    parseDaysUsingDayjs,
    toCurrency,
  } from "../../services/function.js";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Button from "../../components/commons/Button.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import dayjs from "dayjs";
  import Buttonlnv from "../../components/commons/Buttonlnv.svelte";
  import Snackbar, { Label } from "@smui/snackbar";

  let checked = false;

  let amount = "";
  let duration = "";
  let interest_rate = "";
  let maturity_date = "";
  let interest_payout = "";
  let maturity_inst = "";
  let maturity_amount = "";
  let type = "";
  let snackbarOpen;
  let snackbarMessage = "";

  let fd_data;

  let interest_loaded = false;

  onMount(async () => {
    try {
      fd_data = localStorage.getItem("fd_data");
      fd_data = JSON.parse(fd_data);
      amount = currencyToNum(fd_data.amount);
      duration = parseInt(fd_data.duration);
      interest_rate = parseFloat(fd_data.interest_rate);
      type = fd_data.type;
      interest_payout = fd_data.interest_payout;
      maturity_inst = fd_data.maturity_inst;

      const interestData = await request(
        "/api/user_account/fd_interest",
        "POST",
        {
          amount: amount.toString(),
          duration: duration.toString(),
          interest_payout: interest_payout,
        }
      );

      interest_loaded = true;

      interest_rate = Number(interestData.interest_rate);
    } catch (e) {
      //console.log(e);
      alert("You are on wrong page");
    }
  });

  const confirmClick = async () => {
    localStorage.setItem("fd_data", "");

    window.loading(true);

    await request("/api/user_account/fd", "POST", {
      amount: amount.toString(),
      duration: duration.toString(),
      interest_rate: interest_rate.toString(),
      type,
      interest_payout,
      maturity_inst,
    });

    snackbarMessage = "Account is confirmed";
    snackbarOpen.forceOpen();

    setTimeout(async () => {
      window.loading(false);
      navigateTo("/");
    }, 2000);
  };
</script>

<WizardBody title="Confirm your fixed deposit details" back="/">
  <div class="card">
    <div class="top-blue">
      <div
        style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
      >
        <i
          style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
        />
        <i
          style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
        />
      </div>

      <div
        style="margin-top: 0px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72); width: 100%; display: flex; justify-content: center;"
      >
        Fixed Deposit Amount
      </div>
      <div
        style="width: 100%; display: flex; padding-bottom: 24px; justify-content: center; margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
      >
        {toCurrency(amount)}
      </div>
    </div>
    <div
      style="margin-top: 32px; width: 100%; display: flex; flex-direction: column; padding: 0px 26px 32px 26px; gap: 10px;"
    >
      <div
        style="font-style: normal; font-weight: 700; font-size: 20px; line-height: 16px;  color: #FFFFFF; padding-bottom: 18px"
      >
        Deposit Details
      </div>
      <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">FD Duration</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {parseDaysUsingDayjs(duration)}
        </div>
      </div>

      <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">Interest Rate</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {interest_loaded ? `${interest_rate} %` : "Loading"}
        </div>
      </div>

      <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">Maturity Date</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {dayjs(new Date()).add(duration, "day").format("DD MMM YYYY")}
        </div>
      </div>

      <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">Get Interest</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {interest_payout}
        </div>
      </div>

      <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">Maturity Instruction</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {maturity_inst}
        </div>
      </div>

      <!-- <div
        style="display: flex; width: 100%; justify-content: end; align-items: center; "
      >
        <div class="bottom-text">Maturity Amount</div>
        <div class="bottom-text" style="justify-content: end; text-align: end;">
          {interest_loaded
            ? `${toCurrency(
                getMaturityAmount(amount, duration, interest_rate)
              )}`
            : "Loading"}
        </div>
      </div> -->
    </div>
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative;z-index:9999">
    <Snackbar leading bind:this={snackbarOpen} class="success">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <div class="button" style="position: relative;">
    <div style="width: 100%">
      <Buttonlnv
        title="edit"
        onclick={() => {
          navigateTo(`/fd/create/${type}`);
        }}
      />
    </div>

    <div style="width: 100%">
      <Button title="Confirm" onclick={confirmClick} />
    </div>
  </div>
</WizardActionArea>

<style>
  .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 480px;
    max-width: 100%;
    background: #062869;
    mix-blend-mode: normal;
  }
  .top-blue {
    background: linear-gradient(
      66.27deg,
      #13afe9 -10.45%,
      #0f89d0 12.51%,
      #0a54ac 39.13%,
      #083a9a 63.11%
    );
    border-radius: 24px;
    border-radius: 24px;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 26px;
  }
  .bottom-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.26px;
    color: #ffffff;
    display: flex;
    width: 50%;
    justify-content: start;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .button {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      gap: 10px;
      margin-left: 1px;
    }
  }
</style>
