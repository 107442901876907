<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
</script>

<WizardBody title="Fixed Deposit" back="/terms-conditions">
  <div>
    <h2>SBM Bank (India) Ltd.</h2>

    <p>Terms and conditions and Consent for Aadhaar based e-KYC</p>

    <p>
      I/We have opened a Fixed Deposit with SBM Bank (India) Ltd. (will be
      referred hereinafter as SBM Bank) through the online platform of
      paisabazar.com towards availing a secured credit card against the said
      fixed deposit. I, hereby, agree to open the fixed deposit based on Aadhaar
      based online KYC verification process as stated below.
    </p>

    <p>
      I confirm that I have not opened any other bank account other than the
      proposed one with SBM Bank, using OTP based Aadhaar (e-KYC) authentication
      process.
    </p>

    <p>
      I hereby state that I have no objection in authenticating myself with
      Aadhaar based authentication system and give consent to providing my
      Aadhaar Number, Biometric and/or OTP (One Time Pin) (and/or any similar
      authentication mechanism) for Aadhaar Based authentication for the
      purposes of availing of the Banking Service from SBM Bank.
    </p>

    <p>
      I understand that the Biometric and/or OTP and/or any similar
      authentication mechanism, I may provide for authentication, shall be used
      only for authenticating my identity through Aadhaar Authentication system
      for that specific transaction and for no other purposes. I also understand
      that SBM Bank India shall ensure security and confidentiality of my
      personal identity data provided for the purpose of Aadhaar based
      authentication.
    </p>

    <p>
      I , therefore, submit my Aadhaar number and voluntarily give my consent
      to:
    </p>

    <p>
      Use my Aadhaar Details and authenticate myself through Aadhaar based
      authentication system for the purpose of establishing my identity.
    </p>

    <p>
      Use my Registered Mobile Number in the bank records for sending SMS alerts
      to me.
    </p>

    <p>
      Link the Aadhaar Number to all my existing/new/future accounts and
      customer profile (CIF) with your Bank.
    </p>

    <p>
      I hereby give consent to SBM Bank to share my/our personal / KYC details
      with Central KYC Registry.
    </p>

    <p>
      I also consent to receiving information from Central KYC Registry through
      SMS/Email on the above registered number/email address.
    </p>

    <h2>Opening of Fixed Deposits:</h2>

    <p>
      The Fixed Deposit will be opened as a regular FD with mode of operation
      (MOP) as "Self" and with compounding interest facility only.
    </p>

    <p>
      I/We will have the choice of making nominee. However, when Nominee is a
      minor, I/we will specify the name of the guardian and shall submit
      physical copy of the nomination form to the Bank. In case I/we fail to do
      so, information provided online shall be treated as final information
      related to Nominee.
    </p>

    <p>
      I/We hereby authorize the Bank to act upon my instruction regarding
      nomination, cancellation or variation of the nomination for the Fixed
      Deposit. I shall not hold the bank responsible should there be any claim
      raised against the Bank for acting on the instruction of nomination and
      shall ensure that the Bank does not suffer any loss for which I/we will
      keep the Bank indemnified from time to time.
    </p>

    <p>I/We have opted to receive e-statements.</p>

    <p>
      I/We agree that Bank will be sending me/us an e-advice on the registered
      email ID for Term deposit instead of a physical deposit receipt.
    </p>

    <p>
      In case of loss of Fixed Deposit Advice, I/we shall satisfy SBM Bank
      (India) and indemnify it for any cause or consequence arising out of
      issuance of a duplicate advice.
    </p>

    <p>
      I/We also hereby agree that on maturity, the maturity amount will be
      renewed automatically as per the original tenor of the deposit and shall
      keep on renewing till such time the linked credit card will remain live.
    </p>

    <p>
      In case I/we surrender the credit card after full settlement of card dues,
      to the satisfaction of SBM Bank (India), I/we can request the SBM Bank to
      close the deposit and pay me/us the proceeds by way of a demand draft.
    </p>

    <p>
      I hereby declare that the details furnished above are true and correct to
      the best of my/our knowledge and belief and I undertake to inform the Bank
      of any changes therein, immediately. In case any of the above information
      is found to be false or untrue or misleading or misrepresenting, I/we,
      am/are aware that I/we may be held liable for it.
    </p>

    <p />

    <h2>General Terms and Conditions:</h2>

    <p>
      Any Resident Indian who is 18 years and above and provides his/her details
      in the Digital application form for opening FD account offered by SBM Bank
      (India) Limited is a Customer.
    </p>

    <p>
      By visiting the FD– Apply Now link (pl mention specific link) and sharing
      all information, customer consents to provide his/her name, contact
      details and/or any other information at his/her free will with Bank.
    </p>

    <p>
      A customer can also have the option of completing KYC at any SBM Bank
      (India) branch and provide physical KYC documents.
    </p>

    <p>
      While providing all the information as above, a customer authorizes SBM
      Bank (India) to call on his given number to explain the features of the
      product and/or to market any other product.
    </p>

    <p>
      SBM Bank shall not be liable for any connectivity/ signal/ data issues
      leading to non- completion or wrong/false/incomplete information being
      provided by the customer leading to the incompletion of his/her
      application.
    </p>

    <p>
      The customer has agreed to provide his/ her valid Aadhaar number and valid
      PAN Card details. He/she understands that opening an account is subject to
      correct, complete and accurate information provided to the Bank.
    </p>

    <p>
      Customer agrees that upon successful OTP validation of Aadhaar, the name
      of the customer in the account will be the same name as appearing in
      his/her Aadhaar Card.
    </p>

    <p>
      Application once submitted cannot be withdrawn by the customer. Bank shall
      not be liable to pay for any costs (technical/ data plan related or
      otherwise) incurred by the customer in the course of the downloading or
      sharing of his/her details on the application.
    </p>

    <p>
      The customer herewith agrees to provide the accurate documentation and
      information as listed in the app for the purpose of account opening.
      Customer understands and agrees that failure to provide requisite
      documents and information shall result in rejection of application by the
      Bank. The customer agrees that Bank has every right to reject the account
      opening application, if there is any erroneous, incomplete or misleading
      information provided by the customer.
    </p>

    <p>
      While submitting the application, the customer agree with all terms and
      conditions laid down by the Bank from time to time for the said product.
    </p>

    <p>
      Customer has agreed that he/she has not opened any other account with SBM
      Bank (India) Ltd.
    </p>

    <p>
      In case customer fails to complete Aadhar/OTP based authentication
      process, the customer herewith agrees to complete his/her full KYC by
      visiting his nearest SBM Bank (India) branch.
    </p>

    <p>
      The customer agrees that such an account may be subject to regular
      scrutiny and monitoring from the bank, who shall reserve the right to
      completely freeze/debit freeze/ close the account at its sole discretion
      for any reason whatsoever with/without assigning any reason or if KYC
      documents submitted do not comply with the KYC norms of the Bank.
    </p>

    <p>
      This account opening process is not available for existing SBM Bank
      customers who have a relationship with the bank.
    </p>

    <p>
      This account opening process is also not available for customers who are
      FATCA reportable. Such customers are requested to approach the branch and
      to comply with requirement of opening an account.
    </p>

    <p>
      Bank reserves the right to take necessary action, legal or otherwise, if
      it finds any wilful modification/ withholding of information or
      misrepresentation by the customer.
    </p>

    <p>
      Customers, who would like to deposit funds using the funding facility
      available in the FD app, can do so only from their own bank account and
      not from a Third Party Bank account. Bank shall be at liberty to refuse to
      open the accounts funded from a third party bank account at its sole
      discretion.
    </p>

    <p>
      Customer understands and agrees that interest on the amount funded online
      will be paid subject to successful activation of the FD account and only
      from the date when the funds has been credited into the account.
    </p>

    <p>
      The customer hereby understands and agrees that in case funds are received
      and the FD account does not get created, no interest will be paid.
    </p>

    <p>
      The customer shall not enjoy the services provided by the bank unless the
      customer on- boarding process is complete.
    </p>

    <p>
      Customers will have restricted access on this account till KYC is
      completed.
    </p>

    <p>
      Customer declares and confirms that the Bank shall be entitled to rely on
      all/any communication, information and details provided on the electronic
      form and all such communications, information and details shall be final
      and legally binding on the Customer.
    </p>

    <p>
      Customer understands and confirms that the Bank has every right to close
      or debit freeze the account, if the details provided by him/her are found
      to be inaccurate, incorrect or false by the Bank or for any other reason
      whatsoever without assigning any reason thereof. In such an event, the
      bank shall not be liable to pay any interest on the amount of deposit
      &amp; the refund of amount deposited in the account will be refunded to
      the source account/or issue a Demand Draft to the Customer. In such an
      event, bank will retain the documents / Photographs (if any) and any other
      signed document submitted.
    </p>

    <p>This product is applicable only for Resident Indian Individuals.</p>

    <p>
      Customer gives consent to the Bank to fetch his/her demographic details
      from Unique Identification Authority of India (UIDAI), using biometric
      authentication which will be used for KYC purposes. The demographic
      details include name, DOB, Father's Name, gender, address and photograph.
      Customer further consents to the Bank to fetch his/her contact details
      from UIDAI which includes the contact number and email ID.
    </p>

    <p>
      Customer authorises Bank to link the Aadhar number provided by his/her
      with the FD account opened by him/her under this process.
    </p>

    <p>
      The Bank reserves the right to hold the accounts in Debit Freeze or close
      the Account even after account activation in case of any discrepancy found
      as part of regular monitoring and document verification activities.
    </p>

    <p>
      The customer agrees to provide the necessary details (Mobile, Email etc.,)
      as per his/her requirement at the time of submission of details through
      the app. Bank shall not bear any liability for any loss arising out of
      customer's failure to do so.
    </p>

    <p>
      The customer herewith agrees to be contacted by the bank to receive
      information in respect of account maintenance, alerts, payments due,
      updates on existing and new products, servicing of account for sales,
      marketing or servicing their relationship with SBM Bank (India) Ltd. and
      its group companies / associates or agents through Telephone / Mobile /
      SMS / Email etc. Further he/she understands that the consent to receive
      calls / communications shall be valid and shall prevail over their current
      or any subsequent registration of their mobile number for NDNC and shall
      continue to be treated as customer consent / acceptance.
    </p>

    <p>
      The customer herewith agrees that if the application is rejected, Bank
      will retain the documents / Photographs and any other signed document
      submitted by the customer on the Web app or otherwise.
    </p>

    <h2>Undertaking:</h2>

    <p>
      I hereby declare that all the above information voluntarily furnished by
      me is true, correct and complete.
    </p>

    <p>
      I understand that my information submitted to the bank herewith shall not
      be used for any purpose other than mentioned above.
    </p>

    <p>
      The bank may disclose information about customer's account, if required or
      permitted by law, rule or regulations, or at the request of any public or
      regulatory authority or if such disclosure is required for the purpose of
      preventing frauds, or in public interest, without specific consent of the
      account holder/s.
    </p>

    <p>
      I understand that Bank may share my KYC information with central KYC
      registry, if required.
    </p>
  </div>
</WizardBody>

<style>
  h2 {
    color: #1e3868;
    font-weight: 700;
  }
</style>
