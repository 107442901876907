<script>
  import { userCheck } from "./../../../services/userCheck.js";
  import OTPInput from "./../../../components/commons/OTPInputLayout.svelte";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import { onMount } from "svelte";
  import { request } from "../../../services/network";
  import { tweened } from "svelte/motion";
  import Snackbar, { Label } from "@smui/snackbar";
  import Buttonlnv from "../../../components/commons/Buttonlnv.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { decrypt } from "../../../services/function.js";

  let snackbarType;
  let snackbarOpen;
  let snackbarMessage = "";

  let aadhaar = "";

  onMount(async () => {
    let session = JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );

    try {
      aadhaar = session.aadhaar;
    } catch (e) {
      //
    }
  });

  const original = 60; // TYPE NUMBER OF SECONDS HERE
  let timer = tweened(original);
  setInterval(() => {
    if ($timer > 0) {
      $timer--;
    } else {
      $timer = 0;
    }
  }, 1000);
  $: minutes = Math.floor($timer / 60);
  $: seconds = Math.floor($timer - minutes * 60);
  const sendOtp = async () => {
    if (seconds < 1) {
      timer = tweened(original);
      window.loading(true);

      const result = await request("/api/kyc/aadhaar", "POST", {
        aadhaar,
      });

      window.loading(false);
      if (result.type === "success") {
        snackbarType = "success";
        snackbarMessage =
          "OTP is resent to mobile number linked with the Aadhaar";
        snackbarOpen.forceOpen();
      } else if (result.message) {
        snackbarMessage = result.message;
        snackbarOpen.forceOpen();
      }
    }
  };

  let otp = "";

  function onOTPUpdate(event) {
    otp = event.detail.completevalue.trim();
  }

  const buttonClick = async () => {
    window.loading(true);

    if (otp.length !== 6) {
      //window.snackbar("Please enter OTP", "danger", null);
      snackbarType = "snackbar-warning";
      snackbarMessage = "Please enter OTP";
      snackbarOpen.forceOpen();
      return;
    }

    const res = await request("/api/kyc/aadhaar/verify", "POST", { otp: otp });

    if (res.type === "success" && res.aadhaar) {
      //await userCheck(true);
      // navigateTo("/onboarding/aadhaar-confirm");

      snackbarType = "success";
      snackbarMessage = "AADHAAR is verified";
      snackbarOpen.forceOpen();

      setTimeout(async () => {
        window.loading(false);
        navigateTo("/");
      }, 2000);
    } else {
      //window.snackbar("Invalid otp", "danger", null);
      window.loading(false);
      snackbarType = "snackbar-warning";
      snackbarMessage = res.message ? res.message : "OTP is incorrect";
      snackbarOpen.forceOpen();
    }
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };

  const changeAadhar = async () => {
    window.loading(true);

    await request("/api/kyc/aadhaar/change", "POST");

    localStorage.setItem("aadhaar", JSON.stringify({ aadhaar }));

    window.loading(false);

    navigateTo("/");
  };
</script>

<WizardBody title="Enter OTP" back="">
  <div>
    <!-- <div class="pb-3 text-muted otp-msg">
      OTP sent to Aadhaar registered mobile number <span
        class="span"
        style="color:#083A9A;">KNOW MORE</span
      >
    </div> -->

    <div class="pb-3 text-muted otp-msg">
      OTP is sent to mobile number linked with the Aadhaar{aadhaar.length > 2
        ? " "
        : ""}<span style="font-weight: 600"
        >{"**** **** " + aadhaar.slice(8)}</span
      >
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <span class="change-aadhaar" on:click={changeAadhar}>CHANGE</span>
    </div>

    <OTPInput
      numberOfInputs={6}
      on:notify={onOTPUpdate}
      emitEventOnPrefill={false}
    />

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="resend-otp-button" style="cursor: pointer;" on:click={sendOtp}>
      Didn't receive OTP ?<span
        style={seconds < 1
          ? "font-weight: bolder; color: #083A9A; text-decoration: underline"
          : ""}>RESEND</span
      >
      {@html seconds > 0
        ? `<span style="color: #083A9A; font-weight:600;">00:${
            seconds < 10 ? `0${seconds}` : seconds
          }</span>`
        : ""}
    </div>
    <div />
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative;z-index:9999">
    <Snackbar leading bind:this={snackbarOpen} class={snackbarType}>
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <div style="position: relative;">
    <div class="button">
      <Button title="verify" onclick={buttonClick} disabled={otp.length != 6} />

      <!-- <Buttonlnv title="Change AADHAAR" onclick={} /> -->
    </div>
  </div>
</WizardActionArea>

<style>
  .change-aadhaar {
    font-size: 16px;
    line-height: 20px;
    color: #083a9a;
    font-weight: 700;
    cursor: pointer;
  }
  .otp-msg {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 40px;
  }

  .resend-otp-button {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #464448;
    margin-top: 40px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .button {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  @media only screen and (max-width: 768px) {
    .otp-msg {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 16px;
      word-spacing: 1px;
    }

    .resend-otp-button {
      margin-top: 28px;
    }

    .button {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin-left: 1px;
    }

    .change-aadhaar {
      font-size: 14px;
      line-height: 16px;
    }
  }
</style>
