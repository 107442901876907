<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
</script>

<WizardBody title="Debit Card" back="/terms-conditions">
  <div>
    <h2>SBM Visa Debit card</h2>

    <p>Terms and Conditions of Usage ("Terms &amp; Conditions")</p>

    <p>
      Important: Please ensure that you have read these Terms &amp; Conditions
      carefully, before using the Debit Card. By using the SBM Bank (India)
      Debit Card ("Debit Card") you are unconditionally accepting these Terms
      &amp; Conditions and will be bound by them and you accept the onus of
      ensuring compliance with the Applicable Law (defined hereunder). You will
      also continue to remain bound by the terms &amp; conditions of operation
      of your Savings Bank Account / Current Accounts with SBM Bank (India) Ltd.
    </p>

    <h2>DEFINITIONS</h2>

    <p>
      "Account(s)", refers to the Cardholder's Savings or Current Accounts that
      have been designated by SBM Bank to be the eligible account(s) for the
      valid operation of the Debit Card.
    </p>

    <p>
      "Applicable Law" means all statutes governing issuance of and transaction
      in debit cards including Reserve Bank of India Act, 1934, Foreign Exchange
      Management Act, 1999 and all regulations, rules and guidelines framed
      there under. Orders, permits, tariffs and approvals, including those
      relating to the Parties or the subject matter of these Terms &amp;
      Conditions including but not limited to Reserve Bank of India ("RBI"),
      National Payment Corporation of India, Exchange Control Regulations.
    </p>

    <p>
      "ATM", refers to the Automated Teller Machine whether in India or
      overseas, whether of SBM or any other Bank or a shared network, at which
      amongst other things, the Cardholder can use his Debit Card to access his
      funds in his Account(s) held with SBM Bank (India) Ltd.
    </p>

    <p>
      "The Bank" or "SBM Bank" or "Us", means SBM Bank (India) Limited having
      its registered office at 101 Raheja Centre, Free Press Journal Marg,
      Nariman Point, Mumbai 400021, Maharashtra, India on the terms and
      conditions as contained herein subject to amendments a per Bank's internal
      policies and the Applicable Law and its successors and assignees.
    </p>

    <p>
      "International Transactions", refers to the transactions performed by the
      Cardholder through his internationally valid Debit Card, outside India,
      Nepal and Bhutan.
    </p>

    <p>
      "Cardholder", "you", "your", "him/ her" or similar pronouns shall where
      the context to admit, refer to a customer of SBM Bank to whom an SBM Bank
      Debit Card has been issued by SBM Bank to operate on Nominated Account(s).
      All references to the Cardholder in the masculine gender will also include
      the feminine or other gender. The Cardholder should be either the Account
      holder or sole signatory or authorized to act alone when there is more
      than one Account holder/signatory.
    </p>

    <p>
      "Charge" means a charge incurred by the Cardholder for purchase of goods
      or services on the Debit Card or any other charge as may be included by
      the Bank from time to time.
    </p>

    <p>
      "Exchange Control Regulations" means the Exchange Control Manual dated May
      30, 2005 by the RBI and includes any statute, law, regulation, ordinance,
      rule, judgement, rule of law, order, decree, clearance, approval,
      directive, guideline, policy, requirement, or other governmental
      restriction or any similar form of decision, or determination by, or any
      interpretation or administration of any of the foregoing by, any
      governmental authority and in each case as amended;
    </p>

    <p>
      "Primary Account", shall mean in case of multiple accounts linked to the
      Debit Card, the account that has been designated as being their main/first
      account of operation, i.e. the account from which purchase Transactions,
      cash withdrawal charges and fees related to the Debit Card are debited.
    </p>

    <p>
      "Nominated Accounts", include the Primary Account as described above and
      it indicates the Cardholder's account(s) nominated in writing by him to be
      accessed by his Debit Card and PIN.
    </p>

    <p>
      "PIN", means the Personal Identification Number (required to access ATMs)
      allocated to the Cardholder by SBM Bank, chosen by him from time to time.
    </p>

    <p>
      "Transaction/s", means instruction given by a Cardholder by using his
      Debit Card directly or indirectly to SBM Bank to effect action on the
      Account. (Examples of Transactions can be retail purchases, cash
      withdrawals, cash/cheque deposits, etc.)
    </p>

    <p>
      "International Transactions", refers to the transactions performed by the
      Cardholder through his internationally valid Debit Card, outside India,
      Nepal and Bhutan.
    </p>

    <p>
      "Statement" or "Statement of Account" means a periodic statement of
      Account/s sent by SBM Bank to the Cardholder setting out the Transactions
      carried out by the Cardholder(s) during the given period and the balance
      on that Account. It may also include any information the Bank may deem fit
      to include.
    </p>

    <p>
      "Merchant" or "Merchant Establishments" shall mean establishments wherever
      located which accept/honor the Debit Card and shall include among others:
      stores, shops, restaurants, airlines organizations, etc. advertised by the
      Bank or Visa.
    </p>

    <p>
      "Contactless" refers to the technology through which a Debit Card with an
      embedded Radio Frequency Identification (RFID) chip and an antenna
      transmits payment details wirelessly to a contactless reader connected to
      a Merchant's point-of-sale (POS) system.
    </p>

    <h2>CARD VALIDITY AND CARDHOLDER OBLIGATIONS</h2>

    <p>
      The issue and use of the Debit Card shall be subject to the Applicable
      Law. The Bank may from time to time, at its discretion, tie-up with
      various agencies to offer various features on the Debit Card.
    </p>

    <p>
      The Debit Card shall be valid only for Transactions as permitted by the
      Bank from time to time in India and abroad at SBM Bank ATMs, ATMs of other
      Banks, which are members of the Visa ATM network and Visa at the POS swipe
      terminals at Merchant Establishments.
    </p>

    <p>
      The Debit Card is and will be at all times the property of SBM Bank and
      shall be returned to SBM Bank unconditionally and immediately upon SBM
      Bank's request. The Cardholder shall ensure that the identity of the Bank
      officer is established before handing over the Debit Card and the Bank
      shall not be liable for any liability or damage that might arise in this
      regard..
    </p>

    <p>
      The Debit Card is non-transferable and/ or non-assignable by the
      Cardholder under any circumstances.
    </p>

    <p>
      You must sign on the Debit Card immediately upon receipt. You must not
      permit any other person to use it and should always safeguard the Debit
      Card from misuse by retaining the Debit Card under your personal control.
    </p>

    <p>
      The PIN issued to the Cardholder for use with the Debit Card or any number
      chosen by the Cardholder as PIN, should be known only to the Cardholder
      and are for the personal use of the Cardholder and be kept strictly
      confidential. A written record of the PIN number should not be kept in any
      form, place or manner that may facilitate its use by a third party. The
      Cardholder shall ensure that:
    </p>

    <p>
      PIN is received by him in a sealed mailer. Customer should not accept any
      tampered mailer. Or Customer will have an option to generate PIN through
      Mobile and Internet Banking, as advise by the Bank.
    </p>

    <p>
      Immediately upon receipt of PIN, change the same to his choice by using
      the Debit Card at an ATM of the Bank for future Transactions. The PIN
      should not be disclosed to any third party, including to any staff of the
      Bank or to Merchant Establishments, under any circumstances. The Bank
      shall exercise reasonable diligence while issuing the PIN and shall not
      disclose the Cardholder's PIN, except to the Cardholder, except upon
      express written instructions by the Cardholder.
    </p>

    <p>
      The Cardholder's Account will be debited immediately with the amount of
      any withdrawal, transfer and other Transactions effected by the use of the
      Debit Card. The Cardholder should maintain sufficient funds in the Account
      to meet any such Transactions.
    </p>

    <p>
      The Cardholder shall not be able to overdraw the Cardholder's Account with
      SBM Bank or withdraw funds by use of the Debit Card in excess of any
      agreed overdue limit.
    </p>

    <p>
      The Cardholder shall be responsible for Transactions effected by use of
      the Card, whether authorized by the Cardholder or not and shall indemnify
      SBM Bank against any loss or damage caused by any unauthorized use of the
      Card or related PIN, including any penal action arising there from on
      account of any violation of RBI guidelines or rules framed under Foreign
      Exchange Management Act, 1999 or any other law being in force in India
      and/or any other country/state/continent/ territory wherever located in
      the world at the time. Debit Card usage is also prohibited for overseas
      forex trading through electronic/internet trading portals.
    </p>

    <p>
      At the time of Account opening, customer's Savings/Current Account nominee
      will be considered as the default nominee for Debit Card application. Bank
      will not accept any separate Debit Card nomination except the one given at
      the time of opening the Account.
    </p>

    <p>
      You are requested to note that the Debit Card is valid up to the last day
      of the month/year indicated on the Debit Card. You hereby undertake to
      destroy the Debit Card when it expires such that it is unfit for any use.
      Your renewed Debit Card shall be sent to you before the expiry of the
      existing Debit Card at the discretion of SBM Bank, upon evaluation of the
      conduct of your Account. The Bank reserves the sole right of renewing your
      Account on expiry and debit any renewable charges to your Account.
    </p>

    <p>
      The Cardholder will inform SBM Bank in writing within 15 days from the
      Statement date of any irregularities or discrepancies that exist in the
      Transaction details at an ATM/Merchant Establishment in the Statement of
      Account sent by SBM Bank. If no such notice is received within this time,
      SBM Bank will assume the correctness of both, the Transactions and the
      Statement of Account.
    </p>

    <h2>MULTIPLE ACCOUNTS/ JOINT ACCOUNTS</h2>

    <p>
      The Cardholder agrees that in case he has multiple accounts with the Bank,
      the Bank shall have the right to decide the number of accounts, which will
      have the Debit Card facility on them.
    </p>

    <p>
      In case of Debit Cards linked to multiple accounts, Transactions at shared
      networks ATMs and Merchant Establishments will be effected on the Primary
      Account. In case there are no funds in this Account, the Bank will not
      honor the Transactions even if there are funds available in the other
      accounts linked to the same Debit Card.
    </p>

    <p>
      Transfer of Primary Account or any change in operational mode thereof will
      not be allowed unless the Debit Card is surrendered and dues, if any,
      against it, are paid.
    </p>

    <p>
      The Bank will debit the Account linked to the Debit Card for the value of
      all purchases of goods or services, cash, fees, charges and payments
      payable by the use of the Debit Card.
    </p>

    <p>
      The Cardholder shall maintain, at all times, in his Primary Account at the
      Bank the minimum balance amount as applicable from time to time during the
      validity period of the Debit Card, and the Bank may, at its discretion
      levy such penal or service charges as per Bank's rules from time to time
      or withdraw the Debit Card facility, if, at any time, the amount of
      deposit falls or has fallen below the minimum amount of deposit as
      aforesaid, without giving any further notice to the Cardholder and/or
      without incurring any liability or responsibility whatsoever, by reason of
      such withdrawal.
    </p>

    <p>
      The Cardholder or such Cardholder who is a joint account holder permitted
      to operate upon the joint account singly authorizes the Bank to debit the
      Accounts with the amount withdrawn and/ or transfers effected by the use
      of the Card, as per Bank's records. The Bank's record for Transaction
      processed by the ATM machine or Point-of-Sale terminal shall be binding on
      all the Account holders jointly and each severally. All fees/ charges
      related to the Card, as determined by the Bank, from time to time, will be
      recovered by debiting any of the Cardholder's Accounts. In case any of the
      joint account holders desires to give stop payment/ transaction
      instructions, in respect of operations of the Debit Card, at least seven
      days clear prior notice, in writing, will have to be given to the Bank, so
      as to enable the Bank to inform all Banks/ ATM centers participating
      centers participating in the network and take steps in its discretion to
      carry out the instructions.
    </p>

    <h2>INTERNATIONAL USAGE</h2>

    <p>
      Use of the Debit Card must be in strict accordance with the Exchange
      Control Regulations. In the event of your failure to comply with the same,
      you will be liable for action under the Foreign Exchange Management Act,
      1999 and may be debarred from holding the Debit Card from SBM Bank, either
      at the instance of SBM Bank or the RBI. You shall indemnify and hold SBM
      Bank harmless from and against any/all consequences arising on account of
      non-compliance with Exchange Control Regulations.
    </p>

    <p>
      The Card is not valid for foreign currency transaction in Nepal and
      Bhutan.
    </p>

    <p>
      SBM Bank shall be under no liability whatsoever and shall be deemed to be
      indemnified in respect of a loss or damage arising directly or indirectly
      out of the decline of a Charge caused by the Cardholder having exceeded
      the foreign exchange entitlements as prescribed by the RBI as issued from
      time to time, on SBM Bank becoming aware of the Cardholder exceeding his
      entitlements.
    </p>

    <p>
      Non-Resident Indians can hold an internationally valid Debit Card,
      provided all dues arising out of its use in India/overseas are met out of
      an NRE/NRO Account held with SBM Bank.
    </p>

    <p>
      Cardholder agrees that the Bank will not offer facility of international
      transactions on Debit Card, if customer fails to furnish his/her valid PAN
      details at the time of account opening or subsequently as may be required
      under the Applicable Laws and as may be amended.
    </p>

    <p>
      International Debit Cards cannot be used on internet or otherwise for
      purchase of prohibited items, like lottery tickets, banned or proscribed
      magazines, participation in sweepstakes, payment for call back services,
      and/or such items/activities for which no drawal of foreign exchange is
      permitted.
    </p>

    <p>
      Any resident in India collecting and effecting/remitting payments
      directly/indirectly outside India in any form towards overseas foreign
      exchange trading through electronic/internet trading portals would make
      himself/herself/themselves liable to be proceeded against with for
      contravention of the Foreign Exchange Management Act (FEMA) 1999, besides
      being liable for violation of regulations relating to Know Your Customer
      (KYC) norms. Also, if any such prohibited transactions are observed on
      your Debit Card (for online Banking Account), the Debit Card (or Account)
      shall be closed with immediate effect &amp; the same will be reported to
      regulatory authorities.
    </p>

    <p>
      In case of international cash withdrawal transactions, the applicable
      exchange rates and fees will be levied.
    </p>

    <p>
      At a few Merchant Establishments oversees (e.g. United States of America),
      you may have to ask the cashier to swipe your Debit Card as a credit card.
      This is because in some countries "Debit" is connected only to the local
      network and the transaction may be declined, as it will not reach the Visa
      network.
    </p>

    <p>
      Transactions in foreign currency will be converted to Indian rupees on the
      date of conversion based on the rates decided by the Bank/ Visa and shall
      be at their sole discretion only . The rate used for conversion may be
      different from the rate in effect on the date of the transaction due to
      market fluctuation. All Transactions in foreign currency are subject to a
      charge imposed by the Visa association, either as a reimbursement charge
      representing the charge imposed on us or as a direct charge to you. An
      administrative fee as applicable at the time of charging of the foreign
      currency transaction amount for services provided or actions taken by us
      in relation to such foreign currency transactions will be payable by you
      and debited to your Account.
    </p>

    <p>
      Under the Liberalized Remittance Scheme, all resident individuals,
      including minors, are allowed to freely remit up to USD 2,50,000 per
      financial year (April – March) for any permissible current or capital
      account transaction or a combination of both.&nbsp;
    </p>

    <h2>MERCHANT ESTABLISHMENT USAGE</h2>

    <p>
      The Debit Card is accepted at all electronic Point-of-Sale swipe terminal
      at Merchant Establishments in India and overseas which display Visa logo
      and/or any other shared networks and/or agencies as may be recognised by
      SBM Bank from time to time and which have the facility of offering goods
      or services for purchase through internet websites in India. Any usage of
      the Debit Card other than electronic use will be deemed unauthorized and
      the Cardholder will be solely responsible for such Transactions.
    </p>

    <p>
      It shall be the responsibility of the Cardholder to enter the correct PIN
      for all/ any Transactions on the Debit Card failing which, SBM Bank shall
      decline the Transaction if the correct PIN is not entered in three
      attempts and shall proceed to block the Debit Card.
    </p>

    <p>
      You must sign the charge slip, whenever required, and retain your copy of
      the same when you are using the Card at a Merchant Establishment. SBM Bank
      will not furnish copy of charge slips. Any charge wherein charge slip is
      not personally signed by you, but which can be proven, as being authorized
      by you, will be deemed to be your liability.
    </p>

    <p>
      SBM Bank will not accept any responsibility for any dealing the Merchant
      may have with you, including but not limited to the supply of goods and
      services so availed or offered. Should you have any complaint relating to
      any Visa Merchant Establishment, you should resolve the matter with the
      Merchant Establishment and failure to do so will not relieve you from any
      obligations to SBM Bank. If you decide to cancel your purchase and not
      accept the goods after the card is swiped, ensure that the Merchant
      cancels the Transaction immediately and the slip is handed over to you.
      Any cancellation thereafter will be routed as a "charge back" on the
      Merchant through the acquiring bank and will take the time required to
      complete the process. The Bank does not guarantee full/partial return of
      such amount.
    </p>

    <p>
      SBM Bank accepts no responsibility for any charge levied by any Merchant
      Establishment over and above the value/cost of Transactions and debited
      your account along with the Transaction amount.
    </p>

    <p>
      Refunds, if any, will only be credited to your Account (less cancellation
      charges) after it is received from the Merchant by the Bank. If the credit
      is not posted to your Account within 30 days from the day of refund, you
      must notify SBM Bank, along with a copy of the credit note from the
      Merchant.
    </p>

    <p>
      The Debit Card should not be used at hotels during check-in and also at
      other locations where payment is made before completion of the purchase
      Transaction or service. Please note that in case such a Transaction is
      effected, your funds will be temporarily blocked.
    </p>

    <p>
      The Debit Card should not be used over any mail order/phone purchases and
      any such usage will be considered as unauthorized.
    </p>

    <h2>ATM USAGE</h2>

    <p>
      The Debit Card is accepted at SBM Bank ATMs and Visa enabled ATMs
      worldwide.
    </p>

    <p>
      Cash withdrawals and balance inquiry performed by the Cardholder at Visa
      enabled ATMs in countries other than India will be subjected to a fee, as
      per prevailing tariff of charges. Cash withdrawals and balance inquiry at
      Visa ATMs (non- SBM Bank) in India may also be subjected to a fee (as
      decided by SBM Bank from time to time) and will be debited to the Account
      at the time of such cash withdrawal and balance inquiry Transactions. All
      Transactions at non-SBM Bank ATMs are subjected to charges as determined
      by SBM Bank from time to time. The charge schedule shall be updated on
      Bank's website.
    </p>

    <p>
      For all cash withdrawals, cheque/cash deposits at an SBM Bank ATM, any
      statement/receipt issued by the ATM at the time of deposit or withdrawal
      shall be deemed conclusive, unless verified and intimated otherwise by SBM
      Bank. Any such verification shall likewise be final and conclusive, and
      this verified amount be binding on the Cardholder.
    </p>

    <p>
      SBM Bank will not be liable for any failure to provide any service or to
      perform any obligation thereunder where such failure is attributable
      (whether directly or indirectly) to any malfunction of the ATM or the
      Debit Card, temporary insufficiency of funds, any dispute or other
      circumstances beyond its control.
    </p>

    <p>
      SBM Bank will not be liable for any consequential or indirect loss or
      damage arising from or related to the loss/misuse of the Debit Card and
      related PIN, how so ever caused.
    </p>

    <p>
      The availability of ATM services in a country other than that in which the
      card was issued is governed by the local regulations in force in the said
      country. SBM Bank shall not be liable if these services are withdrawn
      without notice thereof.
    </p>

    <p>
      The type of transaction offered on shared network ATMs may differ from
      those offered on the Bank's own network. The Bank will only support cash
      withdrawal and balance enquiry Transactions at the ATMs belonging to
      shared network. The Bank reserves the right to change the types of
      Transactions supported without any notice to the Cardholder.
    </p>

    <p>Fast cash options in the ATM can be used for Primary Account only.</p>

    <p>
      All transactions, in particular deposit of cash and cheques, shall be
      subject to delay, due to transit period. The Bank shall not be responsible
      for any loss or damage or inconvenience caused to the Cardholder due to
      such delays.
    </p>

    <p>
      The Cardholder should retain the record of Transactions generated by the
      ATM with him/ her. The Cardholder agrees that he/ she will be allowed to
      withdraw cash up to a maximum of Rs. 2,00,000/- (Rupees Two Lakh only) and
      make purchases on POS devices/ terminals up to a maximum of Rs. 3,00,000/-
      (Rupees Three Lakh only) and make online/Ecom website purchases of upto a
      maximum of Rs. 2,00,000/- (Rupees Two lakh only), and a total Rs.
      7,00,000/- (Rupees Seven lakh only) across all channels per day subject to
      availability of clear balance in the Account(s) or any such maximum
      withdrawal/ purchase limits decided by the Bank from time to time and in
      accordance with the Applicable Law. Any attempt to violate these limits
      would lead to withdrawal of the Debit Card facility. The Cardholder agrees
      not to attempt to withdraw/ purchase using the Debit Card unless
      sufficient funds are available in the Account. The Bank has the right to
      change these limits without any notice. Further, the Cardholder/ customer
      will have an option to choose these transaction limits, as per his/her
      risk appetite/usage, upto the maximum limit prescribed by the Bank.
    </p>

    <p>
      There shall not be any signed receipts for deposits. Transactions of
      deposits shall be subject to verification by the Bank's officials or its
      designated persons, when the deposit envelopes are opened, and cash/
      cheque (s) is/ are verified. In case of any discrepancy whatsoever, the
      Bank's decision shall be final and binding upon the Cardholder. Deposit
      involving mutilated or soiled notes will be subject to final acceptance/
      reimbursement by RBI, as per their Note Refund Rules as may be applicable
      from time to time. Cheques will be accepted for collection only and the
      proceeds will not be available until they have been cleared. Any deposits
      of foreign currency or otherwise considered unacceptable will be returned
      at the Cardholder's cost, risk and responsibility. Collection and credit
      of proceeds insufficient funds after ATM/or POS terminal debit, the cheque
      would be returned by the Bank.
    </p>

    <h2>ONLINE / INTERNET USAGE</h2>

    <p>
      You agree and accept the Debit Card services that are being provided by
      the Bank at your request to carry out online transactions through Debit
      Card instructions on your savings account, as given by you.
    </p>

    <p>
      You have no objection whatsoever, that your billing details are provided
      to us by Merchants to whom you make payment using these Debit Card
      services.
    </p>

    <p>
      You agree that the record of the instructions given and Transactions
      undertaken by you on the Debit Card with us will be conclusive proof and
      binding for all purposes and can be used as conclusive evidence in any
      proceeding.
    </p>

    <p>
      You understand and agree that the shared networks may provide different
      functionalities and service offerings that may require you to provide
      certain additional details to complete the online Transaction.
    </p>

    <p>
      You agree that unless (i) you enter the complete and correct
      data/information in the verification field/s in the internet website or
      (ii) you properly scan the QR code of your Merchant, you will not be able
      to make payment using your SBM Debit Card.
    </p>

    <p>
      In case you wish to cancel a completed Transaction due to an error or on
      account of agreed merchandise return, the earlier transaction must be
      cancelled with the Merchant and a confirmation of the cancelled receipt
      must be retained in your possession.
    </p>

    <p>
      Reversal/refund of debits due to such transactions will be processed
      manually and the confirmation of the cancelled receipt needs to be
      produced by you, if called for by the Bank.
    </p>

    <p>
      You agree that all refunds due to non-delivery of goods or
      services/rejection of the goods or services will be done at the request of
      the relevant Merchant only. In case of refunds, we will not refund payment
      processing charges levied, if any, on you for processing of the
      Transaction.
    </p>

    <p>
      You agree that without prejudice to anything contained anywhere in these
      Terms &amp; Conditions, Bank will be under no liability, whatsoever, to
      you , in respect of any loss or damage arising, directly or indirectly,
      out of (i) any defect in any goods or services supplied; (ii) the refusal
      of any Merchant to honour or accept the payment through SBM Debit Card;
      (iii) the malfunction of any computer terminal, any delay/technical
      failures; (iv) sharing of access details of your account by you or any
      third party to any unauthorised person; (v) Any misstatement,
      misrepresentation, error or omission in any details disclosed to us by
      you; or (vi) Any discrepancy between the amount required to be paid by the
      respective Merchant(s) and the instructions given by you; or (vii)
      inability of your mobile device to scan the QR code; or (viii) failure of
      the Merchant to raise cancellation/refund request.
    </p>

    <p>
      You agree that this Debit Card facility is purely a facility for you to
      purchase goods and/or make use of services, and we hold out no warranty or
      make no representation about the quality, delivery or otherwise of the
      merchandise.
    </p>

    <p>
      You agree that for reasons that the internet website is not capable of
      accepting all the details required by us and/or the shared networks to
      complete the online Transaction, the said Transaction will not be treated
      as complete and in which case we will not be held liable for any
      incomplete/unprocessed/rejected Transactions.
    </p>

    <p>
      The existence of any claim or dispute will not relieve you of your
      obligation to pay all the charges and you agree to pay promptly such
      charges, notwithstanding any dispute or claim, whatsoever.
    </p>

    <p>
      In case of any dispute, we reserve the right to suspend any
      Merchant/master Merchant either temporarily or permanently. During this
      period, the Debit Card facility will not work for all such suspended
      Merchants.
    </p>

    <h2>CONTACTLESS MODE</h2>

    <p>
      Contact mode (‘CM') refers to dipping the Debit Card in the EMV compliant
      Merchant POS terminal and authenticating the Transaction using an EMV chip
      and PIN or by swiping the Debit Card and authenticating the Transaction
      using a magnetic stripe for the purpose of making a payment/ Transaction.
      Contactless mode (‘CLM') refers to waving or tapping the EMV enabled Debit
      Card on a contactless enabled EMV compliant POS terminal for the purpose
      of making a payment/ Transaction. Contactless card refers to a dual
      interface EMV compliant Debit Card capable of processing transactions
      through both CM and CLM.
    </p>

    <p>
      CLM is an additional feature included in the SBM Debit Card and does not
      in any manner affects the existing functionality of the Debit Card which
      includes but is not limited to Transactions, reward points, cash
      withdrawal, online payments, fees and service charges etc.
    </p>

    <p>
      As per the RBI directive, under CLM feature, a Cardholder is entitled to
      make a purchase not exceeding INR 2,000/- at a merchant POS using a CLM of
      payment, depending on the available balance in the linked Account.
    </p>

    <p>
      For Transactions carried out using CLM, customers are not mandatorily
      required to enter the PIN during the Transaction.
    </p>

    <p>
      CLM of payment may work only at select Merchant locations which have a
      contactless enabled POS or a reader capable processing a CLM Transaction.
      SBM Bank reserves the right to appoint or terminate retail outlets and
      Merchants to the chain of outlets/Merchants offering this CLM/CM feature,
      at its sole discretion and decision of SBM Bank shall be final and
      binding.
    </p>

    <p>
      To make the payment using a CLM, Cardholder is required to bring the Debit
      Card in close proximity, which is approximately 4 cm or less, of the
      contactless enabled POS or a reader and tap or wave the Debit Card.
    </p>

    <p>
      CLM of payment is only applicable for purchases at a Merchant POS and not
      for ATM withdrawals or online purchases.
    </p>

    <p>
      Cardholder may continue to use contact mode for Transactions at a Merchant
      POS for any value depending on the available balance in the linked Account
      and in line with these Terms &amp; Conditions.
    </p>

    <p>
      Cardholder agrees, accepts and acknowledges that use of this CLM/CM
      feature shall be voluntary by the Cardholder and CLM is not mandated by
      SBM Bank. However, SBM Bank shall not be liable for any conditions that
      the Merchant or the acquiring bank may impose on the acceptance /
      non-acceptance of CLM /CM payment.
    </p>

    <p>
      No charge will be levied by SBM Bank solely for using CLM of payment.
      However, the Cardholder accepts, agrees and acknowledges that the Debit
      Card shall continue to attract annual fees, transaction fees, service
      charges and any other charges as applicable on the Debit Card from time to
      time.
    </p>

    <p>
      SBM Bank reserves its right to decline issue of the Debit Card (CLM
      enabled) to the Cardholder without any reason and SBM Bank shall not be
      held liable or responsible for such decision.
    </p>

    <p>
      SBM Bank reserves the right to, without liability or prejudice to any of
      its other rights, at any time, without previous notice and from time to
      time, withdraw/suspend/amend/cancel or disable CLM feature, without
      assigning any reasons thereof. These Terms &amp; Conditions shall be in
      addition to &amp; not in substitution/derogation to the rules &amp;
      regulations governing the use of the SBM Bank debit Card and/or the SBM
      bank website ("Rules &amp; Regulations").
    </p>

    <p>
      Cardholder shall not hold SBM Bank, its group entities or affiliates,
      their respective directors, officers, employees, agents, vendors,
      responsible for or liable for any actions, claims, demands, losses,
      damages, costs, charges and expenses which the Cardholder claims to have
      suffered, sustained or incurred or claims to suffer, sustain or incur, by
      way of and/or on account of this Debit Card facility including but not
      limited to any delays, loss of business, profit, revenue or goodwill,
      anticipated savings, damages, fees costs, expense, etc. or for any
      indirect or consequential, special or exemplary damages or loss arising in
      connection with CLM/CM feature or due to unavailability/usage of CLM/CM
      feature or in performance of its obligations or otherwise.
    </p>

    <p>
      The Cardholder agrees, accepts and undertakes not to use this CLM/CM
      feature for any purpose that might be construed as contrary or repugnant
      to any applicable law, regulation, guidelines, SBM Bank policy or for any
      purpose that might negatively prejudice the goodwill of SBM bank.
    </p>

    <p>
      SBM Bank makes no express or implied warranty, guarantee, representation,
      or undertaking whatsoever, regarding this CLM/CM feature, which are not
      expressly mentioned herein.
    </p>

    <h2>FEES</h2>

    <p>
      Transaction fees for cash withdrawals/balance inquiry, will be debited to
      the Account at the time of posting the cash withdrawal/balance inquiry or
      whenever applicable.
    </p>

    <p>
      All transactions in foreign currency will be billed in the Cardholder's
      bank account Statement in Indian Rupees. You hereby authorize SBM Bank and
      Visa to convert the charges incurred in foreign currency into the Indian
      Rupee.
    </p>

    <h2>FEATURES OF THE DEBIT CARD</h2>

    <p>
      The Bank does not guarantee or warrant the efficacy, efficiency,
      usefulness of any of the products or services offered by any service
      providers/ merchants/ outlets/ agencies. Disputes (if any) would have to
      be taken up with the concerned service providers/merchants/outlets/third
      party agencies, etc. directly, without involving the Bank.
    </p>

    <h2>UNAUTHORISED ELECTRONIC BANKING TRANSACTIONS</h2>

    <p>
      The liability of the Bank or Cardholder/ customer for any unauthorized
      transactions shall be:
    </p>

    <p>
      Zero liability of Cardholder – Cardholder's entitlements to zero liability
      shall arise where the unauthorized transaction occurs in the following
      manner.
    </p>

    <p>
      Contributory fraud / negligence / deficiency on the part of the Bank
      (irrespective of whether or not the Transaction is reported by the
      Cardholder).
    </p>

    <p>
      Third party breach where the deficiency lies neither with the Bank nor
      with the Cardholder but lies elsewhere in the system, and the Cardholder
      notifies the Bank within 3 working days of receiving the communication
      from the Bank regarding the unauthorized Transaction.
    </p>

    <p>
      Limited Liability of a Cardholder – Cardholder shall be liable for the
      loss occurring due to unauthorized Transactions in the following cases:
    </p>

    <p>
      In cases where the loss is due to negligence by a Cardholder, such as
      where he has shared the payment credentials, the Cardholder will bear the
      entire loss until he reports the unauthorized transaction to the Bank. Any
      loss occurring after the reporting of the unauthorized Transaction shall
      be borne by the Bank.
    </p>

    <p>
      In cases where the responsibility for the unauthorized electronic banking
      Transaction lies neither with the Bank nor with the Cardholder, but lies
      elsewhere in the system and when there is a delay (of four to seven
      working days after receiving the communication from the Bank) on the part
      of the Cardholder in notifying the Bank of such a Transaction, the per
      Transaction liability of the Cardholder shall be limited to the
      Transaction value or Rs.10,000/- (Rupees Ten thousand only) whichever is
      lower.
    </p>

    <p>
      The Bank will not offer facility of electronic Transactions, other than
      ATM cash withdrawals to Cardholders who do not provide valid mobile
      numbers. In case the Cardholder notifies the Bank after seven working days
      of receiving the communication from the Bank regarding the unauthorized
      Transaction, the Cardholder liability shall be determined as per the
      Bank's Board approved policy. Bank shall evaluate and determine the same
      as per Bank's Board approved policy. For escalation details, visit SBM
      Bank website (www.SBMBank.co.in)
    </p>

    <h2>DISCLOSURE OF INFORMATION</h2>

    <p>
      When requested by SBM Bank, you shall provide any information, records or
      certificates relating to any matter that SBM Bank deems necessary. You
      will also authorize SBM Bank to verify the veracity of the information
      furnished by whatever means or from whichever source deemed necessary. If
      the data is not provided or if the same is found incorrect, SBM Bank may,
      at its discretion, refuse renewal of the Debit Card or cancel the Debit
      Card forthwith.
    </p>

    <p>
      SBM Bank reserves the right to disclose Cardholder information in any
      court of competent jurisdiction, quasi-judicial authorities, law
      enforcement agencies and any other wing of the central government or the
      state government of India.
    </p>

    <p>
      SBM Bank reserves the right to disclose, in strict confidence, to other
      institution, such information concerning the Cardholder's Account as may
      be necessary or appropriate in connection to its participation in any
      Electronic Funds Transfer Network.
    </p>

    <p>
      SBM Bank reserves the right to report to RBI, expenditure undertaken by
      its Cardholder, any foreign currency withdrawals/ payments effected using
      Debit Card in foreign currencies, to ensure that the other permissible
      entitlements are not exceeded by the Cardholder(s) and that the Foreign
      Exchange Management Act, 1999 is not contravened.
    </p>

    <p>
      SBM Bank may assign any activities to any third party at its sole
      discretion and provide details of the Account to such third party
      agencies, for the purpose of back office processing and other activities
      outsourced as per RBI guidelines. In this connection, the Cardholder(s)
      understands that the Bank needs to and authorizes the Bank to process,
      share, store or transmit information about the Cardholder, the Account
      and/ or the Transaction(s), within the Bank or with any institution or
      agent or third party used by the Bank. The Bank undertakes that any such
      processing, sharing, storage or transmission of information will be done
      on a confidential basis and the Bank will endeavor to maintain the strict
      confidentiality of such information within the Bank unless (a) otherwise
      required or permitted by any Applicable Law, regulation or request of any
      public or regulatory authority; or (b) disclosure is required for the
      purpose of preventing fraud; or (c) the Bank deems disclosure necessary to
      provide Debit Card facility.
    </p>

    <p>
      The Cardholder(s) and the Bank shall comply with all applicable data
      protection laws. The Account holder(s) confirm(s) that all persons whose
      personal or other data is transmitted, processed or otherwise handled,
      have consented to such transmission, processing or other handling under
      these Terms &amp; Conditions, in accordance with these laws, or will do so
      prior to any such transmission, processing or other handling. The
      Cardholder further agrees to indemnify without any limitation and hold us
      harmless safe from all costs, penalties, damages and other losses incurred
      as the result of any breach of this provision.
    </p>

    <p>
      From time to time, SBM Bank communicates various features/ products/
      promotional offers which provide significant benefits to its Cardholders
      and may use the services of third party agencies to do so. The Cardholder
      may avail of the 'Do Not Contact' service to opt out of such
      communications.
    </p>

    <p>
      The Cardholder shall accept the Bank's record of the Transactions as
      conclusive and binding for all purposes. The printed output that is
      produced at the time of operation of the ATMs is the record of the
      Cardholder's operations on the ATM and shall not be construed as the
      Bank's record for this purpose. Transactions conducted after the normal
      business hours, of the Bank/ branches would be reflected in the Bank's
      books of accounts on the next business day. For the purpose of levy of
      charges/ interest etc., the actual date of Transactions will be reckoned.
    </p>

    <p>
      To ensure the Cardholder's interest, the Bank may install CCTV cameras in
      its premises and record, at its own discretion, the access to and the
      presence of any person while availing the use of the Debit Card facilities
      at the Bank's premises. All records maintained by the Bank, in electronic
      or documentary form, of the instructions of the Cardholder and such other
      details, and all camera/ video recording made as mentioned above, shall,
      as against the Cardholder, be deemed to be conclusive evidence of such
      instructions and such other details.
    </p>

    <h2>LOST OR STOLEN CARD</h2>

    <p>
      In the event the Debit Card is lost or stolen, the occurrence must be
      reported to any office of SBM Bank in India on the emergency contact
      number 18001033817 or to the Visa Global Service Centre Helpline whilst
      abroad. You shall be required to disclose information regarding your Debit
      Card number, your Account number, address, date of birth and your mother's
      maiden name. Under no circumstances should you disclose the PIN number.
    </p>

    <p>
      The loss or theft of the Debit Card should be reported to SBM Bank
      immediately. Although loss or theft may be reported by means of the 24
      hours emergency Help-line number, you must confirm the same in writing to
      SBM Bank as soon as possible. A copy of the acknowledgement police
      complaint filed in this regard must accompany the said written
      confirmation.
    </p>

    <p>
      Debit Card lost while in use or otherwise will be replaced at a cost
      decided by the Bank from time to time. A replacement Debit Card may,
      however, be issued at the Bank's discretion against (i) a fresh
      application and (ii) a suitable indemnity in case of a lost Debit Card.
      Upon issuance of a replacement Debit Card, the Cardholder shall continue
      to be subject to these Terms &amp; Conditions.
    </p>

    <p>
      Should Transaction be received by SBM Bank after the Debit Card has been
      lost or stolen but before receipt of your written confirmation, you shall
      be liable for all amounts debited to your Account(s). However, if prior to
      the receipt of your written confirmation, if it can be proved that the
      Debit Card was safeguarded, the loss/theft promptly reported and that you
      acted in good faith and with reasonable care and diligence, your lost card
      liability ("Lost Card Liability") for your purchase Transaction will be
      insured up to the specified limit (once the loss is reported in writing).
      There will be no such coverage provided on cash withdrawals done through
      ATMs, as such transactions require the use of a PIN, which is confidential
      to you.
    </p>

    <p>
      You hereby indemnify SBM Bank against any liability (civil or criminal),
      loss, cost, expenses, or damages that may arise due to loss or misuse of
      the Debit Card in the event that it is lost and not reported to SBM Bank
      or lost and misused before the Bank is informed.
    </p>

    <h2>SURRENDER / REPLACEMENT OF CARD</h2>

    <p>
      Debit Card broken/ damaged/ lost while in use or otherwise will be
      replaced at a cost decided by the Bank from time to time. A replacement
      Debit Card may, however, be issued at the Bank's discretion against (i) a
      fresh application and (ii) a suitable indemnity in case of a surrender of
      the Debit Card if it is broken/ damaged as the case may be. Upon issuance
      of a replacement Debit Card, the Cardholder shall continue to be subject
      to these Terms &amp; Conditions. The Debit Card issued to the Cardholder
      shall remain the property of the Bank and will be surrendered to the Bank,
      on request or in the event the Debit Card is no longer required by the
      Cardholder. The Cardholder shall return the Debit Card to the Bank for
      cancellation in the event the services are no longer required by the
      Cardholder or if the services are withdrawn by the Bank for any reason
      whatsoever. The Bank may, in its absolute discretion issue a replacement
      Debit Card with a new PIN or issue a renewal Card with a new or the same
      PIN on the same terms and conditions as contained herein or such other
      terms and conditions as the Bank may deem fit. Subject to the foregoing
      provisions, the Cardholder will not hold the Bank liable in case of
      improper/ fraudulent/ unauthorized/ erroneous use of the Debit Card in the
      event of the Debit Card falling in the hands of or the PIN coming to the
      knowledge of any third party. In the event the Cardholder recovers the
      lost/ stolen Debit Card, the same shall be destroyed/ returned to the Bank
      by him without any delay.
    </p>

    <h2>INSURANCE</h2>

    <p>
      You acknowledge that the insurance benefit provided on the Debit Card will
      be available to you as per the terms of the relevant insurance policy
      ("Insurance Policy") in force and only so long as you are and remain as
      active Cardholder of SBM Bank with your Account conduct being deemed
      satisfactory and in event of the Debit Card and/or Account facility being
      terminated for whatever reason, the benefit of such insurance cover shall
      automatically and ipso facto cease to be available from the date of
      cessation of Account with the Bank.
    </p>

    <p>
      SBM Bank reserves the right to discontinue the insurance facility so
      offered at any time. In such an event, the Cardholders shall be informed
      about the cut-off date. Any decision of SBM Bank in this case shall be
      final.
    </p>

    <p>
      The zero Lost Card Liability/ purchase protection coverage is provided to
      Cardholders by insurance companies as may be identified by SBM Bank from
      time to time.
    </p>

    <p>
      SBM Bank does not hold any warranty and/or make representation about
      quality/ delivery of cover, claims processing or settlement of the claim
      by the insurance companies as may be identified by SBM Bank from time to
      time in any manner whatsoever.
    </p>

    <p>
      You may be asked to submit such documents as may be deemed necessary
      documents in respect of an insurance claim, as per the specific
      requirements.
    </p>

    <p>
      SBM Bank shall not be liable for any delayed settlement of the claims.
    </p>

    <p>
      As per the terms &amp; conditions of the Insurance Policy, raising an
      insurance claim, intimation and submission of required documents should be
      completed within 30 calendar days from the date of the reporting.
    </p>

    <p>
      The Cardholder shall bear the loss sustained up to the time of
      notification to the Bank of any loss, theft, or copying of the Debit Card
      but only up to a certain limit as per the Lost Card Liability limit
      available on the card from time to time, except where the Cardholder acted
      fraudulently, or negligently.
    </p>

    <p>
      Further queries or assistance regarding the policy, exclusions and claim
      settlements can be directed to the office of the respective insurance
      company.
    </p>

    <h2>STATEMENT OF RECORDS</h2>

    <p>
      The records of Debit Card Transactions will be available on the Statement
      sent by SBM Bank. Such Statements shall be mailed to the Cardholder on a
      periodic basis to the mailing address corresponding to the Primary Account
      on record as indicated by the Cardholder. The Cardholder may also view his
      Statement online by using internet banking facility offered by SBM Bank.
    </p>

    <p>
      SBM Bank's record of Transactions processed using the Debit Card shall be
      conclusive and binding for all purposes.
    </p>

    <h2>DISPUTES</h2>

    <p>
      In case of purchase transactions, a sales slip with the signature of the
      Cardholder together with the Card number noted thereon shall be conclusive
      evidence between SBM Bank and the Cardholder as to the extent of the
      liability incurred by the Cardholder and SBM Bank shall not be required to
      ensure that the Cardholder has received the goods purchased/ availed of
      the services available to the Cardholder's satisfaction.
    </p>

    <p>
      SBM Bank shall make bonafide and reasonable efforts to resolve an
      aggrieved Cardholder's disagreement with an applicable charge indicated in
      the Statement or as otherwise determined by the Cardholder within two (2)
      months of the receipt of the notice of disagreement from the Cardholder.
      If after such efforts SBM Bank determines that the charges are incorrect,
      it shall communicate the same to the Cardholder.
    </p>

    <p>
      SBM Bank accepts no responsibility for the refusal of any Merchant
      Establishment to honor the Debit Card.
    </p>

    <p>
      These Terms &amp; Conditions will be construed in accordance with and
      governed by the laws of India. All disputes of the Cardholder against SBM
      Bank are subject to the exclusive jurisdiction of the courts of Mumbai,
      India, irrespective of whether any other court may have concurrent
      jurisdiction of the matter. All disputes of SBM Bank against the
      Cardholder are subject to the non-exclusive jurisdiction of the courts of
      Mumbai, India. SBM Bank is entitled to enforce its rights against the
      Cardholder in any court, within or outside India, in whose jurisdiction
      the cause of action arises.
    </p>

    <p>
      The Cardholder will be liable for all the costs associated with the
      collection of dues, legal expenses (should it become necessary to refer
      the matter to any agent) or where legal resources have been utilized in
      the resolution of a dispute as may have occurred in relation to anhy
      violation by the Cardholder of these Terms &amp; Conditions.
    </p>

    <p>
      Bank shall be responsible for direct losses incurred by a Cardholder due
      to a system malfunction directly within the Bank's control. However, the
      Bank shall not be held liable for any loss caused by a technical breakdown
      of the payment system which is beyond its reasonable control or in case
      the breakdown of the system was recognizable to the Cardholder by a
      message on the display of the device or otherwise known. The
      responsibility of the Bank for the non-execution or defective execution of
      the Transaction is limited to the principal sum and the loss of the
      interest subject to the provisions of the law governing the terms.
    </p>

    <h2>EXCLUSION FROM LIABILITY</h2>

    <p>
      In consideration of the Bank providing the Cardholder with the facility of
      the Debit Card, the Cardholder hereby agrees to indemnify and keep the
      Bank indemnified from and against all actions, claims, demands,
      proceedings, losses, damages, personal inquiry costs charges and expenses
      which the Bank may at any time incur, sustain, suffer or be put to as a
      consequence of or by reason of or arising out of providing the Cardholder
      the said facility of the Debit Card or by reason of the Bank's acting in
      good faith and taking or refusing to take or omitting to take action on
      the Cardholder's behalf, instructions and in particular arising directly
      or indirectly out of the negligence, mistake or misconduct of the
      Cardholder, breach or non-compliance of the rules, terms and conditions
      relating to the Debit Card and the Account and/ or fraud or dishonesty
      relating to any transaction by the Cardholder or any other person
      authorised by the Cardholder, where such authority shall be determined by
      the Bank in its sole discretion.
    </p>

    <p>
      Without prejudice to the foregoing, the Bank shall be under no liability
      whatsoever to the Cardholder in respect of the handing over of the Debit
      Card by Cardholder to anybody other than the designated employees of the
      Bank at the Bank's premises, the exercise by the Bank of its right to
      demand and procure the surrender of the Card prior to the expiry date
      printed on its face, whether such demand and surrender is made and/ or
      procured by the Bank or any person or computer terminal, ATM or POS
      terminals, the exercise by the Bank of its right to terminate any Debit
      Card, any injury to the credit, character and reputation of the Cardholder
      alleged to have been caused by the repossession of the Debit Card or any
      misstatement, misrepresentation, error or omission in and detail disclosed
      by the Bank.
    </p>

    <p>
      Except as otherwise required by law, if the Bank receives any process
      summons, order, injunction, execution decree, lien, information or notice
      which the Bank in good faith believes to call into question the
      Cardholder's ability, or the ability of someone purporting to be
      authorized by the Cardholder, to transact on the Debit Card, the Bank
      shall decline to allow the Cardholder to obtain any portion of his funds,
      or may pay such funds over to an appropriate authority and take any other
      steps required by Applicable Law.
    </p>

    <p>
      The Bank reserves the right to recover from the Cardholder's Account a
      reasonable service charge and any expenses it incurs, including but not
      limited to reasonable legal fees, due to legal action involving the
      Cardholder's Card.
    </p>

    <p>
      In case the Account gets overdrawn due to any reason, the Cardholder
      should provide enough funds to bring it in credit. For every occasion when
      the Account is overdrawn, a flat fee is levied, in addition to the
      interest. The flat fee and interest shall be recovered at the rate
      determined by the Bank from time to time.
    </p>

    <p>
      In the event of an Account being over drawn due to Debit Card Transaction,
      the Bank reserves the right to set off the amount against any credit lying
      in any of the Cardholder's other Accounts held singly or jointly without
      giving any notice wherever applicable.
    </p>

    <p>
      Nothing in these Terms &amp; Conditions shall affect the Bank's right of
      setoff, transfer and appropriation of monies pursuant to any other
      agreement from time to time subsisting between the Bank and Cardholder.
    </p>

    <h2>GENERAL</h2>

    <p>
      These Terms &amp; Conditions and as amended by the Bank from time to time
      shall govern the use of the Debit Card. The Cardholder shall be deemed to
      have unconditionally agreed to and accepted these terms and conditions by
      signing the Debit Card application form, or acknowledging receipt of the
      Debit Card inviting, signing on the reverse of the card, or by performing
      and accepted these Terms &amp; Conditions by signing the application form,
      or acknowledging receipt of the Debit Card inviting, may signing on the
      reverse of the Debit Card, or by performing a Transaction with the Debit
      Card or by requesting activation of the Debit Card to the Bank or after 10
      days having elapsed since the Debit Card was dispatched to his address on
      record.
    </p>

    <p>
      These terms and conditions will be in addition to and not in derogation of
      the terms and conditions relating to any Account of the Cardholder. The
      Bank may also make changes in the terms and conditions without notice, if
      it is considered that the changes are necessary to maintain or restore the
      security of the electronic system or equipment used for the card
      transactions and/ or for any other reason whatsoever and the same shall be
      binding on the Cardholder. Such terms and condition will be updated and
      available on <a href="http://www.sbmbank.co.in">www.sbmbank.co.in</a>
    </p>

    <p>
      You will promptly notify SBM Bank in writing of any change in your
      employment and/or office and/or residential address and telephone numbers.
    </p>

    <p>
      SBM Bank reserves the right to add, to delete or vary any of these Terms
      &amp; Conditions, policies, features and benefits upon notice to the
      Cardholder. Use of the Debit Card after the date upon which any of these
      alterations are to take effect will be taken to the evidence of
      acceptance, without reservations by the Cardholder of such changes.
    </p>

    <p>
      Any notice hereunder sent by post will be deemed to have been received by
      the Cardholder within 7 days from the posting of the notification to the
      address last given to SBM Bank in writing by the Cardholder. Publication
      of changes by such means as SBM Bank may consider appropriate will
      constitute effective notice to the Cardholder there of.
    </p>

    <p>
      If an Account holder, by using the Card, draws an amount in excess of the
      balance available or overdraft limit permitted by SBM Bank, the Account
      holder will pay SBM Bank unconditionally the entire amount overdrawn with
      interest and penalties, if any, at a rate decided by SBM Bank. However,
      this should not be construed as an agreement, either expressed or implied
      that SBM Bank is bound to grant any overdraft facility whatsoever.
    </p>

    <p>
      In all matters relating to the Debit Card, the decision of the Bank shall
      be final and binding in all respects.
    </p>

    <p>
      Any person taking advantage of the Debit Card in good standing shall be
      deemed to have read, understood and accepted these Terms &amp; Conditions.
    </p>

    <h2>FORCE MAJEURE</h2>

    <p>
      The Bank will not be responsible, nor shall it be liable to indemnify the
      Cardholder in the event of any loss or damage suffered incurred by the
      Cardholder due to any cause or reason including but not limited an act of
      God, local government or government, war, fire, flood, earthquake or
      storm, acts of terrorism, explosion, civil commotion or internet downtime
      or other similar or other causes beyond the Bank's control and in which
      circumstance no other branch, subsidiary or affiliate of the Bank shall be
      responsible therefore.
    </p>

    <h2>TERMINATION</h2>

    <p>
      SBM Bank reserves the right to cancel/ withdraw the Debit Card or any of
      the other services offered at any time without prior notice and without
      assigning any reason.
    </p>

    <p>
      In the event that the Cardholder decides to close his Account with SBM
      Bank, Card(s) issued with this Account, as the Primary Account would
      automatically stand cancelled. The Cardholder must immediately cease to
      use his Debit Card and destroy and return all his additional cards that
      are linked to this Account. In case of any outstanding Debit Card
      transactions that have not yet been debited to the Account, the same will
      be netted off from the balance prior to SBM Bank returning the funds to
      him.
    </p>

    <p>
      In the event the Cardholder decides to terminate the use of the Debit
      Card, the Cardholder shall give SBM bank not less than 7 days prior notice
      in writing and forthwith return the Debit Card and any additional card,
      cut into several pieces through the magnetic strips, to SBM Bank. The
      Cardholder will be responsible for all the card facilities (in addition to
      the Debit Card, wherever applicable) and related charges incurred on the
      Debit Card after the Cardholder claims to have destroyed the Debit Card,
      notwithstanding the termination of these Terms &amp; Conditions.
    </p>

    <p>
      SBM Bank shall be entitled to terminate the Debit Card facility with
      immediate effect and the Card shall be returned upon occurrence of any of
      the following event:
    </p>

    <p>Failure to comply with these Terms &amp; Conditions.</p>

    <p>
      An event of default under an agreement or commitment (contingent or
      otherwise) entered into with SBM Bank.
    </p>

    <p>
      The Cardholder becoming the subject of any bankruptcy, insolvency
      proceedings or proceedings of a similar nature.
    </p>

    <p>Demise of the Cardholder.</p>

    <p>
      Closure of the Cardholder's Account or failure to maintain the minimum
      average balance in the said Account.
    </p>

    <p>Fraudulent use of the Debit Card by the Cardholder.</p>

    <p>Reported lunacy/ insanity/ unsound mind of the Cardholder.</p>

    <p>
      If any adverse report is received from any of the Banks/ Branches in the
      shared network. The Debit Card shall be made non-operational in case of
      Primary Account being made inoperative by the Bank. Notwithstanding
      termination of the Debit Card facility, the Transactions already processed
      but reported to the Cardholder's branch after the termination shall be put
      through the Cardholder's Account.
    </p>

    <p>
      The Debit Card should be returned to SBM Bank prior to the date upon which
      any changes are to take effect, in case of the Cardholder's rejection of
      any of the proposed changes to the features, changes or terms and
      conditions applicable to the Debit Card.
    </p>
  </div>
</WizardBody>

<style>
  h2 {
    color: #1e3868;
    font-weight: 700;
  }
</style>
