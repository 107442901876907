<script>
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { navigateTo } from "svelte-router-spa";

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<WizardBody title="Congratulations on your new credit card" back="">
  <div
    class="success-card d-flex justify-content-center align-items-center my-4"
  >
    <img
      style="max-width:100%; margin-top: 5rem;"
      src="/assets/images/success_card.png"
      alt=""
    />
    <div class="success-text my-4">
      <div>Physical card will be shipped in 5-7 days.</div>
      <div>Meanwhile, you can start using virtual card</div>
    </div>
  </div>
</WizardBody>
<WizardActionArea
  ><Button
    title="Go to dashboard"
    style="z-index: 1; position: relative;"
    onclick={() => {
      navigateTo("/fd/list");
    }}
  /></WizardActionArea
>

<style>
  .success-card {
    background-image: url("/assets/images/confetti.svg");
    background-size: cover;
    width: 600px;
    height: 320px;
    max-width: 100%;
    flex-direction: column;
  }

  .success-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #464448;
    max-width: 100%;
  }
</style>
