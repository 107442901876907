export const cityOptions = [
  "GAUTAM BUDDHA NAGAR",
  "SAHARANPUR",
  "BAGHPAT",
  "BAGPAT",
  "BIJNOR",
  "MORADABAD",
  "RAMPUR",
  "SONBHADRA",
  "KANSHIRAM NAGAR",
  "AMETHI",
  "PRABUDH NAGAR",
  "BHIM NAGAR",
  "RUDRAPRAYAG",
  "UDHAM SINGH NAGAR",
  "BAGESHWAR",
  "HARIDWAR",
  "NAINITAL",
  "PITHORAGARH",
  "SUPAUL",
  "KISHANGANJ",
  "SITAMARHI",
  "NALANDA",
  "BHOJPUR",
  "PURBI CHAMPARAN",
  "EAST CHAMPARAN",
  "MADHUBANI",
  "SAMASTIPUR",
  "SIWAN",
  "AURANGABAD",
  "NAWADA",
  "PATNA",
  "MUZAFFARPUR",
  "GOPALGANJ",
  "PASCHIMI CHAMPARAN",
  "SHEOHAR",
  "SARAN",
  "ROHTAS",
  "MADHEPURA",
  "GAYA",
  "LAKHISARAI",
  "BHAGALPUR",
  "EAST SINGHBHUM",
  "PURBI SINGHBHUM",
  "LOHARDAGGA",
  "LOHARDAGA",
  "PALAMAU",
  "JAMTARA",
  "WEST KAMENG",
  "UPPER SUBANSIRI",
  "KURUNG KUMEY",
  "NAMSAI",
  "MORIGAON",
  "LAKHIMPUR",
  "KARBI ANGLONG",
  "BARPETA",
  "UDALGURI",
  "EAST KHASI HILLS",
  "NORTH GARO HILLS",
  "SOUTH WEST GARO HILLS",
  "KOLASIB",
  "PAPUMPARE",
  "PAPUM PARE",
  "HUGLI",
  "BANKURA",
  "NADIA",
  "DARJILING",
  "DARJEELING",
  "KOHIMA",
  "MON",
  "CHURACHANDPUR",
  "IMPHAL WEST",
  "PHERZAWAL",
  "NAWRANGPUR",
  "BHADRAK",
  "KEONJHAR",
  "KHURDA",
  "BOUDH",
  "NORTH SIKKIM",
  "SOUTH TRIPURA",
  "NICOBAR",
  "BARA BANKI",
  "BARABANKI",
  "FATEHPUR",
  "JHANSI",
  "BANDA",
  "ALLAHABAD",
  "KAUSHAMBI",
  "PRATAPGARH",
  "PRATAPGHAR",
  "SULTANPUR",
  "FAIZABAD",
  "MAHARAJGANJ",
  "MAHRAJGANJ",
  "AZAMGARH",
  "SANT KABIR NAGAR",
  "JAUNPUR",
  "CHANDAULI",
  "VARANASI",
  "SANT RAVIDAS NAGAR",
  "MIRZAPUR",
  "GHAZIPUR",
  "BALLIA",
  "DEORIA",
  "KUSHI NAGAR",
  "KUSHINAGAR",
  "GORAKHPUR",
  "SITAPUR",
  "MAU",
  "MAINPURI",
  "ETAH",
  "MUZAFFARNAGAR",
  "JYOTIBA PHULE NAGAR",
  "PANCHSHEEL NAGAR",
  "CHAMPAWAT",
  "ALMORA",
  "DEHRA DUN",
  "DEHRADUN",
  "NORTH-WEST DELHI",
  "NORTH WEST DELHI",
  "WEST DELHI",
  "SOUTH-WEST DELHI",
  "SOUTH WEST DELHI",
  "SOUTH DELHI",
  "NORTH-EAST DELHI",
  "NORTH EAST DELHI",
  "EAST DELHI",
  "SOUTH-EAST DELHI",
  "SOUTH EAST DELHI",
  "SHAHDARA",
  "SAHIBZADA AJIT SINGH NAGA",
  "TARN TARAN",
  "BOLANGIR",
  "NAWAPARA",
  "SONEPUR",
  "DEOGARH",
  "JHARSUGUDA",
  "EAST SIKKIM",
  "WEST SIKKIM",
  "SOUTH SIKKIM",
  "NORTH TRIPURA",
  "WEST TRIPURA",
  "DHALAI",
  "KHOWAI",
  "SEPAHIJALA",
  "GOMATI",
  "UNAKOTI",
  "SOUTH ANDAMAN",
  "NORTH AND MIDDLE ANDAMAN",
  "LUCKNOW",
  "FIROZABAD",
  "RAI BARELI",
  "UNNAO",
  "HARDOI",
  "CHITRAKOOT",
  "KANPUR NAGAR",
  "KANPUR DEHAT",
  "JALAUN",
  "LALITPUR",
  "AMBEDKAR NAGAR",
  "BALRAMPUR",
  "BASTI",
  "GONDA",
  "BAHRAICH",
  "HAMIRPUR",
  "HAMIRPURHP",
  "MAHOBA",
  "SHRAVASTI",
  "SHRAWASTI",
  "KHERI",
  "PILIBHIT",
  "BAREILLY",
  "SHAHJAHANPUR",
  "FARRUKHABAD",
  "KANAUJ",
  "ETAWAH",
  "SIDDHARTHANAGAR",
  "SIDDHARTHNAGAR",
  "AURAIYA",
  "AGRA",
  "MATHURA",
  "HATHRAS",
  "ALIGARH",
  "BUDAUN",
  "BULANDSHAHR",
  "GHAZIABAD",
  "NAYAGARH",
  "MEERUT",
  "PARBHANI",
  "HINGOLI",
  "BID",
  "OSMANABAD",
  "NANDED",
  "YAVATMAL",
  "CHANDRAPUR",
  "BHANDARA",
  "GONDIA",
  "NAGPUR",
  "WARDHA",
  "AMRAVATI",
  "AKOLA",
  "WASHIM",
  "BULDHANA",
  "NASIK",
  "DHULE",
  "NANDURBAR",
  "JALGAON",
  "LATUR",
  "SINGRAULI",
  "UMARIA",
  "MANDLA",
  "BETUL",
  "BARWANI",
  "UJJAIN",
  "SHIVPURI",
  "PANNA",
  "NARAYANPUR",
  "GARIYABAND",
  "GARIABAND",
  "DHAMTARI",
  "SURGUJA",
  "DANTEWADA",
  "SRIKAKULAM",
  "PRAKASAM",
  "KURNOOL",
  "NIZAMABAD",
  "KARIMNAGAR",
  "KARIM NAGAR",
  "BHADRADRI (KOTHAGUDEM)",
  "KOTHAGUDEM",
  "YADADRI",
  "MAHBUBNAGAR",
  "NAGARKURNOOL",
  "NAGAR KURNOOL",
  "VIKARABAD",
  "BANGALORE URBAN",
  "BENGALURU URBAN",
  "KOLAR",
  "GADAG",
  "BIJAPUR",
  "BIJAPUR(KAR)",
  "CHIKMAGALUR",
  "ARIYALUR",
  "VELLORE",
  "TIRUCHIRAPALLI",
  "NILGIRIS",
  "ERODE",
  "TIRUNELVALI",
  "THENI",
  "KOTTAYAM",
  "KOZHIKODE",
  "PUDUCHERRY",
  "PONDICHERRY",
  "KATIHAR",
  "MUNGER",
  "DARBHANGA",
  "SAHARSA",
  "BEGUSARAI",
  "PURNIA",
  "SHEIKHPURA",
  "JEHANABAD",
  "ARARIA",
  "KHAGARIA",
  "VAISHALI",
  "ARWAL",
  "BOKARO",
  "GARHWA",
  "SAHEBGANJ",
  "DEOGHAR",
  "DUMKA",
  "GODDA",
  "GUMLA",
  "GIRIDIH",
  "GIRIDH",
  "CHATRA",
  "PAKUR",
  "KODERMA",
  "DHANBAD",
  "HAZARIBAG",
  "HAZARIBAGH",
  "RANCHI",
  "WEST SINGHBHUM",
  "PASCHIMI SINGHBHUM",
  "LATEHAR",
  "SERAIKELA-KHARSAWAN",
  "SERAIKELAKHARSAWAN",
  "SIMDEGA",
  "RAMGARH",
  "KHUNTI",
  "EAST KAMENG",
  "LOHIT",
  "WEST SIANG",
  "EAST SIANG",
  "LOWER SUBANSIRI",
  "DIBANG VALLEY",
  "TIRAP",
  "TAWANG",
  "LOWER DIBANG VALLEY",
  "ANJAW",
  "LONGDING",
  "SIANG",
  "KRA DAADI",
  "CHUNGLANG",
  "KAMRUP",
  "BONGAIGAON",
  "GOALPARA",
  "HAILAKANDI",
  "SONITPUR",
  "DARRANG",
  "NALBARI",
  "DIBRUGARH",
  "JORHAT",
  "SIBSAGAR",
  "EASTERN PROVINCE",
  "NAGAON",
  "TINSUKIA",
  "KARIMGANJ",
  "NORTH CACHAR HILLS",
  "DIMA HASSO - NORTH CACHAR HILL",
  "DHUBRI",
  "KOKRAJHAR",
  "CACHAR",
  "KAMRUP METROPOLITAN",
  "CHIRANG",
  "BAKSA",
  "DHEMAJI",
  "EAST GARO HILLS",
  "WEST GARO HILLS",
  "JAINTIA HILLS",
  "WEST KHASI HILLS",
  "RI BHOI",
  "SOUTH GARO HILLS",
  "EAST JAINTIA HILLS",
  "SOUTH WEST KHASI HILLS",
  "AIZAWL",
  "AIZAWAL",
  "CHAMPHAI",
  "LUNGLEI",
  "MAMIT",
  "MAMMIT",
  "SAIHA",
  "SERCHHIP",
  "LAWNGTLAI",
  "KAIMUR",
  "BUXAR",
  "BANKA",
  "JAMUI",
  "UPPER SIANG",
  "KOLKATA",
  "NORTH 24 PARGANAS",
  "SOUTH 24 PARGANAS",
  "HOWRAH",
  "PURBA MEDINIPUR",
  "PASCHIM MEDINIPUR",
  "PURULIYA",
  "BARDDHAMAN",
  "BIRBHUM",
  "MURSHIDABAD",
  "MALDAH",
  "UTTAR DINAJPUR",
  "DAKSHIN DINAJPUR",
  "NORTH DINAJPUR",
  "SOUTH DINAJPUR",
  "JALPAIGURI",
  "COACH BIHAR",
  "LONGLENG",
  "DIMAPUR",
  "MOKOKCHUNG",
  "PEREN",
  "TUENSANG",
  "KIPHIRE",
  "WOKHA",
  "PHEK",
  "ZUNHEBOTO",
  "ZUNHEBOTTO",
  "SENAPATI",
  "IMPHAL EAST",
  "THOUBAL",
  "UKHRUL",
  "BISHENPUR",
  "TAMENGLONG",
  "CHANDEL",
  "KANGPOKPI ",
  "KAKCHING",
  "TENGNOUPAL",
  "KAMJONG ",
  "NONEY",
  "PURI",
  "GAJAPATI",
  "GANJAM",
  "MALKANGIRI",
  "KANDHAMAL",
  "KORAPUT",
  "RAYAGADA",
  "KALAHANDI",
  "ANGUL",
  "CUTTACK",
  "BALESWAR",
  "BALESHWAR",
  "BARGARH",
  "MAYURBHANJ",
  "JAGATSINGHPUR",
  "JAGATSINGHAPUR",
  "JAJPUR",
  "JAJAPUR",
  "DHENKANAL",
  "KENDRAPARA",
  "SUNDARGARH",
  "SAMBALPUR",
  "ALIPURDUAR",
  "GUNTUR",
  "NELLORE",
  "BARNALA",
  "PATIALA",
  "SANGRUR",
  "BATHINDA",
  "FEROZPUR",
  "PATHANKOT",
  "AMRITSAR",
  "FAZILKA",
  "GURDASPUR",
  "MANSA",
  "LUDHIANA",
  "FARIDKOT",
  "ROHTAK",
  "HISAR",
  "SONIPAT",
  "RAMBAN",
  "SHOPIAN",
  "DHOLPUR",
  "SAWAI MADHOPUR",
  "TONK",
  "BHILWARA",
  "DAUSA",
  "AJMER",
  "NAGAUR",
  "BARAN",
  "SIKAR",
  "RAJSAMAND",
  "JHUNJHUNU",
  "JHUJHUNU",
  "CHURU",
  "HANUMANGARH",
  "GANGANAGAR",
  "SRI GANGANAGAR",
  "SRIGANGANAGAR",
  "KARAULI",
  "BIKANER",
  "JAISALMER",
  "BARMER",
  "JALOR",
  "BANSWARA",
  "RAJOURI",
  "BILASPUR",
  "BILASPUR HP",
  "SIRMAUR",
  "ALWAR",
  "BOTAD",
  "BOTAB",
  "MAHISAGAR",
  "SABAR KANTHA",
  "SABARKANTHA",
  "VALSAD",
  "BHAVNAGAR",
  "SURENDRANAGAR",
  "SURENDRA NAGAR",
  "RAIGAD",
  "SATARA",
  "CHAMOLI",
  "GARHWAL",
  "TEHRI GARHWAL",
  "UTTAR KASHI",
  "UTTARKASHI",
  "DELHI",
  "NEW DELHI",
  "CENTRAL DELHI",
  "NORTH DELHI",
  "KAPURTHALA",
  "FATEHGARH SAHIB",
  "JALANDHAR",
  "MOGA",
  "MUKTSAR",
  "SHAHID BHAGAT SINGH NAGAR",
  "HOSHIARPUR",
  "RUPNAGAR",
  "MEWAT",
  "PALWAL",
  "AMBALA",
  "YAMUNANAGAR",
  "KARNAL",
  "PANIPAT",
  "PANCHKULA",
  "MAHENDRAGARH",
  "REWARI",
  "GURGAON",
  "JHAJJAR",
  "FATEHABAD",
  "SIRSA",
  "JIND",
  "KAITHAL",
  "KURUKSHETRA",
  "BHIWANI",
  "FARIDABAD",
  "CHANDIGARH",
  "CHANDIGHAR",
  "KISHTWAR",
  "KULGAM",
  "GANDERBAL",
  "BANDIPURA",
  "REASI",
  "SAMBA",
  "BADGAM",
  "PULWAMA",
  "KUPWARA",
  "KARGIL",
  "SRINAGAR",
  "SIROHI",
  "PALI",
  "JODHPUR",
  "UDAIPUR",
  "DUNGARPUR",
  "CHITTAURGARH",
  "BARAMULLA",
  "ANANTNAG",
  "POONCH",
  "JAMMU",
  "KATHUA",
  "UDHAMPUR",
  "DODA",
  "LEH LADAKH",
  "SIMLA",
  "MANDI",
  "KULU",
  "KANGRA",
  "CHAMBA",
  "LAHUL & SPITI",
  "LAHUL SPITI",
  "KINNAUR",
  "UNA",
  "SOLAN",
  "JAIPUR",
  "BHARATPUR",
  "BUNDI",
  "KOTA",
  "JHALAWAR",
  "TAPI",
  "ARAVALLI",
  "CHHOTAUDEPUR",
  "DEVBHUMI DWARKA",
  "DEVBHOOMI DWERKA",
  "GIR SOMNATH",
  "MORBI",
  "GANDHINAGAR",
  "GANDHI NAGAR",
  "MAHESANA",
  "PATAN",
  "BANAS KANTHA",
  "BANASKANTHA",
  "PANCH MAHALS",
  "DAHOD",
  "VADODARA",
  "NARMADA",
  "BHARUCH",
  "CHITTOOR",
  "CUDDAPAH",
  "JALNA",
  "ANANTAPUR",
  "VIZIANAGARAM",
  "HYDERABAD",
  "NIRMAL",
  "MEDAK",
  "KOMRAM BHEEM (ASIFABAD)",
  "ADILABAD",
  "MANCHERIAL",
  "WARANGAL",
  "KHAMMAM",
  "JAGITIAL",
  "JAGTIAL",
  "PEDDAPALLI",
  "RAJANNA(SIRCILLA)",
  "SIRCILLA",
  "SANGAREDDY",
  "SIDDIPET",
  "SURYAPET",
  "KAMAREDDY",
  "WARANGAL (R)",
  "WARANGAL RURAL",
  "NALGONDA",
  "MAHABUBABAD",
  "MAHABUABABAD",
  "JANGAON",
  "JAYASHANKAR (BHUPALPALLI)",
  "JAYASHANKAR",
  "RANGAREDDY",
  "RANGA REDDY",
  "WANAPARTHY",
  "JOGULAMBA (GADWAL)",
  "GADWAL",
  "MEDCHAL-MALKAJGIRI",
  "RAMANAGARA",
  "RAMANAGAR",
  "CHIKKABALLAPURA",
  "CHIKKABALLAPUR",
  "YADGIR",
  "BANGALORE RURAL",
  "BENGALURU RURAL",
  "MANDYA",
  "HASSAN",
  "MYSORE",
  "CHAMARAJANAGAR",
  "CHAMRAJNAGAR",
  "TUMKUR",
  "TUMAKURU",
  "CHITRADURGA",
  "DAVANGERE",
  "BELLARY",
  "DHARWAD",
  "HAVERI",
  "RAICHUR",
  "KOPPAL",
  "GULBARGA",
  "BIDAR",
  "BAGALKOTE",
  "BAGALKOT",
  "BELGAUM",
  "UTTAR KANNAD",
  "UTTARA KANNADA",
  "SHIMOGA",
  "DAKSHIN KANNAD",
  "DAKSHINA KANNADA",
  "SAGAR",
  "HARDA",
  "DAMOH",
  "KATNI",
  "JABALPUR",
  "DINDORI",
  "SEONI",
  "NARSIMHAPUR",
  "CHHINDWARA",
  "HOSHANGABAD",
  "EAST NIMAR",
  "DEWAS",
  "INDORE",
  "ANUPPUR",
  "WEST NIMAR",
  "DHAR",
  "BURHANPUR",
  "JHABUA",
  "RATLAM",
  "MANDSAUR",
  "NEEMUCH",
  "ASHOKNAGAR",
  "ASHOK NAGAR",
  "SHAJAPUR",
  "RAJGARH",
  "VIDISHA",
  "GUNA",
  "MORENA",
  "BHIND",
  "GWALIOR",
  "DATIA",
  "TIKAMGARH",
  "CHHATARPUR",
  "SATNA",
  "REWA",
  "SIDHI",
  "SHAHDOL",
  "BALAGHAT",
  "BHOPAL",
  "SUKMA",
  "KONDAGAON DW",
  "BALODABAZAR",
  "BALOD BAZER",
  "BEMETARA",
  "BALOD",
  "MUNGELI",
  "SURAJPUR",
  "JASHPUR",
  "JASPUR",
  "DURG",
  "JANJGIR-CHAMPA",
  "JANJGIRCHAMPA",
  "KORBA",
  "KORIYA",
  "RAIGARH",
  "RAIGARH(MH)",
  "MAHASAMUND",
  "RAIPUR",
  "KANKER",
  "BASTAR",
  "KAWARDHA",
  "RAJNANDGAON",
  "EAST GODAVARI",
  "VISHAKHAPATNAM",
  "VISAKHAPATNAM",
  "WEST GODAVARI",
  "KRISHNA",
  "NAVSARI",
  "SURAT",
  "DANGS",
  "KHEDA",
  "ANAND",
  "AHMEDABAD",
  "AHMEDABAD CITY",
  "PORBANDAR",
  "AMRELI",
  "JUNAGADH",
  "RAJKOT",
  "JAMNAGAR",
  "KACHCHH",
  "MUMBAI SUBURBAN",
  "MUMBAI SUBUEBAN",
  "PALGHAR",
  "MUMBAI",
  "THANE",
  "RATNAGIRI",
  "SINDHUDURG",
  "KOLHAPUR",
  "SOLAPUR",
  "SANGLI",
  "PUNE",
  "AHMEDNAGAR",
  "AHMED NAGAR",
  "GADCHIROLI",
  "DAMAN",
  "DIU",
  "NORTH GOA",
  "SOUTH GOA",
  "DADRA&NAGAR HAVELI",
  "DADRA  NAGAR HAVELI",
  "DADRA NAGAR HAVELI",
  "ALIRAJPUR",
  "AGAR MALWA",
  "SEHORE",
  "SHEOPUR",
  "RAISEN",
  "UDIPI",
  "KODAGU",
  "LAKSHADWEEP",
  "KRISHNAGIRI",
  "TIRUPPUR",
  "CHENNAI",
  "THIRUVALLUR",
  "KANCHEEPURAM",
  "CUDDALORE",
  "THIRUVARUR",
  "THANJAVUR",
  "NAGAPATTINAM",
  "KARUR",
  "SALEM",
  "PERAMBALUR",
  "NAMAKKAL",
  "DHARMAPURI",
  "TIRUVANNAMALAI",
  "COIMBATORE",
  "SIVAGANGA",
  "VIRUDHUNAGAR",
  "MADURAI",
  "RAMANATHAPURAM",
  "TOOTHUKUDI",
  "KANYAKUMARI",
  "PUDUKKOTTAI",
  "DINDIGUL",
  "VILLUPURAM",
  "THIRUVANANTHAPURAM",
  "KOLLAM",
  "ALAPUZHA",
  "ERNAKULAM",
  "THRISSUR",
  "PALAKKAD",
  "IDUKKI",
  "PATHANAMTHITTA",
  "MALAPPURAM",
  "KANNUR",
  "WAYANAD",
  "KASARAGOD",
  "KASARGOD",
  "KARAIKAL",
  "MAHE",
  "YANAM",
  "BANGKOK",
  "SPRINGVALE",
  "MAHAJANGA",
  "GRONINGEN",
  "SELANGOR",
  "TRALEE",
  "XIAMEN",
  "NUZVID",
  "RAMCHANDRAPURAM",
  "OTHERS",
  "ALEXANDER CITY",
  "ANDALUSIA",
  "ANNISTON",
  "ATHENS",
  "ATMORE",
  "AUBURN",
  "BESSEMER",
  "BIRMINGHAM",
  "CHICKASAW",
  "CLANTON",
  "CULLMAN",
  "DECATUR",
  "DEMOPOLIS",
  "DOTHAN",
  "ENTERPRISE",
  "EUFAULA",
  "FLORENCE",
  "FORT PAYNE",
  "GADSDEN",
  "GREENVILLE",
  "GUNTERSVILLE",
  "HUNTSVILLE",
  "JASPER",
  "MARION",
  "MOBILE",
  "MONTGOMERY",
  "OPELIKA",
  "OZARK",
  "PHENIX CITY",
  "PRICHARD",
  "SCOTTSBORO",
  "SELMA",
  "SHEFFIELD",
  "SYLACAUGA",
  "TALLADEGA",
  "TROY",
  "TUSCALOOSA",
  "TUSCUMBIA",
  "TUSKEGEE",
  "ANCHORAGE",
  "CORDOVA",
  "FAIRBANKS",
  "HAINES",
  "HOMER",
  "JUNEAU",
  "KETCHIKAN",
  "KODIAK",
  "KOTZEBUE",
  "NOME",
  "PALMER",
  "SEWARD",
  "SITKA",
  "SKAGWAY",
  "VALDEZ",
  "AJO",
  "AVONDALE",
  "BISBEE",
  "CASA GRANDE",
  "CHANDLER",
  "CLIFTON",
  "DOUGLAS",
  "FLAGSTAFF",
  "GILA BEND",
  "GLENDALE",
  "GLOBE",
  "KINGMAN",
  "LAKE HAVASU CITY",
  "MESA",
  "NOGALES",
  "ORAIBI",
  "PHOENIX",
  "PRESCOTT",
  "SCOTTSDALE",
  "SIERRA VISTA",
  "TEMPE",
  "TOMBSTONE",
  "TUCSON",
  "AIRDRIE",
  "BEAUMONT",
  "BROOKS",
  "CALGARY",
  "CAMROSE",
  "CHESTERMERE",
  "COLD LAKE",
  "EDMONTON",
  "FORT SASKATCHEWAN",
  "GRANDE PRAIRIE",
  "LACOMBE",
  "LEDUC",
  "LETHBRIDGE",
  "LLOYDMINSTER",
  "MEDICINE HAT",
  "RED DEER",
  "SPRUCE GROVE",
  "ST. ALBERT",
  "WETASKIWIN",
  "ABBOTSFORD",
  "ARMSTRONG",
  "BURNABY",
  "LAVALTRIE",
  "LEBEL-SUR-QUEVILLON",
  "L'EPIPHANIE",
  "LERY",
  "LEVIS",
  "L'ILE-CADIEUX",
  "L'ILE-DORVAL",
  "L'ILE-PERROT",
  "LONGUEUIL",
  "LORRAINE",
  "LOUISEVILLE",
  "MACAMIC",
  "MAGOG",
  "MALARTIC",
  "MANIWAKI",
  "MARIEVILLE",
  "MASCOUCHE",
  "MATAGAMI",
  "MATANE",
  "MERCIER",
  "METABETCHOUAN–LAC-A-LA-CROIX",
  "METIS-SUR-MER",
  "MIRABEL",
  "MONT-JOLI",
  "MONT-LAURIER",
  "MONTMAGNY",
  "MONTREAL",
  "MONTREAL WEST",
  "MONTREAL-EST",
  "MONT-SAINT-HILAIRE",
  "MONT-TREMBLANT",
  "MOUNT ROYAL",
  "MURDOCHVILLE",
  "NEUVILLE",
  "NEW RICHMOND",
  "NICOLET",
  "NORMANDIN",
  "NOTRE-DAME-DE-L'ILE-PERROT",
  "NOTRE-DAME-DES-PRAIRIES",
  "OTTERBURN PARK",
  "PASPEBIAC",
  "PERC",
  "PINCOURT",
  "PLESSISVILLE",
  "POHENEGAMOOK",
  "POINTE-CLAIRE",
  "PONT-ROUGE",
  "PORT-CARTIER",
  "PORTNEUF",
  "PREVOST",
  "PRINCEVILLE",
  "QUEBEC",
  "REPENTIGNY",
  "RICHELIEU",
  "RICHMOND",
  "RIMOUSKI",
  "RIVIERE-DU-LOUP",
  "RIVIERE-ROUGE",
  "ROBERVAL",
  "ROSEMERE",
  "ROUYN-NORANDA",
  "SAGUENAY",
  "SAINT-AUGUSTIN-DE-DESMAURES",
  "SAINT-BASILE",
  "SAINT-BASILE-LE-GRAND",
  "SAINT-BRUNO-DE-MONTARVILLE",
  "SAINT-CESAIRE",
  "SAINT-COLOMBAN",
  "SAINT-CONSTANT",
  "SAINTE-ADELE",
  "SAINTE-AGATHE-DES-MONTS",
  "SAINTE-ANNE-DE-BEAUPRE",
  "SAINTE-ANNE-DE-BELLEVUE",
  "SAINTE-ANNE-DES-MONTS",
  "SAINTE-ANNE-DES-PLAINES",
  "SAINTE-CATHERINE",
  "SAINTE-CATHERINE-DE-LA-JACQUES-CARTIER",
  "SAINTE-JULIE",
  "SAINTE-MARGUERITE-DU-LAC-MASSON",
  "SAINTE-MARIE",
  "SAINTE-MARTHE-SUR-LE-LAC",
  "SAINTE-THERESE",
  "SAINT-EUSTACHE",
  "SAINT-FELICIEN",
  "SAINT-GABRIEL",
  "SAINT-GEORGES",
  "SAINT-HYACINTHE",
  "SAINT-JEAN-SUR-RICHELIEU",
  "SAINT-JEROME",
  "SAINT-JOSEPH-DE-BEAUCE",
  "SAINT-JOSEPH-DE-SOREL",
  "SAINT-LAMBERT",
  "SAINT-LAZARE",
  "SAINT-LIN-LAURENTIDES",
  "SAINT-MARC-DES-CARRIERES",
  "SAINT-OURS",
  "SAINT-PAMPHILE",
  "SAINT-PASCAL",
  "SAINT-PIE",
  "SAINT-RAYMOND",
  "SAINT-REMI",
  "SAINT-SAUVEUR",
  "SAINT-TITE",
  "SALABERRY-DE-VALLEYFIELD",
  "SCHEFFERVILLE",
  "SCOTSTOWN",
  "SENNETERRE",
  "SEPT-ILES",
  "SHAWINIGAN",
  "SHERBROOKE",
  "SOREL-TRACY",
  "STANSTEAD",
  "SUTTON",
  "TEMISCAMING",
  "TEMISCOUATA-SUR-LE-LAC",
  "TERREBONNE",
  "THETFORD MINES",
  "THURSO",
  "TROIS-PISTOLES",
  "TROIS-RIVIERES",
  "VALCOURT",
  "VAL-D'OR",
  "VARENNES",
  "VAUDREUIL-DORION",
  "VICTORIAVILLE",
  "VILLE-MARIE",
  "WARWICK  ARTHABASKA",
  "WATERLOO",
  "WATERVILLE",
  "WESTMOUNT",
  "WINDSOR",
  "ESTEVAN",
  "FLIN FLON",
  "HUMBOLDT",
  "MARTENSVILLE",
  "MEADOW LAKE",
  "MELFORT  STAR",
  "MELVILLE",
  "MOOSE JAW",
  "NORTH BATTLEFORD",
  "PRINCE ALBERT",
  "REGINA",
  "SASKATOON",
  "SWIFT CURRENT",
  "WARMAN",
  "WEYBURN",
  "YORKTON",
  "WHITEHORSE",
  "BRAMSTHAN",
  "BRISEE VERDIERE",
  "PORT LOUIS",
  "BEAU BASSIN-ROSE HILL",
  "DINEWOOD  GARDEN",
  "QUATRE BORNES",
  "VACOAS-PHOENIX",
  "ALBION",
  "AMAURY",
  "AMITIE-GOKHOOLA",
  "ANSE LA RAIE",
  "ARSENAL",
  "BAIE DU CAP",
  "BAIE DU TOMBEAU",
  "BAMBOUS",
  "BAMBOUS VIRIEUX",
  "BANANES",
  "BEAU VALLON",
  "BEL AIR RIVIERE SECHE",
  "BEL OMBRE",
  "BELLE VUE MAUREL",
  "BENARES",
  "BOIS CHERI",
  "BOIS DES AMOURETTES",
  "BON ACCUEIL",
  "BRITANNIA",
  "CALEBASSES",
  "CAMP CAROL",
  "CAMP DE MASQUE",
  "CAMP DE MASQUE PAVE",
  "CAMP DIABLE",
  "CAMP ITHIER",
  "CAMP THOREL",
  "CAP MALHEUREUX",
  "CASCAVELLE",
  "CASE NOYALE",
  "CENTRE DE FLACQ",
  "CHAMAREL",
  "CHAMOUNY",
  "CHEMIN GRENIER",
  "CLEMENCIA",
  "CLUNY",
  "CONGOMAH",
  "COTTAGE",
  "CREVE COEUR",
  "D'EPINAY",
  "DAGOTIERE",
  "DUBREUIL",
  "ECROIGNARD",
  "ESPERANCE",
  "ESPERANCE TREBUCHET",
  "FLIC EN FLAC",
  "FOND DU SAC",
  "GOODLANDS",
  "GRAND BAIE",
  "GRAND BEL AIR",
  "GRAND BOIS",
  "GRAND GAUBE",
  "GRAND SABLE",
  "GRANDE RETRAITE",
  "GRANDE RIVIERE NOIRE",
  "GRANDE RIVIERE SUD EST",
  "GROS CAILLOUX",
  "L'AVENIR",
  "L'ESCALIER",
  "LA FLORA",
  "LA GAULETTE",
  "LA LAURA-MALENGA",
  "LALMATIE",
  "LAVENTURE",
  "LE HOCHET",
  "LE MORNE",
  "THE VALE",
  "MAHEBOURG",
  "MAPOU",
  "MARE CHICOSE",
  "MARE D'ALBERT",
  "MARE LA CHAUX",
  "MARE TABAC",
  "MEDINE CAMP DE MASQUE",
  "MELROSE",
  "MIDLANDS",
  "MOKA",
  "MONTAGNE BLANCHE",
  "MONTAGNE LONGUE",
  "MORCELLEMENT SAINT ANDRE",
  "NEW GROVE",
  "NOTRE DAME",
  "NOUVELLE DECOUVERTE",
  "NOUVELLE FRANCE",
  "OLIVIA",
  "PAILLES",
  "PAMPLEMOUSSES",
  "PETIT BEL AIR",
  "PETIT RAFFRAY",
  "PETITE RIVIERE",
  "PITON",
  "PLAINE DES PAPAYES",
  "PLAINE DES ROCHES",
  "PLAINE MAGNIEN",
  "POINTE AUX PIMENTS",
  "POSTE DE FLACQ",
  "POUDRE D'OR",
  "POUDRE D'OR HAMLET",
  "PROVIDENCE",
  "QUARTIER MILITAIRE",
  "QUATRE COCOS",
  "QUATRE SOEURS",
  "QUEEN VICTORIA",
  "RIPAILLES",
  "RIVIERE DES ANGUILLES",
  "RIVIERE DES CREOLES",
  "RIVIERE DU POSTE",
  "RIVIERE DU REMPART",
  "ROCHE TERRE",
  "ROCHES NOIRES",
  "ROSE BELLE",
  "SAINT AUBIN",
  "SAINT HUBERT",
  "SAINT JULIEN VILLAGE",
  "SAINT JULIEN D'HOTMAN",
  "SAINT PIERRE",
  "SEBASTOPOL",
  "SEIZIEME MILLE",
  "SOUILLAC",
  "SURINAM",
  "TAMARIN",
  "TERRE ROUGE",
  "TRIOLET",
  "TROIS BOUTIQUES",
  "TROU AUX BICHES",
  "TROU D'EAU DOUCE",
  "TYACK",
  "UNION PARK",
  "VALE",
  "VERDUN",
  "VIEUX GRAND PORT",
  "VILLE BAGUE",
  "CAMPBELL RIVER",
  "CASTLEGAR",
  "CHILLIWACK",
  "COLWOOD",
  "COQUITLAM",
  "COURTENAY",
  "CRANBROOK",
  "DAWSON CREEK",
  "DELTA",
  "DUNCAN",
  "ENDERBY",
  "FERNIE",
  "FORT ST. JOHN",
  "GRAND FORKS",
  "GREENWOOD",
  "KAMLOOPS",
  "KELOWNA",
  "KIMBERLEY",
  "LANGFORD",
  "LANGLEY",
  "MAPLE RIDGE",
  "MERRITT",
  "NANAIMO",
  "NELSON",
  "NEW WESTMINSTER",
  "NORTH VANCOUVER",
  "PARKSVILLE",
  "PENTICTON",
  "PITT MEADOWS",
  "PORT ALBERNI",
  "PORT COQUITLAM",
  "PORT MOODY",
  "POWELL RIVER",
  "PRINCE GEORGE",
  "PRINCE RUPERT",
  "QUESNEL",
  "REVELSTOKE",
  "ROSSLAND",
  "SALMON ARM",
  "SURREY",
  "TERRACE",
  "TRAIL",
  "VANCOUVER",
  "VERNON",
  "VICTORIA",
  "WEST KELOWNA",
  "WHITE ROCK",
  "WILLIAMS LAKE",
  "BRANDON",
  "DAUPHIN",
  "MORDEN",
  "PORTAGE LA PRAIRIE",
  "SELKIRK",
  "STEINBACH",
  "THOMPSON",
  "WINKLER",
  "WINNIPEG",
  "BATHURST",
  "CAMPBELLTON",
  "DIEPPE",
  "EDMUNDSTON",
  "FREDERICTON",
  "MIRAMICHI",
  "MONCTON",
  "SAINT JOHN",
  "CORNER BROOK",
  "MOUNT PEARL",
  "ST. JOHN'S",
  "YELLOWKNIFE",
  "IQALUIT",
  "BARRIE",
  "BELLEVILLE",
  "BRAMPTON",
  "BRANT",
  "BRANTFORD",
  "BROCKVILLE",
  "BURLINGTON",
  "CAMBRIDGE",
  "CLARENCE-ROCKLAND",
  "CORNWALL",
  "DRYDEN",
  "ELLIOT LAKE",
  "GREATER SUDBURY",
  "GUELPH",
  "HALDIMAND COUNTY",
  "HAMILTON",
  "KAWARTHA LAKES",
  "KENORA",
  "KINGSTON",
  "KITCHENER",
  "MARKHAM",
  "MISSISSAUGA",
  "NIAGARA FALLS",
  "NORFOLK COUNTY",
  "NORTH BAY",
  "ORILLIA",
  "OSHAWA",
  "OTTAWA",
  "OWEN SOUND",
  "PEMBROKE",
  "PETERBOROUGH",
  "PICKERING",
  "PORT COLBORNE",
  "PRINCE EDWARD COUNTY",
  "QUINTE WEST",
  "RICHMOND HILL",
  "SARNIA",
  "SAULT STE. MARIE",
  "ST. CATHARINES",
  "ST. THOMAS",
  "STRATFORD",
  "TEMISKAMING SHORES",
  "THOROLD",
  "THUNDER BAY",
  "TIMMINS",
  "TORONTO",
  "VAUGHAN",
  "WELLAND",
  "WOODSTOCK",
  "CHARLOTTETOWN",
  "SUMMERSIDE",
  "ACTON VALE",
  "ALMA",
  "AMOS",
  "AMQUI",
  "ASBESTOS",
  "BAIE-COMEAU",
  "BAIE-D'URFE",
  "BAIE-SAINT-PAUL",
  "BARKMERE",
  "BEACONSFIELD",
  "BEAUCEVILLE",
  "BEAUHARNOIS",
  "BEAUPRE",
  "BECANCOUR",
  "BEDFORD",
  "BELLETERRE",
  "BELOEIL",
  "BERTHIERVILLE",
  "BLAINVILLE",
  "BOISBRIAND",
  "BOIS-DES-FILION",
  "BONAVENTURE",
  "BOUCHERVILLE",
  "BROME LAKE",
  "BROMONT",
  "BROSSARD",
  "BROWNSBURG-CHATHAM",
  "CANDIAC",
  "CAP-CHAT",
  "CAP-SANTE",
  "CARIGNAN",
  "CARLETON-SUR-MER",
  "CAUSAPSCAL",
  "CHAMBLY",
  "CHAPAIS",
  "CHARLEMAGNE",
  "CHATEAUGUAY",
  "CHATEAU-RICHER",
  "CHIBOUGAMAU",
  "CLERMONT",
  "COATICOOK",
  "CONTRECOEUR",
  "COOKSHIRE-EATON",
  "COTE SAINT-LUC",
  "COTEAU-DU-LAC",
  "COWANSVILLE",
  "DANVILLE",
  "DAVELUYVILLE",
  "DEGELIS",
  "DELSON",
  "DESBIENS",
  "DEUX-MONTAGNES",
  "DISRAELI",
  "DOLBEAU-MISTASSINI",
  "DOLLARD-DES-ORMEAUX",
  "DONNACONA",
  "DORVAL",
  "DRUMMONDVILLE",
  "DUNHAM",
  "DUPARQUET",
  "EAST ANGUS",
  "ESTEREL",
  "FARNHAM",
  "FERMONT",
  "FORESTVILLE",
  "FOSSAMBAULT-SUR-LE-LAC",
  "GASPE",
  "GATINEAU",
  "GRACEFIELD",
  "GRANBY",
  "GRANDE-RIVIERE",
  "HAMPSTEAD",
  "HUDSON",
  "HUNTINGDON",
  "JOLIETTE",
  "KINGSEY FALLS",
  "KIRKLAND",
  "LA MALBAIE",
  "LA POCATIERE",
  "LA PRAIRIE",
  "LA SARRE",
  "LA TUQUE",
  "LAC-DELAGE",
  "LACHUTE",
  "LAC-MEGANTIC",
  "LAC-SAINT-JOSEPH",
  "LAC-SERGENT",
  "L'ANCIENNE-LORETTE",
  "L'ASSOMPTION",
  "LAVAL",
  "NAHANT",
  "HERRIN",
  "HIGHLAND PARK",
  "JACKSONVILLE",
  "JOLIET",
  "KANKAKEE",
  "KASKASKIA",
  "KEWANEE",
  "LA SALLE",
  "LAKE FOREST",
  "LIBERTYVILLE",
  "LINCOLN",
  "LISLE",
  "LOMBARD",
  "MACOMB",
  "MATTOON",
  "MOLINE",
  "MONMOUTH",
  "MOUNT VERNON",
  "MUNDELEIN",
  "NAPERVILLE",
  "NAUVOO",
  "NORMAL",
  "NORTH CHICAGO",
  "OAK PARK",
  "OREGON",
  "PALATINE",
  "PARK FOREST",
  "PARK RIDGE",
  "PEKIN",
  "PEORIA",
  "PETERSBURG",
  "PONTIAC",
  "QUINCY",
  "RANTOUL",
  "RIVER FOREST",
  "ROCK ISLAND",
  "ROCKFORD",
  "SHAWNEETOWN",
  "SKOKIE",
  "SOUTH HOLLAND",
  "SPRINGFIELD",
  "STREATOR",
  "SUMMIT",
  "URBANA",
  "VANDALIA",
  "VIRDEN",
  "WAUKEGAN",
  "WHEATON",
  "WILMETTE",
  "WINNETKA",
  "WOOD RIVER",
  "ZION",
  "ANDERSON",
  "BLOOMINGTON",
  "COLUMBUS",
  "CONNERSVILLE",
  "CORYDON",
  "CRAWFORDSVILLE",
  "EAST CHICAGO",
  "ELKHART",
  "ELWOOD",
  "EVANSVILLE",
  "FORT WAYNE",
  "FRENCH LICK",
  "GARY",
  "GENEVA",
  "GOSHEN",
  "GREENFIELD",
  "HAMMOND",
  "HOBART",
  "HUNTINGTON",
  "INDIANAPOLIS",
  "JEFFERSONVILLE",
  "KOKOMO",
  "LAFAYETTE",
  "MADISON",
  "MICHIGAN CITY",
  "MISHAWAKA",
  "MUNCIE",
  "NAPPANEE",
  "NASHVILLE",
  "NEW ALBANY",
  "NEW CASTLE",
  "NEW HARMONY",
  "PERU",
  "PLYMOUTH",
  "SANTA CLAUS",
  "SHELBYVILLE",
  "SOUTH BEND",
  "TERRE HAUTE",
  "VALPARAISO",
  "VINCENNES",
  "WABASH",
  "WEST LAFAYETTE",
  "AMANA COLONIES",
  "AMES",
  "BOONE",
  "CEDAR FALLS",
  "CEDAR RAPIDS",
  "CHARLES CITY",
  "CHEROKEE",
  "CLINTON",
  "COUNCIL BLUFFS",
  "DAVENPORT",
  "WALPI",
  "WINDOW ROCK",
  "WINSLOW",
  "YUMA",
  "ARKADELPHIA",
  "ARKANSAS POST",
  "BATESVILLE",
  "BENTON",
  "BLYTHEVILLE",
  "CAMDEN",
  "CONWAY",
  "CROSSETT",
  "EL DORADO",
  "FAYETTEVILLE",
  "FORREST CITY",
  "FORT SMITH",
  "HARRISON",
  "HELENA",
  "HOPE",
  "HOT SPRINGS",
  "JONESBORO",
  "LITTLE ROCK",
  "MAGNOLIA",
  "MORRILTON",
  "NEWPORT",
  "NORTH LITTLE ROCK",
  "OSCEOLA",
  "PINE BLUFF",
  "ROGERS",
  "SEARCY",
  "STUTTGART",
  "VAN BUREN",
  "WEST MEMPHIS",
  "ALAMEDA",
  "ALHAMBRA",
  "ANAHEIM",
  "ANTIOCH",
  "ARCADIA",
  "BAKERSFIELD",
  "BARSTOW",
  "BELMONT",
  "BERKELEY",
  "BEVERLY HILLS",
  "BREA",
  "BUENA PARK",
  "BURBANK",
  "CALEXICO",
  "CALISTOGA",
  "CARLSBAD",
  "CARMEL",
  "CHICO",
  "CHULA VISTA",
  "CLAREMONT",
  "COMPTON",
  "CONCORD",
  "CORONA",
  "CORONADO",
  "COSTA MESA",
  "CULVER CITY",
  "DALY CITY",
  "DAVIS",
  "DOWNEY",
  "EL CENTRO",
  "EL CERRITO",
  "EL MONTE",
  "ESCONDIDO",
  "EUREKA",
  "FAIRFIELD",
  "FONTANA",
  "FREMONT",
  "FRESNO",
  "FULLERTON",
  "GARDEN GROVE",
  "HAYWARD",
  "HOLLYWOOD",
  "HUNTINGTON BEACH",
  "INDIO",
  "INGLEWOOD",
  "IRVINE",
  "LA HABRA",
  "LAGUNA BEACH",
  "LANCASTER",
  "LIVERMORE",
  "LODI",
  "LOMPOC",
  "LONG BEACH",
  "LOS ANGELES",
  "MALIBU",
  "MARTINEZ",
  "MARYSVILLE",
  "MENLO PARK",
  "MERCED",
  "MODESTO",
  "MONTEREY",
  "MOUNTAIN VIEW",
  "NAPA",
  "NEEDLES",
  "NEWPORT BEACH",
  "NORWALK",
  "NOVATO",
  "OAKLAND",
  "OCEANSIDE",
  "OJAI",
  "ONTARIO",
  "ORANGE",
  "OROVILLE",
  "OXNARD",
  "PACIFIC GROVE",
  "PALM SPRINGS",
  "PALMDALE",
  "PALO ALTO",
  "PASADENA",
  "PETALUMA",
  "POMONA",
  "PORT HUENEME",
  "RANCHO CUCAMONGA",
  "RED BLUFF",
  "REDDING",
  "REDLANDS",
  "REDONDO BEACH",
  "REDWOOD CITY",
  "RIVERSIDE",
  "ROSEVILLE",
  "SACRAMENTO",
  "SALINAS",
  "SAN BERNARDINO",
  "SAN CLEMENTE",
  "SAN DIEGO",
  "SAN FERNANDO",
  "SAN FRANCISCO",
  "SAN GABRIEL",
  "SAN JOSE",
  "SAN JUAN CAPISTRANO",
  "SAN LEANDRO",
  "SAN LUIS OBISPO",
  "SAN MARINO",
  "SAN MATEO",
  "SAN PEDRO",
  "SAN RAFAEL",
  "SAN SIMEON",
  "SANTA ANA",
  "SANTA BARBARA",
  "SANTA CLARA",
  "SANTA CLARITA",
  "SANTA CRUZ",
  "SANTA MONICA",
  "SANTA ROSA",
  "SAUSALITO",
  "SIMI VALLEY",
  "SONOMA",
  "SOUTH SAN FRANCISCO",
  "STOCKTON",
  "SUNNYVALE",
  "SUSANVILLE",
  "THOUSAND OAKS",
  "TORRANCE",
  "TURLOCK",
  "UKIAH",
  "VALLEJO",
  "VENTURA",
  "VICTORVILLE",
  "VISALIA",
  "WALNUT CREEK",
  "WATTS",
  "WEST COVINA",
  "WHITTIER",
  "WOODLAND",
  "YORBA LINDA",
  "YUBA CITY",
  "ALAMOSA",
  "ASPEN",
  "AURORA",
  "BOULDER",
  "BRECKENRIDGE",
  "BRIGHTON",
  "CANON CITY",
  "CENTRAL CITY",
  "CLIMAX",
  "COLORADO SPRINGS",
  "CORTEZ",
  "CRIPPLE CREEK",
  "DENVER",
  "DURANGO",
  "ENGLEWOOD",
  "ESTES PARK",
  "FORT COLLINS",
  "FORT MORGAN",
  "GEORGETOWN",
  "GLENWOOD SPRINGS",
  "GOLDEN",
  "GRAND JUNCTION",
  "GREELEY",
  "GUNNISON",
  "LA JUNTA",
  "LEADVILLE",
  "LITTLETON",
  "LONGMONT",
  "LOVELAND",
  "MONTROSE",
  "OURAY",
  "PAGOSA SPRINGS",
  "PUEBLO",
  "SILVERTON",
  "STEAMBOAT SPRINGS",
  "STERLING",
  "TELLURIDE",
  "TRINIDAD",
  "VAIL",
  "WALSENBURG",
  "WESTMINSTER",
  "ANSONIA",
  "BERLIN",
  "BLOOMFIELD",
  "BRANFORD",
  "BRIDGEPORT",
  "BRISTOL",
  "COVENTRY",
  "DANBURY",
  "DARIEN",
  "DERBY",
  "EAST HARTFORD",
  "EAST HAVEN",
  "ENFIELD",
  "FARMINGTON",
  "GREENWICH",
  "GROTON",
  "GUILFORD",
  "HAMDEN",
  "HARTFORD",
  "LEBANON",
  "LITCHFIELD",
  "MANCHESTER",
  "MANSFIELD",
  "MERIDEN",
  "MIDDLETOWN",
  "MILFORD",
  "MYSTIC",
  "NAUGATUCK",
  "NEW BRITAIN",
  "NEW HAVEN",
  "NEW LONDON",
  "NORTH HAVEN",
  "NORWICH",
  "OLD SAYBROOK",
  "SEYMOUR",
  "SHELTON",
  "SIMSBURY",
  "SOUTHINGTON",
  "STAMFORD",
  "STONINGTON",
  "TORRINGTON",
  "WALLINGFORD",
  "WATERBURY",
  "WATERFORD",
  "WATERTOWN",
  "WEST HARTFORD",
  "WEST HAVEN",
  "WESTPORT",
  "WETHERSFIELD",
  "WILLIMANTIC",
  "WINDHAM",
  "WINDSOR LOCKS",
  "WINSTED",
  "DOVER",
  "LEWES",
  "NEWARK",
  "SMYRNA",
  "WILMINGTON",
  "APALACHICOLA",
  "BARTOW",
  "BELLE GLADE",
  "BOCA RATON",
  "BRADENTON",
  "CAPE CORAL",
  "CLEARWATER",
  "COCOA BEACH",
  "COCOA-ROCKLEDGE",
  "CORAL GABLES",
  "DAYTONA BEACH",
  "DE LAND",
  "DEERFIELD BEACH",
  "DELRAY BEACH",
  "FERNANDINA BEACH",
  "FORT LAUDERDALE",
  "FORT MYERS",
  "FORT PIERCE",
  "FORT WALTON BEACH",
  "GAINESVILLE",
  "HALLANDALE BEACH",
  "HIALEAH",
  "HOMESTEAD",
  "KEY WEST",
  "LAKE CITY",
  "LAKE WALES",
  "LAKELAND",
  "LARGO",
  "MELBOURNE",
  "MIAMI",
  "MIAMI BEACH",
  "NAPLES",
  "NEW SMYRNA BEACH",
  "OCALA",
  "ORLANDO",
  "ORMOND BEACH",
  "PALATKA",
  "PALM BAY",
  "PALM BEACH",
  "PANAMA CITY",
  "PENSACOLA",
  "POMPANO BEACH",
  "SAINT AUGUSTINE",
  "SAINT PETERSBURG",
  "SANFORD",
  "SARASOTA",
  "SEBRING",
  "TALLAHASSEE",
  "TARPON SPRINGS",
  "TITUSVILLE",
  "VENICE",
  "WEST PALM BEACH",
  "WHITE SPRINGS",
  "WINTER HAVEN",
  "WINTER PARK",
  "ALBANY",
  "AMERICUS",
  "ANDERSONVILLE",
  "ATLANTA",
  "AUGUSTA",
  "BAINBRIDGE",
  "BLAIRSVILLE",
  "BRUNSWICK",
  "CALHOUN",
  "CARROLLTON",
  "DAHLONEGA",
  "DALTON",
  "EAST POINT",
  "FITZGERALD",
  "FORT VALLEY",
  "LA GRANGE",
  "MACON",
  "MARIETTA",
  "MILLEDGEVILLE",
  "PLAINS",
  "ROME",
  "SAVANNAH",
  "TOCCOA",
  "VALDOSTA",
  "WARM SPRINGS",
  "WARNER ROBINS",
  "WASHINGTON",
  "WAYCROSS",
  "HANALEI",
  "HILO",
  "HONAUNAU",
  "HONOLULU",
  "KAHULUI",
  "KANEOHE",
  "KAPAA",
  "KAWAIHAE",
  "LAHAINA",
  "LAIE",
  "WAHIAWA",
  "WAILUKU",
  "WAIMEA",
  "BLACKFOOT",
  "BOISE",
  "BONNERS FERRY",
  "CALDWELL",
  "COEUR D?LENE",
  "IDAHO CITY",
  "IDAHO FALLS",
  "KELLOGG",
  "LEWISTON",
  "MOSCOW",
  "NAMPA",
  "POCATELLO",
  "PRIEST RIVER",
  "REXBURG",
  "SUN VALLEY",
  "TWIN FALLS",
  "ALTON",
  "ARLINGTON HEIGHTS",
  "ARTHUR",
  "BELVIDERE",
  "BROOKFIELD",
  "CAHOKIA",
  "CAIRO",
  "CALUMET CITY",
  "CANTON",
  "CARBONDALE",
  "CARLINVILLE",
  "CARTHAGE",
  "CENTRALIA",
  "CHAMPAIGN",
  "CHARLESTON",
  "CHESTER",
  "CHICAGO",
  "CHICAGO HEIGHTS",
  "CICERO",
  "COLLINSVILLE",
  "DEKALB",
  "DES PLAINES",
  "DIXON",
  "EAST MOLINE",
  "EAST SAINT LOUIS",
  "EFFINGHAM",
  "ELGIN",
  "ELMHURST",
  "EVANSTON",
  "FREEPORT",
  "GALENA",
  "GALESBURG",
  "GLEN ELLYN",
  "GLENVIEW",
  "GRANITE CITY",
  "HARRISBURG",
  "DES MOINES",
  "DUBUQUE",
  "ESTHERVILLE",
  "FORT DODGE",
  "GRINNELL",
  "INDIANOLA",
  "IOWA CITY",
  "KEOKUK",
  "MASON CITY",
  "MOUNT PLEASANT",
  "MUSCATINE",
  "NEWTON",
  "OSKALOOSA",
  "OTTUMWA",
  "SIOUX CITY",
  "WEBSTER CITY",
  "WEST DES MOINES",
  "ABILENE",
  "ARKANSAS CITY",
  "ATCHISON",
  "CHANUTE",
  "COFFEYVILLE",
  "COUNCIL GROVE",
  "DODGE CITY",
  "EMPORIA",
  "FORT SCOTT",
  "GARDEN CITY",
  "GREAT BEND",
  "HAYS",
  "HUTCHINSON",
  "INDEPENDENCE",
  "JUNCTION CITY",
  "KANSAS CITY",
  "LAWRENCE",
  "LEAVENWORTH",
  "LIBERAL",
  "MANHATTAN",
  "MCPHERSON",
  "MEDICINE LODGE",
  "OLATHE",
  "OSAWATOMIE",
  "OVERLAND PARK",
  "PITTSBURG",
  "SALINA",
  "SHAWNEE",
  "SMITH CENTER",
  "TOPEKA",
  "WICHITA",
  "ASHLAND",
  "BARBOURVILLE",
  "BARDSTOWN",
  "BEREA",
  "BOONESBOROUGH",
  "BOWLING GREEN",
  "CAMPBELLSVILLE",
  "COVINGTON",
  "ELIZABETHTOWN",
  "FRANKFORT",
  "HARLAN",
  "HARRODSBURG",
  "HAZARD",
  "HENDERSON",
  "HODGENVILLE",
  "HOPKINSVILLE",
  "LEXINGTON",
  "LOUISVILLE",
  "MAYFIELD",
  "MAYSVILLE",
  "MIDDLESBORO",
  "OWENSBORO",
  "PADUCAH",
  "PARIS",
  "ABBEVILLE",
  "ALEXANDRIA",
  "BASTROP",
  "BATON ROUGE",
  "BOGALUSA",
  "BOSSIER CITY",
  "GRETNA",
  "HOUMA",
  "LAKE CHARLES",
  "MONROE",
  "MORGAN CITY",
  "NATCHITOCHES",
  "NEW IBERIA",
  "NEW ORLEANS",
  "OPELOUSAS",
  "RUSTON",
  "SAINT MARTINVILLE",
  "SHREVEPORT",
  "THIBODAUX",
  "BANGOR",
  "BAR HARBOR",
  "BATH",
  "BELFAST",
  "BIDDEFORD",
  "BOOTHBAY HARBOR",
  "CALAIS",
  "CARIBOU",
  "CASTINE",
  "EASTPORT",
  "ELLSWORTH",
  "FORT KENT",
  "GARDINER",
  "HOULTON",
  "KENNEBUNKPORT",
  "KITTERY",
  "LUBEC",
  "MACHIAS",
  "ORONO",
  "PORTLAND",
  "PRESQUE ISLE",
  "ROCKLAND",
  "RUMFORD",
  "SACO",
  "SCARBOROUGH",
  "YORK",
  "ABERDEEN",
  "ANNAPOLIS",
  "BALTIMORE",
  "BETHESDA-CHEVY CHASE",
  "BOWIE",
  "CATONSVILLE",
  "COLLEGE PARK",
  "COLUMBIA",
  "CUMBERLAND",
  "EASTON",
  "ELKTON",
  "EMMITSBURG",
  "FREDERICK",
  "GREENBELT",
  "HAGERSTOWN",
  "HYATTSVILLE",
  "LAUREL",
  "OCEAN CITY",
  "ROCKVILLE",
  "SAINT MARYS CITY",
  "SALISBURY",
  "SILVER SPRING",
  "TAKOMA PARK",
  "TOWSON",
  "ABINGTON",
  "ADAMS",
  "AMESBURY",
  "AMHERST",
  "ANDOVER",
  "ARLINGTON",
  "ATHOL",
  "ATTLEBORO",
  "BARNSTABLE",
  "BEVERLY",
  "BOSTON",
  "BOURNE",
  "BRAINTREE",
  "BROCKTON",
  "BROOKLINE",
  "CHARLESTOWN",
  "CHELMSFORD",
  "CHELSEA",
  "CHICOPEE",
  "COHASSET",
  "DANVERS",
  "DARTMOUTH",
  "DEDHAM",
  "DENNIS",
  "DUXBURY",
  "EASTHAM",
  "EDGARTOWN",
  "EVERETT",
  "FAIRHAVEN",
  "FALL RIVER",
  "FALMOUTH",
  "FITCHBURG",
  "FRAMINGHAM",
  "GLOUCESTER",
  "GREAT BARRINGTON",
  "HARWICH",
  "HAVERHILL",
  "HINGHAM",
  "HOLYOKE",
  "HYANNIS",
  "IPSWICH",
  "LENOX",
  "LEOMINSTER",
  "LOWELL",
  "LUDLOW",
  "LYNN",
  "MALDEN",
  "MARBLEHEAD",
  "MARLBOROUGH",
  "MEDFORD",
  "MILTON",
  "PAMPA",
  "NATICK",
  "NEW BEDFORD",
  "NEWBURYPORT",
  "NORTH ADAMS",
  "NORTHAMPTON",
  "NORTON",
  "NORWOOD",
  "PEABODY",
  "PITTSFIELD",
  "PROVINCETOWN",
  "RANDOLPH",
  "REVERE",
  "SANDWICH",
  "SAUGUS",
  "SOMERVILLE",
  "SOUTH HADLEY",
  "STOCKBRIDGE",
  "STOUGHTON",
  "STURBRIDGE",
  "SUDBURY",
  "TAUNTON",
  "TEWKSBURY",
  "TRURO",
  "WEBSTER",
  "WELLESLEY",
  "WELLFLEET",
  "WEST BRIDGEWATER",
  "WEST SPRINGFIELD",
  "WESTFIELD",
  "WEYMOUTH",
  "WHITMAN",
  "WILLIAMSTOWN",
  "WOBURN",
  "WOODS HOLE",
  "WORCESTER",
  "ADRIAN",
  "ANN ARBOR",
  "BATTLE CREEK",
  "BAY CITY",
  "BENTON HARBOR",
  "BLOOMFIELD HILLS",
  "CADILLAC",
  "CHARLEVOIX",
  "CHEBOYGAN",
  "DEARBORN",
  "DETROIT",
  "EAST LANSING",
  "EASTPOINTE",
  "ECORSE",
  "ESCANABA",
  "FLINT",
  "GRAND HAVEN",
  "GRAND RAPIDS",
  "GRAYLING",
  "GROSSE POINTE",
  "HANCOCK",
  "HOLLAND",
  "HOUGHTON",
  "INTERLOCHEN",
  "IRON MOUNTAIN",
  "IRONWOOD",
  "ISHPEMING",
  "JACKSON",
  "KALAMAZOO",
  "LANSING",
  "LIVONIA",
  "LUDINGTON",
  "MACKINAW CITY",
  "MANISTEE",
  "MARQUETTE",
  "MENOMINEE",
  "MIDLAND",
  "MOUNT CLEMENS",
  "MUSKEGON",
  "NILES",
  "PETOSKEY",
  "PORT HURON",
  "ROYAL OAK",
  "SAGINAW",
  "SAINT IGNACE",
  "SAINT JOSEPH",
  "SAULT SAINTE MARIE",
  "TRAVERSE CITY",
  "TRENTON",
  "WARREN",
  "WYANDOTTE",
  "YPSILANTI",
  "ALBERT LEA",
  "AUSTIN",
  "BEMIDJI",
  "BRAINERD",
  "CROOKSTON",
  "DULUTH",
  "ELY",
  "EVELETH",
  "FARIBAULT",
  "FERGUS FALLS",
  "HASTINGS",
  "HIBBING",
  "INTERNATIONAL FALLS",
  "LITTLE FALLS",
  "MANKATO",
  "MINNEAPOLIS",
  "MOORHEAD",
  "NEW ULM",
  "NORTHFIELD",
  "OWATONNA",
  "PIPESTONE",
  "RED WING",
  "ROCHESTER",
  "SAINT CLOUD",
  "SAINT PAUL",
  "SAUK CENTRE",
  "SOUTH SAINT PAUL",
  "STILLWATER",
  "VIRGINIA",
  "WILLMAR",
  "WINONA",
  "BAY SAINT LOUIS",
  "BILOXI",
  "CLARKSDALE",
  "CORINTH",
  "GRENADA",
  "GULFPORT",
  "HATTIESBURG",
  "HOLLY SPRINGS",
  "MERIDIAN",
  "NATCHEZ",
  "OCEAN SPRINGS",
  "OXFORD",
  "PASCAGOULA",
  "PASS CHRISTIAN",
  "PHILADELPHIA",
  "PORT GIBSON",
  "STARKVILLE",
  "TUPELO",
  "VICKSBURG",
  "WEST POINT",
  "YAZOO CITY",
  "BOONVILLE",
  "BRANSON",
  "CAPE GIRARDEAU",
  "CHILLICOTHE",
  "CLAYTON",
  "EXCELSIOR SPRINGS",
  "FERGUSON",
  "FLORISSANT",
  "FULTON",
  "HANNIBAL",
  "JEFFERSON CITY",
  "JOPLIN",
  "KIRKSVILLE",
  "LAMAR",
  "MARYVILLE",
  "MEXICO",
  "MONETT",
  "NEOSHO",
  "NEW MADRID",
  "ROLLA",
  "SAINT CHARLES",
  "SAINT LOUIS",
  "SAINTE GENEVIEVE",
  "SEDALIA",
  "WARRENSBURG",
  "WEST PLAINS",
  "ANACONDA",
  "BILLINGS",
  "BOZEMAN",
  "BUTTE",
  "DILLON",
  "FORT BENTON",
  "GLENDIVE",
  "GREAT FALLS",
  "HAVRE",
  "KALISPELL",
  "LEWISTOWN",
  "LIVINGSTON",
  "MILES CITY",
  "MISSOULA",
  "VIRGINIA CITY",
  "BEATRICE",
  "BELLEVUE",
  "BOYS TOWN",
  "CHADRON",
  "GRAND ISLAND",
  "KEARNEY",
  "MCCOOK",
  "MINDEN",
  "NEBRASKA CITY",
  "NORFOLK",
  "NORTH PLATTE",
  "OMAHA",
  "PLATTSMOUTH",
  "RED CLOUD",
  "SIDNEY",
  "BOULDER CITY",
  "CARSON CITY",
  "ELKO",
  "FALLON",
  "GENOA",
  "GOLDFIELD",
  "LAS VEGAS",
  "NORTH LAS VEGAS",
  "RENO",
  "SPARKS",
  "WINNEMUCCA",
  "DERRY",
  "DURHAM",
  "EXETER",
  "FRANKLIN",
  "HANOVER",
  "HILLSBOROUGH",
  "KEENE",
  "LACONIA",
  "NASHUA",
  "PORTSMOUTH",
  "SOMERSWORTH",
  "ASBURY PARK",
  "ATLANTIC CITY",
  "BAYONNE",
  "BORDENTOWN",
  "BOUND BROOK",
  "BRIDGETON",
  "CAPE MAY",
  "CRANFORD",
  "EAST ORANGE",
  "EDISON",
  "ELIZABETH",
  "FORT LEE",
  "GLASSBORO",
  "HACKENSACK",
  "HADDONFIELD",
  "HOBOKEN",
  "IRVINGTON",
  "JERSEY CITY",
  "LAKEHURST",
  "LAKEWOOD",
  "LONG BRANCH",
  "MILLBURN",
  "MILLVILLE",
  "MONTCLAIR",
  "MORRISTOWN",
  "MOUNT HOLLY",
  "NEW BRUNSWICK",
  "NEW MILFORD",
  "PARSIPPANY?ROY HILLS",
  "PASSAIC",
  "PATERSON",
  "PERTH AMBOY",
  "PLAINFIELD",
  "PRINCETON",
  "RIDGEWOOD",
  "ROSELLE",
  "RUTHERFORD",
  "SOUTH ORANGE VILLAGE",
  "TOTOWA",
  "UNION",
  "UNION CITY",
  "VINELAND",
  "WAYNE",
  "WEEHAWKEN",
  "WEST NEW YORK",
  "WEST ORANGE",
  "WILLINGBORO",
  "WOODBRIDGE",
  "ACOMA",
  "ALAMOGORDO",
  "ALBUQUERQUE",
  "ARTESIA",
  "BELEN",
  "CLOVIS",
  "DEMING",
  "GALLUP",
  "GRANTS",
  "HOBBS",
  "LAS CRUCES",
  "LOS ALAMOS",
  "LOVINGTON",
  "PORTALES",
  "RATON",
  "ROSWELL",
  "SANTA FE",
  "SHIPROCK",
  "SILVER CITY",
  "SOCORRO",
  "TAOS",
  "TRUTH OR CONSEQUENCES",
  "TUCUMCARI",
  "AMSTERDAM",
  "BABYLON",
  "BATAVIA",
  "BEACON",
  "BINGHAMTON",
  "BRONX",
  "BROOKLYN",
  "BUFFALO",
  "CHAUTAUQUA",
  "CHEEKTOWAGA",
  "COHOES",
  "CONEY ISLAND",
  "COOPERSTOWN",
  "CORNING",
  "CORTLAND",
  "CROWN POINT",
  "DUNKIRK",
  "EAST AURORA",
  "EAST HAMPTON",
  "EASTCHESTER",
  "ELMIRA",
  "FLUSHING",
  "FOREST HILLS",
  "FREDONIA",
  "GLENS FALLS",
  "GLOVERSVILLE",
  "GREAT NECK",
  "HAMMONDSPORT",
  "HARLEM",
  "HEMPSTEAD",
  "HERKIMER",
  "HYDE PARK",
  "ILION",
  "ITHACA",
  "JAMESTOWN",
  "JOHNSTOWN",
  "LACKAWANNA",
  "LAKE PLACID",
  "LEVITTOWN",
  "LOCKPORT",
  "MAMARONECK",
  "MASSENA",
  "MINEOLA",
  "NEW PALTZ",
  "NEW ROCHELLE",
  "NEW WINDSOR",
  "NEW YORK CITY",
  "NEWBURGH",
  "NORTH HEMPSTEAD",
  "NYACK",
  "OGDENSBURG",
  "OLEAN",
  "ONEIDA",
  "ONEONTA",
  "OSSINING",
  "OSWEGO",
  "OYSTER BAY",
  "PALMYRA",
  "PEEKSKILL",
  "PLATTSBURGH",
  "PORT WASHINGTON",
  "POTSDAM",
  "POUGHKEEPSIE",
  "QUEENS",
  "RENSSELAER",
  "ROTTERDAM",
  "RYE",
  "SAG HARBOR",
  "SARANAC LAKE",
  "TAMPA",
  "SCHENECTADY",
  "SENECA FALLS",
  "SOUTHAMPTON",
  "STATEN ISLAND",
  "STONY BROOK",
  "STONY POINT",
  "SYRACUSE",
  "TARRYTOWN",
  "TICONDEROGA",
  "TONAWANDA",
  "UTICA",
  "WATERVLIET",
  "WATKINS GLEN",
  "WEST SENECA",
  "WHITE PLAINS",
  "YONKERS",
  "ASHEBORO",
  "ASHEVILLE",
  "BEAUFORT",
  "CHAPEL HILL",
  "CHARLOTTE",
  "EDENTON",
  "ELIZABETH CITY",
  "GASTONIA",
  "GOLDSBORO",
  "GREENSBORO",
  "HALIFAX",
  "HICKORY",
  "HIGH POINT",
  "KINSTON",
  "KITTY HAWK",
  "LUMBERTON",
  "MOREHEAD CITY",
  "MORGANTON",
  "NAGS HEAD",
  "NEW BERN",
  "PINEHURST",
  "RALEIGH",
  "ROCKY MOUNT",
  "SHELBY",
  "WILSON",
  "WINSTON-SALEM",
  "BISMARCK",
  "DEVILS LAKE",
  "DICKINSON",
  "FARGO",
  "MANDAN",
  "MINOT",
  "RUGBY",
  "VALLEY CITY",
  "WAHPETON",
  "WILLISTON",
  "AKRON",
  "ALLIANCE",
  "ASHTABULA",
  "BARBERTON",
  "BELLEFONTAINE",
  "CINCINNATI",
  "CLEVELAND",
  "CLEVELAND HEIGHTS",
  "CONNEAUT",
  "CUYAHOGA FALLS",
  "DAYTON",
  "DEFIANCE",
  "DELAWARE",
  "EAST CLEVELAND",
  "EAST LIVERPOOL",
  "ELYRIA",
  "EUCLID",
  "FINDLAY",
  "GALLIPOLIS",
  "KENT",
  "KETTERING",
  "LIMA",
  "LORAIN",
  "MARTINS FERRY",
  "MASSILLON",
  "MENTOR",
  "MILAN",
  "NEW PHILADELPHIA",
  "NORTH COLLEGE HILL",
  "OBERLIN",
  "PAINESVILLE",
  "PARMA",
  "PIQUA",
  "PUT-IN-BAY",
  "SANDUSKY",
  "SHAKER HEIGHTS",
  "STEUBENVILLE",
  "TIFFIN",
  "TOLEDO",
  "WOOSTER",
  "WORTHINGTON",
  "XENIA",
  "YELLOW SPRINGS",
  "YOUNGSTOWN",
  "ZANESVILLE",
  "ADA",
  "ALTUS",
  "ALVA",
  "ANADARKO",
  "ARDMORE",
  "BARTLESVILLE",
  "BETHANY",
  "CHICKASHA",
  "CLAREMORE",
  "CUSHING",
  "DURANT",
  "EDMOND",
  "EL RENO",
  "ELK CITY",
  "ENID",
  "GUTHRIE",
  "GUYMON",
  "HOLDENVILLE",
  "HUGO",
  "LAWTON",
  "MCALESTER",
  "MIDWEST CITY",
  "MOORE",
  "MUSKOGEE",
  "NORMAN",
  "OKLAHOMA CITY",
  "OKMULGEE",
  "PAULS VALLEY",
  "PAWHUSKA",
  "PERRY",
  "PONCA CITY",
  "PRYOR",
  "SALLISAW",
  "SAND SPRINGS",
  "SAPULPA",
  "SEMINOLE",
  "TAHLEQUAH",
  "THE VILLAGE",
  "TULSA",
  "VINITA",
  "WEWOKA",
  "WOODWARD",
  "ASTORIA",
  "BAKER CITY",
  "BEAVERTON",
  "BEND",
  "BROOKINGS",
  "BURNS",
  "COOS BAY",
  "CORVALLIS",
  "EUGENE",
  "GRANTS PASS",
  "HILLSBORO",
  "HOOD RIVER",
  "JOHN DAY",
  "KLAMATH FALLS",
  "LA GRANDE",
  "LAKE OSWEGO",
  "LAKEVIEW",
  "MCMINNVILLE",
  "NEWBERG",
  "OREGON CITY",
  "PENDLETON",
  "PORT ORFORD",
  "PRINEVILLE",
  "REDMOND",
  "REEDSPORT",
  "ROSEBURG",
  "SEASIDE",
  "THE DALLES",
  "TILLAMOOK",
  "ALIQUIPPA",
  "ALLENTOWN",
  "ALTOONA",
  "AMBRIDGE",
  "BETHLEHEM",
  "BLOOMSBURG",
  "BRADFORD",
  "CARLISLE",
  "CHAMBERSBURG",
  "ERIE",
  "GERMANTOWN",
  "GETTYSBURG",
  "GREENSBURG",
  "HARMONY",
  "HAZLETON",
  "HERSHEY",
  "HONESDALE",
  "INDIANA",
  "JEANNETTE",
  "JIM THORPE",
  "LOCK HAVEN",
  "LOWER SOUTHAMPTON",
  "MCKEESPORT",
  "MEADVILLE",
  "MONROEVILLE",
  "NANTICOKE",
  "NEW HOPE",
  "NEW KENSINGTON",
  "NORRISTOWN",
  "OIL CITY",
  "PHOENIXVILLE",
  "PITTSBURGH",
  "POTTSTOWN",
  "POTTSVILLE",
  "READING",
  "SCRANTON",
  "SHAMOKIN",
  "SHARON",
  "STATE COLLEGE",
  "STROUDSBURG",
  "SUNBURY",
  "SWARTHMORE",
  "TAMAQUA",
  "UNIONTOWN",
  "WEST CHESTER",
  "WILKES-BARRE",
  "WILLIAMSPORT",
  "BARRINGTON",
  "CENTRAL FALLS",
  "CRANSTON",
  "EAST GREENWICH",
  "EAST PROVIDENCE",
  "NARRAGANSETT",
  "NORTH KINGSTOWN",
  "PAWTUCKET",
  "SOUTH KINGSTOWN",
  "TIVERTON",
  "WARWICK",
  "WESTERLY",
  "WICKFORD",
  "WOONSOCKET",
  "AIKEN",
  "DARLINGTON",
  "GAFFNEY",
  "HARTSVILLE",
  "MYRTLE BEACH",
  "ORANGEBURG",
  "ROCK HILL",
  "SPARTANBURG",
  "SUMTER",
  "BELLE FOURCHE",
  "CUSTER",
  "DE SMET",
  "DEADWOOD",
  "HURON",
  "LEAD",
  "MILBANK",
  "MITCHELL",
  "MOBRIDGE",
  "PIERRE",
  "RAPID CITY",
  "SIOUX FALLS",
  "SPEARFISH",
  "STURGIS",
  "VERMILLION",
  "YANKTON",
  "ALCOA",
  "CHATTANOOGA",
  "CLARKSVILLE",
  "COOKEVILLE",
  "ELIZABETHTON",
  "GALLATIN",
  "GATLINBURG",
  "GREENEVILLE",
  "JOHNSON CITY",
  "JONESBOROUGH",
  "KINGSPORT",
  "KNOXVILLE",
  "MEMPHIS",
  "MURFREESBORO",
  "NORRIS",
  "OAK RIDGE",
  "TULLAHOMA",
  "ALPINE",
  "AMARILLO",
  "BAYTOWN",
  "BIG SPRING",
  "BORGER",
  "BROWNSVILLE",
  "BRYAN",
  "CANYON",
  "CLEBURNE",
  "COLLEGE STATION",
  "CORPUS CHRISTI",
  "CRYSTAL CITY",
  "DALLAS",
  "DEL RIO",
  "DENISON",
  "DENTON",
  "EAGLE PASS",
  "EDINBURG",
  "EL PASO",
  "FORT WORTH",
  "GALVESTON",
  "GARLAND",
  "GOLIAD",
  "HARLINGEN",
  "HOUSTON",
  "IRVING",
  "KILGORE",
  "KILLEEN",
  "KINGSVILLE",
  "LAREDO",
  "LONGVIEW",
  "LUBBOCK",
  "LUFKIN",
  "MARSHALL",
  "MCALLEN",
  "MCKINNEY",
  "MESQUITE",
  "MISSION",
  "NACOGDOCHES",
  "NEW BRAUNFELS",
  "ODESSA",
  "MILLTOWN",
  "MOIRA",
  "PECOS",
  "PHARR",
  "PLAINVIEW",
  "PLANO",
  "PORT ARTHUR",
  "PORT LAVACA",
  "RICHARDSON",
  "SAN ANGELO",
  "SAN ANTONIO",
  "SAN FELIPE",
  "SAN MARCOS",
  "SHERMAN",
  "SWEETWATER",
  "TEMPLE",
  "TEXARKANA",
  "TEXAS CITY",
  "TYLER",
  "UVALDE",
  "WACO",
  "WEATHERFORD",
  "WICHITA FALLS",
  "YSLETA",
  "ALTA",
  "AMERICAN FORK",
  "BOUNTIFUL",
  "BRIGHAM CITY",
  "CEDAR CITY",
  "CLEARFIELD",
  "FILLMORE",
  "GREEN RIVER",
  "HEBER CITY",
  "KANAB",
  "LAYTON",
  "LEHI",
  "LOGAN",
  "MANTI",
  "MOAB",
  "MONTICELLO",
  "MURRAY",
  "NEPHI",
  "OGDEN",
  "ORDERVILLE",
  "OREM",
  "PANGUITCH",
  "PARK CITY",
  "PAYSON",
  "PRICE",
  "PROVO",
  "SAINT GEORGE",
  "SALT LAKE CITY",
  "SPANISH FORK",
  "SPRINGVILLE",
  "TOOELE",
  "VERNAL",
  "BARRE",
  "BELLOWS FALLS",
  "BENNINGTON",
  "BRATTLEBORO",
  "ESSEX",
  "MIDDLEBURY",
  "MONTPELIER",
  "RUTLAND",
  "SAINT ALBANS",
  "SAINT JOHNSBURY",
  "WINOOSKI",
  "ABINGDON",
  "CHARLOTTESVILLE",
  "CHESAPEAKE",
  "FAIRFAX",
  "FALLS CHURCH",
  "FREDERICKSBURG",
  "HAMPTON",
  "HOPEWELL",
  "LYNCHBURG",
  "MANASSAS",
  "MARTINSVILLE",
  "NEW MARKET",
  "NEWPORT NEWS",
  "RESTON",
  "ROANOKE",
  "STAUNTON",
  "SUFFOLK",
  "VIRGINIA BEACH",
  "WAYNESBORO",
  "WILLIAMSBURG",
  "WINCHESTER",
  "ANACORTES",
  "BELLINGHAM",
  "BREMERTON",
  "COULEE DAM",
  "COUPEVILLE",
  "ELLENSBURG",
  "EPHRATA",
  "HOQUIAM",
  "KELSO",
  "KENNEWICK",
  "MOSES LAKE",
  "OAK HARBOR",
  "OLYMPIA",
  "PASCO",
  "POINT ROBERTS",
  "PORT ANGELES",
  "PULLMAN",
  "PUYALLUP",
  "RENTON",
  "RICHLAND",
  "SEATTLE",
  "SPOKANE",
  "TACOMA",
  "WALLA WALLA",
  "WENATCHEE",
  "YAKIMA",
  "BECKLEY",
  "BLUEFIELD",
  "BUCKHANNON",
  "CHARLES TOWN",
  "CLARKSBURG",
  "ELKINS",
  "FAIRMONT",
  "GRAFTON",
  "HARPERS FERRY",
  "HINTON",
  "KEYSER",
  "LEWISBURG",
  "MARTINSBURG",
  "MORGANTOWN",
  "MOUNDSVILLE",
  "NEW MARTINSVILLE",
  "PARKERSBURG",
  "PHILIPPI",
  "POINT PLEASANT",
  "ROMNEY",
  "SHEPHERDSTOWN",
  "SOUTH CHARLESTON",
  "SUMMERSVILLE",
  "WEIRTON",
  "WELCH",
  "WELLSBURG",
  "WESTON",
  "WHEELING",
  "WHITE SULPHUR SPRINGS",
  "WILLIAMSON",
  "APPLETON",
  "BARABOO",
  "BELOIT",
  "EAU CLAIRE",
  "FOND DU LAC",
  "GREEN BAY",
  "JANESVILLE",
  "KENOSHA",
  "LA CROSSE",
  "LAKE GENEVA",
  "MANITOWOC",
  "MARINETTE",
  "MENASHA",
  "MILWAUKEE",
  "NEENAH",
  "NEW GLARUS",
  "OCONTO",
  "OSHKOSH",
  "PESHTIGO",
  "PORTAGE",
  "PRAIRIE DU CHIEN",
  "RACINE",
  "RHINELANDER",
  "RIPON",
  "SHEBOYGAN",
  "SPRING GREEN",
  "STEVENS POINT",
  "STURGEON BAY",
  "SUPERIOR",
  "WAUKESHA",
  "WAUSAU",
  "WAUWATOSA",
  "WEST ALLIS",
  "WEST BEND",
  "WISCONSIN DELLS",
  "CASPER",
  "CHEYENNE",
  "CODY",
  "GILLETTE",
  "LANDER",
  "LARAMIE",
  "NEWCASTLE",
  "POWELL",
  "RAWLINS",
  "RIVERTON",
  "ROCK SPRINGS",
  "SHERIDAN",
  "TEN SLEEP",
  "THERMOPOLIS",
  "WORLAND",
  "HELENSBURGH",
  "GALASHIELS",
  "PERTH",
  "INVERNESS",
  "AYR",
  "KILMARNOCK",
  "COATBRIDGE",
  "GREENOCK",
  "GLENROTHES",
  "STIRLING",
  "FALKIRK",
  "DUMFRIES",
  "MOTHERWELL",
  "RUTHERGLEN",
  "WISHAW",
  "CAMBUSLANG",
  "BEARSDEN",
  "NEWTON MEARNS",
  "CLYDEBANK",
  "ARBROATH",
  "BISHOPBRIGGS",
  "BATHGATE",
  "RENFREW",
  "KIRKINTILLOCH",
  "MUSSELBURGH",
  "DUMBARTON",
  "BELLSHILL",
  "PETERHEAD",
  "BARRHEAD",
  "ST ANDREWS",
  "BONNYRIGG",
  "BLANTYRE",
  "GRANGEMOUTH",
  "KILWINNING",
  "PENICUIK",
  "JOHNSTONE",
  "VIEWPARK",
  "ERSKINE",
  "BROXBURN",
  "GREATER GLASGOW",
  "BOWLING",
  "BUSBY",
  "CLARKSTON",
  "DUNTOCHER AND HARDGATE",
  "ELDERSLIE",
  "FAIFLEY",
  "GIFFNOCK",
  "KILBARCHAN",
  "LINWOOD",
  "MILNGAVIE",
  "NETHERLEE",
  "OLD KILPATRICK",
  "STEPPS AND THORNLIEBANK",
  "WEST DUNBARTONSHIRE",
  "EAST DUNBARTONSHIRE",
  "RENFREWSHIRE",
  "EAST RENFREWSHIRE",
  "NORTH LANARKSHIRE",
  "SOUTH LANARKSHIRE",
  "MUSSELBURGH AND WALLYFORD",
  "COVE BAY AND DYCE",
  "MONIFIETH AND INVERGOWRIE",
  "ANGUS AND PERTH AND KINROSS",
  "MOTHERWELL AND WISHAW",
  "CARFIN",
  "HOLYTOWN",
  "NEW STEVENSTON",
  "NEWARTHILL",
  "NEWMAINS, VIEWPARK AND WISHAW",
  "CARRON",
  "CARRONSHORE",
  "LARBERT",
  "LAURIESTON",
  "MADDISTON",
  "POLMONT",
  "REDDINGMUIRHEAD",
  "STENHOUSEMUIR",
  "WALLACESTONE AND WESTQUARTER",
  "COATBRIDGE AND AIRDRIE",
  "BARGEDDIE",
  "CHAPELHALL AND COATBRIDGE",
  "BOTHWELL",
  "HAMILTON AND UDDINGSTON",
  "INVERKEITHING AND ROSYTH",
  "GREENOCK AND PORT GLASGOW",
  "SARATOGA SPRINGS",
  "SCARSDALE",
  "SMITHTON AND WESTHILL",
  "DALKEITH",
  "GOREBRIDGE",
  "MAYFIELD AND NEWTONGRANGE",
  "CROOKEDHOLM AND HURLFORD",
  "BRIDGE OF ALLAN AND STIRLING",
  "LESLIE",
  "MARKINCH AND THORNTON",
  "THREE TOWNS",
  "METHIL",
  "LEVEN AND BUCKHAVEN",
  "BONNYBRIDGE",
  "VALE OF LEVEN",
  "ALLOA",
  "COWDENBEATH",
  "LARKHALL",
  "MULLAGHBOY",
  "MULLAGHBRACK",
  "MULLAGHGLASS",
  "MALLUSK",
  "NEWBUILDINGS",
  "NEWRY",
  "NEWTOWN CROMMELIN",
  "NEWTOWNABBEY",
  "NEWTOWNARDS",
  "NEWTOWNBUTLER",
  "NEWTOWNCLOGHOGE",
  "NEWTOWNHAMILTON",
  "NEWTOWNSTEWART",
  "NIXON'S CORNER",
  "NEWMILLS",
  "OMAGH",
  "PARK",
  "PARKGATE",
  "PLUMBRIDGE",
  "POMEROY",
  "PORTADOWN",
  "PORTAFERRY",
  "PORTAVOGIE",
  "PORTBALLINTRAE",
  "PORTBRADDON",
  "PORTGLENONE",
  "PORTRUSH",
  "PORTSTEWART",
  "POYNTZPASS",
  "RANDALSTOWN",
  "RASHARKIN",
  "RATHFRILAND",
  "RAVERNET",
  "RICHHILL",
  "RINGSEND",
  "ROCK",
  "ROSSLEA",
  "ROSTREVOR",
  "ROUGHFORT",
  "ROUSKY",
  "SAINTFIELD",
  "SANDHOLES",
  "SCARVA",
  "SCOTCH STREET",
  "SEAFORDE",
  "SESKINORE",
  "SHANMAGHERY",
  "SHANVEY",
  "SHEEPTOWN",
  "SHRIGLEY",
  "SILVERBRIDGE",
  "SION MILLS",
  "SIXMILECROSS",
  "SKEA",
  "SPA",
  "SPAMOUNT",
  "STEWARTSTOWN",
  "STONEYFORD",
  "STRABANE",
  "STRAID",
  "STRAIDARRAN",
  "STRANGFORD",
  "STRANOCUM",
  "STRATHFOYLE",
  "STRAW",
  "SWATRAGH",
  "TAMLAGHT",
  "TAMNAMORE",
  "TANDRAGEE",
  "TARTARAGHAN",
  "TEEMORE",
  "TEMPLEPATRICK",
  "TEMPO",
  "THE BIRCHES",
  "TOBERMORE",
  "TOOME",
  "TRILLICK",
  "TRORY",
  "TULLYHOGUE",
  "TULLYHOMMON",
  "TULLYLISH",
  "TULLYNACROSS",
  "TULLYWIGGAN",
  "TYNAN",
  "UPPER BALLINDERRY",
  "UPPERLANDS",
  "VICTORIA BRIDGE",
  "WARINGSFORD",
  "WARINGSTOWN",
  "WARRENPOINT",
  "WASHING BAY",
  "MONEA",
  "MONEYGLASS",
  "MONEYMORE",
  "MONEYNEANY",
  "MONEYREAGH",
  "MONEYSLANE",
  "MONTEITH",
  "MOORTOWN",
  "MOSS-SIDE",
  "MOUNTFIELD",
  "MOUNTJOY",
  "MOUNTHILL",
  "MOUNTNORRIS",
  "MOY",
  "MOYGASHEL",
  "MULLAGHBAWN",
  "CUSHENDUN",
  "DARKLEY",
  "DERRY DERRYCRIN",
  "DERRYGONNELLY",
  "DERRYHALE",
  "DERRYKEIGHAN",
  "DERRYLIN",
  "DERRYMACASH",
  "DERRYMORE",
  "DERRYNAFLAW",
  "DERRYNOOSE",
  "DERRYTRASNA",
  "DERRYVORE",
  "DERVOCK",
  "DESERTMARTIN",
  "DOAGH",
  "DOLLINGSTOWN",
  "DONAGH",
  "DONAGHADEE",
  "DONAGHCLONEY",
  "DONAGHEY",
  "DONAGHMORE",
  "DONEGORE",
  "DOOISH",
  "DORSEY",
  "DOUGLAS BRIDGE",
  "DOWNHILL",
  "DOWNPATRICK",
  "DRAPERSTOWN",
  "DRINNS BAY",
  "DROMARA",
  "DROMINTEE",
  "DROMORE",
  "DRUMANESS",
  "DRUMBEG",
  "DRUMBO",
  "DRUMLAGHY",
  "DRUMLOUGH",
  "DRUMMULLAN",
  "DRUMNACANVY",
  "DRUMNAKILLY",
  "DRUMQUIN",
  "DRUMRAIGHLAND",
  "DRUMSURN",
  "DUNADRY",
  "DUNDONALD",
  "DUNDROD",
  "DUNDRUM",
  "DUNGANNON",
  "DUNGIVEN",
  "DUNLOY",
  "DUNNAMANAGH",
  "DUNMURRY",
  "DUNNAMORE",
  "DUNNAVAL",
  "DUNSEVERICK",
  "EDENAVEYS",
  "EDENDERRY",
  "EDERNEY",
  "EGLINTON",
  "EGLISH",
  "ENNISKILLEN",
  "ERGANAGH",
  "ESKRA",
  "FEENY",
  "FINTONA",
  "FINVOY",
  "FIVEMILETOWN",
  "FLORENCECOURT",
  "FOREGLEN",
  "FORKILL",
  "GALBALLY",
  "GAMBLESTOWN",
  "GARRISON",
  "GARVAGH",
  "GARVAGHEY",
  "GARVETAGH",
  "GAWLEY'S GATE",
  "GIBSON'S HILL",
  "GILFORD",
  "GILLYGOOLY",
  "GLACK",
  "GLEBE",
  "GLENARM",
  "GLENAVY",
  "GLENGORMLEY",
  "GLENMORNAN",
  "GLENOE",
  "GLENONE",
  "GLYNN",
  "GORTACLARE",
  "GORTIN",
  "GORTNAHEY",
  "WATERFOOT",
  "WHITECROSS",
  "WHITEHEAD",
  "WHITEROCK",
  "WHITEHOUSE",
  "GLASGOW",
  "EDINBURGH",
  "DUNDEE",
  "PAISLEY",
  "EAST KILBRIDE",
  "DUNFERMLINE",
  "CUMBERNAULD",
  "KIRKCALDY",
  "HOLYWOOD",
  "INISHRUSH",
  "IRVINESTOWN",
  "ISLANDMAGEE",
  "JERRETTSPASS",
  "JORDANSTOWN",
  "KATESBRIDGE",
  "KEADY",
  "KELLS-CONNOR",
  "KELLSWATER",
  "KESH",
  "KESHBRIDGE",
  "KILCOO",
  "KILDRESS",
  "KILKEEL",
  "KILLADEAS",
  "KILLALOO",
  "KILLAY",
  "KILLEAD",
  "KILLEN",
  "KILLETER",
  "KILLINCHY",
  "KILLOUGH",
  "KILLOWEN",
  "KILLYLEA",
  "KILLYLEAGH",
  "KILLYMAN",
  "KILLYWOOL",
  "KILMORE",
  "KILREA",
  "KILSKEERY",
  "KINALLEN",
  "KINAWLEY",
  "KIRCUBBIN",
  "KNOCKCLOGHRIM",
  "KNOCKMOYLE",
  "KNOCKNACARRY",
  "LACK",
  "LAMBEG",
  "LANDAHAUSSY",
  "LARGY",
  "LARNE",
  "LAURELVALE",
  "LAWRENCETOWN",
  "LETTERBREEN",
  "LETTERSHENDONEY",
  "LIMAVADY",
  "LISBELLAW",
  "LISLEA",
  "LISNADILL",
  "LISNARICK",
  "LISNASKEA",
  "LISTOODER",
  "LOUGHBRICKLAND",
  "LOUGHGALL",
  "LOUGHGILLY",
  "LOUGHGUILE",
  "LOUGHINISLAND",
  "LOUGHMACRORY",
  "LOUP",
  "LOWER BALLINDERRY",
  "LURGAN",
  "LURGANARE",
  "LURGANURE",
  "LURGANVILLE",
  "LEITRIM",
  "MACKEN",
  "MACOSQUIN",
  "MADDEN",
  "MAGHABERRY",
  "MAGHERA",
  "MAGHERACONLUCE",
  "MAGHERAFELT",
  "MAGHERALIN",
  "MAGHERAMASON",
  "MAGHERAMORNE",
  "MAGHERAVEELY",
  "MAGHERY",
  "MAGUIRESBRIDGE",
  "MARKETHILL",
  "MARTINSTOWN",
  "MAYDOWN",
  "MAYOBRIDGE",
  "MAZETOWN",
  "MEIGH",
  "MILLBANK",
  "MILL BAY",
  "MILLISLE",
  "CORBET",
  "CORKEY",
  "CORRINSHEGO",
  "CRAIGAROGAN",
  "CRAIGAVON",
  "CRANAGH",
  "CRAWFORDSBURN",
  "CREAGH",
  "CREGGAN",
  "CROSSGAR",
  "CROSSMAGLEN",
  "CULLAVILLE",
  "CULLYBACKEY",
  "CULLYHANNA",
  "CULMORE",
  "CULNADY",
  "CURRAN",
  "CUSHENDALL",
  "MAESGLAS MISKIN",
  "NARBERTH",
  "NEATH",
  "NEFYN",
  "NEWBRIDGE",
  "NEWCASTLE EMLYN",
  "NEW QUAY",
  "NEWTOWN",
  "NEYLAND",
  "OLD COLWYN",
  "OVERTON-ON-DEE",
  "PEMBROKE DOCK",
  "PENARTH",
  "PENCOED",
  "PENMAENMAWR",
  "PENRHYN BAY",
  "PONTARDAWE",
  "PONTARDDULAIS",
  "PONTYCLUN",
  "PONTYPOOL",
  "PONTYPRIDD",
  "PORT TALBOT",
  "PORTH",
  "PORTHCAWL",
  "PORTHMADOG",
  "PRESTATYN",
  "PRESTEIGNE",
  "PWLLHELI",
  "QUEENSFERRY",
  "RHAYADER",
  "RHUDDLAN",
  "RHYL",
  "RHYMNEY",
  "RISCA",
  "RUTHIN",
  "ST CLEARS",
  "ST DAVID'S",
  "SENGHENYDD",
  "SALTNEY",
  "SHOTTON",
  "SWANSEA",
  "TALGARTH",
  "TEMPLETON",
  "TENBY",
  "TONYPANDY",
  "TREDEGAR",
  "TREGARON",
  "TREHARRIS",
  "TYWYN",
  "USK",
  "WELSHPOOL",
  "WHITLAND",
  "WREXHAM",
  "YSTRADGYNLAIS",
  "YSTRAD MYNACH",
  "YNYSDDU",
  "AGHACOMMON",
  "AGHADOWEY",
  "AGHADRUMSEE",
  "AGHAGALLON",
  "AGHALEE",
  "AHOGHILL",
  "ALDERGROVE",
  "ALTAMUSKIN",
  "ALTISHANE",
  "ALTMORE",
  "ANNACLONE",
  "ANNAGHMORE",
  "ANNAHILT",
  "ANNAHUGH",
  "ANNALONG",
  "ANNSBOROUGH",
  "ANTRIM",
  "ARDBOE",
  "ARDGARVAN",
  "ARDGLASS",
  "ARDSTRAW",
  "ARMAGH",
  "ARMOY",
  "ARNEY",
  "ARTICLAVE",
  "ARTIGARVAN",
  "ARTIKELLY",
  "ATTICALL",
  "AUGHAFATTEN",
  "AUGHER",
  "AUGHNACLOY",
  "BALLELA",
  "BALLERIN",
  "BALLINAMALLARD",
  "BALLINTOY",
  "BALLOO",
  "BALLYBOGY",
  "BALLYCARRY",
  "BALLYCASSIDY",
  "BALLYCASTLE",
  "BALLYCLARE",
  "BALLYEASTON",
  "BALLYGALLEY",
  "BALLYGAWLEY",
  "BALLYGOWAN",
  "BALLYHALBERT",
  "BALLYHORNAN",
  "BALLYKELLY",
  "BALLYKINLER",
  "BALLYLESSON",
  "BALLYLINNEY",
  "BALLYMACMAINE",
  "ABERAERON",
  "ABERAVON",
  "ABERBARGOED",
  "ABERCARN",
  "ABERDARE",
  "ABERGAVENNY",
  "ABERGELE",
  "ABERPORTH",
  "ABERTILLERY",
  "ABERYSTWYTH",
  "AFONWEN",
  "AMLWCH",
  "AMMANFORD",
  "ARGOED",
  "BAGILLT",
  "BALA",
  "BARGOED",
  "BARMOUTH",
  "BARRY",
  "BEAUMARIS",
  "BEDWAS",
  "BENLLECH",
  "BETHESDA",
  "BLAENAU FFESTINIOG",
  "BLAENAVON",
  "BLACKWOOD",
  "BLAINA",
  "BRECON",
  "BRIDGEND",
  "BRITON FERRY",
  "BRYNMAWR",
  "BUCKLEY",
  "BUILTH WELLS",
  "BURRY PORT",
  "CAERLEON",
  "CAERNARFON",
  "CAERPHILLY",
  "CAERWYS",
  "CALDICOT",
  "CARDIFF",
  "CARDIGAN",
  "CARMARTHEN",
  "CHEPSTOW",
  "CHIRK",
  "COLWYN BAY",
  "CONNAH'S QUAY",
  "CONWY",
  "CORWEN",
  "COWBRIDGE",
  "CRICCIETH",
  "CRICKHOWELL",
  "CRUMLIN",
  "CWMAMMAN",
  "CWMBRAN",
  "DENBIGH",
  "DOLGELLAU",
  "EBBW VALE",
  "EWLOE",
  "FERNDALE",
  "FFESTINIOG",
  "FISHGUARD",
  "GELLIGAER",
  "GLYNNEATH",
  "GOODWICK",
  "GORSEINON",
  "GRESFORD",
  "GOWERTON",
  "HARLECH",
  "HAVERFORDWEST",
  "HAY-ON-WYE",
  "HOLT",
  "HOLYHEAD",
  "HOLYWELL",
  "KIDWELLY",
  "KNIGHTON",
  "LAMPETER",
  "LAUGHARNE",
  "LLANBERIS",
  "LLANDEILO",
  "LLANDOVERY",
  "LLANDRINDOD WELLS",
  "LLANDUDNO",
  "LLANDUDNO JUNCTION",
  "LLANDDULAS",
  "LLANDYSUL",
  "LLANELLI",
  "LLANFAIR CAEREINION",
  "LLANFAIRFECHAN",
  "LLANFYLLIN",
  "LLANGEFNI",
  "LLANGOLLEN",
  "LLANIDLOES",
  "LLANRWST",
  "LLANTRISANT",
  "LLANTWIT MAJOR",
  "LLANWRTYD WELLS",
  "LLANYBYDDER",
  "LOUGHOR",
  "MACHYNLLETH",
  "MAESTEG",
  "MENAI BRIDGE",
  "MERTHYR TYDFIL",
  "MILFORD HAVEN",
  "MOLD",
  "MOUNTAIN ASH",
  "TRING",
  "TROWBRIDGE",
  "TUNBRIDGE WELLS",
  "TWICKENHAM",
  "UCKFIELD",
  "ULVERSTON",
  "UPPINGHAM",
  "UPTON-UPON-SEVERN",
  "UTTOXETER",
  "UXBRIDGE",
  "VENTNOR",
  "VERWOOD",
  "WADEBRIDGE",
  "WADHURST",
  "WAKEFIELD",
  "WALLASEY",
  "WALMER",
  "WALSALL",
  "WALTHAM ABBEY",
  "WALTHAM CROSS",
  "WALTHAMSTOW",
  "WALTON-ON-THAMES",
  "WALTON-ON-THE-NAZE",
  "WANDSWORTH",
  "WANTAGE",
  "WARE",
  "WAREHAM",
  "WARMINSTER",
  "WATCHET",
  "WATFORD",
  "WATH-UPON-DEARNE",
  "WATTON",
  "WEDNESBURY",
  "WELLINGBOROUGH",
  "WELLINGTON",
  "WELLS",
  "WELLS-NEXT-THE-SEA",
  "WELWYN GARDEN CITY",
  "WEM",
  "WENDOVER",
  "WEST BROMWICH",
  "WESTBURY",
  "WESTERHAM",
  "WEST HAM",
  "WESTHOUGHTON",
  "WEST KIRBY",
  "WEST MERSEA",
  "WESTON-SUPER-MARE",
  "WESTWARD HO!",
  "WETHERBY",
  "WEYBRIDGE",
  "WHALEY BRIDGE",
  "WHISTON",
  "WHITBY",
  "WHITCHURCH",
  "WHITEHAVEN",
  "WHITLEY BAY",
  "WHITNASH",
  "WHITSTABLE",
  "WHITWORTH",
  "WIDNES",
  "WIGAN",
  "WIGSTON",
  "WILLENHALL",
  "WIMBLEDON",
  "WIMBORNE MINSTER",
  "WINCANTON",
  "WINCHCOMBE",
  "WINCHELSEA",
  "WINDERMERE",
  "WINSFORD",
  "WISBECH",
  "WITHAM",
  "WITHERNSEA",
  "WITNEY",
  "WIVENHOE",
  "WOKING",
  "WOKINGHAM",
  "WOLVERHAMPTON",
  "WOMBWELL",
  "WOOLER",
  "WOOLWICH",
  "WOOTTON BASSETT",
  "WORKINGTON",
  "WORKSOP",
  "WORTHING",
  "WOTTON-UNDER-EDGE",
  "WYMONDHAM",
  "YARM",
  "YARMOUTH",
  "YATE",
  "YATELEY",
  "YEADON",
  "YEOVIL",
  "YOXALL",
  "SHAFTESBURY",
  "SHANKLIN",
  "SHEERNESS",
  "SHEPSHED",
  "SHEPTON MALLET",
  "SHERBORNE",
  "SHERINGHAM",
  "SHILDON",
  "SHIPSTON - ON - STOUR, SHIRAZ",
  "SHOREHAM-BY-SEA",
  "SHREWSBURY",
  "SIDCUP",
  "SIDMOUTH",
  "SITTINGBOURNE",
  "SKEGNESS",
  "SKELMERSDALE",
  "SKIPTON",
  "SLEAFORD",
  "SLOUGH",
  "SMETHWICK",
  "SNODLAND",
  "SOHAM",
  "SOLIHULL",
  "SOMERTON",
  "SOUTHALL",
  "SOUTHAM",
  "SOUTHBOROUGH",
  "SOUTHEND-ON-SEA",
  "SOUTH MOLTON",
  "SOUTHPORT",
  "SOUTHSEA",
  "SOUTH SHIELDS",
  "SOUTHWELL",
  "SOUTHWOLD",
  "SOUTH WOODHAM FERRERS",
  "SPALDING",
  "SPENNYMOOR",
  "SPILSBY",
  "STAFFORD",
  "STAINES",
  "STAINFORTH",
  "STALYBRIDGE",
  "STANLEY",
  "STAPLEFORD",
  "STAVELEY",
  "STEVENAGE",
  "STOCKPORT",
  "STOCKSBRIDGE",
  "STOCKTON-ON-TEES",
  "STOKE-ON-TRENT",
  "STONE",
  "STONY STRATFORD",
  "STOTFIELD",
  "STOURBRIDGE",
  "STOURPORT-ON-SEVERN",
  "STOWMARKET",
  "STOW-ON-THE-WOLD",
  "STRATFORD-UPON-AVON",
  "STREATHAM",
  "STREET",
  "STROOD",
  "STROUD",
  "SUNDERLAND",
  "SUTTON COLDFIELD",
  "SUTTON-IN-ASHFIELD",
  "SWADLINCOTE",
  "SWAFFHAM",
  "SWANAGE",
  "SWANLEY",
  "SWINDON",
  "SWINTON",
  "TADCASTER",
  "TADLEY",
  "TAMWORTH",
  "TAVISTOCK",
  "TEIGNMOUTH",
  "TELFORD",
  "TENBURY WELLS",
  "TENTERDEN",
  "TETBURY",
  "TEWKESBURY",
  "THAME",
  "THATCHAM",
  "CULLODEN",
  "THETFORD",
  "THIRSK",
  "THONG",
  "THORNABY",
  "THORNBURY",
  "THORNE",
  "TICKHILL",
  "TILBURY",
  "TIPTON",
  "TODMORDEN",
  "TONBRIDGE",
  "TORPOINT",
  "TORQUAY",
  "TOTNES",
  "TOTTENHAM",
  "TOTTON",
  "TOWCESTER",
  "ORPINGTON",
  "OSSETT",
  "OSWESTRY",
  "OTLEY",
  "OTTERY ST MARY",
  "OUNDLE",
  "OUTWOOD",
  "PADDOCK WOOD",
  "PADSTOW",
  "PAIGNTON",
  "PAINSWICK",
  "PEACEHAVEN",
  "PENISTONE",
  "PENRITH",
  "PENRYN",
  "PENZANCE",
  "PERSHORE",
  "PETERLEE",
  "PETERSFIELD",
  "PETWORTH",
  "POCKLINGTON",
  "PONTEFRACT",
  "POLEGATE",
  "POLTIMORE",
  "POOLE",
  "PORTISHEAD",
  "PORTSLADE",
  "POTTERS BAR",
  "POTTON",
  "POULTON-LE-FYLDE",
  "PRESCOT",
  "PRESTON",
  "PRINCES RISBOROUGH",
  "PRUDHOE",
  "PUDSEY",
  "QUEENBOROUGH",
  "QUINTRELL DOWNS",
  "RAMSGATE",
  "RAUNDS",
  "RAYLEIGH",
  "REDCAR",
  "REDDITCH",
  "REDHILL",
  "REDRUTH",
  "REIGATE",
  "RETFORD",
  "RICHMOND-UPON-THAMES",
  "RICKMANSWORTH",
  "RINGWOOD",
  "RIPLEY",
  "ROCHDALE",
  "ROCHFORD",
  "ROMFORD",
  "ROMSEY",
  "ROSS-ON-WYE",
  "ROTHBURY",
  "ROTHERHAM",
  "ROTHWELL",
  "ROWLEY REGIS",
  "ROYSTON",
  "RUGELEY",
  "RUNCORN",
  "RUSHDEN",
  "RYDE",
  "SAFFRON WALDEN",
  "ST ALBANS",
  "ST ASAPH",
  "ST AUSTELL",
  "ST BLAZEY",
  "ST COLUMB MAJOR",
  "ST HELENS",
  "ST IVES",
  "CAMBRIDGESHIRE",
  "ST NEOTS",
  "SALCOMBE",
  "SALE",
  "SALFORD",
  "SALTASH",
  "SALTBURN-BY-THE-SEA",
  "SANDBACH",
  "SANDHURST",
  "SANDOWN",
  "SANDY",
  "SAWBRIDGEWORTH",
  "SAXMUNDHAM",
  "SCUNTHORPE",
  "SEAFORD",
  "SEATON",
  "SEDGEFIELD",
  "SELBY",
  "SELSEY",
  "SETTLE",
  "SEVENOAKS",
  "LICHFIELD",
  "LISKEARD",
  "LITTLEHAMPTON",
  "LIVERPOOL",
  "LIZARD",
  "LONDON",
  "LONG EATON",
  "LONGRIDGE",
  "LOOE",
  "LOSTWITHIEL",
  "LOUGHBOROUGH",
  "LOUGHTON",
  "LOUTH",
  "LOWESTOFT",
  "LUTON",
  "LUTTERWORTH",
  "LYDD",
  "LYDNEY",
  "LYME REGIS",
  "LYMINGTON",
  "LYNTON",
  "LYTCHETT MINSTER",
  "LYTHAM ST ANNES",
  "LOFTHOUSE",
  "MABLETHORPE",
  "MACCLESFIELD",
  "MAGHULL",
  "MAIDENHEAD",
  "MAIDSTONE",
  "MALDON",
  "MALMESBURY",
  "MALTBY",
  "MALTON",
  "MALVERN",
  "MANNINGTREE",
  "MARCH",
  "MARGATE",
  "MARKET DEEPING",
  "MARKET DRAYTON",
  "MARKET HARBOROUGH",
  "MARKET RASEN",
  "MARKET WEIGHTON",
  "MARLOW",
  "MARYPORT",
  "MARSTON MORETAINE",
  "MATLOCK",
  "MELKSHAM",
  "MELTON MOWBRAY",
  "MEXBOROUGH",
  "MIDDLEHAM",
  "MIDDLESBROUGH",
  "MIDDLETON",
  "MIDDLEWICH",
  "MIDHURST",
  "MIDSOMER NORTON",
  "MINEHEAD",
  "MORECAMBE",
  "MORETONHAMPSTEAD",
  "MORETON-IN-MARSH",
  "MORLEY",
  "MORPETH",
  "MUCH WENLOCK",
  "NAILSEA",
  "NAILSWORTH",
  "NANTWICH",
  "NEEDHAM MARKET",
  "NESTON",
  "NEWARK-ON-TRENT",
  "NEWBIGGIN-BY-THE-SEA",
  "NEWBURY",
  "NEWCASTLE-UNDER-LYME",
  "NEWCASTLE UPON TYNE",
  "NEWENT",
  "NEWHAVEN",
  "NEWMARKET",
  "NEW MILLS",
  "NEW MILTON",
  "SHROPSHIRE",
  "NEWPORT PAGNELL",
  "NEWQUAY",
  "NEW ROMNEY",
  "NEWTON ABBOT",
  "NEWTON AYCLIFFE",
  "NEWTON-LE-WILLOWS",
  "NORMANTON",
  "NORTHALLERTON",
  "NORTHAM",
  "NORTH WALSHAM",
  "NORTHWICH",
  "NORTON RADSTOCK",
  "NOTTINGHAM",
  "NUNEATON",
  "OAKHAM",
  "OKEHAMPTON",
  "OLDBURY",
  "OLDHAM",
  "OLLERTON",
  "OLNEY",
  "ORMSKIRK",
  "BASILDON",
  "BASINGSTOKE",
  "BATLEY",
  "BATTLE",
  "BAWTRY",
  "BEAMINSTER",
  "BEBINGTON",
  "BECCLES",
  "BEDALE",
  "BEDLINGTON",
  "BEDWORTH",
  "BEESTON",
  "BELPER",
  "BENTHAM",
  "BERKHAMSTED",
  "BERWICK-UPON-TWEED",
  "BEVERLEY",
  "BEWDLEY",
  "BEXHILL-ON-SEA",
  "BICESTER",
  "BIDDULPH",
  "BIDEFORD",
  "BIGGLESWADE",
  "BILLERICAY",
  "BILSTON",
  "BINGHAM",
  "BISHOP AUCKLAND",
  "BISHOP'S CASTLE",
  "BISHOP'S STORTFORD",
  "BISHOP'S WALTHAM",
  "BLACKBURN",
  "BLACKPOOL",
  "BLANDFORD FORUM",
  "BLETCHLEY",
  "BLYTH",
  "BODMIN",
  "BOGNOR REGIS",
  "BOLLINGTON",
  "BOLSOVER",
  "BOLTON",
  "BOREHAMWOOD",
  "BOTTESFORD",
  "BOURNEMOUTH",
  "BRACKLEY",
  "BRACKNELL",
  "BRADFORD-ON-AVON",
  "BRADLEY STOKE",
  "BRADNINCH",
  "ACCRINGTON",
  "ACTON",
  "ADLINGTON",
  "ALCESTER",
  "ALDEBURGH",
  "ALDERSHOT",
  "ALDRIDGE",
  "ALFORD",
  "ALFRETON",
  "ALNWICK",
  "ALSAGER",
  "ALSTON",
  "ALTRINCHAM",
  "AMBLE",
  "AMERSHAM",
  "AMPTHILL",
  "APPLEBY-IN-WESTMORLAND",
  "ARUNDEL",
  "ASHBOURNE",
  "ASHBURTON",
  "ASHBY-DE-LA-ZOUCH",
  "ASHFORD",
  "ASHINGTON",
  "ASHTON-IN-MAKERFIELD",
  "ASHTON-UNDER-LYNE",
  "ASKERN",
  "ASPATRIA",
  "ATHERSTONE",
  "ATTLEBOROUGH",
  "AXBRIDGE",
  "AXMINSTER",
  "AYLESBURY",
  "GOSHEDAN",
  "GRACEHILL",
  "GRANGE CORNER",
  "GRANVILLE",
  "GREENCASTLE",
  "GREENISLAND",
  "GREYABBEY",
  "GREYSTEEL",
  "GROGGAN",
  "GROOMSPORT",
  "GULLADUFF",
  "HALFPENNY GATE",
  "HAMILTONSBAWN",
  "HELEN'S BAY",
  "HILLHALL",
  "HILLTOWN",
  "BALLYMACNAB",
  "BALLYMAGORRY",
  "BALLYMARTIN",
  "BALLYMAGUIGAN",
  "BALLYMENA",
  "BALLYMONEY",
  "BALLYNAHINCH",
  "BALLYNURE",
  "BALLYRASHANE",
  "BALLYROBERT",
  "BALLYRONAN",
  "BALLYRORY",
  "BALLYSCULLION",
  "BALLYSKEAGH",
  "BALLYSTRUDDER",
  "BALLYVOY",
  "BALLYWALTER",
  "BALNAMORE",
  "BANAGHER",
  "BANBRIDGE",
  "BANNFOOT",
  "BELCOO",
  "BELLAGHY",
  "BELLANALECK",
  "BELLARENA",
  "BELLEEK",
  "BELLEEKS",
  "BENBURB",
  "BENDOORAGH",
  "BERAGH",
  "BESSBROOK",
  "BLACKSKULL",
  "BLACKWATERTOWN",
  "BLANEY",
  "BLEARY",
  "BOHO",
  "BRACKAVILLE",
  "BREADY",
  "BROCKAGH",
  "BROOKEBOROUGH",
  "BROOMHILL",
  "BROUGHSHANE",
  "BRYANSFORD",
  "BUCKNA",
  "BURNFOOT",
  "BURREN",
  "BUSHMILLS",
  "CALEDON",
  "CAMLOUGH",
  "CAMPSIE",
  "CAPECASTLE",
  "CAPPAGH",
  "CARGAN",
  "CARNALBANAGH",
  "CARNCASTLE",
  "CARNLOUGH",
  "CARNTEEL",
  "CARRICKANESS",
  "CARRICKFERGUS",
  "CARRICKMORE",
  "CARROWCLARE",
  "CARROWDORE",
  "CARRYBRIDGE",
  "CARRYDUFF",
  "CASTLECAULFIELD",
  "CASTLEDAWSON",
  "CASTLEDERG",
  "CASTLEROCK",
  "CASTLEWELLAN",
  "CHARLEMONT",
  "CLABBY",
  "CLADY",
  "CLADYMORE",
  "CLANABOGAN",
  "CLAUDY",
  "CLOGH",
  "CLOGHER",
  "CLOGHY",
  "CLONMORE",
  "CLONOE",
  "CLOUGH",
  "CLOUGHMILLS",
  "COAGH",
  "COALISLAND",
  "COGRY-KILBRIDE",
  "COLERAINE",
  "COLLEGELAND",
  "COMBER",
  "CONLIG",
  "COOKSTOWN",
  "KNUTSFORD",
  "KINGSTEIGNTON",
  "LAUNCESTON",
  "LEATHERHEAD",
  "LEAMINGTON SPA",
  "LECHLADE",
  "LEDBURY",
  "LEEDS",
  "LEEK",
  "LEICESTER",
  "LEIGH",
  "LEIGHTON BUZZARD",
  "LEISTON",
  "LETCHWORTH",
  "LEWISHAM",
  "LEYLAND",
  "LEYTON",
  "MASAFI",
  "MASFUT",
  "MIRBAH",
  "MLEIHA",
  "NAHIL",
  "RAS AL KHAIMAH",
  "RUWAIS",
  "SHA'AM",
  "SHARJAH CITY",
  "SILA",
  "SIR BANI YAS",
  "SWEIHAN",
  "UMM AL-QUWAIN",
  "WADI SHAH",
  "ZUBARAH",
  "NAIROBI METRO",
  "NAIROBI",
  "MOMBASA",
  "KISUMU",
  "NAKURU",
  "ELDORET",
  "KEHANCHA",
  "RUIRU",
  "KIKUYU",
  "KANGUNDO-TALA",
  "MALINDI",
  "NAIVASHA",
  "KITUI",
  "MACHAKOS",
  "THIKA",
  "ATHI RIVER (MAVOKO)",
  "KARURI",
  "NERI",
  "KILIFI",
  "GARISA",
  "VIHIGA",
  "MUMIAS",
  "BOMET",
  "MOLO",
  "NGONG",
  "KITALE",
  "LITEIN",
  "LIMURU",
  "KERICHO",
  "KIMILILI",
  "AWASI",
  "KAKAMEGA",
  "KAPSABET",
  "MARIAKANI",
  "KIAMBU",
  "MANDERA",
  "NYAMIRA",
  "MWINGI",
  "KISII",
  "WAJIR",
  "RONGO",
  "BUNGOMA",
  "AHERO",
  "NANDI HILLS",
  "MAKUYU",
  "KAPENGURIA",
  "TAVETA",
  "NAROK",
  "OL KALOU",
  "KAKUMA",
  "WEBUYE",
  "MALABA",
  "MBITA POINT",
  "UKUNDA",
  "WUNDANYI",
  "BUSIA",
  "RUNYENJES",
  "MIGORI",
  "MALAVA",
  "SUNEKA",
  "EMBU",
  "OGEMBO",
  "HOMA BAY",
  "LODWAR",
  "KITENGELA",
  "UTAWALA",
  "KEROKA",
  "MERU",
  "MATUU",
  "OYUGIS",
  "NYAHURURU",
  "KIPKELION",
  "LUANDA",
  "NANYUKI",
  "MAUA",
  "MTWAPA",
  "ISIOLO",
  "ELDAMA RAVINE",
  "VOI",
  "SIAYA",
  "NYANSIONGO",
  "LONDIANI",
  "ITEN/TAMBACH",
  "CHUKA",
  "MALAKISI",
  "JUJA",
  "ONGATA RONGAI",
  "BONDO",
  "MOYALE",
  "MARALAL",
  "GILGIL",
  "NAMBALE",
  "TABAKA",
  "MUHORONI",
  "KERUGOYA/KUTUS",
  "UGUNJA",
  "YALA",
  "RUMURUTI",
  "BURNT FOREST",
  "MARAGUA",
  "KENDU BAY",
  "MAHE ISLANDS",
  "ANSE AUX PINS",
  "ANSE BOILEAU",
  "ANSE ETOILE",
  "LA PLAINE ST. ANDRE",
  "ANSE ROYALE",
  "BAIE LAZARE",
  "BEL AIR",
  "ENGLISH RIVER",
  "MONT BUXTON",
  "MONT FLEURI",
  "PLAISANCE",
  "POINTE LA RUE",
  "PORT GLAUD",
  "TAKAMAKA",
  "LES MAMELLES",
  "ROCHE CAIMAN",
  "PRASLIN ISLANDS",
  "ANSE VOLBERT",
  "GRAND ANSE",
  "LA DIGUE ISLANDS",
  "LA PASSE",
  "COETIVY",
  "JOHANNESBURG",
  "CAPE TOWN",
  "BENONI",
  "DURBAN",
  "PRETORIA",
  "VEREENIGING",
  "PORT ELIZABETH",
  "PIETERMARITZBURG",
  "BLOEMFONTEIN",
  "WELKOM",
  "NELSPRUIT",
  "EAST LONDON",
  "THOHOYANDOU",
  "SPRINGS",
  "UITENHAGE",
  "POLOKWANE",
  "PAARL",
  "KLERKSDORP",
  "GEORGE",
  "RUSTENBURG",
  "BHISHO",
  "MIDDELBURG",
  "VRYHEID",
  "UMTATA",
  "POTCHEFSTROOM",
  "BRITS",
  "QUEENSTOWN",
  "MMABATHO",
  "KROONSTAD",
  "BETHAL",
  "GRAHAMSTOWN",
  "OUDTSHOORN",
  "STANDERTON",
  "UPINGTON",
  "SALDANHA",
  "TZANEEN",
  "KNYSNA",
  "GRAAFF-REINET",
  "PORT SHEPSTONE",
  "VRYBURG",
  "LADYSMITH",
  "BEAUFORT WEST",
  "ALIWAL NORTH",
  "VOLKSRUST",
  "LEBOWAKGOMO",
  "CRADOCK",
  "DE AAR",
  "HERMANUS",
  "ULUNDI",
  "KOMATIPOORT",
  "MESSINA",
  "PORT ALFRED",
  "BLOEMHOF",
  "MOSSEL BAY",
  "BREDASDORP",
  "SWELLENDAM",
  "COLESBERG",
  "BRANDFORT",
  "PRIESKA",
  "SPRINGBOK",
  "KURUMAN",
  "PORT SAINT JOHN’S",
  "CARNARVON",
  "POFADDER",
  "VANRHYNSDORP",
  "ALEXANDER BAY",
  "UBOMBO",
  "MAHIKENG",
  "ABU DHABI CITY",
  "ABU AL ABYAD",
  "ADHEN",
  "AJMAN CITY",
  "AL AIN",
  "AL AJBAN",
  "AL ARYAM",
  "AL AWIR",
  "AL BADIYAH",
  "AL BATAEH",
  "AL BITHNAH",
  "AL FAQA",
  "AL HALAH",
  "AL HAMRANIYAH",
  "AL HAMRIYAH",
  "AL JAZIRAH AL HAMRA",
  "AL JEER",
  "AL KHAWANEEJ",
  "AL LISAILI",
  "AL MADAM",
  "AL MANAMA",
  "AL MIRFA",
  "AL QOR",
  "AL QUOZ",
  "AL QUSAIDAT",
  "AL RAFAAH",
  "AL RASHIDYA",
  "AL SHUWAIB",
  "AL YAHAR",
  "AR-RAMS",
  "ASIMAH",
  "DADNA",
  "DALMA",
  "DHAID",
  "DIBBA AL-FUJAIRAH",
  "DIBBA AL-HISN",
  "DIGDAGA",
  "DUBAI CITY",
  "FALAJ AL MUALLA",
  "FUJAIRAH CITY",
  "GHALILAH",
  "GHAYATHI",
  "GHUB",
  "HABSHAN",
  "HATTA",
  "HUWAYLAT",
  "JEBEL ALI",
  "KALBA",
  "KHATT",
  "KHOR FAKKAN",
  "KHOR KHWAIR",
  "LAHBAB",
  "LIWA OASIS",
  "MADINAT ZAYED",
  "MARAWAH",
  "THAXTED",
  "AYLSHAM",
  "BACUP",
  "BAKEWELL",
  "BALDOCK",
  "BANBURY",
  "BARKING",
  "BARNARD CASTLE",
  "BARNET",
  "BARNOLDSWICK",
  "BARNSLEY",
  "BARNSTAPLE",
  "BARNT GREEN",
  "BARROW-IN-FURNESS",
  "BARTON-UPON-HUMBER",
  "BARTON-LE-CLAY",
  "BRENTFORD",
  "BRENTWOOD",
  "BRIDGNORTH",
  "BRIDGWATER",
  "BRIDLINGTON",
  "BRIDPORT",
  "BRIERLEY HILL",
  "BRIGG",
  "BRIGHOUSE",
  "BRIGHTLINGSEA",
  "BRIXHAM",
  "BROADSTAIRS",
  "BROMLEY",
  "BROMSGROVE",
  "BROMYARD",
  "BROWNHILLS",
  "BUCKFASTLEIGH",
  "BUCKINGHAM",
  "BUDE",
  "BUDLEIGH SALTERTON",
  "BUNGAY",
  "BUNTINGFORD",
  "BURFORD",
  "BURGESS HILL",
  "BURNHAM-ON-CROUCH",
  "BURNHAM-ON-SEA",
  "BURNLEY",
  "BURNTWOOD",
  "BURTON LATIMER",
  "BURTON-UPON-TRENT",
  "BURY",
  "BURY ST EDMUNDS",
  "BUXTON",
  "CAISTOR",
  "CALNE",
  "CAMBERLEY",
  "CAMBORNE",
  "CAMELFORD",
  "CANNOCK",
  "CANTERBURY",
  "CARNFORTH",
  "CARTERTON",
  "CASTLE CARY",
  "CASTLEFORD",
  "CHADDERTON",
  "CHAGFORD",
  "CHARD",
  "CHARLBURY",
  "CHATHAM",
  "CHATTERIS",
  "CHELTENHAM",
  "CHESHAM",
  "CHESHUNT",
  "CHESTERFIELD",
  "CHESTER-LE-STREET",
  "CHICHESTER",
  "CHIPPENHAM",
  "CHIPPING CAMPDEN",
  "CHIPPING NORTON",
  "CHIPPING ONGAR",
  "CHIPPING SODBURY",
  "CHORLEY",
  "CHRISTCHURCH",
  "CHURCH STRETTON",
  "CINDERFORD",
  "CIRENCESTER",
  "CLACTON-ON-SEA",
  "CLECKHEATON",
  "CLEETHORPES",
  "CLEVEDON",
  "CLEVELEYS",
  "CLITHEROE",
  "CLUN",
  "COALVILLE",
  "COCKERMOUTH",
  "COGGESHALL",
  "COLCHESTER",
  "COLEFORD",
  "COLNE",
  "CONGLETON",
  "CONISBROUGH",
  "CORBRIDGE",
  "CORBY",
  "COTGRAVE",
  "COWES",
  "CRAMLINGTON",
  "CRANFIELD",
  "CRAWLEY",
  "CRAYFORD",
  "CREDITON",
  "CREWE",
  "CREWKERNE",
  "CROMER",
  "CROWBOROUGH",
  "CROWLE",
  "CROWTHORNE",
  "CROYDON",
  "CUCKFIELD",
  "CULLOMPTON",
  "CONNOR TOWN",
  "DAGENHAM",
  "DALTON IN FURNESS",
  "DARLEY DALE",
  "DARTFORD",
  "DARWEN",
  "DAVENTRY",
  "DAWLISH",
  "DEAL",
  "DENHOLME",
  "DEREHAM",
  "DESBOROUGH",
  "DEVIZES",
  "DEWSBURY",
  "DIDCOT",
  "DINNINGTON",
  "DISS",
  "DONCASTER",
  "DORCHESTER",
  "DORKING",
  "DOWNHAM MARKET",
  "DRIFFIELD",
  "DRONFIELD",
  "DROITWICH SPA",
  "DROYLSDEN",
  "DUDLEY",
  "DUKINFIELD",
  "DUNSTABLE",
  "DURSLEY",
  "EALING",
  "EARLEY",
  "EASINGWOLD",
  "EASTBOURNE",
  "EAST GRINSTEAD",
  "EAST HAM",
  "EASTLEIGH",
  "EASTWOOD",
  "EDENBRIDGE",
  "EGHAM",
  "ELLESMERE",
  "ELLESMERE PORT",
  "EPPING",
  "EPSOM",
  "EPWORTH",
  "ERITH",
  "ESHER",
  "ETON",
  "EVESHAM",
  "EXMOUTH",
  "EYE",
  "FAILSWORTH",
  "FAIRFORD",
  "FAKENHAM",
  "FAREHAM",
  "FARINGDON",
  "FARNBOROUGH",
  "FARNWORTH",
  "FAVERSHAM",
  "FEATHERSTONE",
  "FELIXSTOWE",
  "FENNY STRATFORD",
  "FERNDOWN",
  "FERRYHILL",
  "FILEY",
  "FILTON",
  "FLEET",
  "FLEETWOOD",
  "FLITWICK",
  "FOLKESTONE",
  "FORDINGBRIDGE",
  "FORDWICH",
  "FOWEY",
  "FRAMLINGHAM",
  "FRINTON-ON-SEA",
  "FRODSHAM",
  "FROME",
  "FOXLEY",
  "GAINSBOROUGH",
  "GATESHEAD",
  "GILLINGHAM",
  "GLASTONBURY",
  "GLOSSOP",
  "GODALMING",
  "GODMANCHESTER",
  "GOOLE",
  "GOSPORT",
  "GRANGE-OVER-SANDS",
  "GRANTHAM",
  "GRAVESEND",
  "GRAYS",
  "GREAT DUNMOW",
  "GREAT TORRINGTON",
  "GREAT YARMOUTH",
  "GRIMSBY",
  "GUILDFORD",
  "GUISBOROUGH",
  "HACKNEY",
  "HADLEIGH",
  "HAILSHAM",
  "HALESWORTH",
  "HALEWOOD",
  "HALSTEAD",
  "HALTWHISTLE",
  "HARLOW",
  "HARPENDEN",
  "HARROGATE",
  "HARROW",
  "HARTLEPOOL",
  "HASLEMERE",
  "HATFIELD",
  "HAVANT",
  "HAWLEY",
  "HAYLE",
  "HAYWARDS HEATH",
  "HEANOR",
  "HEATHFIELD",
  "HEBDEN BRIDGE",
  "HEDON",
  "HELSTON",
  "HEMEL HEMPSTEAD",
  "HEMSWORTH",
  "HENLEY-IN-ARDEN",
  "HENLEY-ON-THAMES",
  "HENDON",
  "HEREFORD",
  "HERNE BAY",
  "HERTFORD",
  "HESSLE",
  "HESWALL",
  "HETTON-LE-HOLE",
  "HEYWOOD",
  "HEXHAM",
  "HIGHAM FERRERS",
  "HIGHWORTH",
  "HIGH WYCOMBE",
  "HINCKLEY",
  "HITCHIN",
  "HODDESDON",
  "HOLMFIRTH",
  "HOLSWORTHY",
  "HONITON",
  "HORLEY",
  "HORNCASTLE",
  "HORNSEA",
  "HORSHAM",
  "HORWICH",
  "HOUGHTON-LE-SPRING",
  "HOUNSLOW",
  "HOYLAKE",
  "HOVE HUCKNALL",
  "HUDDERSFIELD",
  "HUGH TOWN",
  "HULL",
  "HUNGERFORD",
  "HUNSTANTON",
  "HYDE",
  "HYTHE",
  "ILCHESTER",
  "ILFORD",
  "ILFRACOMBE",
  "ILKESTON",
  "ILKLEY",
  "ILMINSTER",
  "IRTHLINGBOROUGH",
  "IVYBRIDGE",
  "JARROW",
  "KEIGHLEY",
  "KEMPSTON",
  "KENDAL",
  "KENILWORTH",
  "KESGRAVE",
  "KESWICK",
  "KEYNSHAM",
  "KIDDERMINSTER",
  "KIDSGROVE",
  "KILLINGWORTH",
  "KINGSBRIDGE",
  "KING'S LYNN",
  "KINGSTON-UPON-HULL",
  "KINGSTON UPON THAMES",
  "KINGTON",
  "KIRKBY",
  "KIRKBY LONSDALE",
  "KIRKHAM",
  "KNARESBOROUGH",
  "KNOTTINGLEY",
  "PORT BLAIR",
  "ADONI",
  "AMARAVATI",
  "CHANDRAGIRI",
  "DOWLAISWARAM",
  "ELURU",
  "KADAPA",
  "KAKINADA",
  "MACHILIPATNAM",
  "NAGARJUNAKOA",
  "RAJAHMUNDRY",
  "TIRUPATI",
  "VIJAYAWADA",
  "YEMMIGANUR",
  "ITANAGAR",
  "DHUBURI",
  "DISPUR",
  "GUWAHATI",
  "SILCHAR",
  "TEZPUR",
  "ARA",
  "BARUNI",
  "BETTIAH",
  "BIHAR SHARIF",
  "BODH GAYA",
  "CHAPRA",
  "DEHRI",
  "DINAPUR NIZAMAT",
  "HAJIPUR",
  "JAMALPUR",
  "MOTIHARI",
  "PUSA",
  "SASARAM",
  "AMBIKAPUR",
  "BHILAI",
  "JAGDALPUR",
  "SILVASSA",
  "MADGAON",
  "PANAJI",
  "AHMADABAD",
  "BHUJ",
  "DWARKA",
  "GODHRA",
  "KANDLA",
  "KHAMBHAT",
  "MORVI",
  "NADIAD",
  "OKHA",
  "PALANPUR",
  "VERAVAL",
  "FIROZPUR JHIRKA",
  "HANSI",
  "PEHOWA",
  "DALHOUSIE",
  "DHARMSHALA",
  "KULLU",
  "NAHAN",
  "SHIMLA",
  "BARAMULA",
  "GULMARG",
  "LEH",
  "PUNCH",
  "RAJAURI",
  "CHAIBASA",
  "JHARIA",
  "RAJMAHAL",
  "SARAIKELA",
  "BADAMI",
  "BALLARI",
  "BANGALORE",
  "BENGALURU",
  "BELGAVI",
  "BHADRAVATI",
  "CHIKKAMAGALURU",
  "CHICKMAGALUR",
  "HALEBID",
  "HUBBALLI-DHARWAD",
  "HUBBALLI",
  "KALABURAGI",
  "MADIKERI",
  "MANGALURU",
  "MYSURU",
  "SHIVAMOGGA",
  "SHRAVANABELAGOLA",
  "SHRIRANGAPATTANA",
  "TUMKURU",
  "ALAPPUZHA",
  "BADAGARA",
  "KOCHI",
  "MATTANCHERI",
  "THALASSERY",
  "BHARHUT",
  "ITARSI",
  "KHAJURAHO",
  "KHANDWA",
  "KHARGON",
  "KHARGONE",
  "MAHESHWAR",
  "MHOW",
  "MURWARA",
  "NARSINGHGARH",
  "NARWAR",
  "NOWGONG",
  "ORCHHA",
  "SARANGPUR",
  "AHMADNAGAR",
  "BHUSAWAL",
  "BULDANA",
  "DAULATABAD",
  "KALYAN",
  "KARLI",
  "MAHABALESHWAR",
  "MALEGAON",
  "MATHERAN",
  "NASHIK",
  "PANDHARPUR",
  "SEVAGRAM",
  "ULHASNAGAR",
  "VASAI-VIRAR",
  "IMPHAL",
  "CHERRAPUNJI",
  "SHILLONG",
  "BALANGIR",
  "BARIPADA",
  "BHUBANESHWAR",
  "BRAHMAPUR",
  "KONARK",
  "PARADIP",
  "PHULABANI",
  "UDAYAGIRI",
  "BATALA",
  "FIROZPUR",
  "NABHA",
  "ABU",
  "AMER",
  "BEAWAR",
  "DHAULPUR",
  "KISHANGARH",
  "MERTA",
  "NATHDWARA",
  "PHALODI",
  "PUSHKAR",
  "SHAHPURA",
  "GANGTOK",
  "GYALSING",
  "LACHUNG",
  "MANGAN",
  "ARCOT",
  "CHENGALPATTU",
  "CHIDAMBARAM",
  "KANCHIPURAM",
  "KANNIYAKUMARI",
  "KODAIKANAL",
  "KUMBAKONAM",
  "MAMALLAPURAM",
  "NAGAPPATTINAM",
  "NAGERCOIL",
  "PALAYANKOTTAI",
  "RAJAPALAIYAM",
  "TIRUCHCHIRAPPALLI",
  "TIRUNELVELI",
  "TUTICORIN",
  "UDHAGAMANDALAM",
  "SANGAREDDI",
  "AGARTALA",
  "AGARTALAÂ",
  "AMROHA",
  "AYODHYA",
  "BITHUR",
  "FARRUKHABAD-CUM-FATEHGARH",
  "FATEHPUR SIKRI",
  "KANNAUJ",
  "KANPUR",
  "MIRZAPUR-VINDHYACHAL",
  "PARTAPGARH",
  "PRAYAGRAJ",
  "PRAYAGRAJ ALLAHABAD",
  "RAE BARELI",
  "RAEBARELI",
  "SAMBHAL",
  "TEHRI",
  "MUSSOORIE",
  "ALIPORE",
  "ALIPUR DUAR",
  "ASANSOL",
  "BAHARAMPUR",
  "BALLY",
  "BALURGHAT",
  "BARANAGAR",
  "BARASAT",
  "BARRACKPORE",
  "BASIRHAT",
  "BHATPARA",
  "BISHNUPUR",
  "BUDGE BUDGE",
  "BURDWAN",
  "CHANDERNAGORE",
  "DIAMOND HARBOUR",
  "DUM DUM",
  "DURGAPUR",
  "HALISAHAR",
  "HAORA",
  "INGRAJ BAZAR",
  "KALIMPONG",
  "KAMARHATI",
  "KANCHRAPARA",
  "KHARAGPUR",
  "KOCH BIHAR",
  "KRISHNANAGAR",
  "MALDA",
  "MIDNAPORE",
  "NAVADWIP",
  "PALASHI",
  "PANIHATI",
  "PURULIA",
  "RAIGANJ",
  "SANTIPUR",
  "SHANTINIKETAN",
  "SHRIRAMPUR",
  "SILIGURI",
  "SIURI",
  "TAMLUK",
  "TITAGARH",
  "JUBAIL INDUSTRIAL CITY",
  "ARIYANKUPPAM",
  "FORESTSIDE",
  "DHALBHUMGARH",
  "KAMALA",
  "BOISAR ",
  "HOJAI",
  "NAWANSHAHR",
  "KASGANJ",
  "DOHAD",
  "ANANTHAPURAMU",
  "ANANTHAPUR",
  "HAPUR",
  "VIJAYAPURA",
  "SRI MUKTSAR SAHIB",
  "CHANGLANG",
  "YEREVAN",
  "LAGUNA GRANDE",
  "HUNG HOM KLN",
  "KALANG",
  "MIRQAB",
  "KUWAIT CITY",
  "RIYADH",
  "KHUSHI NAGAR",
  "DAMMAM",
  "NAVI MUMBAI",
  "MARBURG",
  "AUCKLAND",
  "EAST AHMADI",
  "VANTAA",
  "LIMERICK",
  "KIERSPE",
  "NORTHWOOD",
  "WATTANA",
  "LILIENGASSE",
  "CHIANGMAI",
  "MUANG",
  "DOMBIVALI",
  "CASTEL",
  "BEED",
  "BANGALORE NORTH",
  "BENGALURU NORTH",
  "ORWADA",
  "SIMILIJORE",
  "SONBARSA",
  "SHIBNAGAR ABAD",
  "HEMKUMARI",
  "NARELA",
  "DHAMANA",
  "PAKHANJUR",
  "DHUCHNIKHALI",
  "DANKI GAON",
  "RISHRA M",
  "KUMARDUNGI",
  "URDHABPUR",
  "BANGA",
  "MAIDALPUR",
  "SOHRAUNA",
  "TIRUCHIRAPPALLI",
  "RANGO FOREST",
  "TARAKESWAR",
  "BARADAPAL",
  "CHIRU",
  "LESLIGANJ",
  "MALIRU",
  "CHAKRE",
  "RAMPURWA",
  "KIRASIRA",
  "SATBARWA",
  "CHORASAN",
  "THIRUMANI",
  "BODIDRA KHURD",
  "ADERAN (DANTA)",
  "SOMESHWARBANDA",
  "UMARPUR",
  "BARASAT - I",
  "BALUJINA MUVADA",
  "RAIBARI MAHUAWA",
  "PANDU",
  "RAJEPUR",
  "PENDARI BUZURG",
  "TRI-BHAUNI",
  "GURAP",
  "BIRPUR",
  "COOCH BEHAR",
  "SAULADIH",
  "MACHHAHA URF ",
  "AMANPUR",
  "MACHHAR GANWAN",
  "SINGPUR",
  "ULIPAHARI",
  "BELGAON",
  "KASBA EGRA ",
  "KANCHHAR",
  "ADANA",
  "CHHIPIYA",
  "BAGDOLA",
  "NAUGARH",
  "BUDHIKAPUDI",
  "BUTCHAYYAPETA",
  "GOLLAV",
  "BADLI",
  "AKDANGA",
  "QUADAR GANJ",
  "ABHIRAMPUR (P)",
  "MARANGPURI",
  "KIRARI SULEMAN",
  "POLKAMPALLE",
  "CHIKHALI",
  "MONOHARPUR",
  "GANJ DUNDWARA",
  "TANGRANI",
  "HATIGAON CHARI",
  "KURESWAR",
  "MALVASI",
  "CHHINDLI",
  "BELWA",
  "SERIVENKATAPUR",
  "TALIMPUR",
  "KACHIM CHARA",
  "PALHE KALAN",
  "PUTIMARI BAKS",
  "GOALKHOR",
  "JAGADHRI",
  "JHADAGAN",
  "MANSAI",
  "SOUTH MUMBAI",
  "GANDHI BHAVAN HYDERABAD",
  "CENTRAL CHENNAI",
  "CONNAUGHT PLACE",
  "CHINSURAH",
  "JAMSHEDPUR",
  "BED",
  "HOOGHLY",
  "GRAND BAY",
  "WARSAW",
  "SANGAMNER",
  "STRATHFIELD",
  "KELLYVILLE",
  "NEW SOUTH WALES",
  "NALLACHERUVU",
  "SENDKAP",
  "BAIJNATHPUR",
  "SONIA VIHAR",
  "PASCHIMGHOSHPUR",
  "AGRADWIP",
  "KARAVAL NAGAR",
  "JANERWA",
  "KUCHNI PRATHAM",
  "KARAMDIHI",
  "SIVAKASI",
  "MUSCAT",
  "BHESAN",
  "ISHOEJ",
  "BAHLA",
  "RAMNAGAR",
  "AL MADINA",
  "MERRYLANDS",
  "BARODA",
  "DOHA",
  "PENDURTHI",
  "GABORONE",
  "GURUGRAM",
  "YISHUN",
  "BYNDOOR",
  "HAWALLY",
  "NEUFAHRN BEI FREISING",
  "HAWALI GOVERNORATE",
  "DAVISTOWN",
  "LYSAKER",
  "BRISBANE",
  "OGUN",
  "MANNHEIM",
  "SYDNEY",
  "POWAY",
  "DAR-ES-SALAAM",
  "KUALA",
  "CALIFORNIA",
  "NOIDA",
  "LAGOS",
  "ZIRAKPUR",
  "MOHALI",
  "SINGAPORE",
  "ROSE HILL",
  "ANAPARTHY",
  "CHITTORGARH",
  "KABEERDHAM",
  "HERMITAGE",
  "WILLINGTON",
  "LADAKH",
  "CHENDA",
  "KAMPALA",
  "NUAPADA",
  "QUEENSLAND",
  "ELECTRA STREET",
  "AL RAYYAN",
  "SEOUL",
  "NEW JERSEY",
  "SANJOSE",
  "MANAMA",
  "EBENE",
  "JABEL ALI",
  "KUNDUAPURA",
  "KAPRA",
  "TOKYO",
  "TOKYO TO",
  "PONNERI",
  "MINJUR",
  "RIFFA",
  "SALAMIYA",
  "FENTON",
  "GREAT WORLD CITY",
  "RUWI",
  "HOWELL",
  "NAYALI",
  "MALVAN",
  "MIRI",
  "SARAWAK",
  "ABIDJAN",
  "HONGKONG",
  "UDUPI",
  "PARGANAS",
  "BUKIT PANJANG",
  "KUWAIT",
  "KLEMZIG",
  "THOOTHUKKUDI",
  "KHORDHA",
  "KHORDA",
  "AJAX",
  "BRUSSEL",
  "ZURICH",
  "BARDHAMAN",
  "ACRA",
  "TUCKER",
  "KOWLOON",
  "AHMADI",
  "TARAGONNA",
  "TIRUVALLUR",
  "DEIRA CREEK",
  "SARTEANO SI",
  "GOPAVARAM MDL",
  "BELAGAVI",
  "AJMAN",
  "IKEJA GRA",
  "MARGAO",
  "FELTHAM",
  "WESTLANDS",
  "DUBLIN",
  "MAURITIUS",
  "SUNDBYBERG",
  "GRAND TERRACE",
  "MUNSTER",
  "COPPELL",
  "K.V. RANGAREDDY",
  "CUREPIPE",
  "CARY",
  "DARLINGHURST​",
  "DESA PAHLAWAN",
  "GALWAY",
  "FALAJ AL QABAIL",
  "AL JAZZAT",
  "TUNG CHUNG NT",
  "TRACY",
  "LAGUNA NIGUEL",
  "ATYRAU",
  "LOWER SOXONY",
  "LILONGWE",
  "ALMERE",
  "LINDFIED",
  "FUTIAN SHENZEN",
  "JAKARTA",
  "SALMIYA",
  "FEDERAL WAY",
  "SINGHBHUM",
  "ONGOLE",
  "ALBORG",
  "SAN RAMON",
  "BANJUL",
  "STROVOLOS",
  "KLONGTOEY",
  "ABU HALIFA",
  "VUNG TAU CITY",
  "ROSLYN HEIGHTS",
  "LEIDSCHENDAM",
  "HOUGANG",
  "BUKIT BATOK",
  "BUR DUBAI",
  "KAILASHAHAR",
  "SOLLENTUNA",
  "LIBREVILLE",
  "KHARKIV",
  "MARGATE KENT",
  "USARI",
  "TALLINN",
  "LONG  MOUNTAIN",
  "OBERURSEL TAUNUS",
  "SAS NAGAR",
  "MAJUNGA",
  "NORTHMEAD",
  "BRAZZAVILLE",
  "TUALATIN",
  "THIRUVALLA",
  "NAMUR",
  "YOKOHAMA",
  "NORTH HOLLAND",
  "BAHIR DAR",
  "PETALING JAYA",
  "ANTANANARIVO",
  "MAJURO",
  "KHUWAIR",
  "AL MUGHIERA BIN STREET",
  "PATANCHERU",
  "FUCHI TOWN YANGXIN COUNTY",
  "BURAYDAH",
  "AI WAKRAH",
  "INGERSHEIM",
  "GOLAGHAT",
  "LAMPHUN",
  "REDUIT",
  "SALALAH",
  "KADUNA",
  "WESTERN CAPE",
  "FLOWER MOUND",
  "VALOAS",
  "SOUTH YARRA",
  "KRAKOW",
  "WEST CHAMPARAN",
  "WATTAYAH",
  "BELL CANYON",
  "MAHBOULA",
  "FOLSOM",
  "ADDIS ABABA",
  "LUCAS ,",
  "KARWAR",
  "KANUMA",
  "PEREYBERE",
  "PARRAMATTA",
  "FLOREAL",
  "SOHAR",
  "ALSAFA",
  "SUBANG JAYA",
  "KHAMIS MUSHYAT",
  "KISUTU",
  "ELLICOTT CITY",
  "CUMMING",
  "HAMBURG",
  "LUSAKA",
  "ABITGHAR",
  "SAFAT AL",
  "ANGELES CITY",
  "CHESTERBROOK",
  "ZANZIBAR",
  "HANOI",
  "BONDI JUNCTION",
  "POKFULAM",
  "BAHARAGORA",
  "HARDWAR",
  "RAJPURA",
  "TRIVANDRUM",
  "FLORIDA",
  "MADINAH",
  "GIRONA",
  "TOMS RIVER",
  "MISSISSIPPI",
  "HAWTHORN EAST",
  "POTOMAC",
  "STOCKHOLM",
  "SPANGA",
  "VESTED FINANCE",
  "ENDEAVOUR HILLS",
  "COTONOU",
  "MESAIEED",
  "PANRUTI",
  "SHENZHEN",
  "MIDDLESEX",
  "KUALA LUMPUR",
  "BONN",
  "ISSAQUAH",
  "DALBY",
  "ARMIDALE",
  "TAMATAVE",
  "ETI OSA",
  "DUMMY",
  "KHURD",
  "AL WUKAIR",
  "DUNEDIN",
  "DUSSELDORF",
  "AL RUSAYL",
  "SALMANIYA",
  "PINELLAS PARK",
  "WOODCROFT",
  "WARRINGTON",
  "PIMPRI CHINCHWAD",
  "TRUGANINA",
  "CASTLEKNOCK",
  "RONDEBOSCH",
  "ZHEJIANG",
  "COPENHAGEN",
  "LYONS",
  "MUBARAK- AL- KABEER",
  "KIGALI",
  "BUKIT BARU",
  "VOORBURG",
  "AL JUBAIL",
  "MELAKA",
  "ARUSHA",
  "HISINGS BACKA",
  "CANTON OF ZUG",
  "BARBIL",
  "CHATILLON",
  "BARISWIL",
  "KRIENGSTETTEN",
  "HERNDRON",
  "RADIUM HOT SPRINGS",
  "ARIZONA",
  "CORNELIUS",
  "BUSHEY",
  "JORDAN",
  "AUDERGHEM",
  "DEN HAAG",
  "NABARANGAPUR",
];
