export const banksListWithPaymentMethods = [
    {
      bank_name: "THE A.P. MAHESH COOPERATIVE URBAN",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "ABHYUDAYA COOPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "ICICI BANK LIMITED", upi: true, netbanking: false },
    {
      bank_name: "AHMEDABAD MERCANTILE COOPERATIVE B",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "AIRTEL PAYMENTS BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    { bank_name: "ALLAHABAD BANK", upi: true, netbanking: true },
    { bank_name: "ANDHRA BANK", upi: true, netbanking: false },
    {
      bank_name: "ANDHRA PRAGATHI GRAMEENA BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "ANDHRA PRADESH GRAMEENA VIKAS BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "APNA SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "ASSAM GRAMIN VIKASH BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "ASSOCIATE CO-OPERATIVE BANK LIMITED,SURAT",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "AU SMALL FINANCE BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    { bank_name: "AXIS BANK", upi: true, netbanking: false },
    {
      bank_name: "BANASKANTHA MERCANTILE CO-OPERATIV",
      upi: true,
      netbanking: false,
    },
    { bank_name: "BANDHAN BANK LIMITED", upi: true, netbanking: false },
    { bank_name: "BANK OF AMERICA", upi: true, netbanking: false },
    { bank_name: "BANK OF BARODA", upi: true, netbanking: true },
    { bank_name: "BANK OF INDIA", upi: true, netbanking: false },
    { bank_name: "BANK OF MAHARASHTRA", upi: true, netbanking: false },
    {
      bank_name: "THE GUJARAT STATE COOPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BARODA GUJARAT GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BARODA RAJASTHAN KHETRIYA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BARODA UTTAR PRADESH GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BASSEIN CATHOLIC COOPERATIVE BANK ",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BHAGINI NIVEDITA SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BHARAT COOPERATIVE BANK MUMBAI LIM",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "BHILWARA URBAN CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "CANARA BANK", upi: true, netbanking: true },
    {
      bank_name: "CAPITAL SMALL FINANCE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "CSB BANK LIMITED", upi: true, netbanking: true },
    { bank_name: "CENTRAL BANK OF INDIA", upi: true, netbanking: true },
    {
      bank_name: "CHAITANYA GODAVARI GRAMEENA BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "CHARTERED SAHAKARI BANK NIYAMITHA",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "CHHATTISGARH RAJYA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "CITI BANK", upi: true, netbanking: false },
    {
      bank_name: "CITIZEN CO-OPERATIVE BANK LTD - NOIDA",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "CITIZENS CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    { bank_name: "CITY UNION BANK LIMITED", upi: true, netbanking: true },
    {
      bank_name: "COASTAL LOCAL AREA BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "DAKSHIN BIHAR GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "DBS BANK INDIA LIMITED", upi: true, netbanking: false },
    { bank_name: "DCB BANK LIMITED", upi: true, netbanking: true },
    { bank_name: "DENA BANK", upi: true, netbanking: false },
    {
      bank_name: "DENA GUJARAT GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "DEUTSCHE BANK", upi: true, netbanking: false },
    { bank_name: "DHANALAKSHMI BANK", upi: true, netbanking: true },
    {
      bank_name: "DOMBIVLI NAGARI SAHAKARI BANK LIMI",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "EQUITAS SMALL FINANCE BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    {
      bank_name: "ESAF SMALL FINANCE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "FEDERAL BANK", upi: true, netbanking: false },
    {
      bank_name: "FINCARE SMALL FINANCE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "FINGROWTH CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "FINO PAYMENTS BANK", upi: true, netbanking: false },
    { bank_name: "G P PARSIK BANK", upi: true, netbanking: false },
    { bank_name: "HDFC BANK", upi: true, netbanking: false },
    {
      bank_name: "HIMACHAL PRADESH GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "HSBC BANK", upi: true, netbanking: false },
    {
      bank_name: "HUTATMA SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "IDBI BANK", upi: true, netbanking: false },
    { bank_name: "IDFC FIRST BANK LTD", upi: true, netbanking: true },
    {
      bank_name: "INDIA POST PAYMENT BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "INDIAN BANK", upi: true, netbanking: true },
    { bank_name: "INDIAN OVERSEAS BANK", upi: true, netbanking: true },
    { bank_name: "INDUSIND BANK", upi: true, netbanking: true },
    {
      bank_name: "IRINJALAKUDA TOWN CO-OPERATIVE BAN",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JAMMU AND KASHMIR BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    {
      bank_name: "JALGAON JANATA SAHAKARI BANK LIMIT",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JALNA MERCHANT'S CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JANA SMALL FINANCE BANK LTD",
      upi: true,
      netbanking: true,
    },
    {
      bank_name: "JANAKALYAN SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JANASEVA SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JANASEVA SAHAKARI BANK BORIVLI LIM",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JANATA SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JIO PAYMENTS BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "JIVAN COMMERCIAL CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "KALLAPPANNA AWADE ICHALKARANJI JAN",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "KALUPUR COMMERCIAL COOPERATIVE BAN",
      upi: true,
      netbanking: false,
    },
    { bank_name: "KARNATAKA BANK LIMITED", upi: true, netbanking: false },
    {
      bank_name: "KARNATAKA VIKAS GRAMEENA BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "KARUR VYSYA BANK", upi: true, netbanking: true },
    {
      bank_name: "KASHI GOMTI SAMYUT GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "KERALA GRAMIN BANK", upi: true, netbanking: false },
    {
      bank_name: "KONKAN MERCHANTILE CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "KOLHAPUR DISTRICT CENTRAL CO-OPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "KOTAK MAHINDRA BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    {
      bank_name: "KRISHNA BHIMA SAMRUDDHI LOCAL AREA BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "MAHARASHTRA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "MAHARASHTRA STATE COOPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MALAD SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "MALVIYA URBAN CO-OPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "MANIPUR RURAL BANK", upi: true, netbanking: false },
    {
      bank_name: "MANVI PATTANA SOUHARDA SAHAKARI BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "MARATHA COOPRATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "MEGHALAYA RURAL BANK", upi: true, netbanking: false },
    { bank_name: "MIZORAM RURAL BANK", upi: true, netbanking: false },
    {
      bank_name: "MODEL CO-OPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "NAGARIK SAHAKARI BANK MARYADIT, VIDISHA",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE NAINITAL BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "NKGSB COOPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "NORTH EAST SMALL FINANCE BANK LIMI",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "NSDL PAYMENTS BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "NUTAN NAGARIK SAHAKARI BANK LIMITE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PALI URBAN CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PASCHIM BANGA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PATAN NAGRIK SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PAYTM PAYMENTS BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "KARNATAKA GRAMIN BANK", upi: true, netbanking: false },
    { bank_name: "PRATHAMA BANK", upi: true, netbanking: false },
    {
      bank_name: "PRIME COOPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PRIYADARSHINI NAGARI SAHAKARI BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PUNE CANTONMENT SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "PUNJAB AND MAHARASTRA CO. BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "PUNJAB AND SIND BANK", upi: true, netbanking: false },
    { bank_name: "PUNJAB GRAMIN BANK", upi: true, netbanking: false },
    { bank_name: "PUNJAB NATIONAL BANK", upi: true, netbanking: true },
    {
      bank_name: "RAJASTHAN MARUDHARA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "RAJKOT NAGRIK SAHAKARI BANK LIMITE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "RANI CHANNAMMA MAHILA SAHAKARI BANK BELAGAVI",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SAMARTH SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SAMRUDDHI CO-OP BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SANDUE PATTANA SOUHARDA SAHAKARI BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SARVA HARYANA GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "SARVA UP GRAMIN BANK", upi: true, netbanking: false },
    {
      bank_name: "SARVODAYA COMMERCIAL CO-OPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "SAURASHTRA GRAMIN BANK", upi: true, netbanking: false },
    { bank_name: "SBM BANK INDIA LIMITED", upi: true, netbanking: false },
    {
      bank_name: "SHIVALIK SMALL FINANCE BANK LIMITE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHREE DHARATI CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHREE KADI NAGARIK SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI ARIHANT CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI BASAVESHWAR SAHAKARI BANK NIYAMIT, BAGALKOT",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI CHHATRAPATI RAJASHRI SHAHU UR",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI MAHILA SEWA SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI RAJKOT DISTRICT CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SHRI VEERSHAIV CO OP BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE SINDHUDURG DISTRICT CENTRAL CO",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SMRITI NAGRIK SAHAKARI BANK MARYADIT, MANDSAUR",
      upi: true,
      netbanking: false,
    },
    { bank_name: "SOUTH INDIAN BANK", upi: true, netbanking: true },
    {
      bank_name: "SRI VASAVAMBA COOPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "STANDARD CHARTERED BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "STATE BANK OF INDIA", upi: true, netbanking: false },
    {
      bank_name: "STERLING URBAN CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SUCO SOUHARDA SAHAKARI BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE SURAT PEOPLES COOPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SURYODAY SMALL FINANCE BANK LIMITE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SUTEX COOPERATIVE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SUVARNAYUG SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE SHAMRAO VITHAL COOPERATIVE BAN",
      upi: true,
      netbanking: false,
    },
    { bank_name: "SYNDICATE BANK", upi: true, netbanking: false },
    {
      bank_name: "TAMILNAD MERCANTILE BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    { bank_name: "TELANGANA GRAMIN BANK", upi: true, netbanking: false },
    {
      bank_name: "TELANGANA STATE COOP APEX BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE THANE BHARAT SAHAKARI BANK LIM",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE ADARSH URBAN CO-OP. BANK LTD., HYDERABAD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE AHMEDABAD DISTRICT COOP BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE AHMEDNAGAR MERCHANTS CO-OPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE AKOLA DISTRICT CENTRAL COOPERA",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE ANAND MERCANTILE CO-OP. BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE ANDHRA PRADESH STATE COOPERATI",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE BANASKANTHA DISTRICT CENTRAL CO-OP. BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE BARAMATI SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE COSMOS CO OPERATIVE BANK LIMIT",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE DARUSSALAM CO-OPERATIVE URBAN BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE GADCHIROLI DISTRICT CENTRAL CO",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE GAYATRI CO-OPERATIVE URBAN BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE HASTI COOP BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "HIMACHAL PRADESH STATE COOPERATIVE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE KAIRA DISTRICT CENTRAL CO-OP. BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE KALYAN JANATA SAHAKARI BANK LT",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE KANAKMAHALAKSHMI CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE LAKSHMI VILAS BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "GS MAHANAGAR CO-OPERATIVE BANK LIM",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MEHSANA URBAN COOPERATIVE BANK",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MERCHANTS SOUHARDA SAHAKARI BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MODASA NAGARIK SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MUNICIPAL COOPERATIVE BANK LIM",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE MUSLIM CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE POCHAMPALLY CO-OPERATIVE URBAN BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "RBL BANK LIMITED", upi: true, netbanking: true },
    {
      bank_name: "THE SABARKANTHA DISTRICT CENTRAL COOP BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "SARASWAT COOPERATIVE BANK LIMITED",
      upi: true,
      netbanking: true,
    },
    {
      bank_name: "THE SATARA DISTRIC CENTRAL CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE SURAT DISTRICT COOPERATIVE BAN",
      upi: true,
      netbanking: false,
    },
    { bank_name: "TJSB SAHAKARI BANK LTD", upi: true, netbanking: false },
    {
      bank_name: "THE UDAIPUR MAHILA SAMRIDHI URBAN CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE UDAIPUR MAHILA URBAN CO-OP BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE UDAIPUR URBAN CO OPERATIVE BAN",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE URBAN COOPERATIVE BANK LTD DHARANGAON",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE VALLABH VIDYANAGAR COMMERCIAL CO-OPERATIVE BANK LTD",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE VARACHHA COOPERATIVE BANK LIMI",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE VIJAY CO OPERATIVE BANK LIMITE",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE VISAKHAPATNAM CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "THE VISHWESHWAR SAHAKARI BANK LIMI",
      upi: true,
      netbanking: false,
    },
    { bank_name: "TRIPURA GRAMIN BANK", upi: true, netbanking: false },
    { bank_name: "UCO BANK", upi: true, netbanking: false },
    {
      bank_name: "UJJIVAN SMALL FINANCE BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "UNION BANK OF INDIA", upi: true, netbanking: true },
    {
      bank_name: "UTTARAKHAND GRAMIN BANK",
      upi: true,
      netbanking: false,
    },
    { bank_name: "VANANCHAL GRAMIN BANK", upi: true, netbanking: false },
    {
      bank_name: "VASAI VIKAS SAHAKARI BANK LIMITED",
      upi: true,
      netbanking: false,
    },
    { bank_name: "VIJAYA BANK", upi: true, netbanking: false },
    {
      bank_name: "VIKAS SOUHARDA CO-OPERATIVE BANK LTD.",
      upi: true,
      netbanking: false,
    },
    {
      bank_name: "YADAGIRI LAKSHMI NARASIMHA SWAMY CO-OP URBAN BANK LTD",
      upi: true,
      netbanking: false,
    },
    { bank_name: "YES BANK", upi: true, netbanking: true },
  ];