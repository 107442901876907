<script>
  export let key;
  export let value;
</script>

<div
  style="width: 100%; display: flex; justify-content: end; align-items: center; margin-bottom: 12px;"
>
  <div
    style="width: 100%; font-style: normal; font-weight: 500; font-size: 14px; line-height: 22px; letter-spacing: 0.3px; text-transform: capitalize; color: rgba(70, 68, 72, 0.72);"
  >
    {key}
  </div>
  <div
    style="width: 100%; font-weight: 600; font-size: 14px; line-height: 20px; letter-spacing: 0.26px; color: #464448; text-align: end;"
  >
    {value}
  </div>
</div>
