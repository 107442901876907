<script>
  import Icon from "../../components/commons/Icon.svelte";
  export let note = "";
</script>

<div class="text-start">
  <Icon icon="info" class="note-text" color="#505869" size="20px" />
  <span class="note-text">
    Note: {note}
  </span>
</div>

<style>
  .text-start {
    display: flex;
  }
  .note-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #505869;
    display: inline;
    font-family: Urbanist;
    font-weight: 500;
  }

  @media only screen and (min-width: 768px) {
    .text-start {
      gap: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    .note-text {
      margin-left: 8px;
      line-height: 19px;
    }
  }
</style>
