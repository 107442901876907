<script>
  export let timelines = [];
</script>

<div class="container remove-margin-top-mobile">
  {#each timelines as timeline}
    <div class="timeline-block timeline-block-right">
      <div class="marker {timeline.status}">
        <!-- <i
          class="fa {timeline.status === 'warning'
            ? //TODO:Change from font-awesome to google icons
              'fa-circle'
            : 'fa-check'}  {timeline.status}"
          aria-hidden="true"
        /> -->
      </div>
      <div
        class="timeline-content {timeline.status === '' ? 'inactive' : ''} px-1"
      >
        {timeline.description}
      </div>
    </div>
  {/each}
</div>

<style>
  .container {
    width: 100%;
    padding: 50px 0;
    /* margin: 50px auto; */
    bottom: -50px;
    position: relative;
    overflow: hidden;
  }

  .container:before {
    content: "";
    position: absolute;
    top: 64px;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    bottom: 96px;
    background: #ccd1d9;
    z-index: 1;
  }
  .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
  }

  .timeline-block-right {
    float: right;
  }

  .timeline-block-left {
    float: left;
    direction: rtl;
  }

  .marker {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #e5e9f2;
    background: #fff;
    z-index: 9999;
    text-align: center;
    position: relative;
    left: 0px;
  }

  .marker.active {
    width: 24px;
    height: 24px;
    /* background: #34a751; */
    left: 0px;
    background-image: url("/assets/images/journey_tick.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .marker.warning {
    height: 24px;
    width: 24px;
    left: 0px;
    border-radius: 12px;
    font-size: 12px;
    background: linear-gradient(0deg, #f9a000, #f9a000),
      linear-gradient(0deg, #f9a000, #f9a000), #f9a000;
    /* background-color: white;
    color: #fff; */
    background-image: url("/assets/images/journey_warning.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  i.fa.fa-circle.warning {
    padding-top: 0.32rem;
  }
  i.fa.fa-check {
    display: none;
  }
  i.fa.fa-check.active {
    display: block !important;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 4px;
    margin: auto;
    height: 50%;
    color: #fff;
  }

  .timeline-content {
    width: 85%;
    margin-top: 8px;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #404165;
  }

  .inactive {
    font-weight: 400;
    opacity: 0.6;
  }

  .container:before {
    left: 12px;
    width: 2px;
  }
  .timeline-block {
    width: 100%;
    margin-bottom: 30px;
  }
  .timeline-block-right {
    float: none;
  }

  .timeline-block-left {
    float: none;
    direction: ltr;
  }

  @media only screen and (max-width: 768px) {
  }
  .remove-margin-top-mobile {
    /* padding-top: 0px !important; */
    margin-top: -44px !important;
  }
</style>
