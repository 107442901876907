<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Button from "../../components/commons/Button.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import Snackbar, { Label } from "@smui/snackbar";
  import Dialog, { Content } from "@smui/dialog";
  import Cropper from "svelte-easy-crop";
  import Input from "../../components/commons/Input.svelte";
  import IconButton from "@smui/icon-button";
  import { routeCheck } from "../../services/routeCheck";
  import SegmentedButton from "../../components/commons/SegmentedButton.svelte";
  import Tooltip, { Wrapper, Content as ContentTooltip } from "@smui/tooltip";
  import Bullets from "../../components/commons/Bullets.svelte";
  import { countryCodes } from "./CountryCodes";
  import { cityCodes } from "./CityCodes.js";
  import { valueArray } from "./../../services/function";
  import { cityOptions } from "./cityArray.js";
  import OptionGroupMultiple from "../../components/FDCC/OptionGroupMultiple.svelte";
  import Checkbox from "../../components/commons/Checkbox.svelte";
  import _ from "lodash";
  import InputAutoFill from "../../components/commons/InputAutoFill.svelte";
  import InputAutocomplete from "../../components/commons/InputAutocomplete.svelte";
  let crop = {
    x: 0,
    y: 0,
  };
  let zoom = 1;
  let image, fileInput, pixelCrop, croppedImage;
  let modalOpen = false;
  let isFNameReadOnly;
  let isMNameReadOnly;
  let snackbarOpen;
  let snackbarMessage = "";
  let checkbox_pep = false;
  let checkbox_tra = false;
  let searchable = false;
  let modeOfOperationChecked = true;
  let politically_exposed = "";
  let yes_no_list = ["Yes", "No"];
  let declaration_is_indian = "";
  let declaration_is_non_indian = "";

  let sourceOfFundSelectedList = [];
  let sourceOfFundList = [
    "Salary",
    "Business",
    "Investment",
    "Inheritance",
    "Pension",
    "Rent",
    "Agriculture",
    "Other",
  ];

  let data = {
    fatherFullName: "",
    motherFullName: "",
    maritalStatusValue: "",
    genderValue: "",
    mothersMaidenName: "",
    numberOfDependents: "",
    pwd: "",
    placeOfBirth: "",
    countryOfBirth: "IN",
    citizenship: "IN",
    religion: "",
    religionOther: "",
    category: "",
    illiterate: "No",
    illiterateIdentification: "",
    passportNo: "",
    educationalQualificationValue: "",
    educationalQualificationOther: "",
    profession: "",
    professionOther: "",
    occupationValue: "",
    occupationNameOfEmployer: "",
    occupationDesignation: "",
    occupationBusiness: "",
    netWorth: "",
    grossAnnualIncome: 100000,
    sourceOfFundValue: "",
    sourceOfFundOther: "",
    noOfTxnCash: "",
    noOfTxnNonCash: "",
    expectedTurnoverMonthly: "",
    expectedTurnoverAnnual: "",
    imageUrl: "",
    pep: false,
    pepDetails: "",
  };
  export let currentRoute;
  //obtaining father_fullname and mother_fullname from backend
  onMount(async () => {
    routeCheck(currentRoute.name);
    await request("/api/caf/prefill", "GET").then((response) => {
      // data.fatherFullName = response.FATHER_FULLNAME;
      // data.motherFullName = response.MOTHER_FULLNAME;
      // isFNameReadOnly = data.fatherFullName !== "";
      // isMNameReadOnly = data.motherFullName !== "";

      if (response.type === "success") {
        data.fatherFullName = _.get(response, "caf_details.fatherFullName");
        data.motherFullName = _.get(response, "caf_details.motherFullName");
        data.maritalStatusValue = _.get(
          response,
          "caf_details.maritalStatusValue",
          ""
        );
        data.genderValue = _.get(response, "caf_details.genderValue", "");
        data.mothersMaidenName = _.get(
          response,
          "caf_details.mothersMaidenName",
          ""
        );
        data.numberOfDependents = _.get(
          response,
          "caf_details.numberOfDependents",
          ""
        );
        data.pwd = _.get(response, "caf_details.pwd", "");
        data.placeOfBirth = _.get(response, "caf_details.placeOfBirth", "");
        data.countryOfBirth = _.get(response, "caf_details.countryOfBirth", "");
        data.citizenship = _.get(response, "caf_details.citizenship", "");
        data.religion = _.get(response, "caf_details.religion", "");
        data.religionOther = _.get(response, "caf_details.religionOther", "");
        data.category = _.get(response, "caf_details.category", "");
        data.illiterate = _.get(response, "caf_details.illiterate", "");
        data.illiterateIdentification = _.get(
          response,
          "caf_details.illiterateIdentification",
          ""
        );
        data.passportNo = _.get(response, "caf_details.passportNo", "");
        data.educationalQualificationValue = _.get(
          response,
          "caf_details.educationalQualificationValue",
          ""
        );
        data.educationalQualificationOther = _.get(
          response,
          "caf_details.educationalQualificationOther",
          ""
        );
        data.profession = _.get(response, "caf_details.profession", "");
        data.professionOther = _.get(
          response,
          "caf_details.professionOther",
          ""
        );
        data.occupationValue = _.get(
          response,
          "caf_details.occupationValue",
          ""
        );
        data.occupationNameOfEmployer = _.get(
          response,
          "caf_details.occupationNameOfEmployer",
          ""
        );
        data.occupationDesignation = _.get(
          response,
          "caf_details.occupationDesignation",
          ""
        );
        data.occupationBusiness = _.get(
          response,
          "caf_details.occupationBusiness",
          ""
        );
        data.netWorth = _.get(response, "caf_details.netWorth", "");
        data.grossAnnualIncome = _.get(
          response,
          "caf_details.grossAnnualIncome",
          ""
        );
        data.sourceOfFund = _.get(response, "caf_details.sourceOfFund[0]", "");
        data.sourceOfFundOther = _.get(
          response,
          "caf_details.sourceOfFundOther",
          ""
        );
        data.noOfTxnCash = _.get(response, "caf_details.noOfTxnCash", "");
        data.noOfTxnNonCash = _.get(response, "caf_details.noOfTxnNonCash", "");
        data.expectedTurnoverMonthly = _.get(
          response,
          "caf_details.expectedTurnoverMonthly",
          ""
        );

        data.expectedTurnoverAnnual = _.get(
          response,
          "caf_details.expectedTurnoverAnnual",
          ""
        );

        data.imageUrl = _.get(response, "caf_details.signature_image", "");
        image = _.get(response, "caf_details.signature_image", "");

        data.pep = _.get(response, "caf_details.pep", "");
        data.pepDetails = _.get(response, "caf_details.pepDetails", "");

        if (data.pep === true) {
          politically_exposed = yes_no_list[0];
        } else {
          politically_exposed = yes_no_list[1];
        }

        declaration_is_indian =
          _.get(response, "caf_details.indianTaxResident", "") === true
            ? yes_no_list[0]
            : yes_no_list[1];

        declaration_is_non_indian =
          _.get(response, "caf_details.nonIndianResident", "") === true
            ? yes_no_list[0]
            : yes_no_list[1];

        sourceOfFundSelectedList = _.get(
          response,
          "caf_details.sourceOfFund",
          []
        );

        modeOfOperationChecked = true;
      }
    });
  });

  //making dropdown to searchable for occupation
  const toggle = () => {
    searchable = !searchable;
  };

  //uploading user signature as image and providing a preview
  let onFileSelected = async (e) => {
    let imageFile = e.target.files[0];
    let imageb64 = await toBase64(imageFile);
    data.imageUrl = imageb64;
    image = imageb64;
    modalOpen = true;
  };

  let profilePicture, style;

  let previewCrop = (e) => {
    pixelCrop = e.detail.pixels;
    const { x, y, width } = e.detail.pixels;
    const scale = 200 / width;
    profilePicture.style = `margin: ${-y * scale}px 0 0 ${
      -x * scale
    }px; width: ${profilePicture.naturalWidth * scale}px;`;
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    return canvas.toDataURL();

    // As a blob
    /*return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });*/
  }

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  export function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  let removeImage = () => {
    image = null;
  };

  //on clicking "confirm", user caf details will be pushed to the database
  let formConfirm = async (e) => {
    e.preventDefault();

    data.fatherFullName = data.fatherFullName.trim();
    data.motherFullName = data.motherFullName.trim();
    data.mothersMaidenName = data.mothersMaidenName.trim();
    data.placeOfBirth = data.placeOfBirth.trim();
    data.occupationBusiness = data.occupationBusiness.trim();
    data.occupationNameOfEmployer = data.occupationNameOfEmployer.trim();
    data.occupationDesignation = data.occupationDesignation.trim();

    if (!data.fatherFullName || data.fatherFullName.length < 3) {
      snackbarMessage = "Please enter father's name correctly";
      snackbarOpen.forceOpen();

      return;
    }

    if (!data.motherFullName || data.motherFullName.length < 3) {
      snackbarMessage = "Please  enter mother's name correctly";
      snackbarOpen.forceOpen();

      return;
    }

    if (!data.mothersMaidenName || data.mothersMaidenName.length < 3) {
      snackbarMessage = "Please  enter mother's maiden name correctly";
      snackbarOpen.forceOpen();
      return;
    }

    if (data.numberOfDependents === "") {
      snackbarMessage = "Please add number of dependents";
      snackbarOpen.forceOpen();
      return;
    }

    // if (!data.pwd || data.pwd.length < 1) {
    //   snackbarMessage = "Please add person of disablility";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    if (!data.genderValue || data.genderValue.length < 1) {
      snackbarMessage = "Please make sure if you have selected Gender";
      snackbarOpen.forceOpen();
      return;
    }

    if (!data.maritalStatusValue || data.maritalStatusValue.length < 1) {
      snackbarMessage = "Please make sure if you have selected Marital Status ";
      snackbarOpen.forceOpen();

      return;
    }

    // if (!data.placeOfBirth || data.placeOfBirth.length < 3) {
    //   snackbarMessage = "Please add a valid place of birth";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    if (!data.countryOfBirth || data.countryOfBirth.length < 1) {
      snackbarMessage = "Please select country of birth";
      snackbarOpen.forceOpen();

      return;
    }

    if (!modeOfOperationChecked) {
      snackbarMessage = "Please select mode of operation";
      snackbarOpen.forceOpen();

      return;
    }

    if (!data.citizenship || data.citizenship.length < 1) {
      snackbarMessage = "Please select citizeship";
      snackbarOpen.forceOpen();

      return;
    }

    // if (!data.religion || data.religion.length < 1) {
    //   snackbarMessage = "Please select religion";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    if (data.religion === "Others" && data.religionOther.length < 1) {
      snackbarMessage = "Please add religion other";
      snackbarOpen.forceOpen();

      return;
    }

    // if (!data.category || data.category.length < 1) {
    //   snackbarMessage = "Please select category";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    // if (!data.illiterate || data.illiterate.length < 1) {
    //   snackbarMessage = "Please select illiterate option";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    if (data.illiterate === "Yes" && data.illiterateIdentification.length < 1) {
      snackbarMessage = "Please select identification mark";
      snackbarOpen.forceOpen();

      return;
    }

    if (
      !data.educationalQualificationValue ||
      data.educationalQualificationValue.length < 1
    ) {
      snackbarMessage =
        "Please make sure if you have selected Education Qualification";
      snackbarOpen.forceOpen();

      return;
    }

    if (
      data.educationalQualificationValue === "Others" &&
      data.educationalQualificationOther.length < 1
    ) {
      snackbarMessage = "Please add other education qualification";
      snackbarOpen.forceOpen();

      return;
    }

    if (!data.occupationValue || data.occupationValue.length < 1) {
      snackbarMessage = "Please select occupation type";
      snackbarOpen.forceOpen();

      return;
    }

    // if (data.occupationValue.includes("Service")) {
    //   if (data.occupationNameOfEmployer.length < 1) {
    //     snackbarMessage = "Please add name of employer";
    //     snackbarOpen.forceOpen();
    //     return;
    //   }

    //   if (data.occupationDesignation.length < 1) {
    //     snackbarMessage = "Please add designation";
    //     snackbarOpen.forceOpen();
    //     return;
    //   }
    // }

    // if (
    //   data.occupationValue.includes("Business") &&
    //   data.occupationBusiness.length < 1
    // ) {
    //   snackbarMessage = "Please add nature of business";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    // if (data.occupationValue.includes("Others") && data.profession.length < 1) {
    //   snackbarMessage = "Please select profession";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    // if (
    //   data.occupationValue.includes("Others") &&
    //   data.profession === "Others" &&
    //   data.professionOther.length < 1
    // ) {
    //   snackbarMessage = "Please add profession others";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    if (!data.netWorth || data.netWorth.length < 1) {
      snackbarMessage = "Please select net worth";
      snackbarOpen.forceOpen();

      return;
    }

    if (data.grossAnnualIncome < 1) {
      snackbarMessage = "Minimum amount must be ₹1,00,000";
      snackbarOpen.forceOpen();

      return;
    }

    // if (!data.noOfTxnCash === "") {
    //   snackbarMessage = "Please select expected numbers of cash transactions";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    // if (!data.noOfTxnNonCash === "") {
    //   snackbarMessage =
    //     "Please select expected numbers of non-cash transactions";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    // if (!data.expectedTurnoverMonthly === "") {
    //   snackbarMessage = "Please select monthly expected turnover";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    // if (!data.expectedTurnoverAnnual === "") {
    //   snackbarMessage = "Please select annual expected turnover";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    if (sourceOfFundSelectedList.length < 1) {
      snackbarMessage = "Please select source of fund";
      snackbarOpen.forceOpen();
      return;
    }

    if (
      sourceOfFundSelectedList.includes("Other") &&
      data.sourceOfFundOther.length < 1
    ) {
      snackbarMessage = "Please add other source of fund";
      snackbarOpen.forceOpen();
      return;
    }

    // if (!data.imageUrl || data.imageUrl.length < 1) {
    //   snackbarMessage =
    //     "Please make sure if you have uploaded an image of your signature";
    //   snackbarOpen.forceOpen();

    //   return;
    // }

    if (politically_exposed.length < 1) {
      snackbarMessage = "Please make sure to check politically exposed status.";
      snackbarOpen.forceOpen();

      return;
    }

    if (declaration_is_indian.length === 0) {
      snackbarMessage = "Please select if you are a Tax Resident of India.";
      snackbarOpen.forceOpen();

      return;
    }

    if (declaration_is_non_indian.length === 0) {
      snackbarMessage =
        "Please select if you are a resident of any other country.";
      snackbarOpen.forceOpen();

      return;
    }

    data = {
      ...data,
      pep: politically_exposed === yes_no_list[0] ? true : false,
      indianTaxResident:
        declaration_is_indian === yes_no_list[0] ? true : false,
      nonIndianResident:
        declaration_is_non_indian === yes_no_list[0] ? true : false,
      sourceOfFund: sourceOfFundSelectedList,
    };

    window.loading(true);

    await request("/api/caf", "POST", { data })
      .then((response) => {
        window.loading(false);
        navigateTo("/");
      })
      .catch((err) => {});
  };
</script>

<WizardBody title="Customer Application Form" back="">
  <div class="input-container">
    <div class="select-container">
      <div class="label">Father's Full Name*</div>
      {#if isFNameReadOnly}
        <input
          type="text-only"
          readonly
          value={data.fatherFullName}
          class="input-readonly"
        />
      {:else}
        <Input
          type="text-only"
          placeholder="Enter your Father's Name"
          style="width: 100%"
          bind:value={data.fatherFullName}
          containerStyle="padding: 0px"
          maxLength={18}
        />
      {/if}
    </div>
  </div>
  <div class="input-container">
    <div class="select-container">
      <div class="label">Mother's Full Name*</div>
      {#if isMNameReadOnly}
        <input
          type="text-only"
          readonly
          value={data.motherFullName}
          class="input-readonly"
          style=" padding: 18px; margin-bottom: 0px; "
        />
      {:else}
        <Input
          type="text-only"
          placeholder="Enter your Mother's Name"
          style="width: 100%"
          bind:value={data.motherFullName}
          containerStyle="padding: 0px"
          maxLength={18}
        />
      {/if}
    </div>

    <div class="select-container" style="">
      <div class="label">Mother's Maiden Name*</div>
      <Input
        type="text-only"
        placeholder="Enter your Mother's Name"
        style="width: 100%"
        containerStyle="padding: 0px"
        bind:value={data.mothersMaidenName}
        maxLength={18}
        onchange={(event) => {
          data.mothersMaidenName = event.target.value.replace(/[^A-Za-z]/g, "");
        }}
      />
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Number of Dependents*</div>

      <Input
        type="number"
        placeholder="Number of Dependents"
        style="width: 100%"
        bind:value={data.numberOfDependents}
        containerStyle="padding: 0px"
      />
    </div>

    <div class="select-container">
      <div class="label">Person with Disability</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.pwd}
        >
          <option value="" disabled selected>Select PWD</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Gender*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.genderValue}
        >
          <option value="" disabled selected>Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Transgender">Transgender</option>
          <option value="Others">Others</option>
        </select>
      </div>
    </div>

    <div class="select-container">
      <div class="label">Marital Status*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.maritalStatusValue}
        >
          <option value="" disabled selected>Marital Status</option>
          <option value="Married">Married</option>
          <option value="Single">Unmarried</option>
          <option value="Others">Others</option>
        </select>
      </div>
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Country of Birth*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="country_code"
          name="country_code"
          class="state-select"
          bind:value={data.countryOfBirth}
        >
          <!-- <option value="" selected>Select Country</option> -->
          {#each Object.entries(countryCodes) as [code, country]}
            <option value={code}>{country} ({code})</option>
          {/each}
        </select>
      </div>
    </div>

    <div class="select-container">
      <div class="label">Citizenship*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="citizenship"
          name="citizenship"
          class="state-select"
          bind:value={data.citizenship}
        >
          <!-- <option value="IN" selected >India (IN)</option> -->
          {#each Object.entries(countryCodes) as [code, country]}
            <option value={code}>{country} ({code})</option>
          {/each}
        </select>
      </div>
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Place of birth (City/Town)</div>
      <InputAutocomplete
        bind:inputValue={data.placeOfBirth}
        optionsList={cityOptions}
        placeholder="Place of birth (City/Town)"
      />
      <!--<Input
        type="text-only"
        placeholder="Enter place of birth"
        style="width: 100%"
        bind:value={data.placeOfBirth}
        containerStyle="padding: 0px"
      />-->
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Religion</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.religion}
        >
          <option value="" disabled selected>Religion</option>
          <option value="Hindu">Hindu</option>
          <option value="Muslim">Muslim</option>
          <option value="Sikh">Sikh</option>
          <option value="Christian">Christian</option>
          <option value="Others">Others</option>
        </select>
      </div>
      {#if data.religion === "Others"}
        <Input
          type="text"
          placeholder="Please specify other"
          style="width: 100%"
          bind:value={data.religionOther}
          containerStyle="padding: 0px"
        />
      {/if}
    </div>

    <div class="select-container">
      <div class="label">Category</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.category}
        >
          <option value="" disabled selected>Category</option>
          <option value="General">General</option>
          <option value="OBC">OBC</option>
          <option value="SC">SC</option>
          <option value="ST">ST</option>
        </select>
      </div>
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Illiterate</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.illiterate}
        >
          <option value="No" selected>No</option>
          <option value="Yes">Yes</option>
          <!-- <option value="No">No</option> -->
        </select>
      </div>

      {#if data.illiterate === "Yes"}
        <Input
          type="text"
          placeholder="Enter identification mark"
          style="width: 100%"
          bind:value={data.illiterateIdentification}
          containerStyle="padding: 0px"
        />
      {/if}
    </div>

    <div class="select-container">
      <div class="label">Passport No</div>
      <Input
        type="text"
        placeholder="Passport no"
        style="width: 100%"
        bind:value={data.passportNo}
        containerStyle="padding: 0px"
        maxLength={9}
        onchange={() => {
          data.passportNo = data.passportNo.toUpperCase();
        }}
      />
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Educational Qualification*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.educationalQualificationValue}
        >
          <option value="" disabled selected>Educational Qualification</option>
          <option value="Below Metric (Less than 10th)"
            >Below Metric (Less than 10th)</option
          >
          <option value="Metric (10th)">Metric (10th)</option>
          <option value="Pre-University (12th)">Pre-University (12th)</option>
          <option value="Graduate Bachelors">Graduate Bachelors</option>
          <option value="Masters">Masters</option>
          <option value="Others">Others</option>
        </select>
      </div>

      {#if data.educationalQualificationValue === "Others"}
        <Input
          type="text"
          placeholder="Please specify others"
          style="width: 100%"
          bind:value={data.educationalQualificationOther}
          containerStyle="padding: 0px"
        />
      {/if}
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Occupation Type*</div>
      <div
        style="width: 100%; background-color: #fff; border-radius: 8px;"
        class="input-"
      >
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.occupationValue}
        >
          <option value="" disabled selected>Occupation Type</option>

          <option value="S - Service - Private / Public / Government"
            >S - Service - Private / Public / Government</option
          >
          <option value="B - Business - Non-categorized"
            >B - Business - Non-categorized</option
          >
          <option
            value="O - Others - Professional / Self Employed / Retired / Housewife / Student"
            >O - Others - Professional / Self Employed / Retired / Housewife /
            Student</option
          >
        </select>
      </div>
    </div>

    {#if data.occupationValue.includes("Others")}
      <div class="select-container">
        <div class="label">Profession</div>
        <div style="width: 100%; background-color: #fff; border-radius: 8px;">
          <select
            id="state"
            name="state"
            class="state-select"
            bind:value={data.profession}
          >
            <option value="" disabled selected>Profession</option>
            <option value="CA / CS / CMA">CA / CS / CMA</option>
            <option value="Doctor">Doctor</option>
            <option value="Lawyer">Lawyer</option>
            <option value="Others">Others</option>
          </select>
        </div>
        {#if data.profession === "Others"}
          <Input
            type="text"
            placeholder="Please specify other"
            style="width: 100%"
            bind:value={data.professionOther}
            containerStyle="padding: 0px"
          />
        {/if}
      </div>
    {/if}
  </div>

  {#if data.occupationValue.includes("Business")}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label">Nature of Business</div>
        <Input
          type="text-only"
          placeholder="Nature of Business"
          style="width: 100%"
          containerStyle="padding: 0px"
          bind:value={data.occupationBusiness}
        />
      </div>
    </div>
  {/if}

  {#if data.occupationValue.includes("Service")}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label">Name of Employer</div>
        <Input
          type="text-only"
          placeholder="Name of the Employer"
          style="width: 100%"
          containerStyle="padding: 0px"
          bind:value={data.occupationNameOfEmployer}
        />
      </div>

      <div class="select-container" style="">
        <div class="label">Designation</div>
        <Input
          type="text-only"
          placeholder="Designation"
          style="width: 100%"
          containerStyle="padding: 0px"
          bind:value={data.occupationDesignation}
        />
      </div>
    </div>
  {/if}

  <div class="input-container">
    <div class="select-container">
      <div class="label">Net Worth*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.netWorth}
        >
          <option value="" disabled selected>Net Worth</option>

          <option value="<= 25 lakh">&lt;= 25 lakh</option>
          <option value="25 lakh to 49.99 lakh">25 lakh to 49.99 lakh</option>
          <option value="50 lakh to 99.99 lakh">50 lakh to 99.99 lakh</option>
          <option value="1 crore to 2.49 crore">1 crore to 2.49 crore</option>
          <option value="2.5 crore to 4.99 crore"
            >2.5 crore to 4.99 crore</option
          >
          <option value=">= 5 crore">&gt;= 5 crore</option>
        </select>
      </div>
    </div>

    <div class="select-container">
      <div class="label">Gross Annual Income (INR)*</div>
      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <Input
          type="number"
          placeholder="Gross Annual Income"
          style="width: 100%"
          bind:value={data.grossAnnualIncome}
          containerStyle="padding: 0px"
        />
        <!-- <select
          id="state"
          name="state"
          class="state-select"
          bind:value={data.grossAnnualIncome}
        >
          <option value="" disabled selected>Gross Annual Income</option>

          <option value="> 1 Lakh ">&lt; 1 Lakh </option>
          <option value="1 Lakh < Annual Income <= 3 Lakh"
            >1 Lakh &lt; Annual Income &lt;= 3 Lakh</option
          >
          <option value="3 Lakh < Annual Income <= 5 Lakh"
            >3 Lakh &lt; Annual Income &lt;= 5 Lakh</option
          >
          <option value="5 Lakh < Annual Income <= 7.5 Lakh"
            >5 Lakh &lt; Annual Income &lt;= 7.5 Lakh</option
          >
          <option value="7.5 Lakh < Annual Income <= 10 Lakh"
            >7.5 Lakh &lt; Annual Income &lt;= 10 Lakh</option
          >
          <option value="10 Lakh < Annual Income <= 15 Lakh"
            >10 Lakh &lt; Annual Income &lt;= 15 Lakh</option
          >
          <option value="15 Lakh < Annual Income <= 25Lakh"
            >15 Lakh &lt; Annual Income &lt;= 25 Lakh</option
          >
          <option value="25 Lakh < Annual Income <= 50Lakh"
            >25 Lakh &lt; Annual Income &lt;= 50 Lakh</option
          >
          <option value="> 50Lakh">&gt; 50 Lakh</option>
        </select> -->
      </div>
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Expected number of transactions</div>
      <div class="label">Cash</div>

      <Input
        type="number"
        placeholder="Cash"
        style="width: 100%"
        containerStyle="padding: 0px"
        bind:value={data.noOfTxnCash}
      />
    </div>

    <div class="select-container">
      <div class="label">&nbsp;</div>
      <div class="label">Non Cash</div>

      <Input
        type="number"
        placeholder="Non Cash"
        style="width: 100%"
        containerStyle="padding: 0px"
        bind:value={data.noOfTxnNonCash}
      />
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Expected turnover in the account</div>
      <div class="label">Monthly</div>

      <Input
        type="number"
        placeholder="Monthly"
        style="width: 100%"
        containerStyle="padding: 0px"
        bind:value={data.expectedTurnoverMonthly}
      />
    </div>

    <div class="select-container">
      <div class="label">&nbsp;</div>
      <div class="label">Annual</div>

      <Input
        type="number"
        placeholder="Annual"
        style="width: 100%"
        containerStyle="padding: 0px"
        bind:value={data.expectedTurnoverAnnual}
      />
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Source Of Fund*</div>

      <OptionGroupMultiple
        bind:optionSelected={sourceOfFundSelectedList}
        bind:optionGroup={sourceOfFundList}
        style="gap: 18px"
      />

      {#if sourceOfFundSelectedList.includes("Other")}
        <Input
          type="text"
          placeholder="Please specify other"
          style="width: 100%"
          containerStyle="padding: 0px"
          bind:value={data.sourceOfFundOther}
        />
      {/if}
    </div>
  </div>

  <div class="input-container">
    <div class="select-container">
      <div class="label">Mode of operation*</div>

      <div style="margin-left: -12px">
        <Checkbox bind:checked={modeOfOperationChecked}
          ><div
            style="color: #464448; margin-left: -12px; font-weight: 400; font-size: 12px; line-height: 16px;"
          >
            Self
          </div>
        </Checkbox>
      </div>
    </div>
  </div>

  <div class="image">
    <div class="label" style="display: flex;">
      <span style="margin-right: 10px;"> Signature</span>
      <div class="info">
        <Wrapper rich>
          <Label
            ><img
              src="/assets/images/info.svg"
              alt="info"
              style="width: 16px; height: 16px"
            /></Label
          >
          <Tooltip>
            <ContentTooltip>
              <Bullets
                points={[
                  "Upload a scanned copy of your signature.",
                  "Signature cannot be in capital letters. ",
                  "Signature cannot be consecutive letters (aaa, bbb, cc, etc).",
                  "Signature cannot be an image.",
                  "Signature cannot be numbers.",
                ]}
              />
            </ContentTooltip>
          </Tooltip>
        </Wrapper>
      </div>
    </div>

    {#if !image}
      <input
        class="image-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        on:change={(e) => onFileSelected(e)}
        bind:this={fileInput}
      />
      <!-- <Note
        note="Please ensure that the signature is taken on white piece of paper, and upload."
      /> -->
    {:else}
      <div class="cropped-image">
        <div class="modal-header-icon">
          <IconButton on:click={removeImage} class="material-icons"
            >close</IconButton
          >
        </div>
        <img style="width:320px;max-width:100%" alt="" src={data.imageUrl} />
        <input name="imageUrl" type="hidden" bind:value={data.imageUrl} />
      </div>
    {/if}
  </div>
</WizardBody>

<WizardActionArea>
  <div class="userDetails">
    <div class="checkbox-pep">
      <!-- <Checkbox bind:checked={checkbox_pep} /> -->
      <div
        style="display: flex; gap: 6px; width: 100%; margin-bottom: 18px; align-items: center;"
      >
        <span style="width: 100%; display: flex; align-items: center;">
          <span>Are you a Politically Exposed Person?</span>

          <div class="info">
            <Wrapper rich>
              <Label
                ><img
                  src="/assets/images/info.svg"
                  alt="info"
                  style="width: 16px; height: 16px"
                /></Label
              >
              <Tooltip>
                <ContentTooltip>
                  <Bullets
                    points={[
                      "Politically Exposed Persons” (PEPs) are individuals who are or have been entrusted with prominent public functions by a foreign country, including the Heads of States/Governments, senior politicians, senior government or judicial or military officers, senior executives of state-owned corporations and important political party officials.",
                    ]}
                  />
                </ContentTooltip>
              </Tooltip>
            </Wrapper>
          </div>
        </span>
        <SegmentedButton
          choices={yes_no_list}
          bind:selected={politically_exposed}
        />
      </div>
      {#if politically_exposed === "Yes"}
        <input
          class="input-checkbox"
          type="text"
          placeholder="Provide Details"
          style="margin: 0px; margin-bottom: 16px"
          bind:value={data.pepDetails}
          required
        />
      {/if}
    </div>
    <div
      class="checkbox-tra"
      style=" flex-direction: column; align-items: flex-start;"
    >
      <!-- <Checkbox bind:checked={checkbox_tra}>
        <span> I am a Tax Resident of India</span>
      </Checkbox>
 -->
      <div
        style="display: flex; gap: 6px;  width: 100%; margin-bottom: 18px; align-items: center;"
      >
        <span style="width: 100%;">I am a Tax Resident of India</span>
        <SegmentedButton
          choices={yes_no_list}
          bind:selected={declaration_is_indian}
        />
      </div>
      {#if declaration_is_indian === "No"}
        <div class="popup-note">
          Online Account Opening facility is only available to Tax Residents of
          India
        </div>
      {/if}
      <div
        style="display: flex; gap: 6px; width: 100%; margin-bottom: 18px; align-items: center;"
      >
        <span style="width: 100%;"
          >I am not a resident of any other country</span
        >
        <SegmentedButton
          choices={yes_no_list}
          bind:selected={declaration_is_non_indian}
        />
      </div>
      {#if declaration_is_non_indian === "No"}
        <div class="popup-note">
          Online Account Opening facility is only available to Residents of
          India
        </div>
      {/if}
    </div>
  </div>
  <div style="position: relative">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button title="Confirm" onclick={formConfirm} />
</WizardActionArea>

<Dialog fullscreen bind:open={modalOpen}>
  <Content>
    <div style="position: relative; width: 100%; height: 300px;">
      <Cropper
        {image}
        bind:crop
        bind:zoom
        on:cropcomplete={previewCrop}
        aspect={2}
      />
    </div>
    <h2>Preview</h2>
    <div class="prof-pic-wrapper">
      <img
        bind:this={profilePicture}
        class="prof-pic"
        src={image}
        alt="Profile example"
        {style}
      />
    </div>
    <div class="button">
      <Button
        type="button"
        title="confirm"
        onclick={async () => {
          data.imageUrl = await getCroppedImg(image, pixelCrop);
          modalOpen = false;
        }}
      />
    </div>
  </Content>
</Dialog>

<style>
  .popup-note {
    color: red;
    font-weight: 400;
    font-size: 12px;
    margin-left: 0.1rem;
    margin-bottom: 0.2rem;
  }
  .info:hover {
    cursor: pointer;
  }
  .state-select {
    position: relative;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/images/select-caret-rx.png") no-repeat 100%;
    background-attachment: cover;
    border: 0;
    padding-right: 30px !important;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
    cursor: pointer;
  }
  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
    margin-bottom: 14px;
  }

  .checkbox-pep > span,
  .checkbox-tra > span {
    margin-left: -0.3125rem;
  }
  @media only screen and (min-width: 768px) {
    .input-editable {
      width: 100%;
      margin-left: -10px;
    }
    .input-container {
      display: flex;
      align-items: baseline;
      width: 100%;
      gap: 2rem;
      margin-top: 3rem;
    }

    .field-input {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 0.25rem;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 0.25rem;
    }

    .image {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.25rem;
      justify-content: space-between;
      margin-top: 2rem;
    }

    .image-input {
      background: #ffffff;
      border: 1px solid #eaf0f9;
      border-radius: 8px;
      resize: none;
      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      padding: 20px;
      display: flex;
      height: 100%;
      box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
      cursor: pointer;
      margin-top: 0.3125rem;
    }

    .input-readonly {
      display: block;
      background-color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1rem;
      border: 1px solid #eaf0f9;
      border-radius: 0.5rem;
      padding: 1.25rem;
      width: 100%;
      background: repeating-linear-gradient(45deg, transparent 10px, #eee 20px);
      outline: none;
      box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    }

    .button {
      margin-top: 0.75rem;
    }

    .userDetails {
      display: flex;
      flex-direction: column;
      margin-top: 7rem;
    }

    .cropped-image {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-end;
    }
  }
  .prof-pic-wrapper {
    height: 100px;
    width: 200px;
    position: relative;
    border: solid;
    overflow: hidden;
  }
  .prof-pic {
    position: absolute;
  }

  .checkbox-pep {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .checkbox-tra {
    display: flex;
    align-items: center;
  }

  .checkbox-tra {
    margin-bottom: 1rem;
  }

  .input-checkbox {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    outline: none;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
    width: 60%;
  }

  @media only screen and (max-width: 768px) {
    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.25rem;
    }

    .select-container,
    .field-input {
      gap: 0.125rem;
      margin-top: 0.75rem;
    }

    .image-input {
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 0.75rem;
    }

    .input-readonly {
      margin-top: 0.75rem;
      display: flex;
      align-items: center;
      padding: 1rem;
      outline: none;
      border-radius: 0.5rem;
      border: none;
      background: repeating-linear-gradient(45deg, transparent 10px, #eee 20px);
      width: 100%;
    }

    .button {
      margin-top: 0.75rem;
    }

    .checkbox-pep {
      display: flex;
      align-items: center;
    }

    .input-checkbox {
      width: 100%;
    }

    .modal-header-icon {
      margin-left: 290px;
    }

    .image {
      margin-top: 0.75rem;
    }

    .checkbox-pep > span,
    .checkbox-tra > span {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
</style>
