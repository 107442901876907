<script>
  import dayjs from "dayjs";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import FdProgressIndicator from "../../components/FDCC/FdProgressIndicator.svelte";
  import FdDetailsHeaderItem from "../../components/FDCC/FdDetailsHeaderItem.svelte";
  import FdDetailsItem from "../../components/FDCC/FdDetailsItem.svelte";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import {
    bankToJsDate,
    dateDiff,
    parseDays,
    toCurrency,
  } from "../../services/function";
  import FdTransactionListItem from "../../components/FDCC/FDTransactionListItem.svelte";
  import { navigateTo } from "svelte-router-spa";
  import Button from "../../components/commons/Button.svelte";

  export let currentRoute;

  const fd_id = currentRoute.namedParams.fd_id;

  let fdData = {};
  let percentage = 0;
  let fdAmount = 0;

  let transactionList = [];

  let fdDetailsViewMore = false;
  let fdDetailsListViewMore = false;

  onMount(async () => {
    /* const fdGetRes = await request(`/api/fd/${fd_id}`, "GET");

    fdData = fdGetRes.data;
    fdAmount = fdData.amount;

    const mDate = bankToJsDate(fdData.data.MaturityDt); //TOOD: change later according to api data
    const creationDate = bankToJsDate(fdData.data.AcctOpnDt); //TOOD: change later according to api data
    const fdDays = dateDiff(mDate, new Date());
    const completedDays = dateDiff(new Date(), creationDate);
    percentage = (completedDays / fdDays) * 100;

    const fdTxnsGet = await request(`/api/fd/transactions/${fd_id}`, "GET");

    transactionList = fdTxnsGet.data; */
  });
</script>

<WizardBody title="" back="/">
  <div
    style="position: relative;display: flex;flex-direction: column;height: 100%;justify-content: center;align-items: center;"
  >
    <div style="margin-top: 130px;">
      <img
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTUiIGhlaWdodD0iOTUiIHZpZXdCb3g9IjAgMCA5NSA5NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM4XzcyMDQ4KSI+CjxyZWN0IHdpZHRoPSI5NSIgaGVpZ2h0PSI5NSIgcng9IjQ3LjUiIGZpbGw9IiMxM0FGRTkiLz4KPHBhdGggZD0iTTM5LjU4MjcgNjAuMDU1Nkw3My4xNjk4IDI2LjQ2NDlDNzQuNzE1MiAyNC45MTk0IDc3LjIyMTMgMjQuOTE5MyA3OC43NjczIDI2LjQ2NDNDODAuMzE0MSAyOC4wMSA4MC4zMTUgMzAuNTE3NSA3OC43Njg4IDMyLjA2MzdMNDIuMzgxNyA2OC40NTA4QzQwLjgzNTkgNjkuOTk2NyAzOC4zMjk2IDY5Ljk5NjcgMzYuNzgzOCA2OC40NTA4TDE3LjE5MDQgNDguODU3NUMxNS42NDQ5IDQ3LjMxMTkgMTUuNjQ0OSA0NC44MDYgMTcuMTkwNCA0My4yNjA0QzE4LjczNiA0MS43MTQ4IDIxLjI0MTkgNDEuNzE0OCAyMi43ODc1IDQzLjI2MDRMMzkuNTgyNyA2MC4wNTU2WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8zOF83MjA0OCI+CjxyZWN0IHdpZHRoPSI5NSIgaGVpZ2h0PSI5NSIgcng9IjQ3LjUiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg=="
        style="width: 96px; height: 96px"
        alt="H"
      />
    </div>
    <div
      style="margin-top: 24px;font-style: normal; font-weight: 700; font-size: 40px; line-height: 48px; text-align: center; color: #1E3868;"
    >
      Your Fixed Deposit is successfully closed.
    </div>
    <div
      style="margin-top: 12px; font-style: normal; font-weight: 400; font-size: 16px; line-height: 20px;text-align: center; color: #464448;"
    >
      The amount will be debited in your account within 24hrs.
    </div>
  </div>
</WizardBody>
<WizardActionArea style="margin-top: -24px">
  <Button onclick={() => navigateTo("/")} title="Go to home" />
</WizardActionArea>

<style>
</style>
