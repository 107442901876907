<script>
  export let id = "name";
  export let placeholder = "";
  export let type = "text";
  export let name = "name";
  export let style = "";
  export let value = "";
  export let containerStyle = "";

  let isFirstUpdated = false;

  export let maxLength = 1000;
  export let errorMsg = "";
  export let onchange = () => {};
  export let validate = () => {
    return "";
  };
</script>

<div class="container" style={containerStyle}>
  {#if type === "number"}
    <input
      {id}
      {name}
      type="number"
      class="input"
      {placeholder}
      {style}
      bind:value
      on:keypress
      on:blur={(e) => {
        isFirstUpdated = true;
        errorMsg = validate(e.target.value);
        onchange(e);
      }}
      on:focusin={() => {
        window.setFocus(true);
      }}
      on:focusout={() => {
        window.setFocus(false);
      }}
      on:input={(e) => {
        if (maxLength && maxLength > 0 && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
          value = e.target.value.slice(0, maxLength);
        }
        if (isFirstUpdated === true) {
          errorMsg = validate(e.target.value);
        }
        onchange(e);
        /* inputValTemp = e.target.value;
        if (e.target.value.length > 0) {
          isFirstUpdated = true;
        } */
      }}
    />
  {:else if type === "text-only"}
    <input
      {id}
      {name}
      type="text"
      class="input"
      {placeholder}
      {style}
      bind:value
      on:keypress
      on:blur={(e) => {
        isFirstUpdated = true;
        errorMsg = validate(e.target.value);
        onchange(e);
      }}
      on:focusin={() => {
        window.setFocus(true);
      }}
      on:focusout={() => {
        window.setFocus(false);
      }}
      on:input={(e) => {
        if (maxLength && maxLength > 0 && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
          value = e.target.value.slice(0, maxLength);
        }
        value = value.replace(/[^a-zA-Z\.'\s]/g, "");
        if (isFirstUpdated === true) {
          errorMsg = validate(e.target.value);
        }
        onchange(e);
      }}
    />
  {:else}
    <input
      {id}
      {name}
      type="text"
      class="input"
      {placeholder}
      {style}
      maxlength={maxLength}
      bind:value
      on:keypress
      on:blur={(e) => {
        isFirstUpdated = true;
        errorMsg = validate(e.target.value);
        onchange(e);
      }}
      on:focusin={() => {
        window.setFocus(true);
      }}
      on:focusout={() => {
        window.setFocus(false);
      }}
      on:input={(e) => {
        if (maxLength && maxLength > 0 && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
          value = e.target.value.slice(0, maxLength);
        }
        if (isFirstUpdated === true) {
          errorMsg = validate(e.target.value);
        }
        onchange(e);
        /* inputValTemp = e.target.value;
        if (e.target.value.length > 0) {
          isFirstUpdated = true;
        } */
      }}
    />
  {/if}

  {#if errorMsg.length > 0}
    <span class="error-msg">{errorMsg}</span>
  {/if}

  <!-- {#if isFirstUpdated === true && ((minLength > 0 && inputValTemp.length < minLength && errorMsg.length > 0) || (maxLength > 0 && inputValTemp.length > maxLength && errorMsg.length > 0))}
    <span class="error-msg">{errorMsg}</span>
  {/if} -->
</div>

<style>
  .input {
    display: block;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
    max-width: 100%;
    margin: 0px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media only screen and (max-width: 768px) {
    .input {
      width: 100% !important;
    }
  }

  .error-msg {
    font-size: 12px;
    color: red;
    margin-top: 6px;
    position: relative;
    display: block;
  }
</style>
