<script>
  import { onMount } from "svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import FdCardItem from "../../components/FDCC/FdCardItem.svelte";

  let fdList = [];

  onMount(async () => {
    const fdListReq = await request("/api/fd", "GET");

    fdList = fdListReq.data;

    //fdList.map((item) => (totalAmount = totalAmount + item.amount));

    const userData = await request("/api/user", "GET");

    //user_name = `Hi ${makeFirstCap(userData.data.name)}`;
  });
</script>

<WizardBody title="Hi Jessika Kumar" back="">
  <div style="max-width:420px;">
    <div>
      <div style="margin-top: 24px; display: flex; flex-direction: column; ">
        <div
          style="font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; color: #1E3868; margin-top: 4px"
        >
          Your Fixed Deposits
        </div>
        <div style="display: flex; flex-direction: column; margin-top: 16px;">
          {#each fdList as fdData, index}
            <FdCardItem {fdData} />
          {/each}
        </div>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          style="display: flex; margin-top: 4px; gap: 8px; align-items: center; cursor: pointer;"
          on:click={() => navigateTo("/onboarding/presetup")}
        >
          <i
            style="background-image: url('/assets/images/add_circle_outline.svg');background-size: 24px;width: 24px;height: 24px;display: block;background-repeat: no-repeat;"
          />
          <span
            style="font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; color: #1E3868;"
          >
            Start New FD
          </span>
        </div>
      </div>
    </div>
  </div>
</WizardBody>

<WizardActionArea>
  <!-- <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
    <Label>{snackbarMessage}</Label>
  </Snackbar>
  <Button title="Next" onclick={buttonClick} /> -->
</WizardActionArea>

<style>
  /* .otp-msg {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 40px;
  }

  .resend-otp-button {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #464448;
    margin-top: 40px;
  } */
</style>
