<script>
  import dayjs from "dayjs";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import FdProgressIndicator from "../../components/FDCC/FdProgressIndicator.svelte";
  import FdDetailsHeaderItem from "../../components/FDCC/FdDetailsHeaderItem.svelte";
  import FdDetailsItem from "../../components/FDCC/FdDetailsItem.svelte";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import {
    bankToJsDate,
    dateDiff,
    parseDays,
    toCurrency,
  } from "../../services/function";
  import FdTransactionListItem from "../../components/FDCC/FDTransactionListItem.svelte";
  import { navigateTo } from "svelte-router-spa";
  import Icon from "../../components/commons/Icon.svelte";
  import Button from "../../components/commons/Button.svelte";

  export let currentRoute;

  const fd_id = currentRoute.namedParams.fd_id;

  let fdData = {};
  let percentage = 0;
  let fdAmount = 0;

  let transactionList = [];

  let fdDetailsViewMore = false;
  let fdDetailsListViewMore = false;

  let withdrwlAmt = 0;
  let penalIntPcnt = 0;
  let actualIntAmt = 0;
  let intTaken = 0;
  let lienAmt = 0;
  let penalIntAmt = 0;
  let totalPayout = 0;

  onMount(async () => {
    const fdGetRes = await request(`/api/fd/${fd_id}`, "GET");

    fdData = fdGetRes.data;
    fdAmount = fdData.amount;

    const mDate = bankToJsDate(fdData.data.MaturityDt); //TOOD: change later according to api data
    const creationDate = bankToJsDate(fdData.data.AcctOpnDt); //TOOD: change later according to api data
    const fdDays = dateDiff(mDate, new Date());
    const completedDays = dateDiff(new Date(), creationDate);
    percentage = (completedDays / fdDays) * 100;

    const fdTxnsGet = await request(`/api/fd/transactions/${fd_id}`, "GET");

    transactionList = fdTxnsGet.data;

    const fdTrialClose = await request(
      `/api/fd/fd_trial_close/${fd_id}`,
      "GET"
    );

    const fdTrialCloseData =
      fdTrialClose.data.FIXML.Body.executeFinacleScriptResponse
        .executeFinacleScript_CustomData;

    withdrwlAmt = parseFloat(fdTrialCloseData.WithdrwlAmt);
    penalIntPcnt = parseFloat(fdTrialCloseData.PenalIntPcnt);
    actualIntAmt = parseFloat(fdTrialCloseData.ActualIntAmt);
    intTaken = parseFloat(fdTrialCloseData.IntTaken);
    lienAmt = parseFloat(fdTrialCloseData.LienAmt);
    penalIntAmt = parseFloat(fdTrialCloseData.PenalIntAmt);

    totalPayout =
      withdrwlAmt + actualIntAmt - (intTaken + lienAmt + penalIntAmt);
  });

  const submitForm = async () => {
    window.loading(true);

    const fdCloseRes = await request(`/api/fd/fd_close/${fd_id}`, "POST");

    window.loading(false);

    navigateTo("/fd-details-deposit-success");
  };
</script>

<WizardBody title="" back="/">
  <div style="position: relative; display: flex; flex-direction: column;">
    <div class="position: relative; display: flex; flex-direction: column;">
      <div
        style="position: relative; z-index: 1; display: flex; flex-direction: column; background: linear-gradient(69.09deg, #5E75A1 22.3%, #13AFE9 167.46%); border-radius: 24px; padding: 28px"
      >
        <div
          style="display:flex; width: 100%; margin-bottom: 12px; justify-content: center; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;  color: rgba(255, 255, 255, 0.72);"
        >
          Final Payout Amount
        </div>
        <div
          style="margin-bottom: 42px; display:flex; width: 100%; justify-content: center;font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
        >
          {toCurrency(totalPayout)}
        </div>

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
          >
            Deposit Amount
          </div>
          <div
            style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
          >
            {toCurrency(withdrwlAmt)}
          </div>
        </div>

        {#if penalIntPcnt}
          <div style="display: flex; width: 100%; margin-bottom: 12px;">
            <div
              style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
            >
              Effective Interest
            </div>
            <div
              style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
            >
              {penalIntPcnt}%
            </div>
          </div>
        {/if}

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
          >
            Interest Amount
          </div>
          <div
            style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
          >
            {toCurrency(actualIntAmt)}
          </div>
        </div>

        {#if intTaken}
          <div style="display: flex; width: 100%; margin-bottom: 12px;">
            <div
              style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
            >
              Interest Already Withdrawal
            </div>
            <div
              style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
            >
              - {toCurrency(intTaken)}
            </div>
          </div>
        {/if}

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
          >
            Dues
          </div>
          <div
            style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
          >
            - {toCurrency(lienAmt)}
          </div>
        </div>

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;color: rgba(255, 255, 255, 0.72);"
          >
            Premature withdrawal penalty
          </div>
          <div
            style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: #FFFFFF;"
          >
            - {toCurrency(penalIntAmt)}
          </div>
        </div>

        <div
          style="width: 100%; display: flex; padding-top: 16px; border-top: 1px solid #E6E6E6; margin-top: 4px;"
        >
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
          >
            Final payout amount
          </div>
          <div
            style="display: flex; width: 100%; justify-content: end; font-style: normal; font-weight: 700; font-size: 20px; line-height: 16px;  color: #FFFFFF;"
          >
            {toCurrency(totalPayout)}
          </div>
        </div>
      </div>

      <div
        style="position: relative; display: flex;  flex-direction: column; background: #455678; border-radius: 0px 0px 24px 24px; padding: 28px; padding-top: 22%; margin-top: -18%;"
      >
        <div
          style="font-style: normal; font-weight: 700; font-size: 18px; line-height: 16px;  color: #FFFFFF;"
        >
          Payout Bank Details
        </div>
        <div
          style="font-style: normal; font-weight: 400; margin-top: 4px; margin-bottom: 16px; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          You’ll receive money in this bank account
        </div>

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;  letter-spacing: 0.3px; text-transform: capitalize; color: #FFFFFF; opacity: 0.72;"
          >
            Bank Name
          </div>
          <div
            style="justify-content: end; display: flex; width: 100%; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; letter-spacing: 0.26px; color: #FFFFFF;"
          >
            ICICI Limited
          </div>
        </div>

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;  letter-spacing: 0.3px; text-transform: capitalize; color: #FFFFFF; opacity: 0.72;"
          >
            Account No.
          </div>
          <div
            style="justify-content: end; display: flex; width: 100%; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; letter-spacing: 0.26px; color: #FFFFFF;"
          >
            87910020213
          </div>
        </div>

        <div style="display: flex; width: 100%; margin-bottom: 12px;">
          <div
            style="display: flex; width: 100%; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;  letter-spacing: 0.3px; text-transform: capitalize; color: #FFFFFF; opacity: 0.72;"
          >
            IFSC Code
          </div>
          <div
            style="justify-content: end; display: flex; width: 100%; font-style: normal; font-weight: 700; font-size: 14px; line-height: 16px; letter-spacing: 0.26px; color: #FFFFFF;"
          >
            ICIC00875
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 16px;">
      <Icon icon="info" class="note-text" color="#505869" size="18px" />
      <span class="note-text">
        Note: If you close this FD then your Credit Card linked to this FD will
        also get deactivated.
      </span>
    </div>
  </div>
</WizardBody>
<WizardActionArea style="margin-top: -24px">
  <Button onclick={() => submitForm()} title="Close Fixed Deposit" />
</WizardActionArea>

<style>
</style>
