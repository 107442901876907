<script>
  export let placeholder = "";
  export let type = "number";
  export let name = "name";
  export let style = "";

  export let maskInput = false;
  export let inputType = maskInput ? "password" : "tel";
  export let value = "";
  export let componentIndex;
  export let handleChange;
  export let dataAttr = "";
  let component;

  function onChange(e) {
    value = e.target.value;
    value = value.slice(-1);
    const regex = /^[0-9]/;
    if (!regex.test(value)) {
      value = "";
      component.value = "";
    }
    let key = e.key || e.code;
    if (value != "" || key === "Backspace") {
      if (handleChange) {
        handleChange(componentIndex, e);
      }
    }
  }
</script>

<svelte:window />

<div>
  <input
    id={name}
    {name}
    {type}
    class="input"
    {placeholder}
    {style}
    maxlength={1}
    {value}
    on:keyup={onChange}
    bind:this={component}
    on:focusin={() => {
      window.setFocus(true);
    }}
    on:focusout={() => {
      window.setFocus(false);
    }}
    data-testid={dataAttr}
    data-input={"elem"}
    regex="[0-9]"
    inputmode="numeric"
  />
</div>

<style>
  .input {
    display: block;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);

    margin: 0px !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  @media (min-width: 768px) {
    .input {
      padding: 18px;
    }
  }

  @media only screen and (max-width: 768px) {
    .input {
      padding: 15px;
    }
  }
</style>
