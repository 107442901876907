<script>
  import { onMount } from "svelte";
  import { Router } from "svelte-router-spa";
  import { routes } from "./routes";
  import { request } from "./services/network";
  import { session } from "./services/store";
  import { get } from "svelte/store";

  function isLoggedIn() {
    let userSession = get(session);
    if (
      userSession.user &&
      userSession.user.user_id &&
      userSession.user.user_id > 0
    )
      return true;
    else return false;
  }

  onMount(() => {
    try {
      setInterval(async () => {
        if (isLoggedIn()) {
          await request("/api/user/is_logged_in", "GET");
        }
      }, 30 * 60 * 1000);
    } catch (err) {}
  });
</script>

<Router {routes} />
