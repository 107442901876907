import App from "./App.svelte";
import "../public/layout.css";
import "../public/global.css";
const app = new App({
  target: document.body,
  props: {
    name: "worlds",
  },
});

export default app;
