<script>
  import { userCheck } from "./../../../services/userCheck.js";
  import OTPInput from "../../../components/commons/OTPInputLayout.svelte";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../../services/network";
  import { tweened } from "svelte/motion";
  import Snackbar, { Label } from "@smui/snackbar";

  export let currentRoute;

  const verify_id = currentRoute.namedParams.verify_id;
  let userPhone = "";
  let snackbarOpen;
  let snackbarMessage = "";
  const original = 120; // TYPE NUMBER OF SECONDS HERE
  let timer = tweened(original);
  let expiry = tweened(120);
  setInterval(() => {
    $expiry--;
    if ($timer > 0) {
      $timer--;
    } else {
      $timer = 0;
    }
    if ($expiry < 0) {
      snackbarMessage = "OTP has expired.";
      snackbarOpen.forceOpen();
      return;
    }
  }, 1000);

  $: minutes = Math.floor($timer / 60);
  $: seconds = Math.floor($timer - minutes * 60);

  const resendOtp = async () => {
    // if (seconds < 1) {
    //   timer = tweened(original);
    //   window.loading(true);
    //   await request(`/api/verify/resend/${verify_id}`, "GET");
    //   window.loading(false);
    // }
    window.loading(true);
    const result = await request("/api/verify/phone", "POST", {
      phone: userPhone.toString(),
      whatsapp_notify: "false",
    });

    window.loading(false);

    if (result.type === "success" && result.verify_id) {
      // expiry = tweened(120);
      // timer = tweened(original);
      location.href = `/onboarding/phone-verify/${result.verify_id}`;
    } else {
      snackbarMessage = result.message
        ? result.message
        : "Something went wrong";

      snackbarOpen.forceOpen();
      return;
    }
  };

  onMount(async () => {
    const result = await request(`/api/verify/${verify_id}`, "GET");
    userPhone = result.data[0].credential;
  });

  let otp = "";

  const buttonClick = async () => {
    if (otp.length !== 6) {
      snackbarMessage = "Invalid OTP";
      snackbarOpen.forceOpen();
      return;
    }

    window.loading(true);

    const verifyRes = await request(`/api/verify/phone/${verify_id}`, "POST", {
      otp: otp,
    });

    window.loading(false);

    if (verifyRes.user_id > 0) {
      userCheck(true, null, true);
    } else {
      snackbarMessage = verifyRes.message;
      snackbarOpen.forceOpen();

      if (verifyRes.message === "Maximum number of attempts reached.") {
        setTimeout(() => {
          navigateTo("/");
        }, 1500);
      } else if (
        verifyRes.message === "Invalid Auth Token. Please revalidate"
      ) {
        setTimeout(() => {
          navigateTo("/");
        }, 1500);
      }
    }
  };

  function onOTPUpdate(event) {
    otp = event.detail.completevalue.trim();
  }

  const goBack = () => {
    navigateTo("/onboarding/phone");
  };
</script>

<WizardBody title="Enter OTP" back="">
  <div>
    <div class="pb-3 text-muted otp-msg">
      OTP sent to +91 {userPhone}
      <span class="span" on:click={goBack}>CHANGE</span>
    </div>

    <OTPInput
      numberOfInputs={6}
      on:notify={onOTPUpdate}
      emitEventOnPrefill={false}
    />
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="resend-otp-button" style="cursor: pointer;">
      <!-- Didn't receive OTP ? -->
      <!-- <span
        style={seconds < 1
          ? "font-weight: bolder; color: #083A9A; text-decoration: underline"
          : ""}>RESEND</span
      > -->

      {#if $expiry <= 0}
        OTP has expired.<span
          style="font-weight: bolder; color: #083A9A; text-decoration: underline"
          on:click={resendOtp}>RESEND</span
        >
      {:else}
        OTP Expires in
        {@html $timer > 0
          ? `<span style="color: #083A9A; font-weight:600;">${
              minutes < 10 ? `0${minutes}` : minutes
            }:${seconds < 10 ? `0${seconds}` : seconds}</span>`
          : ""}
      {/if}
      <!-- {#if $expiry < 10}
        <br />OTP Expires in {@html $expiry > 0
          ? `<span style="color: #083A9A; font-weight:600;"> 00:0` +
            Math.round($expiry) +
            `</span>`
          : "00:00"}
      {/if} -->
    </div>
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button title="Confirm" onclick={buttonClick} disabled={otp.length != 6} />
</WizardActionArea>

<style>
  .otp-msg {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 30px;
  }

  .resend-otp-button {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #464448;
    margin-top: 40px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .span {
    font-size: 16px;
    font-weight: 600;
    color: #083a9a;
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    .otp-msg {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 16px;
    }

    .resend-otp-button {
      margin-top: 28px;
    }

    .span {
      font-size: 14px;
      line-height: 16px;
    }
  }
</style>
