<script>
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  export let checked;
</script>

<FormField>
  <Checkbox class="checkbox" bind:checked touch />
  <span slot="label"><slot /></span>
</FormField>
