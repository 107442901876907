<script lang="ts">
  import SegmentedButton, { Segment } from "@smui/segmented-button";
  import { Label } from "@smui/common";

  export let choices = [];
  export let selected = "";
</script>

<!-- <SegmentedButton segments={choices} let:segment singleSelect bind:selected>
  <Segment
    {segment}
    style="background: #F8F8F9; border: 1px solid #D1D5DC; border-radius: 4px; margin-right: 12px;"
  >
    <div class="label">
      <Label>{segment}</Label>
    </div>
  </Segment>
</SegmentedButton> -->
<div class="body-main">
  {#each choices as choice}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      on:click={() => {
        selected = choice;
      }}
      class={`label ${selected === choice ? "label-selected" : ""}`}
    >
      {choice}
    </div>
  {/each}
</div>

<style>
  .body-main {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .label {
    text-transform: capitalize;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #090a0c;
    padding: 8px 16px 8px 16px;
    background: #f8f8f9;
    border: 1px solid #d1d5dc;
    border-radius: 4px;
    cursor: pointer;
  }
  .label-selected {
    background: #083a9a;
    border: 1px solid #083a9a;
    color: #fff;
  }
</style>
