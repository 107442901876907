<script context="module">
  import Kitchen from "@smui/snackbar/kitchen";
  let kitchen;
  export function snackbar(label, type, actions) {
    kitchen.push({
      props: {
        variant: "stacked",
        class: type,
      },
      label,
      actions,

      dismissButton: true,
    });
  }
</script>

<Kitchen bind:this={kitchen} dismiss$class="material-icons" />

<style>
</style>
