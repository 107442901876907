<script>
  export let banks = [];
</script>

<table>
  <thead>
    <tr>
      <th>BANK NAME</th>
      <th>UPI</th>
      <th>NETBANKING</th>
    </tr>
  </thead>
  <tbody>
    {#each banks as bank}
      <tr>
        <td style="text-align: left;">{bank.bank_name}</td>
        <td>{bank.upi ? `✔` : `❌`}</td>
        <td>{bank.netbanking ? `✔` : `❌`}</td>
      </tr>
    {/each}
  </tbody>
</table>

<style>
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
</style>
