import { request } from "./network";
import { navigateTo } from "svelte-router-spa";
export const routeCheck = async (routeName) => {
  let { data } = await request("/api/next", "GET");
  window.loading(false);
  //data = data.split("?").shift();
  if (data !== routeName) {
    navigateTo("/");
  }
};
