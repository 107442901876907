<script>
  import { onMount } from "svelte";
  import { userCheck } from "../../services/userCheck";

  onMount(() => {
    userCheck(true);
  });
</script>

<div
  style="position: absolute; left: 0px; top: 0px; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;"
>
  <!-- <img src="/assets/images/sbm-logo.png" alt="SBM Logo" />
  <div>Loading...</div> -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="margin: auto; background: rgb(255, 255, 255,0); display: block; shape-rendering: auto;"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#083a9a"
      stroke-width="4"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
</div>
