<script>
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Icon from "../../../components/commons/Icon.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../../services/network";
  import Snackbar, { Label } from "@smui/snackbar";
  import { routeCheck } from "../../../services/routeCheck";
  let snackbarOpen;
  let snackbarMessage = "";

  let addressList = [];
  let addressTextList = [];

  let selectedAddressIndex = -1;

  export let currentRoute;

  let addressIdSelected = 0;

  const getAllAddresses = async () => {
    const addressRes = await request("/api/address", "GET");
    addressList = [];
    const addressListDb = addressRes.data;
    let index = 0;
    const addressListTemp = [];
    addressTextList = [];
    addressListDb.map((addressDb) => {
      let addressLine = "";

      const addressArray = [
        "address",
        "landmark",
        "locality",
        "district",
        "city",
        "state",
        "pincode",
      ];

      for (const i in addressArray) {
        const value = addressArray[i];

        const tempAddress = addressDb[value];

        if (tempAddress) {
          addressLine += tempAddress + ", ";
        }
      }

      addressLine = addressLine.slice(0, -2);
      // addressLine = `${addressDb.address}, ${addressDb.landmark}, ${addressDb.city}, ${addressDb.state},  ${addressDb.pincode}`;
      if (!addressTextList.includes(addressLine)) {
        addressListTemp.push({
          id: index, //this will be index
          address_id: addressDb.address_id,
          address: addressLine,
          checked: index === selectedAddressIndex ? true : false,
          verified_by: addressDb.verified_by,
        });
        addressTextList.push(addressLine);
        return index++;
      }
    });

    addressList = addressListTemp;
  };

  onMount(async () => {
    routeCheck(currentRoute.name);
    addressIdSelected = currentRoute.queryParams.id;
    await getAllAddresses();
    if (addressIdSelected && addressIdSelected > 0) {
      addressList.map((address, index) => {
        if (address.address_id.toString() === addressIdSelected) {
          document.getElementById(`address_${address.address_id}`).click();
          selectedAddressIndex = index;
        }
        if (index === 0) {
          selectedAddressIndex = index;
        }
      });
      buttonClick();
    }
    if (addressList.length === 1) {
      selectedAddressIndex = 0;
    }
  });

  const buttonClick = async () => {
    if (selectedAddressIndex < 0) {
      snackbarMessage = "Please select address";
      snackbarOpen.forceOpen();
      return;
    }
    const selectedAddress = addressList[selectedAddressIndex];
    const selectedAddressId = selectedAddress.address_id;

    window.loading(true);

    await request("/api/user_account/address", "POST", {
      address_id: selectedAddressId.toString(),
    });

    window.loading(false);

    navigateTo("/");
  };

  const radioChecked = (event) => {
    selectedAddressIndex = parseInt(event.currentTarget.value);
  };

  const deleteAddress = async (address_id) => {
    window.loading(true);
    await request(`/api/address/${address_id}`, "DELETE");

    window.loading(false);
    await getAllAddresses();
  };
</script>

<WizardBody title="Select communication / delivery address" back="">
  <div class="sub-header">
    We have fetched these details from Aadhaar Records
  </div>

  <!-- <FdAddressSelect
    addresses={[
      { id: "1", address: "Address 1", checked: false },
      { id: "2", address: "Address 2", checked: true },
      { id: "3", address: "Address 3", checked: false },
      { id: "4", address: "Address 4", checked: false },
    ]}
  /> -->

  <div class="radio-parent" style="flex-wrap: wrap; gap: 20px">
    {#each addressList as address, index}
      <label class="addr-label" for={`address_${address.address_id}`}>
        <div class="address-area">
          <div class="address-heading">
            <div>
              {#if address.verified_by === "AADHAAR"}
                <span class="address-text-heading">Permanent Address</span>
              {:else}
                <span class="address-text-heading">Communication Address</span>
              {/if}
            </div>
            <div />
            <input
              id={`address_${address.address_id}`}
              type="radio"
              name="address"
              class="radio"
              checked={address.checked ||
              addressList.length === 1 ||
              selectedAddressIndex === index
                ? true
                : false}
              value={address.id}
              on:change={radioChecked}
            />
          </div>

          <div
            style="position: relative; overflow: hidden; display: flex; width: 100%; max-width: 270px; height: 100%"
          >
            <div class={`address-view`} style="margin-right: 24px;">
              <div class="address-text">
                {address.address}
              </div>
            </div>
          </div>
          {#if address.verified_by !== "PAN" && address.verified_by !== "AADHAAR" && address.verified_by !== "CKYC" && address.verified_by !== "VKYC"}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              class="delete-btn"
              on:click={() => {
                deleteAddress(address.address_id);
              }}
            >
              <Icon
                icon="delete"
                class="note-text"
                color="#505869"
                size="22px"
              />
            </div>

            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              class="edit-btn"
              on:click={navigateTo(
                `/onboarding/add-address/${address.address_id}`
              )}
            >
              <Icon icon="edit" class="note-text" color="#505869" size="18px" />
            </div>
          {/if}
        </div>
      </label>
    {/each}
  </div>

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-missing-attribute -->
  {#if addressList.length < 2}
    <a
      on:click={() => {
        navigateTo("/onboarding/add-address");
      }}
      style="cursor: pointer;"
    >
      <div class="add-adrs" style="cursor: pointer; margin-top: 28px;">
        <Icon
          icon="add_circle"
          size="20px"
          color="#1E3868"
          variant="material-symbols-outlined"
        />
        <span style="font-weight: 700; font-size: 16px; line-height: 16px;"
          >Add Communication Address</span
        >
      </div>
    </a>
  {/if}
  <!-- <div class="info-parent">
    
    <img
      src="/assets/images/info.svg"
      alt="info"
      style="width: 16px; height: 16px;"
    />
    <span class="info-txt"
      >Note: You will have to sign a self declaration form <span
        style="color: #1e3868; cursor: pointer">KNOW MORE</span
      ></span
    >
  </div> -->
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button title="Next" onclick={buttonClick} />
</WizardActionArea>

<style>
  .address-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .sub-header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #979797;
    margin-top: -10px;
  }

  .addr-label {
    display: flex;
    width: 40%;
    position: relative;
    background: #ffffff;
    border: 1px solid rgba(30, 56, 104, 0.4);
    border-radius: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    min-width: 260px;
    min-height: 120px;
    cursor: pointer;
    align-items: flex-start;
  }

  .address-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  .addr-label:hover {
    background: rgb(249, 247, 247);
  }

  .add-adrs {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #1e3868;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .info-parent {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 32px;
  }

  .info-txt {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #505869;
  }

  .radio-parent {
    display: flex;
    width: 100%;
    overflow: hidden;
    margin-top: 10px;
  }

  .address-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 96%;
    color: #505869;
    text-align: left;
  }

  .address-text-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    width: 96%;
    color: #505869;
    text-align: left;
    text-decoration: underline;
  }
  .radio {
    width: 22px;
    height: 22px;
    transform: scale(1);
    margin: 0px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .address-selected {
    opacity: 0.8;
    border: 1px solid #1e3868;
    border-radius: 8px;
  }
  .radio:checked {
    background: rgb(16, 49, 111);
  }
  input[type="radio"]:checked {
    background: #000;
    border-color: green;
  }

  .edit-btn {
    position: absolute;
    bottom: 9px;
    right: 12px;
    cursor: pointer;
  }

  .edit-btn:hover {
    text-decoration: underline;
  }

  .delete-btn {
    position: absolute;
    bottom: 8px;
    right: 34px;
    cursor: pointer;
  }

  .delete-btn:hover {
    text-decoration: underline;
  }
</style>
