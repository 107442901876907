<script>
  import { toCurrency } from "../../services/function";
  import Option from "./Option.svelte";

  export let amount;
  export let optionGroup;

  export let style = "";
</script>

<div style={`display: flex; flex-wrap: wrap; gap: 12px; ${style}`}>
  {#each optionGroup as amountItem, index}
    <Option
      isSelected={toCurrency(amount) === toCurrency(amountItem.value)
        ? true
        : false}
      value={toCurrency(amountItem.value)}
      bind:amount
      recommended={amountItem.recommended ? true : false}
      badgeSelected={toCurrency(amount) === toCurrency(amountItem.value) &&
      amountItem.recommended
        ? true
        : false}
    />
  {/each}
</div>
<!--
<div class="option-group">
  <Option isSelected={true} value="₹ 60,000" recommended={true} />
  <Option isSelected={false} value="₹ 80,000" recommended={false} />
  <Option isSelected={false} value="₹ 90,000" recommended={false} />
</div>

<style>
  .option-group {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 40px;
  }
</style>
-->
