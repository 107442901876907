<script>
  import Button from "../../components/commons/Button.svelte";
  import Checkbox from "../../components/commons/Checkbox.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { navigateTo } from "svelte-router-spa";
  import Snackbar, { Label } from "@smui/snackbar";

  let snackbarOpen;
  let snackbarMessage = "";

  function buttonClick() {
    if (!checked) {
      /* window.snackbar(
        "Please accept the terms & conditions to move ahead",
        "danger",
        null
      );
      return null; */
      snackbarMessage = "Please accept the terms & conditions to move ahead";
      snackbarOpen.forceOpen();
      return;
    }
    navigateTo("/onboarding/confirm");
  }
  let checked = false;
</script>

<WizardBody title="Accept these Terms & Conditions" back="/">
  <div>
    <div class="pb-3 text-muted">Please read through all the below points.</div>

    <div style="display: flex; flex-direction: column; width: 100%;">
      <div class="my-3" style="display: flex; width: 100%">
        <i
          style="margin-top: 6px; width: 12px; height: 12px; background: #A0B0CE; border-radius: 50%;"
        />

        <span class="list-font"
          >I hereby declare that I am a tax resident of India(not an NRI) and
          that I comply with FATCA declaration. I declare that I am neither
          politically exposed or related to a politically exposed person.</span
        >
      </div>

      <div class="my-3" style="display: flex; width: 100%">
        <i
          style="margin-top: 6px; width: 12px; height: 12px; background: #A0B0CE; border-radius: 50%;"
        />

        <span class="list-font"
          >I agree to SBM Account <a
            href="https://www.google.com"
            style="color:#083A9A;">Terms & Conditions</a
          ></span
        >
      </div>

      <div class="my-3" style="display: flex; width: 100%">
        <i
          style="margin-top: 6px; width: 12px; height: 12px; background: #A0B0CE; border-radius: 50%;"
        />

        <span class="list-font"
          >I hereby declare that I am a tax resident of India(not an NRI) and
          that I comply with FATCA declaration. I declare that I am neither
          politically exposed or related to a politically exposed person.</span
        >
      </div>
    </div>

    <!-- <ul>
      <li class="my-3 list-font">
        I hereby declare that I am a tax resident of India(not an NRI) and that
        I comply with FATCA declaration. I declare that I am neither politically
        exposed or related to a politically exposed person.
      </li>
      <li class="my-3 list-font">
        I agree to SBM Account <a
          href="https://www.google.com"
          style="color:#083A9A;">Terms & Conditions</a
        >
      </li>
      <li class="my-3 list-font">
        I hereby declare that I am a tax resident of India(not an NRI) and that
        I comply with FATCA declaration. I declare that I am neither politically
        exposed or related to a politically exposed person.
      </li>
      <li class="my-3 list-font">
        I agree to SBM Account <a
          href="https://www.google.com"
          style="color:#083A9A;">Terms & Conditions</a
        >
      </li>
    </ul> -->
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative; margin-bottom: -110px">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>

  <div style="margin-left: -12px; margin-bottom: 10px;">
    <Checkbox bind:checked
      ><span style="color: #464448;"
        >I have read and understood all of the above points
      </span></Checkbox
    >
  </div>

  <Button title="I Accept" onclick={buttonClick} />
</WizardActionArea>

<style>
  ul li::before {
    padding-bottom: 0.5rem;
  }

  .list-font {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #505869;
    margin-left: 26px;
    display: flex;
    width: 100%;
  }
</style>
