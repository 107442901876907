<script>
  export let smallTitle;
  export let bigTitle;
  export let attractive = false;
  export let selected = false;
  export let selectedTenureIndex = -1;
  export let index = -1;
  export let maturityDate = "";
  export let customMaturityDate = "";

  const setSelected = () => (selectedTenureIndex = index);
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="tenure-item"
  style={selected ? `border: 1px solid #083A9A` : null}
  on:click={() => {
    maturityDate = "";
    customMaturityDate = "";
    setSelected();
  }}
>
  {#if attractive}
    <div
      class="attractive-parent"
      style="display: flex; justify-content: center"
    >
      <div
        class="attractive"
        style={!attractive || !selected
          ? "background: #979797; color: #fff"
          : ""}
      >
        Most attractive
      </div>
    </div>
  {/if}
  <div class="small-title">{smallTitle}</div>
  <div class="big-title">{bigTitle}</div>
</div>

<style>
  .tenure-item {
    width: 40%;
    height: 100%;
    margin-bottom: 24px;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      #e2eaf5 24.91%,
      rgba(166, 180, 202, 0) 97.17%
    );
    border: 1px solid #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 18px;
    justify-content: end;
    cursor: pointer;
  }

  .attractive-parent {
    position: absolute;
    top: -10px;
    left: 0px;
    width: 100%;
    right: 0px;
  }

  .attractive {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 8px;
    color: #ffffff;
    background: #eb3c23;
    border-radius: 2px;
    padding: 6px 4px 6px 4px;
  }

  .tenure-item:hover {
    background: linear-gradient(
      180deg,
      #d8e3f3 24.91%,
      rgba(157, 171, 194, 0) 97.17%
    );
  }

  .small-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #979797;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .big-title {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #505869;
  }
</style>
