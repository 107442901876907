<script>
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Dialog, { Content } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import { navigateTo } from "svelte-router-spa";
  import {
    getDurationAndRate,
    getMaturityAmount,
    parseDays,
    toCurrency,
    decrypt,
  } from "../../services/function";
  import dayjs from "dayjs";

  let open = false;
  export let currentRoute;

  setTimeout(function () {
    open = true;
  }, 5000);

  const fdId = currentRoute.queryParams.id;

  let duration = "";
  let amount = "";
  let interest_rate = "";
  let maturity_amount = "";
  let maturity_date = "";
  let interestList = [];

  onMount(async () => {
    const fdGet = await request(`/api/fd/${fdId}`, "GET");

    const getInterestRatesRes = await request("/api/fd/interest", "GET");
    interestList = getInterestRatesRes.data;

    duration = parseDays(fdGet.data.duration);
    amount = toCurrency(fdGet.data.amount);
    interest_rate = fdGet.data.interest_rate.toFixed(2);
    maturity_amount = toCurrency(
      getMaturityAmount(
        fdGet.data.amount,
        fdGet.data.duration,
        getDurationAndRate(interestList, fdGet.data.duration).interest_rate
      )
    );
    maturity_date = dayjs(new Date())
      .add(fdGet.data.duration, "day")
      .format("DD/MM/YYYY");

    JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );
  });

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  sheet
  aria-describedby="sheet-content"
>
  <Content id="sheet-content" style="width:740px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text text-center">
          You’ve Just unlocked a Credit Card against your Fixed Deposit
        </div>
        <div class="modal-header-icon">
          <IconButton on:click={() => (open = false)} class="material-icons"
            >close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <div class="card-unlocked-img text-center my-4 responsive-scroll" />
        <Button title="Get it now" />
        <div on:click={() => (open = false)} class="text-action text-center">
          Remind me later
        </div>
      </div>
    </div></Content
  >
</Dialog>
<WizardBody title="Congratulations on your new Fixed Deposit" back="">
  <div
    style="position: relative; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;"
  >
    <div class="confetti" />
    <div class="success-card" style=" margin-top: 60px;">
      <div
        class="d-flex"
        style="justify-content: space-between; align-items: center; gap: 134px"
      >
        <img
          class="p-4"
          style="margin-right: auto;"
          src="/assets/images/sbm_logo_card.png"
          alt=""
        />
        <div style="display: flex;">
          <img
            class="py-4 px-2"
            style="margin-block: auto;"
            src="/assets/images/share.png"
            alt=""
          />
          <img
            class="py-4 ps-3 pe-4"
            style="margin-block: auto;"
            src="/assets/images/download.png"
            alt=""
          />
        </div>
      </div>

      <div class="success-value-text mx-4 my-3">Fixed Deposit Receipt</div>

      <div
        class="d-flex flex-row mx-4 pt-3"
        style="justify-content: space-between;"
      >
        <div style="display: flex; flex-direction:column; width: 100%;">
          <div class="success-label-text">FD Amount</div>
          <div class="success-value-text">{amount}</div>
        </div>
        <div
          style="display: flex; flex-direction:column; width: 100%; align-items: center;"
        >
          <div class="success-label-text">FD Duration</div>
          <div class="success-value-text">{duration}</div>
        </div>
        <div
          style="display: flex; flex-direction:column; width: 100%; align-items: flex-end;"
        >
          <div class="success-label-text">Interest Rate</div>
          <div class="success-value-text">{interest_rate}</div>
        </div>
      </div>
      <div class="d-flex flex-row mx-4 my-4" style="">
        <div
          style="width: 100%; display: flex;font-family: 'IBM Plex Sans'; font-style: normal; font-weight: 500; font-size: 10px; line-height: 13px; letter-spacing: -0.5px; color: #FFFFFF;
"
        >
          Maturity Amount - {maturity_amount}
        </div>
        <div
          style="width: 100%; display: flex; justify-content: end ; font-family: 'IBM Plex Sans'; font-style: normal; font-weight: 500; font-size: 10px; line-height: 13px; letter-spacing: -0.5px; color: #FFFFFF;
"
        >
          Maturity Date - {maturity_date}
        </div>
      </div>
    </div>

    <div
      style="position: relative; margin-top: 18px; margin-left: 120px; margin-right: 120px; font-family: 'Urbanist'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 18px; text-align: center; color: #464448;"
    >
      We will share a copy of your FD receipt on registered E-mail Id.
    </div>
  </div>
</WizardBody>
<WizardActionArea
  ><Button
    title="Go to dashboard"
    style="z-index: 1; position: relative;"
    onclick={() => {
      navigateTo("/fd/list");
    }}
  /></WizardActionArea
>

<style>
  .confetti {
    position: absolute;
    background-image: url("/assets/images/confetti.svg");
    background-size: cover;
    width: 100%;
    min-height: 480px;
    max-width: 100%;
    top: 0px;
  }
  .success-card {
    background: linear-gradient(66.53deg, #13afe9 -11.43%, #083a9a 88.91%);
    max-width: 480px;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .success-value-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #ffffff;
  }

  .success-label-text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: -0.5px;
    color: #ffffff;
  }

  .maturity {
    margin-top: 2.5rem;
  }

  .modal {
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 44px;
  }

  .modal-header {
    width: 100%;
    color: #000;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-header-text {
    width: 100%;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #1e3868;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  .card-unlocked-img {
    background-image: url("/assets/images/card_unlocked.png");
    background-size: cover;
    width: 18rem;
    height: 12rem;
    max-width: 100%;
  }

  .text-action {
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
    color: #1e3868;
  }
</style>
