<script>
  // get the timings for particular date
  export let startTime;
  export let endTime;
  export let slotId;
  export let setSlotId;
</script>

<div style="width: fit-content">
  <button on:click={() => setSlotId(slotId)} class="timings"
    >{startTime} - {endTime}</button
  >
</div>

<style>
  .timings {
    width: 140px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    font-size: 12px;
    line-height: 14px;
  }

  @media only screen and (max-width: 368px) {
    .timings {
      width: 100px;
      cursor: pointer;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      font-size: 10px;
      line-height: 12px;
    }
  }
</style>
