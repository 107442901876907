<script lang="ts">
  export let percentage = 0;
</script>

<div
  style="height: 4px; width: 100%; background: #EAEAEA; overflow: hidden; display: flex; position: relative;  border-radius: 8px;"
>
  <div
    style={`height: 4px; width: ${percentage}%; background: #65BF73; position: absolute; left: 0px;`}
  />
</div>
