<script>
  import { userCheck } from "./../../../services/userCheck.js";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import { request } from "../../../services/network";
  import Card from "@smui/card";
  import { onMount } from "svelte";

  onMount(async () => {
    //get aadhaar details
    const res = await request("/api/kyc/aadhaar", "GET");

    //TODO: aadhar feetching api is not in documentation

    
  });

  const buttonClick = async () => {
    window.loading(true);

    const res = await request("/api/kyc/aadhaar/confirm", "POST");

    window.loading(false);

    if (res.type === "success" && res.status === "CONFIRMED") {
      await userCheck(true);
      //navigateTo("/onboarding/address");
    }
  };

  let name = "Jessica Kumar";
  let gender = "Male";
  let fathers_name = "Ramesh Pandey";
  let dob = "11 Apr 1980";
  let address =
    "803, Shravan Heights, Road no 2, Nadiwala Colony, Vashi, Navi Mumbai, 400064";

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<WizardBody title="Confirm your Aadhaar details" back="">
  <div class="responsive">
    <Card
      padded
      style="box-shadow: 3px 3px 7px rgb(0 0 0 / 6%);border-radius: 12px;"
      ><table class="table">
        <tr class="table-row">
          <td class="type">Name</td>
          <td class="value">{name}</td>
        </tr>
        <tr class="table-row">
          <td class="type">Gender</td>
          <td class="value">{gender}</td>
        </tr>
        <tr class="table-row">
          <td class="type">Father’s Name</td>
          <td class="value">{fathers_name}</td>
        </tr>
        <tr class="table-row">
          <td class="type">DOB</td>
          <td class="value">{dob}</td>
        </tr>
        <tr class="table-row">
          <td class="type">Address</td>
          <td class="value">{address}</td>
        </tr>
      </table></Card
    >
  </div>
</WizardBody>
<WizardActionArea
  ><Button title="Confirm" onclick={buttonClick} /></WizardActionArea
>

<style>
  .table {
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
    border-spacing: 1rem;
  }

  td {
    width: 10rem;
  }

  .type {
    font-size: 14px;
    line-height: 22px;
    color: #6e6e6e;
  }

  .value {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #464448;
  }
</style>
