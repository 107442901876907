<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import OTPInput from "./../../components/commons/OTPInputLayout.svelte";
  import { tweened } from "svelte/motion";
  import Button from "../../components/commons/Button.svelte";
  import Snackbar, { Label } from "@smui/snackbar";
  import { request } from "../../services/network";
  import { navigateTo } from "svelte-router-spa";
  import { routeCheck } from "../../services/routeCheck";
  import { onMount } from "svelte";
  import Buttonlnv from "../../components/commons/Buttonlnv.svelte";

  let otpShow = false;
  let phone;
  let snackbarOpen;
  let snackbarMessage = "";
  let otp = "";
  let snackbarType;
  export let currentRoute;

  onMount(async () => {
    routeCheck(currentRoute.name);
  });
  function onOTPUpdate(event) {
    otp = event.detail.completevalue.trim();
  }

  let session = localStorage.getItem("session");
  if (session) {
    try {
      session = JSON.parse(session);
      phone = session.user.phone;
    } catch (e) {
      session = {};
    }
  }

  let original = 120;
  let timer = tweened(original); // TYPE NUMBER OF SECONDS HERE
  let expiry = tweened(120);

  $: minutes = Math.floor($timer / 60);
  $: seconds = Math.floor($timer - minutes * 60);
  let timerInterval;

  function startTimer() {
    timerInterval = setInterval(() => {
      $expiry--;
      if ($timer > 0) {
        $timer--;
      } else {
        $timer = 0;
        clearInterval(timerInterval);
      }
      if ($expiry < 0) {
        snackbarMessage = "OTP has expired.";
        snackbarOpen.forceOpen();
        return;
        // setTimeout(function () {
        //   navigateTo("/");
        // }, 1500);
      }
    }, 1000);
  }

  function resetTimer() {
    $timer = original;
    clearInterval(timerInterval);
    startTimer();
  }

  const editClicked = async () => {
    let response = await request("/api/caf/edit", "PUT");

    if (response.type !== "success") {
      snackbarType = "snackbar-warning";
      snackbarMessage = "Error Occurred. Could not move back";
      snackbarOpen.forceOpen();
    } else {
      navigateTo("/");
    }
  };

  const sendOtp = () => {
    request("/api/caf/otp_send", "GET").then((response) => {
      resetTimer();

      otpShow = true;
      if (seconds < 1) {
        timer = tweened(original);

        window.loading(true);

        if (response.type === "success") {
          window.loading(false);
          snackbarMessage = `OTP is sent to +91 ${phone}`;
        } else {
          snackbarType = "snackbar-warning";
          snackbarMessage = response.message
            ? response.message
            : "Could not generate OTP. Please Try again.";
        }
        snackbarOpen.forceOpen();
        return;
      }
    });
  };

  const resendOtp = () => {
    request("/api/caf/otp_send", "GET").then((response) => {
      otpShow = true;

      if (seconds < 1) {
        timer = tweened(original);
        expiry = tweened(120);
        window.loading(true);
        resetTimer();
        if (response.type === "success") {
          window.loading(false);
          snackbarMessage = `OTP is sent to +91 ${phone}`;
        } else {
          snackbarType = "snackbar-warning";
          snackbarMessage = response.message
            ? response.message
            : "Could not generate OTP. Please Try again.";
        }
        snackbarOpen.forceOpen();
        return;
      }
    });
  };

  const buttonClick = async () => {
    window.loading(true);
    if (otp.length !== 6) {
      // window.snackbar("Please enter OTP", "danger", null);
      window.loading(false);
      snackbarType = "snackbar-warning";
      snackbarMessage = "Please enter OTP";
      snackbarOpen.forceOpen();
      return;
    } else {
      const response = await request("/api/caf/otp_verify", "POST", {
        otp,
      });
      if (response.type == "success") {
        snackbarType = "success";
        snackbarMessage = "Application form confirmed.";
        snackbarOpen.forceOpen();

        setTimeout(async () => {
          window.loading(false);
          navigateTo("/");
        }, 2000);
      } else if (response.type == "failure") {
        window.loading(false);

        snackbarType = "snackbar-warning";
        snackbarMessage = "OTP is incorrect";
        snackbarOpen.forceOpen();
      } else if (response.type == "danger") {
        window.loading(false);

        snackbarType = "snackbar-warning";
        snackbarMessage = "OTP is invalid";
        snackbarOpen.forceOpen();
      } else {
        window.loading(false);

        snackbarType = "snackbar-warning";
        snackbarMessage = "Something went wrong. Please try again later.";
        snackbarOpen.forceOpen();
      }
    }
  };
</script>

<WizardBody title="Customer Application Form" back="">
  <!--<iframe
    src="/caf/acceptance"
    title="Customer Application Form"
    width="100%"
    style="margin-top:0.625rem; width:500px; transform: scale(0.6,0.6);"
    frameborder="0"
  />-->
  <div class="edit-button">
    <Buttonlnv title="edit" onclick={editClicked} style="margin-top: 20px" />
  </div>
  <div class="wrap">
    <iframe
      scrolling="yes"
      title="Customer Application Form"
      class="frame mx-auto"
      src="/api/caf/acceptance?{new Date().getTime()}"
    />
  </div>
  <!--<div style="width:540px; height: 480px; direction:rtl;">
    <iframe
      title="Customer Application Form"
      src="/caf/acceptance"
      style="transform: scale(0.4,0.4) translate(1280px,-865px); width:1280px"
    />
  </div>-->
</WizardBody>

<WizardActionArea>
  <div style="margin-bottom:10px">
    {#if !otpShow}
      <span class="otp-declaration"
        >I confirm that I have read and understood the above declaration, and
        that the details provided on the form are correct by OTP verification.</span
      >
      <div class="otp-msg pb-3 text-muted">
        OTP will be sent to +91 {phone}
      </div>
      <Button title="send otp" onclick={sendOtp} />
    {/if}
    {#if otpShow}
      <div class="otp-msg pb-3 text-muted">
        OTP was sent to +91 {phone}
      </div>

      <OTPInput
        numberOfInputs={6}
        on:notify={onOTPUpdate}
        emitEventOnPrefill={false}
      />

      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="resend-otp-button"
        style="cursor: pointer;"
        on:click={() => {
          if (seconds < 1) {
            // sendOtp();
          }
        }}
      >
        <!-- Didn't receive OTP ? -->

        <!-- <span
          style={seconds < 1
            ? "font-weight: bolder; color: #083A9A; text-decoration: underline"
            : ""}>RESEND</span
        > -->
        {#if $expiry <= 0}
          OTP has expired.<span
            style="font-weight: bolder; color: #083A9A; text-decoration: underline"
            on:click={resendOtp}>RESEND</span
          >
        {:else}
          OTP Expires in
          {@html $timer > 0
            ? `<span style="color: #083A9A; font-weight:600;">${
                minutes < 10 ? `0${minutes}` : minutes
              } :${seconds < 10 ? `0${seconds}` : seconds} </span>`
            : ""}
        {/if}
        <!-- {#if $expiry < 10}
          <br />OTP Expires in {@html $expiry > 0
            ? `<span style="color: #083A9A; font-weight:600;"> 00:0` +
              Math.round($expiry) +
              `</span>`
            : "00:00"}
        {/if} -->
      </div>

      <div style="position: relative; z-index: 9999">
        <Snackbar leading bind:this={snackbarOpen} class={snackbarType}>
          <Label>{snackbarMessage}</Label>
        </Snackbar>
      </div>

      <Button
        title="confirm"
        onclick={buttonClick}
        disabled={otp.length != 6}
      />
    {/if}
  </div>
</WizardActionArea>

<style>
  /*iframe {
    -ms-transform: scale(0.25);
    -moz-transform: scale(0.25);
    -o-transform: scale(0.25);
    -webkit-transform: scale(0.25);
    transform: scale(0.25);
    
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }*/

  .otp-msg {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    margin-bottom: 40px;
  }

  .resend-otp-button {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #464448;
    margin-top: 40px;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .otp-declaration {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #1e3868;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    .otp-msg {
      margin-bottom: 12px;
      font-size: 14px;
      line-height: 16px;
      word-spacing: 1px;
      margin-top: 8px;
    }

    .resend-otp-button {
      margin-top: 28px;
    }

    .wrap {
      width: 576px;
      max-width: 100%;
      height: 65vh;
      padding: 0;
      overflow-x: hidden;
      float: left;
      overflow-y: hidden;
    }
    .frame {
      width: 1400px;
      height: 200vh;
      border: 2px solid black;
      -moz-transform: scale(0.35);
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.35);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.35);
      -webkit-transform-origin: 0 0;
      overflow-y: scroll;
    }

    .otp-declaration {
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }

  @media (min-width: 768px) {
    .wrap {
      width: 576px;
      max-width: 100%;
      height: 50vh;
      padding: 0;
      overflow: hidden;
      float: left;
      overflow-y: hidden;
    }
    .frame {
      width: 800px;
      height: 75vh;
      border: 2px solid black;
      -moz-transform: scale(0.6);
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.6);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.6);
      -webkit-transform-origin: 0 0;
      overflow-y: scroll;
    }

    .otp-msg {
      margin-top: 10px;
    }

    .edit-button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 10px;
    }
  }
</style>
