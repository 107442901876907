<script>
  import {
    getMaturityAmount,
    parseDays,
    toCurrency,
  } from "./../../services/function.js";
  import { userCheck } from "./../../services/userCheck.js";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import Buttonlnv from "../../components/commons/Buttonlnv.svelte";
  import dayjs from "dayjs";
  import { routeCheck } from "../../services/routeCheck.js";
  import Tooltip, { Wrapper, Content as ContentTooltip } from "@smui/tooltip";
  import Bullets from "../../components/commons/Bullets.svelte";
  export let currentRoute;
  let type;
  let data = 0;
  let amount = 0;
  let duration = 0;
  let interest_rate = 0;
  let maturity_date = new Date();
  let interest_payout = "";
  let maturity_inst = "";

  onMount(async () => {
    routeCheck(currentRoute.name);
    window.loading(true);
    const pendingAccount = await request("/api/payment/details", "GET");
    window.loading(false);
    type = pendingAccount.data.type;
    if (!pendingAccount.data.type) {
      navigateTo("/");
    }
    data = pendingAccount.data.data;
    amount = data.amount;
    interest_rate = data.interest_rate;
    duration = data.duration;
    interest_payout = data.interest_payout;
    maturity_inst = data.maturity_inst;
    if (amount < 1) {
      navigateTo("/user-account/success");
    }
  });

  // const successClicked = async () => {
  //   window.loading(true);
  //   const paymentData = await request("/api/payment/checkout", "GET");
  //   const razorPayData = JSON.parse(paymentData.data);
  //   const rzp1 = new Razorpay(razorPayData.data);
  //   window.loading(false);
  //   rzp1.open();
  // };

  const successClicked = async () => {
    navigateTo("/payment/account-validate");
  };

  const editClicked = async () => {
    window.loading(true);
    // await request("/api/payment/cancel", "POST");
    await request("/api/user/flow", "POST", { flow: "fd" });
    let response = await request("/api/user_account/fd/edit", "PUT");

    if (response.type !== "success") {
      snackbarType = "snackbar-warning";
      snackbarMessage = "Error Occurred. Could not move back";
      snackbarOpen.forceOpen();
    } else {
      navigateTo("/");
    }
    window.loading(false);
    await userCheck(true);
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<WizardBody
  title={`Confirm payment towards your ${
    type === "FD" || type === "SC"
      ? "Fixed Deposit"
      : type === "SA"
        ? "Savings Account"
        : ""
  }`}
  back=""
>
  <!-- <div class="card-container p-3">
    <div class="card">
      <div class="top-blue" />

      <div class="card-content">
        <div
          style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
        >
          <i
            style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
          />
          <i
            style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
          />
        </div>
        <div
          style="margin-top: 18px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          {type === "FD" || type === "SC"
            ? "Fixed Deposit"
            : type === "SA"
            ? "Savings Account"
            : ""}
        </div>
        <div
          style="margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
        >
          {toCurrency(amount)}
        </div>
        <div
          style="margin-top: 36px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          {type === "FD" || type === "SC"
            ? "Interest Rate"
            : type === "SA"
            ? "Intitial Deposit Amount"
            : ""}
        </div>
        <div
          style="margin-top: 4px;font-style: normal; font-weight: 700; font-size: 24px; line-height: 24px;color: #FFFFFF;"
        >
          {type === "FD" || type === "SC"
            ? `${data.interest_rate.toFixed(2)}%`
            : type === "SA"
            ? toCurrency(amount)
            : ""}
        </div>
      </div>
    </div>
  </div> -->
  <div class="card">
    <div class="top-blue">
      <div
        style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
      >
        <i
          style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
        />
        <i
          style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
        />
      </div>

      <div
        style="margin-top: 0px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72); width: 100%; display: flex; justify-content: center;"
      >
        {type === "FD" || type === "SC"
          ? "Fixed Deposit Amount"
          : type === "SA"
            ? "Savings Account Amount"
            : ""}
      </div>
      <div
        style="width: 100%; display: flex; padding-bottom: 24px; justify-content: center; margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
      >
        {toCurrency(amount)}
      </div>
    </div>
    <div
      style="margin-top: 32px; width: 100%; display: flex; flex-direction: column; padding: 0px 26px 32px 26px; gap: 10px;"
    >
      <div
        style="font-style: normal; font-weight: 700; font-size: 20px; line-height: 16px;  color: #FFFFFF; padding-bottom: 18px"
      >
        {type === "FD" || type === "SC"
          ? "Deposit Details"
          : type === "SA"
            ? "Savings Account Details"
            : ""}
      </div>

      {#if type === "FD" || type === "SC"}
        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">FD Duration</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {parseDays(duration)}
          </div>
        </div>

        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Interest Rate</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {interest_rate} %
          </div>
        </div>

        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Maturity Date</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {dayjs(new Date()).add(duration, "day").format("DD MMM YYYY")}
          </div>
        </div>

        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Get Interest</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {interest_payout}
          </div>
        </div>

        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Maturity Instruction</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {maturity_inst}
          </div>
        </div>

        <!-- <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Maturity Amount</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            {toCurrency(getMaturityAmount(amount, duration, interest_rate))}
          </div>
        </div> -->
      {:else if type === "SA"}
        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Active Returns</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            Signature Debit Card
          </div>
        </div>
        <!-- <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">ATM 24/7</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            Customised Account Number
          </div>
        </div> -->
        <div
          style="display: flex; width: 100%; justify-content: end; align-items: center; "
        >
          <div class="bottom-text">Zero Charges</div>
          <div
            class="bottom-text"
            style="justify-content: end; text-align: end;"
          >
            +Other Advantages
          </div>
        </div>
      {/if}
    </div>
  </div>

  <div style="margin-top: 20px; font-size: 22px">
    You are going to make a payment of {toCurrency(amount)}
  </div>
</WizardBody>
<WizardActionArea>
  <div class="flex-dir" style="display: flex; gap: 16px;">
    <Button title="continue" onclick={successClicked} />

    <Buttonlnv title="edit" onclick={editClicked}>
      <div class="info">
        <Wrapper rich>
          <Tooltip>
            <ContentTooltip>
              <Bullets
                points={[
                  "Do you wish to increase/decrease and/or make changes to FD tenure?",
                ]}
              />
            </ContentTooltip>
          </Tooltip>
        </Wrapper>
      </div>
    </Buttonlnv>
  </div>
</WizardActionArea>

<style>
  /* .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 380px;
  }
  .top-blue {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 70%;
    background: rgba(20, 20, 20, 0.39);
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    border-radius: 24px 24px 0px 0px;
  }
  .card-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 99;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  } */

  .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 480px;
    max-width: 100%;
    background: #062869;
    mix-blend-mode: normal;
  }
  .top-blue {
    background: linear-gradient(
      66.27deg,
      #13afe9 -10.45%,
      #0f89d0 12.51%,
      #0a54ac 39.13%,
      #083a9a 63.11%
    );
    border-radius: 24px;
    border-radius: 24px;
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 26px;
  }
  .bottom-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.26px;
    color: #ffffff;
    display: flex;
    width: 50%;
    justify-content: start;
  }

  @media only screen and (max-width: 768px) {
    .flex-dir {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 768px) {
    .flex-dir {
      flex-direction: row;
    }
  }
</style>
