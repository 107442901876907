<script>
  import { navigateTo } from "svelte-router-spa";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import Button from "../../components/commons/Button.svelte";
  import BodyText from "../../components/welcome/BodyText.svelte";
  import MainHeader from "../../components/welcome/MainHeader.svelte";
  import { onMount } from "svelte";

  const next = async (flow) => {
    await request("/api/user/flow", "POST", {
      flow,
    });
    navigateTo("/");
  };

  onMount(() => {
    next("sa");
  });
</script>

<!-- <WizardBody title="Start your journey with" back="/">
  <div style="display: flex; flex-direction: column; gap: 66px">
    <div
      class="body-child-card body-child-card-width"
      style=" box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 12%); border-radius: 10px; padding: 20px; "
    >
      <MainHeader
        title="Fixed Deposit"
        style=" font-size: 30px; line-height: 1; text-align: left; width: 100%;"
      />

      <div class="direction-res">
        <img src="/assets/images/fd_card.png" class="img-banner" alt="" />

        <div style=" width: 100%; display: flex; flex-direction: column; ">
          <BodyText
            text="Secure your funds with the highest interests in the industry."
          />
          <Button
            onclick={() => {
              next("fd");
            }}
            title="Apply Now"
            style="margin-top: 20px !important"
          />

          <div
            style="width: 100%;display: flex;justify-content: center;align-items: center; margin-top: 20px;"
          >
            <i class="safe-secure-approve-rbi" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-child-card body-child-card-width"
      style=" box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 12%); border-radius: 10px; padding: 20px; "
    >
      <MainHeader
        title="Savings Account"
        style=" font-size: 30px; line-height: 1; text-align: left; width: 100%;"
      />
      <div class="direction-res">
        <img src="/assets/images/sa_card.png" class="img-banner" alt="" />

        <div style=" width: 100%; display: flex; flex-direction: column; ">
          <BodyText
            text="Benefit from a Savings account that goes the extra mile for you. Earn monthly interest pay-outs and enjoy the advantage of connected banking with 24*7 uninterrupted services."
          />
          <Button
            onclick={() => {
              next("sa");
            }}
            title="Apply Now"
            style="margin-top: 20px !important;"
          />

          <div
            style="width: 100%;display: flex;justify-content: center;align-items: center; margin-top: 20px;"
          >
            <i class="safe-secure-approve-rbi" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-child-card body-child-card-width"
      style=" box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 12%); border-radius: 10px; padding: 20px; "
    >
      <MainHeader
        title="Secure Card"
        style=" font-size: 30px; line-height: 1; text-align: left; width: 100%;"
      />
      <div class="direction-res">
        <img src="/assets/images/sc_card.png" class="img-banner" alt="" />

        <div style=" width: 100%; display: flex; flex-direction: column; ">
          <BodyText
            text="Turn your Fixed deposit into a Freedom Deposit. Get credit card without any income proof."
          />
          <Button
            onclick={() => {
              next("sc");
            }}
            title="Apply Now"
            style="margin-top: 20px !important"
          />

          <div
            style="width: 100%;display: flex;justify-content: center;align-items: center; margin-top: 20px;"
          >
            <i class="safe-secure-approve-rbi" />
          </div>
        </div>
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea /> -->

<style>
  .safe-secure-approve-rbi {
    background-image: url("/assets/images/safe-secure-approve-rbi.svg");
    background-size: 230px;
    width: 230px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
    margin-top: 2px;
  }

  .body-child-card-width {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .direction-res {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  @media only screen and (min-width: 769px) {
    .body-child-card-width {
      width: 100%;
      flex-direction: column;
    }
    .img-banner {
      width: 50%;
    }

    .direction-res {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 768px) {
    .body-child-card-width {
      width: 100%;
      flex-direction: column;
    }
    .img-banner {
      width: 100%;
    }
    .direction-res {
      flex-direction: column;
    }
  }
</style>
