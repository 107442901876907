<script>
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Sa from "../../components/welcome/SA.svelte";
  import Sc from "../../components/welcome/SC.svelte";
  import Fd from "../../components/welcome/FD.svelte";
  import { request } from "../../services/network";
  import { onMount } from "svelte";
  import { navigateTo } from "svelte-router-spa";

  export let currentRoute;
  const type = currentRoute.namedParams.type;

  onMount(async () => {
    if (type === "sa" || type === "sc" || type === "fd") {
      let payload = { flow: type, variant: "general" };
      if (currentRoute.queryParams.hasOwnProperty("variant")) {
        payload.variant = currentRoute.queryParams.variant;
      }
      await request("/api/user/flow", "POST", payload);
      localStorage.setItem("session", JSON.stringify({ flow: type }));
      localStorage.setItem("flow", type);
      location.href = "/onboarding/phone";
    } else {
      localStorage.setItem("session", JSON.stringify({ flow: "sa" }));
      localStorage.setItem("flow", "sa");
      location.href = "/onboarding/phone";
    }
  });
</script>

<!--
<WizardBody title="" back="">
  {#if type === "sa"}
    <Sa />
  {:else if type === "sc"}
    <Sc />
  {:else if type === "fd"}
    <Fd />
  {/if}
</WizardBody>
<WizardActionArea />
-->
