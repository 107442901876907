<script>
  export let key;
  export let value;

  export let style;
</script>

<div
  cals
  style={`display: flex; flex-direction: column; gap: 12px; width: 100%; ${style}`}
>
  <div
    style="font-style: normal; font-weight: 400; font-size: 14px; line-height: 12px; color: #464448; opacity: 0.72;"
  >
    {key}
  </div>
  <div
    style="font-style: normal; font-weight: 700; font-size: 20px; line-height: 12px; color: #464448;"
  >
    {value}
  </div>
</div>
