<script>
  export let value;
  export let list;
  export let disabled = false;
  export let style = "";
</script>

<div style={`display: flex; flex-wrap: wrap; gap: 12px; ${style}`}>
  {#each list as item, index}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="option"
      style={value === item
        ? "border-color: #1E3868; background-color: #EBF0F9"
        : ""}
      on:click={() => {
        if (!disabled) {
          value = item;
        }
      }}
    >
      <div class="input-option" style={value === item ? "color: #464448" : ""}>
        {item}
      </div>
    </div>
  {/each}
</div>

<style>
  .option {
    min-width: 130px;
    border: 1px solid #979797;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-option {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #979797;
    border: none;
    display: block;
    margin: 0px;
    padding: 0px;
    outline: none;
    text-align: center;
  }
</style>
