<script>
  import OtpInput from "./OTPInput.svelte";
  import { createEventDispatcher, tick } from "svelte";

  export const pageInputParams = {
    width: "100%",
    type: "number",
  };

  export let numberOfInputs = 4;
  export let separator = "-";
  export let initialValue = "";
  export let placeholder = "";
  export let customTextInputClass = "";
  export let customSeparatorClass = "";
  export let customRowClass = "";
  export let customWrapperClass = "";
  export let customInputWrapperClass = "";
  export let maskInput = false;
  export let autoFocusNextOnInput = true;
  export let focusPreviousOnDelete = true;
  export let emitEventOnPrefill = false;
  export let onchange;

  function getComponents() {
    return Array.from(Array(numberOfInputs).keys()).map((i) => {
      const initVal = initialValue[i] || "";
      const initPlaceholder = placeholder[i] || "";
      return {
        componentIndex: `otp${i}`,
        ref: null,
        initialValue: initVal,
        placeholder: initPlaceholder,
      };
    });
  }

  function checkValidity(doNotify, extrakeys = {}) {
    let completevalue = "";
    let isInputComplete = true;

    components.forEach((i) => {
      let value = `${i?.ref?.$$?.ctx[0]}`;
      if (!value) {
        isInputComplete = false;
      }
      completevalue += value || " ";
    });

    let returnObj = {
      completevalue,
      isInputComplete:
        isInputComplete && completevalue.length === numberOfInputs,
    };
    if (extrakeys) {
      returnObj = {
        ...returnObj,
        ...extrakeys,
      };
    }
    if (doNotify) {
      dispatch("notify", returnObj);
    } else {
      return returnObj;
    }
  }

  let components = getComponents();

  $: {
    async function prefillValueOnInitialValueChange() {
      if (initialValue !== undefined && initialValue.trim().length > 0) {
        components = getComponents();
        await tick();
        if (emitEventOnPrefill) {
          checkValidity(true, { onValueUpdateOrPrefill: true });
        }
      }
    }
    prefillValueOnInitialValueChange();
  }

  const dispatch = createEventDispatcher();

  export const getValue = () => {
    return checkValidity(false);
  };

  const handleChange = (currentElement, event) => {
    const isDeleteEvent =
      event.code === "Backspace" || event.inputType === "deleteContentBackward" || event.key === "Backspace";

    const currentIndex = components.findIndex(
      (i) => i.componentIndex === currentElement
    );
    let nextIndex;

    if (isDeleteEvent && focusPreviousOnDelete) {
      nextIndex = currentIndex === 0 ? 0 : currentIndex - 1;
      const nextRef = components[nextIndex]?.ref;
      nextRef?.$$?.ctx[6]?.focus();
    }

    if (!isDeleteEvent && autoFocusNextOnInput) {
      nextIndex =
        currentIndex < components.length - 1 ? currentIndex + 1 : currentIndex;
      const nextRef = components[nextIndex].ref;
      nextRef?.$$?.ctx[6]?.focus();
    }

    checkValidity(true);
  };
</script>

<div
  style="display: flex; gap: 16px
"
>
  {#each components as comp, index}
    <OtpInput
      style="max-width:100%; width:100%; text-align:center;"
      {...pageInputParams}
      value={comp.initialValue}
      componentIndex={comp.componentIndex}
      {handleChange}
      placeholder={comp.placeholder}
      bind:this={comp.ref}
      customInputClass={customTextInputClass}
      {customInputWrapperClass}
      {maskInput}
      dataAttr={`elem-${index}`}
      on:input={onchange}
    />
  {/each}
</div>
