<script>
  import dayjs from "dayjs";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import FdProgressIndicator from "../../components/FDCC/FdProgressIndicator.svelte";
  import FdDetailsHeaderItem from "../../components/FDCC/FdDetailsHeaderItem.svelte";
  import FdDetailsItem from "../../components/FDCC/FdDetailsItem.svelte";
  import { onMount } from "svelte";
  import { request } from "../../services/network";
  import Dialog, { Content } from "@smui/dialog";
  import {
    bankToJsDate,
    currencyToNum,
    dateDiff,
    getMaturityAmount,
    parseDays,
    toCurrency,
  } from "../../services/function";
  import FdTransactionListItem from "../../components/FDCC/FDTransactionListItem.svelte";
  import { navigateTo } from "svelte-router-spa";
  import IconButton from "@smui/icon-button";

  export let currentRoute;

  const fd_id = currentRoute.namedParams.fd_id;

  let fdData = {};
  let percentage = 0;
  let fdAmount = 0;
  let maturityDate = "";
  let maturityAmount = "";

  let dialogOpen = false;

  let transactionList = [];

  let fdDetailsViewMore = false;
  let fdDetailsListViewMore = false;
  let nomineeName = "";

  onMount(async () => {
    const fdGetRes = await request(`/api/fd/${fd_id}`, "GET");

    fdData = fdGetRes.data;
    fdAmount = fdData.amount;
    const duration = fdData.duration;
    const interest_rate = fdData.interest_rate;

    maturityAmount = getMaturityAmount(fdAmount, duration, interest_rate);

    /* const matu

    const mDate = bankToJsDate(fdData.data.MaturityDt); //TOOD: change later according to api data
    const creationDate = bankToJsDate(fdData.data.AcctOpnDt); //TOOD: change later according to api data
    const fdDays = dateDiff(mDate, new Date());
    const completedDays = dateDiff(new Date(), creationDate);
    percentage = (completedDays / fdDays) * 100; */

    const completedDays = dateDiff(
      new Date(),
      dayjs(fdData.created_at).toDate()
    );
    percentage = (completedDays / fdData.duration) * 100;

    const fdTxnsGet = await request(`/api/fd/transactions/${fd_id}`, "GET");

    transactionList = fdTxnsGet.data;

    const nomineeGet = await request(
      `/api/nominee/${fdData.nominee_id}`,
      "GET"
    );

    nomineeName = nomineeGet.data[0].name;
  });
</script>

<!-- svelte-ignore missing-declaration -->
<Dialog bind:dialogOpen aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">
          Are you sure you want to close this deposit?
        </div>
        <div class="modal-header-icon">
          <IconButton
            on:click={() => (dialogOpen = false)}
            class="material-icons">close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <span style=" align-items: center; display: flex; ">Select Date:</span
          >
          <div class="col-xs-12 col-md-6 my-3">
            <div class="input-container">
              <!-- <input
                class="dob-input-field"
                type="date"
                placeholder="DD/MM/YYYY"
                bind:value={maturityDateTemp}
              /> -->
            </div>
          </div>
        </div>
        <!-- <Button
          title="Save"
          onclick={() => {
            maturityDate = "";
            maturityDate = maturityDateTemp;
            open = false;
          }} -->
        />
      </div>
    </div>
  </Content>
</Dialog>

<WizardBody title="" back="/">
  <div style="display: flex; flex-direction: column;">
    <div style="position: relative; display: flex">
      <div
        style="width: 100%; display: flex; flex-direction: column; gap: 16px;"
      >
        <div
          style="font-style: normal; font-weight: 600; font-size: 14px; line-height: 10px; letter-spacing: 0.26px; color: #464448; opacity: 0.72;"
        >
          FD No 64545605950
        </div>
        <div
          style="font-style: normal; font-weight: 700; font-size: 48px; line-height: 36px; letter-spacing: 0.26px; color: #464448;"
        >
          {toCurrency(fdAmount)}
        </div>

        {#if fdData.cc_enabled === 1}
          <div
            style="position: relative; padding: 10px;  display: flex; width: 160px; background: #F5DCAE; border-radius: 16px; align-items: center; justify-content: center; overflow: hidden;"
          >
            <i class="crd-ico" />
            <div
              style="font-style: normal; font-weight: 700; font-size: 12px; line-height: 8px;  color: #9A7025; margin-left: 6px;"
            >
              Credit Card Linked
            </div>
          </div>
        {/if}

        <div style="max-width: 120px; overflow: hidden;">
          <FdProgressIndicator {percentage} />
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 8px;">
        <button
          class="need-help-button"
          on:click={() => {
            navigateTo("/");
          }}>NEED HELP</button
        >
        <button
          on:click={() => {
            navigateTo(`fd-details-deposit/${fd_id}`);
          }}
          class="close-fd-button"
          style="cursor: pointer;">CLOSE FD</button
        >
      </div>
    </div>

    <div style="margin-top: 24px;display: flex; flex-direction: column;">
      <div
        style="display: flex; width: 100%; justify-content: space-evenly; align-items: center;"
      >
        <FdDetailsHeaderItem
          key="Interest Rate"
          value={`${fdData.interest_rate} %`}
          style="text-align: start"
        />

        <!-- TODO: -->
        <FdDetailsHeaderItem
          key="Interest Earned"
          value="₹480"
          style="text-align: center"
        />

        <FdDetailsHeaderItem
          key="Maturity Date "
          value={dayjs(fdData.created_at)
            .add(fdData.duration, "day")
            .format("DD MMM YYYY")}
          style="text-align: end"
        />
      </div>

      <!-- fdDetailsViewMore -->

      <div
        style={`flex-direction: column; position: relative; transition-duration: 300ms;${
          fdDetailsViewMore ? "display: flex;" : "display: none;"
        }`}
      >
        <div
          style="margin-top:32px; padding-bottom: 16px; font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; text-transform: capitalize; color: #1E3868;"
        >
          Details
        </div>

        <FdDetailsItem key="FD Duration" value={parseDays(fdData.duration)} />

        <FdDetailsItem
          key="Start Date"
          value={dayjs(fdData.created_at).format("DD MMM YYYY")}
        />

        <FdDetailsItem key="Get Interest" value={`On Maturity`} />

        <FdDetailsItem key="Maturity Instruction" value="Auto Renew" />

        <FdDetailsItem
          key="Maturity Amount"
          value={toCurrency(parseInt(maturityAmount))}
        />

        <FdDetailsItem key="Transfer to Account" value="ICICI-0854700264" />

        <FdDetailsItem key="Nominee" value={nomineeName} />
      </div>

      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        on:click={() => (fdDetailsViewMore = !fdDetailsViewMore)}
        style="cursor: pointer; margin-top: 24px; text-transform: uppercase; padding-bottom: 16px; font-style: normal; font-weight: 700; font-size: 16px; line-height: 14px; text-transform: capitalize; color: #1E3868;"
      >
        {#if fdDetailsViewMore}
          VIEW LESS
        {:else}
          VIEW MORE
        {/if}
      </div>

      <div
        style="margin-top: 32px; display: flex; flex-direction: column; width: 100%; padding: 32px; background: #FFFFFF; box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.2); border-radius: 24px;"
      >
        <div
          style="font-style: normal; font-weight: 700; font-size: 16px; line-height: 16px; text-transform: capitalize; color: #1E3868;"
        >
          Interest Payout Schedule
        </div>

        <div style="width: 100%; display: flex; margin-top: 32px;">
          <div
            style="display: flex; width: 100%;font-weight: 600; font-size: 14px; line-height: 10px; letter-spacing: 0.26px; color: #464448; justify-content: start;"
          >
            Due Date
          </div>

          <div
            style="display: flex; width: 100%;font-weight: 600; font-size: 14px; line-height: 10px; letter-spacing: 0.26px; color: #464448; justify-content: center;"
          >
            Status
          </div>

          <div
            style="display: flex; width: 100%;font-weight: 600; font-size: 14px; line-height: 10px; letter-spacing: 0.26px; color: #464448; justify-content: end;"
          >
            Interest
          </div>
        </div>

        {#each transactionList as transactionListItem, index}
          {#if index <= 2}
            <FdTransactionListItem {transactionListItem} />
          {/if}
        {/each}

        <div
          style={`flex-direction: column; ${
            fdDetailsListViewMore ? "display: flex;" : "display: none;"
          }`}
        >
          {#each transactionList as transactionListItem, index}
            {#if index > 2}
              <FdTransactionListItem {transactionListItem} />
            {/if}
          {/each}
        </div>

        {#if transactionList.length > 2}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <div
            on:click={() => (fdDetailsListViewMore = !fdDetailsListViewMore)}
            style="cursor: pointer; margin-top: 24px; text-transform: uppercase; font-style: normal; font-weight: 700; font-size: 16px; line-height: 12px; text-transform: capitalize; color: #1E3868;"
          >
            {#if fdDetailsListViewMore}
              VIEW LESS
            {:else}
              VIEW MORE
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea />

<style>
  .modal-header-text {
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #464448;
  }
  .crd-ico {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjI1IDIuNUgxLjI1VjEuMjVIMTEuMjVNMTEuMjUgOC43NUgxLjI1VjVIMTEuMjVNMTEuMjUgMEgxLjI1QzAuNTU2MjUgMCAwIDAuNTU2MjUgMCAxLjI1VjguNzVDMCA5LjA4MTUyIDAuMTMxNjk2IDkuMzk5NDYgMC4zNjYxMTYgOS42MzM4OEMwLjYwMDUzNyA5Ljg2ODMgMC45MTg0NzkgMTAgMS4yNSAxMEgxMS4yNUMxMS41ODE1IDEwIDExLjg5OTUgOS44NjgzIDEyLjEzMzkgOS42MzM4OEMxMi4zNjgzIDkuMzk5NDYgMTIuNSA5LjA4MTUyIDEyLjUgOC43NVYxLjI1QzEyLjUgMC41NTYyNSAxMS45Mzc1IDAgMTEuMjUgMFoiIGZpbGw9IiM5QTcwMjUiLz4KPC9zdmc+Cg==');
    background-size: 14px;
    width: 14px;
    height: 11px;
    display: block;
    background-repeat: no-repeat;
  }

  .need-help-button {
    background: #5e75a1;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
    color: #ffffff;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 128px;
  }

  .close-fd-button {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #979797;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 12px;
    color: #464448;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 128px;
  }
</style>
