<script>
  import { navigateTo } from "svelte-router-spa";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import { onMount } from "svelte";
  import { decrypt } from "../../services/function";

  onMount(async () => {
    const userData = JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );

    if (userData.user.attributes.flow === "sa") {
      window.loading(true);
      // VKYC OR SELFIE IS moved to a later stage, this decision is to be made on the fly on /payment page
      await request("/api/user_account/sa", "POST", {});
      window.loading(false);
      navigateTo("/");
    } else if (
      userData.user.attributes.flow === "fd" ||
      userData.user.attributes.flow === "sc"
    ) {
      //for fd
      navigateTo(`/fd/create/${userData.user.attributes.flow}`);
    }
  });
</script>

<WizardBody title="Create user account" back="/" />
<WizardActionArea />

<style>
</style>
