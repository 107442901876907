export const declaration = {
  en: {
    name: "English",
    value:
      "<li>I voluntarily opt for Aadhaar OVD KYC or e-KYC or Offline verification, and submit to the bank my Aadhaar Number, Virtual ID, e-Aadhaar, XML, Masked Aadhaar, Aadhaar details, demographic information, Identity information, Aadhaar registered mobile number, face authentication details and /or biometric information (collectively, “Information”)</li>" +
      "<li>I am informed by the bank that,</li>" +
      "<ol>" +
      "<li>Submission of Aadhaar is not mandatory, and there are alternative options for KYC and establishing identity including by way of physical KYC with officially valid documents other than Aadhaar. These include coming to bank branch with any two of the other KYC documents like PAN card / Passport/ Driving license/ MNREGA Card /Voter id card etc. The process for the same is available at all branches of the bank. All options of establishing identity were offered to me by the bank.</li>" +
      "<li>For e-KYC/ authentication /offline verification, Bank will share Aadhaar number and / or biometrics with CIDR/UIDAI, and CIDR/UIDAI will share with the Bank, authentication data, Aadhaar data, demographic details, registered mobile number, identity information, which shall be used for the informed purposes mentioned at point 3 below.</li>" +
      "</ol>" +
      "<li>I authorise and give my consent to the bank (and its service providers), for the following informed purposes:</li>" +
      "<ol>" +
      "<li>KYC and periodic KYC process as per the PML Act, 2002 and rules thereunder and RBI guidelines, or for establishing my identity, carrying out my identification, offline verification or e-KYC or Yes/No authentication, demographic or other authentication/ verification/ identification as may be permitted as per applicable law, for all accounts, facilities, services and relationship of/through the bank, existing and future;</li>" +
      "<li>Collecting, sharing, storing, preserving information, maintaining records and using the information and authentication/verification/identification of records: (i) for the informed purposes above, (ii) as well as for regulatory and legal reporting and filings and /or (iii)where required under applicable law;</li>" +
      "<li>Enabling my account for Aadhaar enable payment services (AEPS);</li>" +
      "<li>Producing records and logs of consent, information or of authentication, identification, verification etc, for evidentiary purposes including before a court of law, or any authority or in arbitration.</li>" +
      "</ol>" +
      "<li>I hereby acknowledge that the bank shall maintain the logs of my consent for the purpose as provided at point 3 above.</li>" +
      "<li>My consent is being taken in accordance with the Aadhaar Act, 2016 and rules and regulations framed thereunder.</li>" +
      "<li>I have further been informed that the bank shall not share my e-KYC or Aadhaar number with other entities.</li>" +
      "<li>I understand that the Aadhaar number and core biometrics will not be stored / shared except as per law and for CIDR submission. I have downloaded the e-Aadhaar myself using the OTP received on my Aadhaar registered mobile number. I will not hold the Bank or its officials responsible in the event this document is not found to be in order or in case any incorrect information provided by me.</li>" +
      "<li>The above consent and purpose of collecting Information has been explained to me and available to me in my local language.</li>",

    audio: "/aadhaar/assets/aadhaar-en.mp3",
  },
  hi: {
    name: "हिंदी (Hindi)",
    value:
      "<ol>" +
      "<li>मैं स्वेच्छा से आधार ओवीडी केवाइसी या ई-केवाइसी या ऑफ़लाइन सत्यापन कराने और बैंक के पास अपना आधार नंबर, वर्चुअल आइडी, ई-आधार, XML मास्क्ड आधार, आधार विवरण, जनसांख्यिक जानकारी, पहचान संबंधी जानकारी, आधार पंजीकृत मोबाइल नंबर, फेस ऑथेन्टिकेशन (मुख सत्यापन) के विवरण और/या बायोमेट्रिक जानकारी (संयुक्त रूप से ‘‘जानकारी’’) जमा करने का फैसला ले रहा/रही हूं।</li>" +
      "<li>मुझे बैंक द्वारा बताया गया है कि," +
      "<ol>a. आधार जमा करना अनिवार्य नहीं है, और केवाइसी के अन्य विकल्प भी उपलब्ध हैं, जिनमें आधार के अलावा अन्य आधिकारिक रूप से वैध दस्तावेज़ों के वास्तविक सत्यापन द्वारा पहचान सिद्ध करने का समावेश है।</ol>" +
      "<ol> b. ई-केवाइसी/सत्यापन/ऑफ़लाइन सत्यापन के लिये बैंक CIDR/UIDAI के साथ आधार नंबर साझा करेंगे और CIDR/UIDAI बैंक के साथ सत्यापन संबंधी डाटा, आधार डाटा, जनसांख्यिक विवरण, पंजीकृत मोबाइल नंबर, पहचान संबंधी जानकारी बैंक के साथ साझा करेंगे, जिनका इस्तेमाल नीचे दिये गये बिंदु 3 में व्यक्त सूचित उद्देश्यों के लिये किया जायेगा। </ol>" +
      "</li>" +
      "<li>मैं बैंक (और उसके सेवा प्रदाताओं) को निम्नलिखित सूचित उद्देश्यों के लिये स्वीकृति देता/देती हूं: <ol>a. PML कानून, 2002 और उसके अधीनस्थ नियम और आरबीआइ के दिशानिर्देशों के अनुसार बैंक के साथ / बैंक के माध्यम से सभी वर्तमान और भविष्य के खातों, सेवाओं, और संबंधों केवाइसी और आवधिक केवाइसी प्रकिया, या मेरी पहचान की पुष्टि के लिये, मेरी पहचान सिद्ध करने के लिये, ऑडलाइअन सत्यापन या ई-केवाइसी या हाँ/नहीं सत्यापन, कानून द्वारा स्वीकृत जनसांख्यिक या अन्य सत्यापन / पुष्टि / पहचान;</ol>" +
      "<ol> b. जानकारी प्राप्त करने, साझा करने, रखने, संरक्षित करने, रेकॉर्ड रखने और जानकारी का उपयोग करने तथा रेकॉर्ड्स का सत्यापन / पुष्टि / पहचान करने: (i) ऊपर दिये गये उद्देश्यों के लिये, (ii) नियामक और कानूनी सूचनाऔर फाइलिंग के लिये और/या (iii) जब कानूनन यह आवश्यक हो;</ol> " +
      "<ol> c. मेरे खाते को आधार निर्भर भुगतान सेवाओं (AEPS); </ol>" +
      "<ol> d. न्यायालय या किसी प्राधिकरण या मध्यस्थता में प्रमाण के लिये स्वीकृति, सूचना या सत्यापन, पहचान, पुष्टि इत्यादि का रेकॉर्ड और लॉग प्रस्तुत करना। </ol>" +
      "</li>" +
      "<li>मैं पुष्टि करता/करती हूं कि बैंक ऊपर दिये गये बिंदु 3 में व्यक्त उद्देश्यों के लिये मेरी स्वीकृतियों का ब्यौरा रखेगा।</li>" +
      "<li>मेरी स्वीकृति आधार कानून, 2016  और उसके अधीन गठित नियमों और कायदों के अनुसार ली जा रही है।</li>" +
      "<li> मुझे यह भी बताया गया है कि बैंक अन्य निकायों के साथ मेरा ई-केवाइसी या आधार नंबर साझा नहीं करेगा। </li>" +
      "<li>मैं समझता/समझती हूं कि आधार नंबर और मुख्य बायोमेट्रिक्स को कानून के अनुसार और CIDR में जमा करने के उद्देश्य के अलावा रखा/साझा नहीं किया जायेगा। मैंने मेरे आधार पंजीकृत मोबाइल नंबर पर प्राप्त ओटीपी की मदद से ख़ुद ई-आधार डाउनलोड कर लिया है। यह दस्तावेज़ व्यस्थित न होने या मेरे द्वारा दी गयी कोई जानकारी ग़लत पाये जाने पर मैं बैंक या उसके अधिकारियों को ज़िम्मेदार नहीं ठहराऊंगा/ठहराऊंगी।</li>" +
      "<li>जानकारी लेने के लिये उपर्युक्त स्वीकृति और उसका उद्देश्य मुझे समझाया गया है और मेरे लिये मेरी प्रादेशिक भाषा में उपलब्ध है।</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-hi.mp3",
  },
  be: {
    name: "বাংলা (Bengali)",
    value:
      "<ol>" +
      "<li>	আমি স্বেচ্ছায় আধার OVD KYC অথবা e-KYC অথবা অফলাইন যাচাইকরণের বিকল্প নির্বাচন করি, এবং ব্যাঙ্কের নিকট আমার আধার নম্বর, ভার্চুয়াল ID, e-আধার, XML, মাস্কড আধার, আধার বিবরণ, জন-তাত্ত্বিক (ডেমোগ্রাফিক) তথ্য, পরিচয় তথ্য আধার এর সঙ্গে রেজিস্টার করা মোবাইল নম্বর, মুখ-মন্ডল ভিত্তিক সত্যতা যাচাইকরণ বিবরণ এবং / অথবা জীবমিতি (বায়োমেট্রিক) তথ্য (সমন্বিতভাবে 'তথ্য‍') জমা করি৷</li>" +
      "<li>আমাকে ব্যাঙ্কের দ্বারা অবগত করা হয়েছে যে," +
      "<ol>a.	আধার জমা দেওয়া বাধ্যতামূলক নয়, আধার ব্যতীত প্রাতিষ্ঠানিকভাবে বৈধ নথিপত্রের সাহায্যে বাস্তবিক KYC এর দ্বারা পাসপোর্ট / ড্রাইভিং লাইসেন্স / MNREGA কার্ড / ভোটদাতার পরিচয় পত্র ইত্যাদির মত অন্যান্য KYC নথিগুলির মধ্যে যেকোনো দুটি নিয়ে ব্যাঙ্কে আসা৷ এই প্রক্রিয়াটি ব্যাঙ্কের সকল শাখায় উপলব্ধ৷ ব্যাঙ্কের দ্বারা পরিচয় প্রতিষ্ঠা করার সকল বিকল্পগুলি আমার নিকট প্রস্তাব করা হয়েছিল৷</ol>" +
      "<ol>b.	e-KYC / সত্যতা যাচাই / অফলাইন যাচাইকরণের জন্য ব্যাঙ্ক, CIDR/UIDAI এর সঙ্গে আধার নম্বর এবং / অথবা বায়োমেট্রিক শেয়ার করবে এবং CIDR/UIDAI ব্যাঙ্কের সঙ্গে সত্যতা যাচাইকরণ তথ্য আধার তথ্য, ডেমোগ্রাফিক বিবরণ, রেজিস্টার করা মোবাইল নম্বর, পরিচয়ের তথ্য জানাবে যেগুলি নীচের দফা 3 এ উল্লেখিত উদ্দেশ্যে ব্যবহার করা হবে৷</ol>" +
      "</li>" +
      "<li>আমি ব্যাঙ্ককে (এবং তাদের পরিষেবা প্রদানকারীদের), নিম্নলিখিত অবহিত উদ্দেশ্যগুলির জন্য, কর্তৃত্বযুক্ত করি এবং আমার সম্মতি প্রদান করি: " +
      "<ol>a.PML আইন, 2002 এবং তদোধীন নিয়মসমূহ এবং RBI এর নির্দেশিকা অনুসারে KYC এবং সময়ান্তরভিত্তিক KYC প্রক্রিয়ার জন্য, অথবা ব্যাঙ্কের বিদ্যমান / ভবিষ্যতের সকল অ্যাকাউন্ট, পরিষেবা এবং সম্পর্কের উদ্দেশ্যে, আমার পরিচয় প্রতিষ্ঠা করার জন্য, আমার পরিচয় নির্ধারণ, অফলাইন যাচাইকরণ, অথবা e-KYC অথবা KYC অথবা হ্যাঁ/না সত্যতা নিরূপণ, ডেমোগ্রাফিক অথবা অন্যান্য সত্যতা নিরূপণ / যাচাইকরণ / সনাক্তকরণ, আইনের দ্বারা যে প্রকার অনুমোদিত হতে পারে৷</ol>" +
      "<ol>b.তথ্য সংগ্রহ করা, জানানো, মজুত করা, সংরক্ষণ করা, নথি রক্ষণাবেক্ষণ করা এবং তথ্য এবং নথিসমূহের সত্যতা যাচাইকরণ / যাচাইকরণ / সনাক্তকরণ: (i) উপরে অবহিত উদ্দেশ্যগুলির জন্য, (ii) এছাড়াও নিয়ন্ত্রণমূলক এবং আইনভিত্তিক প্রতিবেদনকরণ এবং জমাকরণ এবং / অথবা (iii) যেখানে প্রযোজ্য আইনের অধীনে প্রয়োজনীয়;</ol>" +
      "<ol>c. আমার অ্যাকাউন্টটিকে আধার এনাবেল পেমেন্ট সার্ভিসেস (AEPS) এর জন্য সক্ষম করা;</ol>" +
      "<ol>d. একটি আইনের আদালতের অথবা যেকোনো কর্তৃপক্ষ অথবা সালিসির ক্ষেত্রে অন্তর্ভুক্ত করে যেকোনো প্রামাণিক উদ্দেশ্যে, সম্মতি, তথ্য অথবা সত্যতা যাচাইকরণ, সনাক্তকরণ, যাচাইকরণ ইত্যাদির নথি এবং তালিকাগুলি উপস্থাপিত করা৷</ol>" +
      "</li>" +
      "<li>আমি এতদ্বারা স্বীকার করি যে উপরের দফা 3 এ প্রদত্ত উদ্দেশ্যে ব্যাঙ্ক আমার সম্মতি তালিকা রক্ষণাবেক্ষণ করবে৷</li>" +
      "<li>আমার সম্মতি গ্রহণ করা হচ্ছে, আধার আইন, 2016 এবং তদোধীনে গঠিত নিয়ম এবং বিধিসমূহ অনুসারে৷</li>" +
      "<li>আমাকে আরও জানানো হয়েছে যে ব্যাঙ্ক আমার e-KYC অথবা আধার নম্বর অন্যান্য সত্তাগুলিকে জানাবে না৷</li>" +
      "<li>আমি অনুধাবন করি যে আধার নম্বর এবং মূল বায়োমেট্রিক্স, আইন অনুসারে এবং CIDR জমাকরণ ব্যতীত মজুতকরণ / জানানো হবে না৷ আমি, আমার আধার রেজিস্টার্ড মোবাইল নম্বরে প্রাপ্ত করা OTP ব্যবহার করে নিজে e-আধার ডাউনলোড করেছি৷ আমি ব্যাঙ্ক অথবা তাদের আধিকারিকদের, এই নথিটিতে যথাযথ হিসাবে উদঘাটিত না হওয়ার, আমার দ্বারা যেকোনো অশুদ্ধ তথ্য প্রদান করার ক্ষেত্রে দায়ী সাব্যস্ত করব না৷</li>" +
      "<li>উপরের সম্মতি এবং তথ্য সংগ্রহ করার উদ্দেশ্যটি আমার নিকট আমার স্থানীয় ভাষায় ব্যাখ্যা করা এবং উপলব্ধ করা হয়েছে৷</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-bengali.mp3",
  },
  gu: {
    name: "ગુજરાતી (Gujarati)",
    value:
      "<ol>" +
      "<li>	હું સ્વેચ્છાએ આધાર ઓવીડી કેવાયસી અથવા ઈ-કેવાયસી અથવા ઑફલાઈન વેરિફિકેશન પસંદ કરું છું અને બૅન્કને મારો આધાર નંબર, વર્ચ્યુઅલ આઈડી, ઈ-આધાર, એક્સએમએલ, માસ્કડ આધાર, આધારની વિગતો, વસતિ વિષયક માહિતી, ઓળખની માહિતી, આધાર રજિસ્ટર્ડ મોબાઈલ નંબર, ફૅસ ઓથેન્ટિકેશન વિગતો અને/અથવા બાયોમેટ્રિક માહિતી (સંયુક્ત રીતે 'માહિતી') રજૂ કરું છું.</li>" +
      "<li>બૅન્ક દ્વારા મને જણાવવામાં આવ્યું છે કે," +
      "<ol>ફ.	આધાર રજૂ કરવું એ ફરજિયાત નથી તેમ જ કેવાયસી અને ઓળખ સ્થાપિત કરવા પર્યાયી વિકલ્પો છે જેમાં આધાર સિવાય કાયદેસર માન્ય દસ્તાવેજો સાથે ફિઝિકલ કેવાયસી સામેલ છે. આમાં અન્ય કેવાયસી દસ્તાવેજો માંથી કોઈ પણ બે જેમ કે પેન કાર્ડ, પાસપોર્ટ, ડ્રાઈવિંગ લાઈસન્સ, મનરેગા કાર્ડ, મતદાતા ઓળખપત્ર ઈત્યાદિ સાથે બૅન્કની શાખામાં આવવું સામેલ છે. આ માટેની પ્રક્રિયા બૅન્કની બધી જ શાખાઓમાં ઉપલબ્ધ છે. બૅન્ક તરફથી ઓળખ પ્રસ્થાપિત કરવાના બધાં જ વિકલ્પો મને પૂરાં પાડવામાં આવ્યાં હતાં.</ol>" +
      "<ol>બ.	ઈ-કેવાયસી / ઑથેન્ટિકેશન / ઑફલાઈન વેરિફિકેશન માટે, બૅન્ક આધાર નંબર અને/અથવા બાયોમેટ્રિક સીઆઈડીઆર, યુઆઈડીએઆઈ સાથે વહેંચશેઅને સીઆઈડીઆર / યુઆઈડીએઆઈ બૅન્ક સાથે  વહેંચશે, ઑથેન્ટિકેશન ડેટા, આધાર ડેટા, વસતિવિષયમ વિગતો, રજિસ્ટર્ડ મોબાઈલ નંબર, ઓળખની માહિતી જે નીચે પૉઈન્ટ ૩માં જણાવેલા સૂચિત હેતુઓસર ઉપયોગ કરાય છે.</ol>" +
      "</li>" +
      "<li>હું નીચે જણાવેલા સૂચિત હેતુઓસર બૅન્કને (અને તેનાં સેવા પ્રદાતાઓને) અધિકૃત કરું અને મારી સંમતિ આપું છું:" +
      "<ol>ફ.	પીએમએલ ઍક્ટ, ૨૦૨૨ અને તે અંતર્ગત નિયમો અનુસાર તેમ જ આરબીઆઈની માર્ગદર્શિકા મુજબ કેવાયસી અને સમયાંતરે થતી કેવાયસી પ્રક્રિયા અથવા મારી ઓળખ સ્થાપિત કરવા, મારી ઓળખ નિર્ધારિત કરવા, ઑફલાઈન વેરિફિકેશન અથવા ઈ-કેવાયસી અથવા હા / ના પ્રમાણભૂતતા, વસતિ વિષયક (ડેમોગ્રાફિક) અથવા અન્ય પ્રમાણભૂતતા / ચકાસણી / ઓળખ જે પણ લાગુ કાયદાઓ મુજબ માન્ય હોય તે બધાં જ ખાતાઓ, સુવિધાઓ, સેવાઓ અને બન્કોના/મારફત સંબંધો પ્રવર્તમાન અને ભાવિ બંને સહિત લાગુ રહેશે.</ol>" +
      "<ol>બ.	રેકૉર્ડસ મેળવવા, વહોંચવા, સંગ્રહ કરવા, માહિતી સાચવવી, રેકૉર્ડસ જાળવવા તેમ જ માહિતીનો ઉપયોગ કરવો અને રેકૉર્ડસ પ્રમાણભૂત/ચકાસણી /ઓળખ કરવા: (૧) ઉપર જણાવેલા સૂચિત હેતુઓસર, (૨) તેમ જ નિયમનકારી અને કાનૂની રિપૉર્ટિંગ અને ફાઈલિંગ્સ અને/અથવા (૩) લાગુ કાયદા હેઠળ જ્યાં આવશ્યક હોય.</ol>" +
      "<ol>ભ.	આધાર એનેબલ પેમેન્ટ સર્વિસીસ (એઈપીએસ) માટે મારું અકાઉન્ટ સક્ષમ કરવું:</ol>" +
      "<ol>મ.	રેકૉર્ડસ અને સંમતિના લૉગ્સ રજૂ કરવા, માહિતી અથવા પ્રમાણભૂતતા, ઓળખ, ચકાસણી ઈત્યાદિ માટે, પુરાવાના હેતુઓસર જેમાં ન્યાયાલય સમક્ષ અથવા અન્ય કોઈ સત્તાધિકારી સમક્ષ અથવા લવાદ સામેલ છે.</ol>" +
      "</li>" +
      "<li>હું આ દ્વારા સ્વીકૃત કરું છું કે ઉપર પૉઈન્ટ ૩માં જણાવ્યા મુજબના હેતુઓ માટે  બૅન્ક મારી સંમતિના લૉગ્સ જાળવશે. </li>" +
      "<li>મારી સંમતિ આધાર ઍક્ટ, ૨૦૧૬ અને તે અંતર્ગત બનાવેલા નિયમો અને નિયમનો અનુસાર લેવામાં આવી છે.  </li>" +
      "<li>વધુમાં મને જણાવવામાં આવ્યું છે કે બૅન્ક મારું ઈ-કેવાયસી અથવા આધાર નંબર અન્ય મંડળો સાથે વહેંચશે નહીં.</li>" +
      "<li>હું સમજું છું કે આધાર નંબર અને કોર બાયોમેટ્રિક્સ કાયદા અનુસાર અને સીઆઈડીઆર રજૂઆત માટેના કારણ સિવાય વહેંચવામાં નહીં આવે. મારા આધાર નોંધણીકૃત મોબાઈલ નંબર પર મેળવેલ ઓટીપીના ઉપયોગ દ્વારા મેં જાતે ઈ-આધાર ડાઉનલોડ કરેલ છે. હું બૅન્ક અથવા તેના અધિકારીઓને જવાબદાર નહીં ઠેરવું જો અગર આ દસ્તાવેજો બરાબર ન જણાય અથવા મેં કોઈ ખોટી માહિતી પૂરી પાડી હોય.</li>" +
      "<li>ઉપરોક્ત સંમતિ અને માહિતી મેળવવાનો હેતુ મને સમજાવવામાં આવ્યા છે તથા મારી સ્થાનિક ભાષામાં મને ઉપલબ્ધ છે.৷</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-gujarati.mp3",
  },
  ka: {
    name: "ಕನ್ನಡ (Kannada)",
    value:
      "<ol>" +
      "<li>ನಾನು ಸ್ವಯಂಪ್ರೇರಣೆಯಿಂದ ಆಧಾರ್ OVD KYC (ಒವಿಡಿ ಕೆವೈಸಿ) ಅಥವಾ e-KYC (ಇ-ಕೆವೈಸಿ) ಅಥವಾ ಆಫ್‌ಲೈನ್ ಪರಿಶೀಲನೆಯನ್ನು ಆರಿಸಿಕೊಳ್ಳುತ್ತೇನೆ ಮತ್ತು ಬ್ಯಾಂಕ್‌ಗೆ ನನ್ನ ಆಧಾರ್ ಸಂಖ್ಯೆ, ವರ್ಚುವಲ್ ಐಡಿ, ಇ-ಆಧಾರ್, XML (ಎಕ್‌ಸ್‌ಎಂಎಲ್), ಮಾಸ್‌ಕ್ಡ್‌ ಅಥವಾ ಮುಸುಕಿನ ಆಧಾರ್, ಆಧಾರ್ ವಿವರಗಳು, ಜನಸಂಖ್ಯಾ ಮಾಹಿತಿ, ಗುರುತಿನ ನೋಂದಾಯಿತ ಮೊಬೆ‘ಲ್ ಮಾಹಿತಿ, ಆಧಾರ್ ನೋಂದಾಯಿತ ಮಾಹಿತಿ ಸಂಖ್ಯೆ, ಮುಖದ ದಢೀಕರಣ ವಿವರಗಳು ಮತ್ತು/ಅಥವಾ ಬಯೋಮೆಟ್ರಿಕ್ ಮಾಹಿತಿಯನ್ನು (ಒಟ್ಟಾರೆಯಾಗಿ, ’’ಮಾಹಿತಿ’’) ಸಲ್ಲಿಸುತ್ತೇನೆ.</li>" +
      "<li>ಬ್ಯಾಂಕ್ ನನಗೆ ಈ ಕೆಳಗಿನ ವಿಷಯಗಳನ್ನು ತಿಳಿಸಿ ಹೇಳಿದೆ," +
      "<ol>ಎ.	ಆಧಾರ್ ಸಲ್ಲಿಕೆ ಕಡ್ಡಾಾಯವಲ್ಲ, ಮತ್ತು KYC (ಕೆವೈಸಿ) ಗಾಗಿ ಪರ್ಯಾಯ ಆಯ್ಕೆಗಳಿವೆ ಮತ್ತು ಆಧಾರ್ ಹೊರತುಪಡಿಸಿ ಅಕತವಾಗಿ ಮಾನ್ಯವಾದ ದಾಖಲೆಗಳೊಂದಿಗೆ ಭೌತಿಕ KYC (ಕೆವೈಸಿ) ಮೂಲಕ ಗುರುತನ್ನು ಸ್ಥಾಪಿಸುವುದು. ಪ್ಯಾನ್ ಕಾರ್ಡ್/ಪಾಸ್‌ಪೋರ್ಟ್/ಡ್ರೆವಿಂಗ್ ಲೈಸೆನ್ಸ್‌/ಎಂಎನ್‌ಆರ್‌ಇಜಿಎ ಕಾರ್ಡ್/ವೋಟರ್ ಐಡಿ ಕಾರ್ಡ್ ಮುಂತಾದ ಯಾವುದೇ ಎರಡು ಕೆವೆ‘ಸಿ ದಾಖಲೆಗಳನ್ನು ತೆಗೆದುಕೊಂಡು ಬ್ಯಾಂಕ್ ಶಾಖೆಗೆ ಬರುವುದು ಇವುಗಳಲ್ಲಿ ಸೇರಿರುತ್ತವೆ. ಇದಕ್ಕೆ ಸಂಬಂಸಿದ ಪ್ರಕ್ರಿಯೆಯು ಬ್ಯಾಂಕ್‌ನ ಎಲ್ಲಾ ಶಾಖೆಗಳಲ್ಲಿಯೂ ಲಭ್ಯವಿದೆ. ಗುರುತನ್ನು ಸ್ಥಾಪಿಸುವ ಎಲ್ಲಾ ಆಯ್ಕೆಗಳನ್ನು ಬ್ಯಾಂಕ್ ನನಗೆ ನೀಡಿದೆ.</ol>" +
      "<ol>ಬಿ.	e-KYC (ಇ-ಕೆವೈಸಿ)/ದಢೀಕರಣ/ಆನ್‌ಲೈನ್ ಪರಿಶೀಲನೆಗಾಗಿ, ಬ್ಯಾಂಕ್ ಆಧಾರ್ ಸಂಖ್ಯೆ ಮತ್ತು/ಅಥವಾ ಬಯೋಮೆಟ್ರಿಕ್‌ಗಳನ್ನು CIDR / UIDAI (ಸಿಐಡಿಆರ್/ಯುಐಡಿಎಐ) ಇವರೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳುತ್ತದೆ, ಮತ್ತು CIDR/UIDAI ಬ್ಯಾಂಕಿನೊಂದಿಗೆ ದಢೀಕರಣ ಡೇಟಾ, ಆಧಾರ್ ಡೇಟಾ, ಜನಸಂಖ್ಯಾ ವಿವರಗಳು, ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ, ಗುರುತಿನ ಮಾಹಿತಿಯನ್ನು ಹಂಚಿಕೊಳ್ಳುತ್ತದೆ, ಇವುಗಳನ್ನು ಈ ಕೆಳಗಿನ ಅಂಶ 3 ರಲ್ಲಿ ತಿಳಿಸಲಾದ ಮಾಹಿತಿಯ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಇದನ್ನು ಬಳಸಲಾಗುತ್ತದೆ.</ol>" +
      "</li>" +
      "<li>ಈ ಕೆಳಗಿನ ಮಾಹಿತಿಯುಕ್ತ ಉದ್ದೇಶಗಳಿಗಾಗಿ ನಾನು ಬ್ಯಾಂಕಿಗೆ (ಮತ್ತು ಅದರ ಸೇವಾ ಪೂರೈಕೆದಾರರಿಗೆ) ಅಕಾರ ನೀಡುತ್ತೇನೆ ಮತ್ತು ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ನೀಡುತ್ತೇನೆ:" +
      "<ol>ಎ.	PML (ಪಿಎಂಎಲ್) ಕಾಯಿದೆ, 2002 ರ ಪ್ರಕಾರ KYC ಮತ್ತು ಆವರ್ತಕ KYC ಪ್ರಕ್ರಿಯೆ ಮತ್ತು ಅದರ ಅಡಿಯಲ್ಲಿನ ನಿಯಮಗಳು ಮತ್ತು ಕಆರ್‌ಬಿಐ ಮಾರ್ಗಸೂಚಿಗಳು, ಅಥವಾ ನನ್ನ ಗುರುತನ್ನು ಸ್ಥಾಪಿಸಲು, ನನ್ನ ಗುರುತನ್ನು ರವಾನಿಸಲು, ಆಫ್‌ಲೈನ್ ಪರಿಶೀಲನೆ ಅಥವಾ ಇ-ಕೆವೈಸಿ ಅಥವಾ ಹೌದು/ಇಲ್ಲ ಎಂಬ ದಢೀಕರಣ, ಜನಸಂಖ್ಯಾ ಅಥವಾ ಇತರ ದಢೀಕರಣ/ಪರಿಶೀಲನೆ/ಗುರುತಿಸುವಿಕೆ ಅನ್ವಯವಾಗುವ ಕಾನೂನಿನ ಪ್ರಕಾರ ಅನುಮತಿಸಬಹುದಾದಂತೆ, ಎಲ್ಲಾ ಖಾತೆಗಳು, ಸೌಲಭ್ಯಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಬ್ಯಾಂಕ್‌ನ/ಮೂಲಕ, ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಮತ್ತು ಭವಿಷ್ಯತ್ತಿನ ಸಂಬಂಧ;</ol>" +
      "<ol>ಬಿ.	ಮಾಹಿತಿಯನ್ನು ಸಂಗ್ರಹಿಸುವುದು, ಹಂಚಿಕೊಳ್ಳುವುದು, ಶೇಖರಿಸುವುದು, ಸಂರಕ್ಷಿಸುವುದು, ದಾಖಲೆಗಳನ್ನು ನಿರ್ವಹಿಸುವುದು ಮತ್ತು ಮಾಹಿತಿ ಮತ್ತು ದಢೀಕರಣ/ಪರಿಶೀಲನೆ/ದಾಖಲೆಗಳ ಗುರುತಿಸುವಿಕೆಯನ್ನು ಬಳಸುವುದು: (i) ಮೇಲ್ಕಂಡ ಮಾಹಿತಿಯ ಉದ್ದೇಶಗಳಿಗಾಗಿ, (ii)ಹಾಗೆಯೇ ನಿಯಂತ್ರಕ ಮತ್ತು ಕಾನೂನು ವರದಿ ಮತ್ತು ಫೈಲಿಂಗ್‌ಗಳಿಗಾಗಿ ಮತ್ತು/ಅಥವಾ (iii) ಅನ್ವಯವಾಗುವ ಕಾನೂನಿನ ಅಡಿಯಲ್ಲಿ ಅಗತ್ಯವಿರುವಲ್ಲಿ;</ol>" +
      "<ol>ಸಿ.	ಆಧಾರ್ ಸಕ್ರಿಯಗೊಳಿಸಿದ ಪಾವತಿ ಸೇವೆಗಳಿಗೆ (AEPS) ನನ್ನ ಖಾತೆಯನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತಿದೆ;</ol>" +
      "<ol>	ಡಿ.	ಕಾನೂನು ನ್ಯಾಯಾಲಯದ ಮುಂದೆ, ಅಥವಾ ಯಾವುದೇ ಅಕಾರ ಅಥವಾ ಮಧ್ಯಸ್ಥಿಕೆಯಲ್ಲಿ ಸೇರಿದಂತೆ ಸಾಕ್ಷ್ಯ ಉದ್ದೇಶಗಳಿಗಾಗಿ ಸಮ್ಮತಿ, ಮಾಹಿತಿ ಅಥವಾ ದಢೀಕರಣ, ಗುರುತಿಸುವಿಕೆ, ಪರಿಶೀಲನೆ ಇತ್ಯಾದಿಗಳ ದಾಖಲೆಗಳು ಮತ್ತು ದಾಖಲೆಗಳನ್ನು ರಚಿಸುವುದು, .</ol>" +
      "</li>" +
      "<li>ಮೇಲ್ಕಂಡ ಅಂಶ 3 ರಲ್ಲಿ ಒದಗಿಸಲಾಗಿರುವ ಉದ್ದೇಶಕ್ಕಾಗಿ ಬ್ಯಾಂಕ್ ನನ್ನ ಒಪ್ಪಿಗೆಯ ಲಾಗ್‌ಗಳನ್ನು ನಿರ್ವಹಿಸುತ್ತದೆ ಎಂದು ನಾನು ಈ ಮೂಲಕ ಒಪ್ಪಿಕೊಳ್ಳುತ್ತೇನೆ.</li>" +
      "<li>ಆಧಾರ್ ಕಾಯಿದೆ, 2016 ಮತ್ತು ಅದರ ಅಡಿಯಲ್ಲಿ ರೂಪಿಸಲಾದ ನಿಯಮಗಳು ಮತ್ತು ನಿಬಂ‘ನೆಗಳ ಅನುಸಾರವಾಗಿ ನನ್ನ ಒಪ್ಪಿಗೆಯನ್ನು ತೆಗೆದುಕೊಳ್ಳಲಾಗುತ್ತಿದೆ.</li>" +
      "<li>ಬ್ಯಾಂಕ್ ನನ್ನ ಇ-ಕೆವೆ‘ಸಿ ಅಥವಾ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ಇತರ ಘಟಕ ಅಥವಾ ಅಸ್ಥಿತ್ವಗಳೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಲಾಗುವುದಿಲ್ಲ ಎಂದು ನನಗೆ ತಿಳಿಸಲಾಗಿದೆ.</li>" +
      "<li>ಕಾನೂನಿನ ಪ್ರಕಾರ ಮತ್ತು ಇಐಈ್ಕ ಸಲ್ಲಿಕೆಯನ್ನು ಹೊರತುಪಡಿಸಿ ಆಧಾರ್ ಸಂಖ್ಯೆ ಮತ್ತು ಕೋರ್ ಬಯೋಮೆಟ್ರಿಕ್ಸ್‌ ಅನ್ನು ಸಂಗ್ರಹಿಸಲಾಗುವುದಿಲ್ಲ/ಹಂಚಿಕೊಳ್ಳಲಾಗುವುದಿಲ್ಲ ಎಂಬುದನ್ನು ನಾನು ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ. ನನ್ನ ಆಧಾರ್ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಬಂದ  ಬಳಸಿಕೊಂಡು ಇ-ಆಧಾರ್ ಅನ್ನು ನಾನೇ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿಕೊಂಡಿದ್ದೇನೆ. ಈ ದಾಖಲೆಯು ಕ್ರಮಬದ್ಧವಾಗಿರದಿದ್ದಲ್ಲಿ ಅಥವಾ ನಾನು ಒದಗಿಸಿದ ಯಾವುದೇ ಮಾಹಿತಿಯು ತಪ್ಪಾಗಿದ್ದ ಸಂದರ್ಭದಲ್ಲಿ ನಾನು ಬ್ಯಾಂಕ್ ಅಥವಾ ಅದರ ಅಕಾರಿಗಳನ್ನು ಜವಾಬ್ದಾರರನ್ನಾಗಿ ಮಾಡುವುದಿಲ್ಲ.</li>" +
      "<li>ಮಾಹಿತಿ ಸಂಗ್ರಹಿಸುವ ಮೇಲ್ಕಂಡ ಸಮ್ಮತಿ ಮತ್ತು ಉದ್ದೇಶವನ್ನು ನನಗೆ ವಿವರಿಸಿ ಹೇಳಲಾಗಿದೆ ಮತ್ತು ನನಗೆ ತಿಳಿದಿರುವ ಅಥವಾ ಅದು ನನ್ನ ಸ್ಥಳೀಯ ಭಾಷೆಯಲ್ಲಿ ನನಗೆ ಲಭ್ಯವಿದೆ. </li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-kannada.mp3",
  },
  mar: {
    name: "मराठी (Marathi)",
    value:
      "<ol>" +
      "<li>मी स्वेच्छेने आधार ओव्हीडी केवायसीचा किंवा ई-केवायसीचा किंवा ऑफलाइन पडताळणीचा पर्याय निवडत आहे, आणि माझ्या बँकेला माझा आधार क्रमांक, व्हर्च्युअल आयडी, ई-आधार, एक्सएमएल, मास्क्ड आधार, आधार तपशील, सांख्यिकीय माहिती, ओळखीची माहिती, आधार नोंदणीकृत मोबाईल क‘मांक, चेहरा ओळखण्याचे तपशील आणि / किंवा बायोमेट्रिक माहिती सादर करत आहे (एकत्रितपणे, ‘‘माहिती’’)</li>" +
      "<li>मला बँकेकडून कळविण्यात आले आहे की," +
      "<ol> अ.	आधार सादर करणे आवश्यक नाही, आणि केवायसीसाठी व ओळख प्रस्थापित करण्याचे इतर पर्याय आहेत ज्यामध्ये आधार व्यतिरिक्त अधिकृतरीत्या वैध असणारी इतर भौतिक स्वरूपातील कागदपत्रे वापरता येतात. ह्यासाठी बँकेच्या शाखेमध्ये पॅन कार्ड/ पासपोर्ट/ चालक परवाना / मनरेगा कार्ड / मतदार पत्र इ. पैकी कोणतीही दोन केवायसी कागदपत्रे घेऊन येता येते. ह्यासाठीची प्रक्रिया बँकेच्या सर्व शाखांमध्ये उपलब्ध आहे. ओळख प्रस्थापित करण्याचे सर्व मार्ग मला बँकेने देऊ केले होते. </ol>" +
      "<ol> ब. ई-केवायसी / अधिप्रमाणन / ऑफलाइन पडताळणी करण्यासाठी, बँक आधार क‘मांक आणि / किंवा बायोमेट्रिक सीआयडीआर / युआयडीएआय कडे पाठवेल आणि सीआयडीआर / युआयडीएआय बँकेकडे अधिप्रमाणन माहिती, आधार माहिती, सांख्यिकीय तपशील, नोंदणीकृत मोबाईल क‘मांक, ओळखीची माहिती पाठवेल, जिचा वापर खालील मुद्दा क‘. 3 मध्ये उल्लेखित माहितीप्राप्त हेतुंसाठी होईल.</ol>" +
      "</li>" +
      "<li>मी बँकेला (आणि तिच्या सेवा पुरवठादारांना) खालील माहितीप्राप्त हेतुंकरिता अधिप्रमाणित करत आहे आणि माझी संमती देत आहे :" +
      "<ol>अ.	पीएमएल अधिनियम, 2002 आणि त्यामधील नियमांन्वये व आरबीआयच्या निर्देशांनुसार केवायसी आणि कालांतराने होणाऱ्या केवायसी प्रकि‘येसाठी, किंवा माझी ओळख प्रस्थापित करण्यासाठी, माझी ओळख तपासण्यासाठी, ऑफलाइन पडताळणी किंवा ई-केवायसी किंवा अधिप्रमाणन स्वीकारण्यासाठी / नाकारण्यासाठी, सांख्यिकीय किंवा इतर अधिप्रमाणनासाठी / पडताळणीसाठी / ओळख निश्चितीसाठी लागू कायद्यानुसार अनुमत असल्यानुसार, सर्व खात्यांसाठी, सुविधांसाठी, सेवांसाठी आणि बँकेसोबत / मार्फत असणाऱ्या विद्यमान आणि भावी संबंधांसाठी.</ol>" +
      "<ol>ब. माहिती गोळा करणे, वितरित करणे, साठवणे, जतन करणे, अभिलेख राखणे आणि माहिती वापरणे व अभिलेखांचे अधिप्रमाणन / पडताळणी / ओळख निश्चिती करणे : (1) उपरोक्त माहितीप्राप्त हेतुंकरिता, (2) नियामकीय आणि कायदेशीर अहवाल सादर आणि दाखल करण्यासाठी आणि / किंवा (3) लागू कायद्यानुसार आवश्यक असेल त्यासाठी;</ol>" +
      "<ol>क.	माझे खाते आधार सक्षम अधिदान सेवांसाठी (एईपीएस) सक्रिय करणे :</ol>" +
      "<ol>ड. न्यायालयापुढे किंवा इतर कोणत्याही प्राधिकरणापुढे किंवा लवादामध्ये पुराव्याच्या हेतुंकरिता संमतीचे, माहितीचे किंवा अधिप्रमाणनाचे, ओळख निश्चितीचे, पडताळणीचे, इ. अभिलेख आणि नोंदी सादर करणे.</ol>" +
      "</li>" +
      "<li>मी ह्याद्वारे मान्य करत आहे की बँक माझ्या संमतीच्या नोंदी वर मुद्दा क्रमांक 3 मध्ये मांडलेल्या हेतुसाठी राखून ठेवेल.</li>" +
      "<li>माझी संमती आधार अधिनियम 2016 चे आणि त्यामध्ये आखलेल्या नियमांचे व नियमनांचे अनुपालन करून घेतली जात आहे.</li>" +
      "<li>मला पुढे असे कळविण्यात आले आहे की बँक माझे ई-केवायसी किंवा आधार क्रमांक इतर संस्थांना देणार नाही.</li>" +
      "<li>मला समजते की आधार क्रमांक आणि मूळ बायोमेट्रिक्स कायद्यानुसार आणि सीआयडीआर सुपूर्दतेसाठी आवश्यक असेल तेव्हाचा अपवाद वगळता जतन केले / इतर कोणाला दिले जाणार नाहीत. मी स्वत: माझ्या आधार नोंदणीकृत मोबाईल क्रमांकावर आलेला ओटीपी वापरून ई-आधार स्वत: डाऊनलोड केला आहे. हे पत्रक वैध स्वरूपात असल्याचे आढळले नाही किंवा मी जर एखादी चुकीची माहिती दिली असेन, तर मी त्यासाठी बँकेला किंवा तिच्या अधिकाऱ्यांना जबाबदार धरणार नाही.</li>" +
      "<li>वरील संमती आणि माहिती घेण्याचा हेतु मला समजावून देण्यात आला आहे आणि माझ्यासाठी तो माझ्या भाषेमध्ये उपलब्ध आहे. </li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-marathi.mp3",
  },
  pu: {
    name: "ਪੰਜਾਬੀ (Punjabi)",
    value:
      "<ol>" +
      "<li>ਮੈਂ ਸਵੈ-ਇੱਛਾ ਨਾਲ ਆਦਾਰ ਓਵੀਡੀ ਕੇਵਾਈਸੀ ਜਾਂ ਈ-ਕੇਵਾਈਸੀ ਜਾਂ ਆੱਫ਼ਲਾਇਨ ਤਸਦੀਕ ਕਰਾਉਣ ਅਤੇ ਬੈਂਕ ਦੇ ਕੋਲ ਆਪਣਾ ਆਧਾਰ ਨੰਬਰ, ਵਰਚੁਅਲ ਆਈਡੀ, ਈ-ਆਧਾਰ, XML, ਮਾਸਕਡ ਆਧਾਰ, ਆਧਾਰ ਵੇਰਵਾ, ਜਨਸੰਖਿਆ ਜਾਣਕਾਰੀ, ਪਛਾਣ ਸੰਬੰਧੀ ਜਾਣਕਾਰੀ, ਆਧਾਰ ਪੰਜੀਕ੍ਰਿਤ ਮੋਬਾਈਲ ਨੰਬਰ, ਫੇਸ ਆੱਥੇਂਟਿਕੇਸ਼ਨ (ਮੂੰਹ ਦੀ ਤਸਦੀਕ) ਦੇ ਵੇਰਵੇ ਅਤੇ/ਜਾਂ ਬਾਯੋਮੇਟ੍ਰਿਕ ਜਾਣਕਾਰੀ (ਸੰਯੁਕਤ ਰੂਪ ਨਾਲ ‘ਜਾਣਕਾਰੀ‘) ਜਮ੍ਹਾ ਕਰਨ ਦਾ ਫੈਸਲਾ ਲੈ ਰਹਾਂ/ਰਹੀ ਹਾਂ ।</li>" +
      "<li>ਮੈਂਨੂੰ ਬੈਂਕ ਦੁਆਰਾ ਦੱਸਿਆ ਗਿਆ ਹੈ ਕਿ," +
      "<ol> a. ਆਧਾਰ ਜਮ੍ਹਾ ਕਰਨਾ ਲਾਜ਼ਮੀ ਨਹੀਂ ਹੈ, ਅਤੇ ਕੇਵਾਈਸੀ ਦੇ ਹੋਰ ਵਿਕਲਪ ਵੀ ਉਪਲਬਧ ਹਨ, ਜਿਨ੍ਹਾਂ ਵਿੱਚ ਆਧਾਰ ਦੇ ਇਲਾਵਾ ਹੋਰ ਅਧਿਕਾਰਕ ਰੂਪ ਨਾਲ ਵੈਧ ਦਸਤਾਵੇਜ਼ਾਂ ਦੇ ਅਸਲ ਤਸਦੀਕ ਦੁਆਰਾ ਪਛਾਣ ਸਿੱਧ ਕਰਨ ਦਾ ਸਮਾਵੇਸ਼ ਹੈ ।</ol>" +
      "<ol>b. ਈ-ਕੇਵਾਈਸੀ/ਤਸਦੀਕ/ਆੱਫ਼ਲਾਇਨ ਤਸਦੀਕ ਲਈ ਬੈਂਕ CIDR/UIDAI ਦੇ ਨਾਲ ਆਧਾਰ ਨੰਬਰ ਸਾਂਝਾ ਕਰੇਗਾ ਅਤੇ CIDR/UIDAI ਬੈਂਕ ਦੇ ਨਾਲ ਤਸਦੀਕ ਸੰਬੰਧੀ ਡਾਟਾ, ਆਧਾਰ ਡਾਟਾ, ਜਨਸੰਖਿਆ ਵੇਰਵਾ, ਪੰਜੀਕ੍ਰਿਤ ਮੋਬਾਈਲ ਨੰਬਰ, ਪਛਾਣ ਸੰਬੰਧੀ ਜਾਣਕਾਰੀ ਬੈਂਕ ਦੇ ਨਾਲ ਸਾਂਝਾ ਕਰੇਗਾ, ਜਿਨ੍ਹਾਂ ਦਾ ਇਸਤੇਮਾਲ ਹੇਠਾਂ ਦਿੱਤੇ ਗਏ ਪੁਆਇੰਟ 3 ਵਿੱਚ ਦੱਸੇ ਸੂਚਿਤ ਉਦੇਸ਼ਾਂ ਲਈ ਕੀਤਾ ਜਾਵੇਗਾ । </ol>" +
      "</li>" +
      "<li>ਮੈਂ ਬੈਂਕ (ਅਤੇ ਉਸ ਦੇ ਸੇਵਾ ਪ੍ਰਦਾਤਾਵਾਂ) ਨੂੰ ਨਿਮਨਲਿਖਤ ਸੂਚਿਤ ਉਦੇਸ਼ਾਂ ਲਈ ਸਵੀਕ੍ਰਿਤੀ ਦਿੰਦਾ/ਦਿੰਦੀ ਹਾਂ :" +
      "<ol>a. PML ਕਾਨੂੰਨ, 2002 ਅਤੇ ਉਸਦੇ ਅਧੀਨ ਨਿਯਮ ਅਤੇ ਆਰਬੀਆਈ ਦੇ ਦਿਸ਼ਾ ਨਿਰਦੇਸ਼ਾਂ ਦੇ ਅਨੁਸਾਰ ਬੈਂਕ ਦੇ ਨਾਲ/ਬੈਂਕ ਦੇ ਰਾਹੀਂ ਸਾਰੇ ਵਰਤਮਾਣ ਅਤੇ ਭਵਿੱਖ ਦੇ ਖਾਤਿਆਂ, ਸੇਵਾਵਾਂ, ਅਤੇ ਸੰਬੰਧਿਤ ਕੇਵਾਈਸੀ ਅਤੇ ਮੁਕੱਰਰ ਕੇਵਾਈਸੀ ਪ੍ਰਕ੍ਰਿਆ, ਜਾਂ ਮੇਰੀ ਪਛਾਣ ਦੀ ਪੁਸ਼ਟੀ ਲਈ, ਮੇਰੀ ਪਛਾਣ ਸਿੱਧ ਕਰਨ ਲਈ, ਆੱਫ਼ਲਾਇਨ ਤਸਦੀਕ ਜਾਂ ਈ-ਕੇਵਾਈਸੀ ਜਾਂ ਹਾਂ/ਨਹੀਂ ਤਸਦੀਕ, ਕਾਨੂੰਨ ਦੁਆਰਾ ਸਵਿਕ੍ਰਿਤ ਜਨਸੰਖਿਆ ਜਾਂ ਹੋਰ ਤਸਦੀਕ/ ਪੁਸ਼ਟੀ / ਪਛਾਣ :</ol>" +
      "<ol>b. ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨ, ਸਾਂਝਾ ਕਰਨ, ਰੱਖਣ, ਇਕੱਠੀ ਕਰਨ, ਰਿਕਾੱਰਡ ਰੱਖਣ ਅਤੇ ਜਾਣਕਾਰੀ ਦੀ ਵਰਤੋਂ ਕਰਨ ਅਤੇ ਰਿਕਾੱਰਡਸ ਦੀ ਤਸਦੀਕ ਪੁਸ਼ਟੀ/ਪਛਾਣ ਕਰਨ ( 1 ) ਉਪਰੋਕਤ ਦਿੱਤੇ ਗਏ ਉਦੇਸ਼ਾਂ ਲਈ, (2) ਰੇਗੂਲੇਟਰੀ ਅਤੇ ਕਾਨੂੰਨੀ ਸੂਚਨਾ ਅਤੇ ਫਾਇਲਿੰਗ ਲਈ ਅਤੇ / ਜਾਂ (3) ਜਦੋਂ ਕਾਨੂੰਣਨ ਇਹ ਜ਼ਰੂਰੀ ਹੋ :</ol>" +
      "<ol>c. ਮੇਰੇ ਖਾਤੇ ਨੂੰ ਆਧਾਰ ਨਿਰਭਰ ਭੁਗਤਾਨ ਸੇਵਾਵਾਂ (AEPS)</ol>" +
      "<ol>d. ਅਦਾਲਡ ਜਾਂ ਕਿਸੇ ਅਥਾਰਟੀ ਜਾਂ ਆਰਬਿਟਰੇਸ਼ਨ ਵਿੱਚ ਪ੍ਰਮਾਣ ਲਈ ਸਵਿਕ੍ਰਿਤੀ, ਸੂਚਨਾ ਜਾਂ ਤਸਦੀਕ, ਪਛਾਣ, ਪੁਸ਼ਟੀ ਆਦਿ ਦਾ ਰੇਕਾੱਰਡ ਅਤੇ ਲਾੱਗ ਪ੍ਰਸਤੁਤ ਕਰਨਾ ।</ol>" +
      "</li>" +
      "<li>ਮੈਂ ਪੁਸ਼ਟੀ ਕਰਦਾ / ਕਰਦੀ ਹਾਂ ਕਿ ਬੈਂਕ ਉਪਰੋਕਤ ਦਿੱਤੇ ਗਏ ਪੁਆਇੰਟ 3 ਵਿੱਚ ਦੱਸੇ ਉਦੇਸ਼ਾਂ ਲਈ ਮੇਰੀ ਸਵਿਕ੍ਰਿਤੀਆਂ ਨੂੰ ਬਰਕਰਾਰ ਰੱਖੇਗਾ ।</li>" +
      "<li>ਮੇਰੀ ਸਵਿਕ੍ਰਿਤੀ ਆਧਾਰ ਕਾਨੂੰਨ, 2016 ਅਤੇ ਉਸਦੇ ਅਧੀਨ ਬਣਾਏ ਗਏ ਨਿਯਮਾਂ ਅਤੇ ਕਾਯਦਿਆਂ ਦੇ ਅਨੁਸਾਰ ਲਿੱਤੀ ਜਾ ਰਹੀ ਹੈ ।</li>" +
      "<li>ਮੈਨੂੰ ਇਹ ਵੀ ਦੱਸਿਆ ਗਿਆ ਹੈ ਕਿ ਬੈਂਕ ਹੋਰ ਸੰਸਥਾਵਾਂ ਦੇ ਨਾਲ ਮੇਰਾ ਈ-ਕੇਵਾਈਸੀ ਜਾਂ ਆਧਾਰ ਨੰਬਰ ਸਾਂਝਾ ਨਹੀਂ ਕਰੇਗਾ।</li>" +
      "<li>ਮੈਂ ਸਮਝਦਾ / ਸਮਝਦੀ ਹਾਂ ਕਿ ਆਧਾਰ ਨੰਬਰ ਅਤੇ ਫੇਸ ਥਾਯੋਟ੍ਰਿਕਸ ਨੂੰ ਕਾਨੂੰਨ ਦੇ ਅਨੁਸਾਰ ਅਤੇ CIDR ਵਿੱਚ ਜਮ੍ਹਾ ਕਰਨ ਦੇ ਉਦੇਸ਼ ਦੇ ਇਲਾਵਾ ਰੱਖਾ / ਸਾਂਝਾ ਨਹੀਂ ਕੀਤਾ ਜਾਵੇਗਾ । ਮੈਂ ਮੇਰੇ ਆਧਾਰ ਪੰਜੀਕ੍ਰਿਤ ਮੋਬਾਈਲ ਨੇਥਰ ਤੇ ਪ੍ਰਾਪਤ ਓਟੀਪੀ ਦੀ ਮਦਦ ਨਾਲ ਆਪਣੇ ਆਪ ਈ-ਆਧਾਰ ਡਾਊਨਲੋਡ ਕਰ ਲਿੱਤਾ ਹੈ । ਇਹ ਦਸਤਾਵੇਜ਼ ਸਹੀ ਨਾ ਹੋਣ ਤੇ ਜਾਂ ਮੇਰੇ ਦੁਆਰਾ ਦਿੱਤੀ ਗਈ ਕੋਈ ਜਾਣਕਾਰੀ ਗਲਤ ਪਾਏ ਜਾਣ ਤੇ ਮੈਂ ਬੈਂਕ ਜਾਂ ਉਸਦੇ ਅਧਿਕਾਰੀਆਂ ਨੂੰ ਜ਼ਿੰਮੇਵਾਰ ਨਹੀਂ ਠਹਿਰਾਵਾਂਗਾ/ਠਹਿਰਾਵਾਂਗੀ।</li>" +
      "<li>ਜਾਣਕਾਰੀ ਲੈਣ ਲਈ ਉਪਯੁਕਤ ਸਵਿਕ੍ਰਿਤੀ ਅਤੇ ਉਸਦਾ ਉਦੇਸ਼ ਮੈਂਨੂੰ ਸਮਝਾਈਆ ਗਿਆ ਹੈ ਅਤੇ ਮੇਰੇ ਲਈ ਮੇਰੀ ਸਥਾਨਕ ਭਾਸ਼ਾ ਵਿੱਚ ਉਪਲਬਧ ਹੈ ।</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-punjabi.mp3",
  },
  ta: {
    name: "தமிழ் (Tamil)",
    value:
      "<ol>" +
      "<li>நான் என் சுய விருப்பத்துடன் ஆதார் OVD KYC அல்லது e-KYC அல்லது ஆஃப்-லைன் சரிப்பார்த்தலைத் தேர்ந்தெடுத்து வங்கியிடம் என் ஆதார் எண், வர்சுவல் ஐடி, இ-ஆதார், எக்ஸ்எம்எல், மாஸ்க்ட் ஆதார், ஆதார் விவரங்கள், மக்கள் தொகையியல் விவரம், அடையாள விவரம், ஆதாருடன் பதிவு செய்துகொண்டிருக்கும் மொபைல் எண், முக அங்கீகார விவரங்கள் மற்றும் / அல்லது பையோமெட்ரிக் விவரங்கள் (பொதுவாக, ‘ விவரங்கள்’) தேர்ந்தெடுத்திருக்கிறேன்.</li>" +
      "<li>ஆதார் விவரங்கள் அளிப்பது கட்டாயமில்லை என்றும், பல்வேறு வழிகளில் கேஒய்சி தேவைகளைப் பூர்த்தி செய்துகொள்ளலாம் என்றும், அடையாளச் சான்றுகளாகவும், பௌதீக கேஒய்சி ஆவணமாகவும் இதர பிற ஏற்றுக்கொள்ளத்தக்க ஆவணங்களை, ஆதார் அட்டை தவிர்த்து, கொடுக்கலாம் என்றும் வங்கி என்னிடம் தெரிவித்திருக்கிறது., " +
      "<ol>a.	 இதற்கு வங்கி கிளை ஒன்றில் மற்ற கேஒய்சி ஆவணங்களான PAN கார்டு / பாஸ்போர்ட் /ஓட்டுனர் உரிமம் / MNREGA கர்டு / வாக்காளர் அடையாள அட்டை போன்றவைகளில் ஏதேனும் இரண்டை எடுத்துச் செல்லலாம்.  இந்த சரிபார்த்தல் முறை அனைத்து வங்கி கிளைகளிலும் கிடைக்கிறது. அடையாளச் சான்று உறுதி படுத்தும் அனைத்து வழிமுறைகளும் எனக்குத் தெரிவிக்கப்பட்டுள்ளன.</ol>" +
      "<ol> b.	இ-கேஒய்சி / அங்கீகாரம் / ஆஃப்லைன் சரிபார்த்தலுக்கு வங்கி ஆதார் எண் மற்றும் / அல்லது பையோமெட்ரிக்ஸ் விவரங்களை CIDR / UIDAI  வுடன் பகிர்ந்துகொள்ளும்,  CIDR / UIDAI அங்கீகரிக்கும் தரவுகள், மக்கள் தொகையியல் விவரங்கள், பதிவு செய்துகொண்டிருக்கும் மொபைல் எண், அடையாள விவரங்கள் ஆகியவற்றை வங்கியுடன் பகிர்ந்துகொள்ளும், இவை கீழே 3வது பத்தியில் கொடுக்கப்பட்டிருக்கும் விவரமறிந்த நோக்கங்களுக்காகப் பயன்படுத்தப்படும். </ol>" +
      "</li>" +
      "<li>கீழே கொடுக்கப்பட்டிருக்கும் விவரமறிந்த நோக்கங்களுக்காக வங்கி (மற்றும் அதன் சேவை வழங்குபவர்களுக்கு) நான் அதிகாரமளித்து என் ஒப்புதலைத் தெரிவித்துக் கொள்கிறேன்:" +
      "<ol>a.	பிஎம்எல் சட்டம் 2002 மற்றும் அதன் கீழ் செய்யப்பட்டிருக்கும் விதிகள் மற்றும் ஆர்பிஐ வழிநடத்தல் குறிப்புகள் படி கேஒய்சி மற்றும் வழக்கமாகச் செய்யப்படும் கேஒய்சி நடைமுறைகள் அல்லது என் அடையாளத்தை உறுதி செய்யும் பொருட்டு, என் அடையாளத்தைச் சரிபார்க்க, ஆஃப்லைனில் சரிபார்க்க அல்லது இ-கேஒய்சி அல்லது ஆமாம் / இல்லை அங்கீகாரம், மக்கள் தொகையியல் முறை அல்லது மற்ற அங்கீகாரம் / சரிபார்த்தல் / அடையாளப்படுத்துதலுக்காக, பொருந்தும் சட்ட விதிகள் அனுமதிக்கும் விதமாக, அனைத்து கணக்குகள், வசதிகள், சேவைகள் மற்றும் வங்கியுடனான /வங்கி மூலமான உறவு முறைகளுக்கு அவை ஏற்கனவே இருக்கலாம் மற்றும் எதிர்காலத்திற்கானதாக இருக்கலாம்;</ol>" +
      "<ol>b.	விவரங்களைச் சேகரித்தல், பகிர்ந்து கொள்ளுதல், தேக்கி வைத்துக்கொள்ளுதல் பத்திரப்படுத்துதல், பராமரித்தல் மற்றும் விவரங்களைப் பயன்படுத்துதல் மற்றும் பதிவுகளை அங்கீகரித்தல் / சரிபார்த்தல் / அடையாளப்படுத்துதல்; (i) மேலே சொல்லப்பட்டிருக்கும் விவரமறிந்த நோக்கங்களுக்காக, (ii) ஒழுங்குமுறை மற்றும் சட்டப்படியான தகவல் அறிவித்தல் மற்றும் நிரப்புதல் மற்றும் / அல்லது (iii) பொருந்தும் சட்ட விதிகளின் படி தேவைப்படும் இடங்களில்;</ol>" +
      "<ol>c.	ஆதார் இயலச் செய்யும் பேமென்ட் சேவைகளை (அஉககு) இயங்கச் செய்தல்;</ol>" +
      "<ol>     d.	பதிவுகளை வழங்குதல் மற்றும் ஒப்புதல் விவரங்களை அறிவித்தல், அங்கீகாரம், அடையாளம், சரிபார்த்தல் போன்ற சான்றளிக்கும் நோக்கங்களுக்காக ஒரு நீதிமன்றத்தில் அல்லது அதிகாரத்திடம் அல்லது நடுவர் தீர்ப்பாயத்திடம் சார்ப்பித்தல்.</ol>" +
      "</li>" +
      "<li>மேலே பத்தி 3-ல் விவரிக்கப்பட்டிருப்பது போல் வங்கி நான்  சம்மதம் அளித்திருக்கும் பதிவுகளைப் பராமரித்து வைத்துக்கொள்ளும் என்பதை இதன் மூலம் ஏற்றுக்கொள்கிறேன்</li>" +
      "<li>ஆதார் சட்டம் 2016 மற்றும் அதன் கீழ் எழுதப்பட்டிருக்கும் விதிகள் மற்றும் விதிமுறைகளின் படி என் சம்மதம் பெறப்படுகிறது. </li>" +
      "<li>என் இ-கேஒய்சி அல்லது ஆதார் எண் வேறு அமைப்புகளுடன் வங்கி பகிர்ந்துகொள்ளாது என்று எனக்குத் தெரிவிக்கப்பட்டுள்ளது</li>" +
      "<li><சட்ட விதிகளின் படியும், சிஐடிஆர் அமைப்புக்கு அனுப்புவதைத் தவிர்த்து ஆதார் எண் மற்றும் முக்கியமான பையோமெட்ரிக்ஸ் சேமித்து வைத்துக்கொள்ளப்படாது / பகிர்ந்து கொள்ளப்படாது என்று நான் புரிந்து கொண்டிருக்கிறேன். பதிவு செய்துகொண்டிருக்கும் என் மொபைல் நம்பரில் ஓடிபி விவரங்களைப் பெற்று நான் சுயமாக இ-ஆதார் விவரங்களைப் பதிவிறக்கம் செய்துள்ளேன். ஒருவேளை இந்த ஆவணம் சரியாக இல்லை அல்லது ஏதாவது தவறான விவரங்களை நான் அளித்திருந்தால், வங்கி அல்லது அதன் அதிகாரிகளை இதற்குப் பொறுப்பாக்க மாட்டேன்./li>" +
      "<li>மேலே சொல்லப்பட்டிருக்கும் என் சம்மதம் மற்றும் விவரங்களைச் சேகரிக்கும் நோக்கம் எனக்கு விளக்கிச் சொல்லப்பட்டுள்ளது, அவை என் உள்ளூர் மொழியிலும் கிடைக்கிறது.</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-tamil.mp3",
  },
  te: {
    name: "తెలుగు (Telegu)",
    value:
      "<ol>" +
      "<li>ఆధార్ ఒవిడి కైవెసి లేదా ఇ-కైవెసి లేదా ఆఫ్‌లైన్ తనిఖీ కొరకు మరియు నా ఆధార్ నంబరు, వర్చువల్ ఐడి, ఇ- ఆధార్, ఎక్స్‌ఎమ్‌ఎల్, మాస్క్డ్ ఆధార్, ఆధార్ వివరాలు, జనన సంబంధిత వివరాలు, గుర్తింపు సమాచారం, ఆధార్ రిజిష్టరు  చేయబడిన మొబైల్ నంబరు, ముఖ అధికృత వివరాలు మరియు /లేదా బయోమెట్రిక్ సమాచారం (సంక్తంగా సమాచారం)-వీటన్నిటిని బ్యాంకుకు సమర్పించడానికి నేను స్వచ్ఛందంగా ఎంచుకుంటున్నాను.</li>" +
      "<li>బ్యాంకు ద్వారా నాకు ఇలా తెలియజేయబడినది" +
      "<ol>a)	ఆధార్‌ను సమర్పించడం తప్పనిసరి కాదు (మేండేటరి) మరియు కైవెసికి మరియు ఆధార్ కాకుండా ఇతర అధికారికంగా చెల్లే డాక్యుమెంట్లతో భౌతిక కైవెసి విధానంతో సహా గుర్తింపును ఖాుపరచడానికి ప్రత్యామ్నాయ అభిమతాలు ఉన్నార. పాన్ కార్డ్ /పాస్‌పోర్ట్‌/ డ్రైవింగ్ లైసెన్స్/ మన్రెగా కార్డు/ వోటర్ ఐడి మొదలయనటువంటి ఇతర కైవెసి డాక్యుమెంట్లు ఏవైనా రెండుతో బ్యాంక్ శాఖకు విచే్చుడం కూడా ఉన్నార. దీనికి అవసరమయన ప్రక్రియ బ్యాంకు యుక్క అన్ని శాఖలలో అందుబాటులో ఉన్నది. గుర్తింపును ఖాయ పరచడానికి అన్ని అభిమతాలు బ్యాంకు ద్వారా నాకు అందజూపబడ్డాయ.</ol>" +
      "<ol>b)	ఇ-కైవెసి/ అధీకతం/ ఆఫ్‌లైన్ తనిఖీ కొరకు, ఆధార్ నంబరు మరియు లేదా బయోమెట్రిక్స్ ను సిఐడిఆర్/ యుఐడిఎఐతో బ్యాంకు పంచుకుంటుంది మరియు సిఐడిఆర్/ ఐడిఎఐ అధీకృత డేటా, ఆధార్ డేటా, జనన సంబంధిత వివరాలు,. రిజిష్టరు చేయబడిన మొబైలు నంబరు. గుర్తింపు సమాచారం బ్యాంకు తో పంచుకుంటుంది, ఇవన్నీ కూడా క్రింద 3వ పాయింటు వద్ద తెలిుపరచిన ఉద్దేశ్యాలకు ఉపయోగించడం జరుగుతుంది.</ol>" +
      "</li>" +
      "<li>ఈ క్రింది తెలియపరచబడే ఉద్దేశ్యాల కొరకు బ్యాకుకు (మరియు దాని సర్వీసు ప్రొవైడర్లు) నేను అధికారం మరియు నా సమ్మతిని ఇస్తున్నాను:" +
      "<ol>a)	పిఎమ్‌ఎల్ చట్టం, 2002 మరియు దాని క్రింద నిబంధనల  ప్రకారం మరియు ఆర్‌బిఐ మార్గదర్శకాల ప్రకారం   కైవెసి మరియు నిర్ధారిత సమయాలలో కైవెసి ప్రక్రియ కొరకు లేదా నా గుర్తింపును ఖాయం చేయడానికి, నా గుర్తింపును చేబట్టడానికి, ఆఫ్‌లైన్ తనిఖీ లేదా ఇ-కైవెసి  కొరకు లేదా/ అవును కాదు అధీకృతం, జనన సంబంధిత లేదా ఇతర అధికృతం/ తనిఖీ/ వర్తించే చట్టం ప్రకారం బహుశ అనుమతించబడే గుర్తింపు, అన్ని ఖాతాలకు, సదుపాయీలకు, సేవలకు మరియు బ్యాంకు యొక్క సంబంభం /బ్యాంకు ద్వారా ప్రస్తుతం ఉన్నది మరియు భవిష్యత్తు కొరకు</ol>" +
      "<ol>b)	సేకరించడం, పంచుకోవడం, నిల్వచేయడం, సమాచారాన్ని భద్రపరచడం, రికార్డులను అట్టేపెట్టడం మరియు సమాచారాన్ని ఉపయోగించడం మరియు  రికార్డులను అధికృతం చేుడం/  తనిఖీ/ గుర్తింపు  (i)  పైన పేర్కొనబడిన తెలియపరచబడిన ఉద్దేశ్యాల కొరకు (ii)  అలాగే క్రమబద్ధీకరణ మరియు న్యాయపరంగా నివేదించడం మరియు పూరించడం మరియు/ (iii) లేదా వర్తించే చట్టం క్రింద అవసరమరన చోట</ol>" +
      "<ol>c)	ఆధార్ ఎనేబుల్ పేమెంట్ సర్వీసెస్(ఎఇపిఎస్) కొరకు నా ఖాతాను వీలుకలిగించడం</ol>" +
      "<ol>d)	న్యాయస్థానం, అధికారవర్గం ఏవరైనా లేదా ఆర్బిట్రేషన్ తో సహా వారి ముందు  సాక్షం ఇచ్చే ఉద్దేశ్యాలకొరకు రికార్డులను మరియు సమ్మతి లాగ్స్‌ను , సమాచారం లేదా అధికృతం, గుర్తింపు, తనిఖీ వెదలరన వాటిని సమర్పించడం.</ol>" +
      "</li>" +
      "<li>పైన 3వ పారంటు వద్ద పొందుపరచబడిన ఉద్దేశ్యాల కొరకు నా సమ్మతి యొక్క     లాగ్స్‌ను బ్యాంక్ అట్టేపెడుతుందని నేను ఇందుమూలంగా ఒప్పుకుంటున్నాను.</li>" +
      "<li>ఆధార్ చట్టం, 2016 మరియు దాని  తరువాత  రూపొందించబడిన నియమనిబంధనలకు అనుగుణంగా నా సమ్మతి తీసుకోబడుతున్నది. </li>" +
      "<li>ఇతర అస్థిత్వాలతో (ఎంటైటీస్)నా ఇ-కైవెసి లేదా ఆధార్ నంబరును బ్యాంకు పంచుకోదని నాకు తదుపరి తెలిుజేుబడింది.</li>" +
      "<li>చట్టం ప్రకారం మరియు సిఐడిఆర్ సమర్పణ మినహాయించి, ఆధార్ నంబరు మరియు కీలకైమెన బయోమెట్రిక్స్ నిల్వచేయబడవు / పంచుకోబడవని నేను అర్థం చేసుకున్నాను. నా ఆధార్ రిజిష్టరు చేయబడిన మొబైలు నంబరు పైన అందుకున్న ఒటిపిని ఉపయోగించి నాకు నేనే ఇ-ఆధార్‌ని డౌన్‌లోడు చేసుకున్నాను. ఈ డాక్యుమెంటు సక్రమంగా లేదని కనుగొనబడితే లేదా నా ద్వారా ఏదైనా తప్పుడు సమాచారం పొందుపరచబడినా బ్యాంకు లేదా దాని అధికారులను నేను బాధ్యులను చేయను.</li>" +
      "<li>పై సమ్మతి మరియు సమాచారాన్ని సేకరించే ఉద్దేశ్యాన్ని గురించి నాకు వివరించబడింది మరియు నా ప్రాంతీు భాషలో నాకు అందుబాటులో ఉన్నది.</li>" +
      "</ol>",
    audio: "/aadhaar/assets/aadhaar-telegu.mp3",
  },
  mal: {
    name: "ഇംഗ്ലീഷിൽ (Malayalam)",
    value:
      "<ol> " +
      "<li>ആധാർ ഒവിഡി കെവൈസി അല്ലെങ്കിൽ ഇ-കെവൈസി അല്ലെങ്കിൽ ഓഫ്‌ലൈൻ വെരിഫിക്കേഷൻ തിരഞ്ഞെടുത്ത് ബാങ്കിന് എന്റെ ആധാർ നമ്പർ, വെർച്വൽ ഐഡി, ഇആധാർ, എക്‌സ് എം എൽ, മാസ്‌ക്ഡ് ആധാർ, ആധാർ വിശദാംശങ്ങൾ, ഡെമോഗ്രാഫിക് വിവരങ്ങൾ, ഐഡന്റിറ്റി  വിവരങ്ങൾ, ആധാർ രജിസ്റ്റേർഡ് മൊബൈൽ  നമ്പർ, മുഖ പ്രാമാണീകരണ വിശദാംശങ്ങൾ ഒപ്പം / അല്ലെങ്കിൽ ബയോമെട്രിക് വിവരങ്ങൾ (മൊത്തമായി, 'വിവരങ്ങൾ') ചെയ്ത വിവരങ്ങൾ ബാങ്കിനു സമർപ്പിക്കുവാൻ ഞാൻ സ്വമേധയാ തീരുമാനിച്ചിരിക്കുന്നു.</li>" +
      "<li>ബാങ്ക് എന്നെ അറിയിച്ചിരിക്കുന്ന് എന്തെന്നാൽ" +
      "<ol> എ. ആധാർ സമർപ്പിക്കൽ നിർബന്ധിതമല്ല,  കെവൈസി നടപ്പാക്കുന്നതിന് ആധാർ ഒഴികെയുള്ള ഔദ്യോഗികമായി സാധുവായ രേഖകൾ ഉപയോഗിച്ച് ഫിസിക്കൽ കെവൈസി വഴി തിരിച്ചറിയൽ സ്ഥാപിക്കുന്നതിന് ബദൽ ഓപ്ഷനുകളുണ്ട്. പാൻ കാർഡ് / പാസ്‌പോർട്ട് / ഡ്രൈവിംഗ് ലൈസൻസ് / തൊഴിലുറപ്പ്  കാർഡ് / വോട്ടർ ഐഡി കാർഡ് തുടങ്ങിയ മറ്റേതെങ്കിലും രണ്ട് കെവൈസിരേഖകളുമായി ബാങ്ക് ശാഖയിൽ വന്ന് ഇത് പൂർത്തിയാക്കാം. അതിനുള്ള പ്രക്രിയ ബാങ്കിന്റെ എല്ലാ ശാഖകളിലും ലഭ്യമാണ്. ഐഡന്റിറ്റി സ്ഥാപിക്കുന്നതിനുള്ള എല്ലാ ഓപ്ഷനുകളും ബാങ്ക് എനിക്ക് വാഗ്ദാനം ചെയ്തു.</ol>" +
      "<ol>ബി. ഇ-കെവൈസി / പ്രാമാണീകരണം / ഓഫ്‌ലൈൻ സ്ഥിരീകരണത്തിനായി, ബാങ്ക് സിഐഡിആർ / യുഐഡിഎഐ ആധാർ നമ്പർ കൂടാതെ / അല്ലെങ്കിൽ ബയോമെട്രിക്‌സ് പങ്കിടും, അതിനു ശേഷം സിഐഡിആർ / യുഐഡിഎഐ  പ്രാമാണീകരണ ഡാറ്റ, ആധാർ ഡാറ്റ, ഡെമോഗ്രാഫിക്  വിശദാംശങ്ങൾ, രജിസ്റ്റേർഡ്  മൊബൈൽ നമ്പർ, ഐഡന്റിറ്റി വിവരങ്ങൾ എന്നിവ ബാങ്കുമായി പങ്കിടും, അവ ചുവടെയുള്ള പോയിന്റ് 3ൽ സൂചിപ്പിച്ചിട്ടുള്ള വിവരമറിഞ്ഞുള്ള ആവശ്യങ്ങൾക്കായി ഇത് ഉപയോഗിക്കും.</ol>" +
      " </li>" +
      "<li>ഇനിപ്പറയുന്ന വിവരമറിഞ്ഞുള്ള ആവശ്യങ്ങൾക്കായി ഞാൻ ബാങ്കിന് (അതിന്റെ സേവന ദാതാക്കൾക്കും) അംഗീകാരം നൽകുകയും സമ്മതം നൽകുകയും ചെയ്യുന്നു:" +
      "<ol> എ.  നിലവിലുള്ളതും ഭാവിയിലുള്ളതുമായ എല്ലാ അക്കൗണ്ടുകൾക്കും സൗകര്യങ്ങൾക്കും സേവനങ്ങൾക്കും ബാങ്ക് വഴിയുള്ള/ബന്ധം എന്നിവയ്ക്കും ബാധകമായ നിയമം അനുസരിച്ച് അനുവദനീയമായ,  പിഎംഎൽ ആക്റ്റ്, 2002, അതിനു കീഴിൽ വരുന്ന ചട്ടങ്ങൾ, ആർബിഐ മാർ'നിർദ്ദേശങ്ങൾ എന്നിവ അനുസരിച്ചുള്ള  കെവൈസി, പിരിയോഡിക്  കെവൈസി പ്രക്രിയയും,  അല്ലെങ്കിൽ എന്റെ ഐഡന്റിറ്റി സ്ഥാപിക്കുന്നതിന്, എന്റെ ഐഡന്റിഫിക്കേഷൻ നടപ്പാക്കുന്നതിന് , ഓഫ്‌ലൈൻ സ്ഥിരീകരണം അല്ലെങ്കിൽ ഇകെവൈസി അല്ലെങ്കിൽ  ആധികാരികത ഉണ്ട്/ ഇല്ല, ഡെമോഗ്രാഫിക്  അല്ലെങ്കിൽ മറ്റ് ആധികാരികത / സ്ഥിരീകരണം /ഐഡന്റിഫിക്കേഷൻ ;</ol>" +
      "<ol>ബി. വിവരങ്ങൾ ശേഖരിക്കുക, പങ്കിടുക, സംഭരിക്കുക, സൂക്ഷിക്കുക, രേഖകൾ പരിപാലിക്കുക, വിവരങ്ങൾ ഉപയോഗിക്കുക, രേഖകളുടെ  ആധികാരികത / സ്ഥിരീകരണം /  ഐഡന്റിഫിക്കേഷൻ  1) മുകളിൽ പറഞ്ഞ വിവരമറിഞ്ഞുള്ള ഉദ്ദേശ്യങ്ങൾക്ക്, 2) അതുപോലെ തന്നെ റെഗുലേറ്ററി , ലീഗൽ റിപ്പോർട്ടിംഗിനും ഫയലിംഗിനും ഒപ്പം/ അല്ലെങ്കിൽ 3) ബാധകമായ നിയമപ്രകാരം ആവശ്യമുള്ളിടത്ത്;</ol>" +
      "<ol>സി. ആധാർ എനേബിൾ പേയ്‌മെന്റ് സർവീസിനായി ( എഇപിഎസ്) എന്റെ അക്കൗണ്ട് പ്രവർത്തനക്ഷമമാക്കുന്നു</ol>" +
      "<ol>ഡി. അനുമതി, വിവരങ്ങൾ അല്ലെങ്കിൽ പ്രാമാണീകരണം, ഐഡന്റിഫിക്കേഷൻ, സ്ഥിരീകരണം മുതലായവയുടെ രേഖകളും ലോഗുകളും, കോടതി മുമ്പാകെ, അല്ലെങ്കിൽ ഏതെങ്കിലും അധികാരികളുടെ മുന്നിൽ അല്ലെങ്കിൽ ആർബിട്രേഷൻ ഉൾപ്പെടെ, തെളിവ് ആവശ്യങ്ങൾക്കായി ഹാജരാക്കുന്നു.</ol>" +
      "  </li>" +
      "<li>മുകളിലെ പോയിന്റ് 3ൽ നൽകിയിരിക്കുന്നത് പോലെ ബാങ്ക് എന്റെ അനുമതിയുടെ  രേഖകൾ സൂക്ഷിക്കുമെന്ന് ഞാൻ ഇതിനാൽ അംഗീകരിക്കുന്നു.</li>" +
      "<li>2016ലെ ആധാർ നിയമത്തിനും അതിന് കീഴിലുള്ള നിയമങ്ങൾക്കും ചട്ടങ്ങൾക്കും   അനുസൃതമായാണ് എന്റെ അനുമതി എടുക്കുന്നത്</li>" +
      "<li>ബാങ്ക് എന്റെ ഇകെവൈസിയോ ആധാർ നമ്പറോ മറ്റ് സ്ഥാപനങ്ങളുമായി പങ്കിടില്ലെന്ന് എന്നെ അറിയിച്ചിട്ടുണ്ട്.</li>" +
      "<li>ആധാർ നമ്പറും കോർ ബയോമെട്രിക്‌സും നിയമപ്രകാരമല്ലാതെയും സിഐഡിആർ സമർപ്പണത്തിനായും അല്ലാതെ സംഭരിക്കപ്പെടുകയോ പങ്കിടുകയോ ചെയ്യില്ലെന്ന് ഞാൻ മനസ്സിലാക്കുന്നു. എന്റെ ആധാർ രജിസ്റ്റേർഡ് മൊബൈൽ നമ്പറിൽ ലഭിച്ച  ഒടിപി ഉപയോഗിച്ച് ഞാൻ തന്നെ ഇആധാർ ഡൗൺലോഡ് ചെയ്തിട്ടുണ്ട്. ഈ ഡോക്യുമെന്റ് ക്രമത്തിലല്ലെങ്കിലോ ഞാൻ നൽകിയ  വിവരങ്ങളിൽ എന്തെങ്കിലും തെറ്റ്  ഉണ്ടായാലോ ബാങ്കിനെയോ അതിന്റെ ഉദ്യോഗസ്ഥരെയോ ഞാൻ ഉത്തരവാദികളാക്കില്ല.</li>" +
      "<li>മേൽപ്പറഞ്ഞ അനുമതിയെയും വിവരങ്ങൾ ശേഖരിക്കുന്നതിന്റെ ഉദ്ദേശ്യവും എനിക്ക് വിശദീകരിക്കുകയും എന്റെ പ്രാദേശിക ഭാഷയിൽ എനിക്ക് ലഭ്യമാക്കുകയും ചെയ്തിട്ടുണ്ട്.</li>" +
      " </ol>",
    audio: "/aadhaar/assets/aadhaar-malayalam.mp3",
  },
};
