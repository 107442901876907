<script>
  import Snackbar, { Label } from "@smui/snackbar";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import { request } from "../../services/network";
  import { routeCheck } from "../../services/routeCheck.js";
  import Input from "../../components/commons/Input.svelte";
  export let currentRoute;
  import _ from "lodash";
  import Tooltip, { Wrapper, Content } from "@smui/tooltip";
  import Bullets from "../../components/commons/Bullets.svelte";
  import SegmentedButton from "../../components/commons/SegmentedButton.svelte";
  import Dialog, {
    Content as ContentModal,
    Header,
    Title,
    Actions,
  } from "@smui/dialog";
  import BanksList from "./BanksList.svelte";
  import { banksListWithPaymentMethods } from "./BanksListWithPaymentMethods";

  let error = "";
  let showModal = false;
  let accountNumber, accountNumberValue;
  let isfcCode,
    isfcCodeValue = "";
  let branchName, branchNameValue;
  let bankName, bankNameValue;

  const bankNamesList = [];

  for (const bank of banksListWithPaymentMethods) {
    bankNamesList.push(bank.bank_name);
  }

  let isPaymentAllowed = false;
  let beneficiaryName;
  let paymentList = [];
  let selectedpaymentMethod = "";
  let snackbarOpen;
  let snackbarMessage = "";
  let amount = 0;

  onMount(async () => {
    //routeCheck(currentRoute.name);
    const pendingAccount = await request("/api/payment/details", "GET");
    amount = pendingAccount?.data?.data?.amount;
  });

  let verifyStatus = false;

  const verifyDetails = async () => {
    if (selectedpaymentMethod.length < 3) {
      snackbarMessage = "Please choose a payment method";
      snackbarOpen.forceOpen();
      return;
    }

    if (!accountNumber || accountNumber.length < 4) {
      snackbarMessage = "Please enter account number";
      snackbarOpen.forceOpen();
      return;
    }

    if (!isfcCode || isfcCode.length < 11) {
      snackbarMessage = "Please enter a valid IFSC number";
      snackbarOpen.forceOpen();
      return;
    }

    window.loading(true);

    const verifyData = await request("/api/payment/account_validate", "POST", {
      ifsc: isfcCode.toString(),
      account_number: accountNumber.toString(),
    });

    window.loading(false);

    beneficiaryName = _.get(verifyData, "results.registered_name", "");
    if (verifyData.type === "success") {
      verifyStatus = true;
    } else {
      snackbarMessage = "Something went wrong. Please try again";
      snackbarOpen.forceOpen();
    }
  };

  const payNow = async () => {
    if (verifyStatus) {
      window.loading(true);
      const paymentData = await request("/api/payment/checkout", "POST", {
        method: selectedpaymentMethod,
      });
      const razorPayData = JSON.parse(paymentData.data);
      const rzp1 = new Razorpay(razorPayData.data);
      window.loading(false);
      rzp1.open();
    }
  };

  const setBranchName = async (ifsc) => {
    const branchDetails = await request("/api/payment/ifsc", "POST", {
      ifsc,
    });
    if (branchDetails.type !== "danger" && branchDetails.BRANCH) {
      branchName = branchDetails.BRANCH;
      branchNameValue = branchDetails.BRANCH;
      bankName = branchDetails.BANK;
      bankNameValue = branchDetails.BANK;
      if (!bankNamesList.includes(bankName)) {
        // snackbarMessage = `Sorry, payments are not available for ${bankName}.`;
        // snackbarOpen.forceOpen();
        showModal = true;
        return;
      } else {
        const tempPaymentList = banksListWithPaymentMethods.reduce(
          (acc, bank) => {
            if (bank.bank_name === bankName) {
              if (bank.upi) {
                if (bank.upi && amount > 100000) {
                  // snackbarMessage = `${bank.bankName} offers UPI services for transactions below ₹1,00,000.`;
                  // snackbarOpen.forceOpen();
                } else if (!acc.includes("UPI")) {
                  acc.push("UPI");
                }
              }
              if (bank.netbanking && !acc.includes("NETBANKING")) {
                acc.push("NETBANKING");
              }
            }
            return acc;
          },
          []
        );
        paymentList = tempPaymentList;

        if (paymentList.length === 0) {
          error =
            "No payment method is available for the selected bank. Either create FD below 1L or use a different bank account supported in the list";
          // snackbarMessage = `${bankName} offers UPI services for transactions below ₹1,00,000.`;
          // snackbarOpen.forceOpen();
          return;
        }
        isPaymentAllowed = true;
      }
    } else {
      branchName = "";
      branchNameValue = "";
      bankName = "";
      bankNameValue = "";
      snackbarMessage = "Please enter a valid IFSC number";
      snackbarOpen.forceOpen();
      return;
    }
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<Dialog
  fullscreen
  bind:open={showModal}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <div style="display: flex; justify-content: space-between; width: 90%">
      <Title id="over-fullscreen-title" class="modal-header-top"
        ><span
          class="modal-header-top modal-title-left-margin"
          style="font-size: 18px;"
          >Payments are available for the following banks:</span
        >
      </Title>
    </div>
  </Header>
  <ContentModal id="sheet-content">
    <div class="modal">
      <div class="modal-body">
        <div
          class="d-flex"
          style="width: 100%; margin-bottom: 20px; flex-direction: column"
        >
          {#if bankNamesList.includes(bankName) && paymentList.length === 0}
            <div style="display: flex; align-items: center; gap: 8px">
              <!-- svelte-ignore a11y-click-events-have-key-events -->

              <img src="/assets/images/error-red.svg" alt="error" />
              <p class="error-message">
                Sorry, payments are not available for {bankName}.
              </p>
            </div>
          {/if}
          <span
            style="margin-bottom: 10px; color: #505869; line-height: 20px; text-align: justify;"
          >
            Note: UPI transactions are available for deposits below ₹1,00,000 (1
            Lakh).
          </span>
          <!-- <span
            style="margin-bottom: 10px; color: #505869; line-height: 20px; text-align: justify; font-weight: 800"
          >
            Note: UPI transactions are available for deposits below ₹1,00,000 (1
            Lakh).
          </span> -->
          <BanksList banks={banksListWithPaymentMethods} />
        </div>
      </div>
    </div>
  </ContentModal>
  <Actions class="modal-button">
    <div
      class="modal-button"
      style=" margin-left: 14px; margin-right: 14px; margin-bottom: 14px; justify-content: end;"
    >
      <Button
        title="Close"
        onclick={() => {
          showModal = false;
        }}
        style="display: flex; justify-content: center; border: 2px solid #13afe9"
      />
    </div>
  </Actions>
</Dialog>

<WizardBody
  title="from where you wish to fund your fixed deposit"
  back="/payment"
>
  <div class="info">
    <div class="info-container">
      <p>Payments are available for these banks:</p>
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <img
        src="/assets/images/info.svg"
        alt="info"
        on:click={() => {
          showModal = true;
        }}
      />
    </div>
  </div>
  <div class="input-container">
    <div class="select-container" style="">
      <div class="label">Account Number</div>
      <div class="row">
        <Input
          type="text"
          style={"width:320px;"}
          placeholder="Enter your Account Number"
          bind:value={accountNumber}
          onKeyPress={(e) => {}}
          onchange={(event) => {
            accountNumberValue = event.target.value.replace(/\D/g, "");
          }}
          validate={(value) => {
            return "";
          }}
        />
      </div>
    </div>
  </div>
  <div class="input-container">
    <div class="select-container" style="">
      <div class="label">IFSC Code</div>
      <div class="row">
        <Input
          type="text"
          style={"width:320px;"}
          placeholder="Enter your IFSC Code"
          bind:value={isfcCode}
          maxLength={12}
          onKeyPress={(e) => {}}
          onchange={(event) => {
            isfcCode = isfcCode.toUpperCase();
            isfcCodeValue = event.target.value.toUpperCase();
          }}
          validate={(value) => {
            beneficiaryName = null;
            if (value.length >= 11) {
              setBranchName(value);
            }
            return "";
          }}
        />
      </div>
    </div>
  </div>

  {#if bankName && isfcCodeValue.length === 11}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label">Bank Name</div>

        <div class="branch_name">{bankName}</div>
      </div>
    </div>
  {/if}

  {#if branchName && isfcCodeValue.length === 11}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label">Branch Name</div>

        <div class="branch_name">{branchName}</div>
      </div>
    </div>
  {/if}

  {#if bankName && branchName && isfcCodeValue.length === 11 && paymentList.length > 0}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label" style="margin-top: 5px">Payment Options</div>
        <SegmentedButton
          choices={paymentList}
          bind:selected={selectedpaymentMethod}
        />
      </div>
    </div>
  {/if}

  {#if bankName && branchName && isfcCodeValue.length === 11 && paymentList.length === 0}
    <div class="minimum-note">
      No payment method is available for the selected bank. Either create FD
      below ₹1,00,000 (1 Lakh) or use a different bank account supported in the
      list.
    </div>
  {/if}

  {#if beneficiaryName}
    <div class="input-container">
      <div class="select-container" style="">
        <div class="label">Beneficiary Name</div>

        <div class="branch_name">{beneficiaryName}</div>
      </div>
    </div>
  {/if}
  <div></div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative; margin-bottom: -10px">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <div class="flex-dir" style="display: flex; gap: 16px;">
    {#if !verifyStatus}
      <Button
        title="Verify"
        onclick={verifyDetails}
        disabled={!isPaymentAllowed || !selectedpaymentMethod}
      />
    {:else}
      <Button title="Pay Now" onclick={payNow} disabled={!isPaymentAllowed} />
    {/if}
  </div>
</WizardActionArea>

<style>
  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
    margin-bottom: 8px;
  }

  .info:hover {
    cursor: pointer;
  }

  .info-container {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  .error-message {
    color: #b8342d;
    font-weight: 700;
  }
  @media only screen and (max-width: 768px) {
    .flex-dir {
      flex-direction: column;
    }

    .select-container {
      gap: 0.125rem;
      margin-top: 0.75rem;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.25rem;
    }

    .branch_name {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .flex-dir {
      flex-direction: row;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 0.25rem;
    }

    .input-container {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 2rem;
      margin-top: 1rem;
    }

    .branch_name {
      width: 320px;
    }
  }

  .branch_name {
    display: block;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
    max-width: 100%;
    margin: 0px !important;
  }

  .minimum-note {
    color: red;
    font-weight: 400;
    font-size: 14px;
    margin-left: 0.1rem;
    margin-top: 7px;
  }
</style>
