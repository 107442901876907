<script>
  import { Route } from "svelte-router-spa";
  export let currentRoute;
  const params = {};
  import Card, { Content } from "@smui/card";
</script>

<div class="app">
  <section class="section">
    <div class="card-container">
      <Card style="width:480px; max-width:100%;">
        <Route {currentRoute} {params} />
      </Card>
    </div>
  </section>
</div>

<style>
  .card-container {
    margin: auto;
    width: 480px;
    max-width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
