<script>
  export let onclick;
  export let title;
  export let id = "";
  export let name = "";
  export let style = "";
</script>

<button {id} {title} {name} class={`button`} {style} on:click={onclick}
  >{title}
</button>

<style>
  .button {
    font-family: "Urbanist";
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    color: #13afe9;
    background-color: #ffffff;
    border: 2px solid #13afe9;
    line-height: 16px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }

  .button:hover {
    background-color: #ffffff;
    box-shadow: 0px 15px 20px rgba(19, 175, 233, 0.4);
    color: #13afe9;
    transform: translateY(-4px);
  }

  @media only screen and (min-width: 768px) {
    .button {
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 56px;
      padding-right: 56px;
      margin: 0px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .button {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 46px;
      padding-right: 46px;
      width: 100%;
      border-radius: 10px;
    }
  }
</style>
