<script>
  import Input from "../../../components/commons/Input.svelte";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Checkbox from "../../../components/commons/Checkbox.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../../services/network";
  import { onMount } from "svelte";
  import Snackbar, { Label } from "@smui/snackbar";
  import Select from "../../../components/commons/Select.svelte";
  import SelectAutoComplete from "../../../components/commons/SelectAutoComplete.svelte";
  import Dialog, { Content, Header, Title, Actions } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import InputAutocomplete from "../../../components/commons/InputAutocomplete.svelte";
  import { cityOptions } from "../../caf/cityArray";
  import _ from "lodash";

  let checked = false;
  let address = "";
  let landmark = "";
  let locality = "";
  let district = "";
  let pincode = "";
  let city = "";
  let state = "";
  let snackbarOpen;
  let modalOpen = false;
  let snackbarMessage = "";
  let declarationAccepted = false;
  let states = [
    "Andhra Pradesh",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "Telangana",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra & Nagar Haveli",
    "Daman & Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  export let currentRoute;
  const address_id = currentRoute.namedParams.address_id;

  onMount(async () => {
    if (address_id && (address_id.length > 0 || address_id > 0)) {
      //get request to get address and set values
      const addressRes = await request(`/api/address/${address_id}`, "GET");

      address = _.get(addressRes, "data.address", "");
      landmark = _.get(addressRes, "data.landmark", "");
      locality = _.get(addressRes, "data.locality", "");
      district = _.get(addressRes, "data.district", "");
      pincode = _.get(addressRes, "data.pincode", "");
      city = _.get(addressRes, "data.city", "");
      state = _.get(addressRes, "data.state", "");
    }
  });

  const buttonClick = async () => {
    if (!checked) {
      /* window.snackbar("Please accept the Self Declaration", "danger", null); */
      snackbarMessage = "Please accept the Self Declaration";
      snackbarOpen.forceOpen();
      return;
    }
    if (address.length < 1) {
      //window.snackbar("Invalid address", "danger", null);
      snackbarMessage = "Invalid address";
      snackbarOpen.forceOpen();
      return;
    }
    if (landmark.length < 1) {
      //window.snackbar("Invalid landmark", "danger", null);
      snackbarMessage = "Invalid landmark";
      snackbarOpen.forceOpen();
      return;
    }
    if (locality.length < 1) {
      //window.snackbar("Invalid landmark", "danger", null);
      snackbarMessage = "Invalid locality";
      snackbarOpen.forceOpen();
      return;
    }

    if (district.length < 1) {
      //window.snackbar("Invalid landmark", "danger", null);
      snackbarMessage = "Invalid district";
      snackbarOpen.forceOpen();
      return;
    }

    if (pincode.length < 6) {
      //window.snackbar("Invalid pincode", "danger", null);
      snackbarMessage = "Invalid pincode";
      snackbarOpen.forceOpen();
      return;
    }

    if (city.length < 1) {
      //window.snackbar("Invalid city", "danger", null);
      snackbarMessage = "Invalid city";
      snackbarOpen.forceOpen();
      return;
    }

    if (!cityOptions.includes(city)) {
      snackbarMessage = "City provided is not in the specified list";
      snackbarOpen.forceOpen();
      return;
    }

    if (state.length < 1) {
      //window.snackbar("Invalid state", "danger", null);
      snackbarMessage = "Invalid state";
      snackbarOpen.forceOpen();
      return;
    }

    window.loading(true);

    let address_api = "/api/address";
    let address_api_method = "POST";

    pincode = pincode.toString();
    address = address.replace(/[^A-Za-z0-9]+$/, "");
    landmark = landmark.replace(/[^A-Za-z0-9]+$/, "");
    locality = locality.replace(/[^A-Za-z0-9]+$/, "");
    district = district.replace(/[^A-Za-z0-9]+$/, "");
    pincode = pincode.replace(/[^A-Za-z0-9]+$/, "");
    city = city.replace(/[^A-Za-z0-9]+$/, "");
    state = state.replace(/[^A-Za-z0-9]+$/, "");

    let addressBody = {
      address,
      landmark,
      locality,
      district,
      pincode,
      city,
      state,
      type: "communication",
      verified_by: "",
      proof: "",
    };

    if (address_id && (address_id.length > 0 || address_id > 0)) {
      // this will occur if we come to address page for edit
      address_api = `/api/address/${address_id}`;
      address_api_method = "PUT";
      addressBody = { ...addressBody, address_id };
    }

    const addressRes = await request(
      address_api,
      address_api_method,
      addressBody
    );

    window.loading(false);

    if (addressRes.type === "success") {
      // navigateTo(`/onboarding/address?id=${addressRes.data}`);
      navigateTo("/onboarding/address");
    } else if (addressRes.type === "dropoff") {
      navigateTo("/onboarding/dropoff");
    }
  };

  /* $: if (checked) {
    if (declarationAccepted === false) {
      modalOpen = true;
      checked = false;
    }
  } else {
    declarationAccepted = false;
  } */

  function beforeunload(event) {
    event.preventDefault();
    return (event.returnValue = "");
  }
</script>

<svelte:window on:beforeunload={beforeunload} />

<Dialog
  fullscreen
  bind:open={modalOpen}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <Title id="over-fullscreen-title" class="modal-header-top"
        ><span class="modal-header-top modal-title-left-margin"
          >Address Self Declaration</span
        ></Title
      >
      <div style="position: relative; bottom: -6px; ">
        <IconButton on:click={() => (modalOpen = false)} class="material-icons"
          >close</IconButton
        >
      </div>
    </div>
  </Header>
  <Content id="sheet-content">
    <div class="modal">
      <div class="modal-body">
        <div
          class="d-flex"
          style="width: 100%; margin-bottom: 20px; flex-direction: column"
        >
          <span style="margin-bottom: 10px;">
            I am giving here under my current Indian address for your records. I
            also confirm, in the event of change in address due to relocation or
            any other reason, I would intimate the new address to the Bank
            immediately.
          </span>
        </div>
      </div>
    </div>
  </Content>
  <Actions class="modal-button">
    <div
      class="modal-button"
      style=" margin-left: 14px; margin-right: 14px; margin-bottom: 14px; "
    >
      <Button
        title="Accept"
        onclick={() => {
          modalOpen = false;

          setTimeout(() => {
            checked = true;
            declarationAccepted = true;
          }, 200);
        }}
        style="display: flex; width: 100%; justify-content: center; border: 2px solid #13afe9"
      />
    </div>
  </Actions>
</Dialog>

<WizardBody title="Add Communication address" back="/onboarding/address">
  <div
    style="font-style: normal; font-weight: 500; font-size: 16px; line-height: 16px; color: #464448; margin-top: 32px; margin-bottom: 16px;"
  >
    House No, Flat No, Building Name, Society*
  </div>

  <textarea
    placeholder="Address"
    class="address-textarea"
    rows="4"
    bind:value={address}
  />

  <div class="row">
    <div class="input-container" style="margin-top: 24px;">
      <div class="label">Landmark*</div>
      <div class="input-field">
        <Input
          name="landmark"
          type="text"
          placeholder="Landmark "
          width="480px"
          style="margin: 0px! important; width: 100%;"
          bind:value={landmark}
        />
      </div>
    </div>

    <div class="input-container" style="margin-top: 24px;">
      <div class="label">Locality*</div>
      <div class="input-field">
        <Input
          name="locality"
          type="text"
          placeholder="Locality"
          width="480px"
          style="margin: 0px! important; width: 100%"
          bind:value={locality}
        />
      </div>
    </div>

    <div class="input-container" style="margin-top: 24px;">
      <div class="label">District*</div>
      <div class="input-field">
        <Input
          name="district"
          type="text"
          placeholder="District"
          width="480px"
          style="margin: 0px! important; width: 100%"
          bind:value={district}
        />
      </div>
    </div>

    <div class="input-container" style="margin-top: 24px;">
      <div class="label">Pincode*</div>
      <div class="input-field">
        <Input
          name="pincode"
          type="number"
          placeholder="Pincode"
          width="480px"
          style="margin: 0px! important; width: 100%;"
          maxLength={6}
          onchange={(event) => {
            pincode = event.target.value.replace(/[^0-9]+/g, "");
          }}
          bind:value={pincode}
        />
      </div>
    </div>
  </div>

  <div class="row" style="margin-bottom: 120px;">
    <div class="input-container" style="margin-top: 24px;">
      <div class="label">City*</div>
      <div class="input-field-city">
        <!-- <Input
          name="city"
          type="text"
          placeholder="City"
          style="margin: 0px! important; width: 100%;"
          bind:value={city}
        /> -->
        <InputAutocomplete
          bind:inputValue={city}
          optionsList={cityOptions}
          placeholder="City"
        />
      </div>
    </div>

    <div class="input-container" style="margin-top: 24px;">
      <div class="label">State*</div>
      <!-- <div class="select-field">
        <Select options={states} label="States" bind:value={state} />
        <SelectAutoComplete
          options={states}
          label="States"
          bind:valueOutlined={state}
        />
      </div> -->

      <div style="width: 100%; background-color: #fff; border-radius: 8px;">
        <select id="state" name="state" class="state-select" bind:value={state}>
          <option value="" disabled selected>Select your state</option>
          <option value="AS">Assam</option>
          <option value="AR">Arunachal Pradesh</option>

          <option value="AD">Andhra Pradesh</option>
          <option value="BR">Bihar</option>
          <option value="CG">Chhattisgarh</option>
          <option value="GA">Goa</option>
          <option value="GJ">Gujarat</option>
          <option value="HR">Haryana</option>
          <option value="HP">Himachal Pradesh</option>
          <option value="JH">Jharkhand</option>
          <option value="JK">Jammu & Kashmir</option>
          <option value="KA">Karnataka</option>
          <option value="KL">Kerala</option>
          <option value="LA">Ladakh</option>
          <option value="MP">Madhya Pradesh</option>
          <option value="MH">Maharashtra</option>
          <option value="MN">Manipur</option>
          <option value="ML">Meghalaya</option>
          <option value="MZ">Mizoram</option>
          <option value="NL">Nagaland</option>
          <option value="OD">Odisha</option>
          <option value="PB">Punjab</option>
          <option value="RJ">Rajasthan</option>
          <option value="SK">Sikkim</option>
          <option value="TN">Tamil Nadu</option>
          <option value="TS">Telangana</option>
          <option value="TR">Tripura</option>
          <option value="UP">Uttar Pradesh</option>
          <option value="UK">Uttarakhand</option>
          <option value="WB">West Bengal</option>
          <option value="AN">Andaman and Nicobar Islands</option>
          <option value="CH">Chandigarh</option>
          <option value="DH">Dadra & Nagar Haveli</option>
          <option value="DD">Daman & Diu</option>
          <option value="DL">Delhi</option>
          <option value="LD">Lakshadweep</option>
          <option value="PY">Puducherry</option>
        </select>
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative; margin-bottom: -110px;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <div style="margin-left: -12px; " class="mb-3">
    <Checkbox bind:checked
      ><div
        style="color: #464448; margin-left: -8px; font-weight: 400; font-size: 12px; line-height: 16px;"
      >
        I am giving here under my current Indian address for your records. I
        also confirm, in the event of change in address due to relocation or any
        other reason, I would intimate the new address to the Bank immediately.
        <!-- <span style="color: #1e3868; cursor: pointer"
          >KNOW MORE</span
        > -->
      </div></Checkbox
    >
  </div>
  <Button title="Next" onclick={buttonClick} />
</WizardActionArea>

<style>
  .address-textarea {
    background: #ffffff;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    resize: none;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    padding: 20px;
    display: flex;
    height: 100%;
    row: 4;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
  }

  .state-select {
    position: relative;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/images/select-caret-rx.png") no-repeat 100%;
    background-attachment: cover;
    border: 0;
    padding-right: 30px !important;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  }

  .label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
    margin-bottom: 14px;
  }

  @media only screen and (max-width: 768px) {
    .input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    .input-field {
      width: 106%;
      margin-left: -12px;
    }

    .input-field-city {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .input-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-start;
    }

    .input-field {
      width: 108%;
      margin-left: -10px;
    }

    .input-field-city {
      width: 100%;
    }
  }
</style>
