<script>
  import { navigateTo } from "svelte-router-spa";
  import {
    bankToJsDate,
    dateDiff,
    parseDays,
    toCurrency,
  } from "../../services/function";
  import FdProgressIndicator from "./FdProgressIndicator.svelte";

  export let fdData;

  const mDate = bankToJsDate(fdData.data.MaturityDt); //TOOD: change later according to api data
  const creationDate = bankToJsDate(fdData.data.AcctOpnDt); //TOOD: change later according to api data
  const fdDays = dateDiff(mDate, new Date());
  const completedDays = dateDiff(new Date(), creationDate);
  const daysPercentage = (completedDays / fdDays) * 100;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="main-body"
  style="cursor: pointer;"
  on:click={() => {
    navigateTo(`/fd-details/${fdData.user_fd_id}`);
  }}
>
  <div style="display: flex; flex-direction: column; width: 100%; gap: 6px;">
    <div
      style="font-style: normal; font-weight: 600; font-size: 10px; line-height: 10px; letter-spacing: 0.26px; color: #464448; opacity: 0.72;"
    >
      FD No 89864313547
    </div>
    <div
      style="font-style: normal; font-weight: 700; font-size: 18px; line-height: 18px; letter-spacing: 0.26px; color: #464448;"
    >
      {toCurrency(fdData.amount)}
    </div>
  </div>
  <div style="display: flex; flex-direction: column; width: 100%; gap: 6px;">
    <div
      style="font-style: normal; font-weight: 700; font-size: 14px; line-height: 14px; letter-spacing: 0.26px; color: #464448;"
    >
      {parseDays(fdData.duration)}
      <span style="color: #13AFE9;">@{fdData.interest_rate}%</span>
    </div>
    <!-- <div
      style="height: 4px; width: 100%; background: #EAEAEA; overflow: hidden; display: flex; position: relative;  border-radius: 8px;"
    >
      <div
        style="height: 4px; width: 60%; background: #65BF73; position: absolute; left: 0px;"
      />
    </div> -->
    <FdProgressIndicator percentage={daysPercentage} />
  </div>
  <div style="margin-top: 3px;">
    <i
      style="background-image: url('/assets/images/right_arrow-dark.svg');background-size: 10px;width: 18px;height: 18px;display: block;background-repeat: no-repeat;"
    />
  </div>
</div>

<style>
  .main-body {
    position: relative;
    display: flex;
    margin-bottom: 16px;
    background: #ffffff;
    border: 1px solid #eaf0f9;
    box-shadow: 0px 1px 36px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 30px;
    align-items: center;
  }
</style>
