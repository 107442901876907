<!--Button-->
<script>
  export let onclick;
  export let title;
  export let id = "";
  export let name = "";
  export let style = "";
  export let disabled;
  export let className;
  export let fade = false;
</script>

<button
  {id}
  {title}
  {name}
  class={`button ${disabled ? "button-disabled" : ""} ${className}`}
  {style}
  on:click={onclick}
  {disabled}
  >{title}
</button>

<style>
  .button-disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
  }
  .button {
    background-color: #13afe9;
    color: #fff;
    font-family: "Urbanist";
    font-style: normal;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    border: none;
    line-height: 16px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin: 0px !important;
  }

  .button:hover {
    box-shadow: 0px 15px 20px rgba(19, 175, 233, 0.4);
    color: #fff;
    transform: translateY(-4px);
  }
  @media only screen and (min-width: 768px) {
    .button,
    .button-disabled {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 58px;
      padding-right: 58px;
    }
  }

  @media only screen and (max-width: 768px) {
    .button,
    .button-disabled {
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 48px;
      padding-right: 48px;
      width: 100%;
      border-radius: 10px;
    }
  }
</style>
