<script>
  import { onMount } from "svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import { request } from "../../services/network";
  import { navigateTo } from "svelte-router-spa";
  import Button from "../../components/commons/Button.svelte";
  import _ from "lodash";
  //   export let currentRoute;

  //   let type = currentRoute.namedParams.type;

  let count = 0;
  let message;
  let weblink;
  let status;
  let vkyc_status;

  async function statusCheck() {
    const statusRes = await request("/api/kyc/vkyc/status", "GET");
    vkyc_status = _.get(statusRes, "vkyc_status.status", "");
    message = _.get(statusRes, "message", "");
    weblink = _.get(statusRes, "weblink", "");
    status = _.get(statusRes, "status", "");

    if (
      vkyc_status === "AGENT_SUCCESS" ||
      vkyc_status === "VKYC_SUCCESS" ||
      vkyc_status === "VKYC_FAILURE" ||
      [
        "AUDITOR_ASSIGNED",
        "AGENT_SUCCESS",
        "VKYC_SUCCESS",
        "VKYC_FAILURE",
      ].includes(status)
    ) {
      clearInterval(timer);
      navigateTo("/");
    }
  }

  onMount(() => {
    statusCheck();
  });

  let timer = setInterval(async () => {
    // if (count <= 60 && type === "video-start") {
    if (count <= 360) {
      statusCheck();
    }
    count = count + 1;
  }, 10000);
</script>

<WizardBody title="" back="">
  <div class="text-container">
    <div class="text">
      <!-- {#if type === "video-start"}
        <p>
          The video call will proceed on another tab. You will shortly receive
          an update of the status of your application post Video KYC via email.
        </p>
      {:else if type === "notification-sent"}
        <p>
          Link has been created!. Message has been sent to the registered phone
          number with the link. Please do not share this message with anyone.
        </p>
        <p>
          You will receive an update of the status of your application post
          Video KYC via email.
        </p>
      {:else if type === "booking-confirm"}
        <p>
          The booking has been confirmed. You will shortly receive an update of
          the status of your application via email.
        </p> -->
      {#if message && message.length > 0}
        <p>
          {message}
        </p>
      {:else}
        <!-- <p>There was an error. Please try again.</p> -->
      {/if}

      {#if weblink && !["CALL_COMPLETED", "VKYC_FAILURE", "VKYC_RECAPTURE", "VKYC_SUCCESS", "CALL_ENDED"].includes(status) && !["AGENT_SUCCESS"].includes(vkyc_status)}
        <a href={weblink} rel="noreferrer">
          <Button title="Continue video call" />
        </a>
      {/if}
    </div>
  </div>
</WizardBody>

<style>
  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  .text {
    text-align: center;
  }
</style>
