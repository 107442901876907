<script>
  import Input from "../../../components/commons/Input.svelte";
  import Button from "../../../components/commons/Button.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import { navigateTo } from "svelte-router-spa";
  import Snackbar, { Label } from "@smui/snackbar";
  import { request } from "../../../services/network";
  import { get } from "svelte/store";
  import { prefill } from "../../../services/store";
  import { onMount } from "svelte";
  let phone = "";
  let checked = false;
  let inputValue = "";
  let snackbarOpen;
  let snackbarMessage = "";
  let isValid = false;
  export let currentRoute;
  const routeName = currentRoute.name;
  get(prefill);
  const sendOtp = async () => {
    prefill.set({ ...$prefill, phone }); // set value
    window.loading(true);
    const result = await request("/api/verify/phone", "POST", {
      phone: phone.toString(),
      whatsapp_notify: checked.toString(),
    });

    window.loading(false);

    if (result.type === "success" && result.verify_id) {
      navigateTo(`/onboarding/phone-verify/${result.verify_id}`);
    } else {
      snackbarMessage = result.message
        ? result.message
        : "Something went wrong";

      snackbarOpen.forceOpen();
    }
  };
  const buttonClick = () => {
    if (phone.toString().length !== 10 || !isValid) {
      snackbarMessage = "Invalid phone number";
      snackbarOpen.forceOpen();
      return;
    }
    sendOtp();
  };
  onMount(() => {
    if ($prefill.hasOwnProperty("phone")) {
      phone = $prefill.phone;
    }
    prefill.set({});
  });
  let focus = false;
</script>

<WizardBody title="Enter your mobile number to get started" back="" bind:focus>
  <div class="row">
    <Input
      name="phone"
      type="number"
      placeholder="Enter mobile number"
      width="480px"
      maxLength={10}
      bind:value={phone}
      onchange={(event) => {
        inputValue = event.target.value.replace(/[^0-9]+/g, "");
        phone = inputValue;
        isValid = false;
        const regex = /^[56789]\d{9}$/;
        if (regex.test(phone)) {
          isValid = true;
        }
      }}
      on:keypress={(e) => {
        if (!e) e = window.event;
        const keyCode = e.code || e.key;
        if (keyCode == "Enter") {
          buttonClick();
          return false;
        }
      }}
      validate={(value) => {
        if (value.length != 10) {
          return "Phone number must be 10 digits";
        }
        return "";
      }}
    />
    <!--
      <WizardBody
        title="Thank you for your interest in SBM Bank India Fixed Deposit / Savings Account."
        back=""
        bind:focus
      >
    <p style="padding-left:16px; padding-top:32px;padding-bottom:4px;">
      To enhance your digital experience with us, we are upgrading the Online
      Savings Account / Fixed Deposit journey. Hence the same is currently
      unavailable. We will be back soon.
    </p>
    <p style="padding-left:16px; padding-top:4px;padding-bottom:32px;">
      We apologize any inconvenience caused and encourage you to try again
      later.
    </p>
  </div>
  -->
    <p style="font-size: 12px; font-weight: bold; color: gray;">
      This account opening journey is for New to Bank customers. Existing
      customer?
      <a
        href="https://www.sbmbank.co.in/aboutus/login.php"
        style="text-decoration: underline; color: #007bff;"
      >
        Click here
      </a>
    </p>

    <!-- <div class="checkbox">
    <Checkbox bind:checked>
      <span class="checkbox-label" style="color: #464448; margin-left: -8px;"
        >I want to receive communications via WhatsApp</span
      >
    </Checkbox>
  </div> -->
  </div></WizardBody
>

<WizardActionArea>
  <p style="font-size: 10px;">
    SBM Bank India obtains Personal Information of the applicant such as
    applicant's name, age, gender, contact details, email addresses, passport
    number, income, PAN, details of nominees, account numbers, types of account,
    credit ratings, financial information, and any details pertaining to the
    online digital journey. The Bank recognizes the expectations of its
    applicants with regards to privacy, confidentiality and security of their
    Personal Information that resides with the Bank. This Personal Information
    stored by the Bank for processing under lawful contract resides on secured
    servers of the Bank.
  </p>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>
  <Button
    title="Get OTP"
    onclick={buttonClick}
    disabled={inputValue.length !== 10 || !isValid}
  />
</WizardActionArea>

<style>
  .checkbox {
    margin-left: -12px;
  }

  @media only screen and (min-width: 768px) {
    .checkbox {
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .checkbox {
      margin-top: 10px;
    }
    .checkbox-label {
      font-size: 12px;
      line-height: 16px;
    }
  }
</style>
