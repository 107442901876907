<script>
  import WizardBody from "../../components/Wizard/Body.svelte";
  import { request } from "../../services/network";
  import { onMount } from "svelte";
  import { toCurrency } from "../../services/function";
  import Bullets from "../../components/commons/Bullets.svelte";

  let amount = 0;
  let months;
  let interest_rate;
  let maturity_amount;
  let maturity_date;

  onMount(async () => {
    request("/api/payment/success", "GET").then((response) => {
      amount = response.user_account.amount;
      months = response.user_account.months;
      interest_rate = response.user_account.interest_rate;
      maturity_amount = response.user_account.maturity_amount;
      maturity_date = response.user_account.maturity_date;
    });
  });
</script>

<WizardBody
  title="Congratulations! Funding towards your SBM Fixed Deposit has been successful"
  back=""
>
  <div
    style="position: relative; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column;"
  >
    <!-- <div class="confetti" /> -->

    <video
      autoPlay={true}
      playsInline={true}
      muted={true}
      controls={false}
      preload="metadata"
      class="confetti-video"
    >
      <source src="/assets/images/confetti.mp4" type="video/mp4" />
    </video>
    <div class="success-card">
      <!-- <div class="payment-card">
        <img
          class="p-4"
          style="margin-right: auto;"
          src="/assets/images/sbm_logo_card.png"
          alt=""
        />
      </div> -->

      <div class="success-value-text mx-4 my-3">
        Fixed Deposit being processed
      </div>

      <div
        class="d-flex flex-row mx-4 pt-3"
        style="justify-content: space-between;"
      >
        <!--<div style="display: flex; flex-direction:column; width: 100%;">
          <div class="success-label-text">FD Amount</div>
          <div class="success-value-text">{toCurrency(amount)}</div>
        </div>
        <div
          style="display: flex; flex-direction:column; width: 100%; align-items: center;"
        >
          <div class="success-label-text">FD Duration</div>
          <div class="success-value-text">{months} months</div>
        </div>
        <div
          style="display: flex; flex-direction:column; width: 100%; align-items: flex-end;"
        >
          <div class="success-label-text">Interest Rate</div>
          <div class="success-value-text" style="">
            {interest_rate}%
          </div>
        </div>-->
      </div>
      <div
        class="d-flex flex-row mx-4 my-4"
        style="position:relative; top: 5rem"
      >
        <!--<div
          style="width: 100%; display: flex;font-family: 'IBM Plex Sans'; font-style: normal; font-weight: 500; font-size: 10px; line-height: 13px; letter-spacing: -0.5px; color: #FFFFFF;
    "
        >
          Maturity Amount - {maturity_amount}
        </div>
        <div
          style="width: 100%; display: flex; justify-content: end ; font-family: 'IBM Plex Sans'; font-style: normal; font-weight: 500; font-size: 10px; line-height: 13px; letter-spacing: -0.5px; color: #FFFFFF;
    "
        >
          Maturity Date - {maturity_date}
        </div>-->
      </div>
    </div>
    <span class="receipt-info"
      >You will receive details of your SBM Fixed Deposit on confirmation of
      successful Video KYC report from concurrent auditor</span
    >
    <span
      class="receipt-info"
      style="margin: 0px; margin-top: 10px; text-align: left;"
    >
      <Bullets
        points={[
          "You will recieve further communication on registered email id and phone number",
          "All deliverables (where applicable) will be sent to communication address",
          "If you have any further queries, kindly call our toll-free number 1800 1033 817 or write to us at customercare@sbmbank.co.in so that we may assist with your application.",
          "Please log in to SBM internet banking or SBM mobile banking for managing account",
        ]}
      />
    </span>
    <div
      style="display: flex; justify-content: space-evenly; gap: 30px; align-items: center;"
      class="my-3"
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.mode.sbmindia&hl=en_IN&gl=US"
        target="_blank"
        rel="noreferrer"><i class="android-logo" /></a
      >
      <a
        href="https://apps.apple.com/in/app/sbm-bank-india-ltd/id1530410515"
        target="_blank"
        rel="noreferrer"><i class="ios-logo" /></a
      >
      <a
        href="https://retail.sbmbank.co.in/mfmbs/ib/login.jsp"
        target="_blank"
        rel="noreferrer"><i class="sbm-logo" /></a
      >
    </div>
  </div>
</WizardBody>

<style>
  .confetti {
    position: absolute;
    background-image: url("/assets/images/confetti.svg");
    background-size: cover;
    width: 100%;
    min-height: 480px;
    max-width: 100%;
    top: 0px;
  }

  video::-webkit-media-controls {
    /* Works only on Chrome-based browsers */
    display: none;
  }
  .confetti-video {
    position: fixed;
    z-index: -1;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .success-card {
    background-image: url("/assets/images/bluebox.png");
    /* background: linear-gradient(66.53deg, #13afe9 -11.43%, #083a9a 88.91%); */
    max-width: 480px;
    display: flex;
    flex-direction: column;
    /* z-index: 1; */
    width: 414px;
    height: 300px;
  }

  .success-value-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 21px;
    letter-spacing: -0.5px;
    color: #ffffff;
  }

  .success-label-text {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    letter-spacing: -0.5px;
    color: #ffffff;
  }

  .android-logo {
    background: url("/assets/images/google-play-badge.png");
    background-size: 100px 38px;
    width: 100px;
    height: 38px;
    display: block;
    background-repeat: no-repeat;
  }

  .ios-logo {
    background: url("/assets/images/app-store-badge.png");
    background-size: 82px;
    width: 82px;
    height: 32px;
    display: block;
    background-repeat: no-repeat;
    margin-bottom: -4px;
  }

  .sbm-logo {
    background: url("/assets/images/sbm-logo.png");
    background-size: 100px 38px;
    width: 100px;
    height: 38px;
    display: block;
    background-repeat: no-repeat;
  }

  @media only screen and (min-width: 768px) {
    .success-card {
      /* background: linear-gradient(66.53deg, #13afe9 -11.43%, #083a9a 88.91%); */
      background-image: url("/assets/images/bluebox.png");
      max-width: 480px;
      display: flex;
      flex-direction: column;
      /* z-index: 1; */
      margin-top: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      width: 380px;
    }

    .success-label-text,
    .success-value-text {
      position: relative;
      top: 4.5rem;
    }

    .receipt-info {
      font-size: 16px;
      line-height: 20px;
      margin-top: 1rem;
      margin-left: 0.75rem;
      text-align: center;
      /* width: 33ch; */
    }

    /* .payment-card {
      justify-content: space-between;
      align-items: center;
      gap: 134px;
      min-width: 600px;
      min-height: 120px;
    } */
  }
  @media only screen and (max-width: 768px) {
    .success-card {
      /* background: linear-gradient(66.53deg, #13afe9 -11.43%, #083a9a 88.91%); */
      background-image: url("/assets/images/bluebox.png");
      max-width: 320px;
      display: flex;
      flex-direction: column;
      /* z-index: 1; */
      margin-top: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      /* margin-top: 5rem; */
      margin-right: 4px;
    }

    .success-label-text,
    .success-value-text {
      position: relative;
      top: 4.5rem;
    }
    /* .payment-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 400px;
      min-height: 100px;
      margin: 10px 10px 0px 10px;
    } */

    .success-value-text {
      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 16px;
      letter-spacing: -0.5px;
      color: #ffffff;
    }

    .receipt-info {
      margin-top: -2rem;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      width: 33ch;
    }
  }
</style>
