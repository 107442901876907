<script>
  import { toCurrency } from "../../services/function";

  export let id = "name";
  export let placeholder = "";
  export let name = "name";
  export let style = "";
  export let maxLength = "80";
  export let value = "";
</script>

<div>
  <input
    {id}
    {name}
    type="text"
    class="input"
    {placeholder}
    {style}
    maxlength={maxLength}
    bind:value
    regex="[0-9*]"
    on:input={() => {
      value = toCurrency(value);
    }}
  />
</div>

<style>
  .input {
    display: block;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
    max-width: 100%;
  }

  @media only screen and (max-width: 768px) {
    .input {
      width: 100%;
    }
  }
</style>
