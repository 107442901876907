<script>
  export let style = "";
</script>

<div class={`bottom-action-area padding-y`} {style}>
  <slot />
</div>

<style>
  .bottom-action-area {
    align-items: flex-start;
    order: 1;
    position: relative;
  }

  @media only screen and (max-width: 768px) {
    .bottom-action-area {
      width: 100%;
    }
    .padding-y {
      padding-top: 0px !important;
      padding-bottom: 0px !important;

      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .padding-y {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
</style>
