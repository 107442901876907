<script>
  import { getDurationAndRate, parseDays } from "../../services/function";
  import Icon from "../commons/Icon.svelte";
  import Tenure from "./Tenure.svelte";

  export let selectedTenureIndex;
  export let onclick;
  export let tenureList;
  export let interestList = [];
  export let maturityDate;
  export let customMaturityDate;

  const items = [
    { smallTitle: "5 Years", bigTitle: "6.25%", attractive: true },
    { smallTitle: "2 Years", bigTitle: "5.75%", attractive: false },
    { smallTitle: "1 Years", bigTitle: "5.25%", attractive: false },
  ];

  tenureList.map((item, index) => {
    if (item.default === true) {
      selectedTenureIndex = index;
    }
  });
</script>

<div class="p-2 row">
  {#each tenureList as item, index}
    <Tenure
      smallTitle={parseDays(item.value)}
      bigTitle={`${getDurationAndRate(
        interestList,
        item.value
      ).interest_rate.toFixed(2)}%`}
      attractive={item.recommended}
      bind:selectedTenureIndex
      selected={selectedTenureIndex === index ? true : false}
      {index}
      bind:maturityDate
      bind:customMaturityDate
    />
  {/each}

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="tenure-item"
    on:click={() => {
      selectedTenureIndex = -1;
      onclick();
    }}
  >
    <div class="small-title">
      <Icon icon="add_circle" class="note-text" color="#979797" size="32px" />
    </div>
    <div class="big-title">Custom</div>
  </div>
</div>

<style>
  .tenure-item {
    width: 150px;
    height: 128px;
    margin-right: 24px;
    margin-bottom: 24px;
    border-radius: 12px;
    background: linear-gradient(
      180deg,
      #e2eaf5 24.91%,
      rgba(166, 180, 202, 0) 97.17%
    );
    border: 1px solid #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 18px;
    justify-content: end;
    cursor: pointer;
  }

  .tenure-item:hover {
    background: linear-gradient(
      180deg,
      #d8e3f3 24.91%,
      rgba(157, 171, 194, 0) 97.17%
    );
  }

  .small-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #979797;
  }

  .big-title {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #505869;
  }
</style>
