<script>
  import IconButton from "@smui/icon-button";
  import Button from "../../../components/commons/Button.svelte";
  import Checkbox from "../../../components/commons/Checkbox.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Input from "../../../components/commons/Input.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../../services/network";
  import { onMount } from "svelte";
  import Snackbar, { Label } from "@smui/snackbar";
  import Dialog, { Content, Header, Title, Actions } from "@smui/dialog";
  import { declaration } from "./declaration.js";
  import AudioPlayer, {
    stopAll,
  } from "../../../components/commons/AudioPlayer.svelte";
  import Buttonlnv from "../../../components/commons/Buttonlnv.svelte";
  import { fly } from "svelte/transition";
  import { routeCheck } from "../../../services/routeCheck";
  let snackbarOpen;
  let snackbarMessage = "";
  let inputValue = "";
  let aadhaarValue = "";

  let langModalOpen = false;

  let checked = false;

  let declarationAccepted = false;

  let lang = "en";

  let declarationText = "";
  let declarationAudio = "";
  export let currentRoute;

  const setDeclarationContent = () => {
    declarationText = declaration[lang].value;
    declarationAudio = declaration[lang].audio;
  };

  $: if (!langModalOpen && declarationAudio.length > 0) {
    stopAll();
  }

  onMount(async () => {
    //await userCheck(true);
    routeCheck(currentRoute.name);
    setDeclarationContent();

    try {
      const localData = localStorage.getItem("aadhaar");

      if (localData) {
        const data = JSON.parse(localData);

        aadhaarValue = data.aadhaar;
        inputValue = data.aadhaar;

        localStorage.setItem("aadhaar", null);

        /*  setTimeout(() => {
          inputValue = data.aadhaar;
        }, 2000); */
      }
    } catch (e) {}
  });

  const setLanguage = (e) => {
    lang = e.target.value;
    //langModalOpen = true;

    setDeclarationContent();
  };

  $: if (checked) {
    if (declarationAccepted === false) {
      langModalOpen = true;
      checked = false;
    }
  } else {
    declarationAccepted = false;
  }

  const buttonClick = async () => {
    if (!checked) {
      /* window.snackbar(
        "Please accept the terms & conditions to move ahead",
        "danger",
        null
      ); */

      snackbarMessage = "Please accept the aadhaar self declaration";
      snackbarOpen.forceOpen();
      return;
    }

    if (
      aadhaarValue.toString().length !== 12 ||
      !/^\d+$/.test(aadhaarValue.toString())
    ) {
      //window.snackbar("Invalid aadhaar number", "danger", null);
      snackbarMessage = "Invalid aadhaar number";
      snackbarOpen.forceOpen();
      return;
    }

    if (!checked) {
      snackbarMessage = "Please accept self declaration";
      snackbarOpen.forceOpen();
      return;
    }

    window.loading(true);

    const result = await request("/api/kyc/aadhaar", "POST", {
      aadhaar: aadhaarValue.toString().trim(),
    });

    window.loading(false);
    if (result.type === "success") {
      navigateTo("/onboarding/aadhaar-otp");
    } else if (result.message) {
      snackbarMessage = result.message;
      snackbarOpen.forceOpen();
    }
  };

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };
</script>

<Dialog
  transition={{
    x: "0",
    y: "200",
    duration: 1000,
    easing: "easeIn",
  }}
  fullscreen
  bind:open={langModalOpen}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <Title id="over-fullscreen-title"
        ><span class="modal-header-top modal-title-left-margin"
          >Aadhaar declaration</span
        ></Title
      >
      <!-- <div style="position: relative; bottom: -6px; ">
        <IconButton
          on:click={() => (langModalOpen = false)}
          class="material-icons">close</IconButton
        >
      </div> -->
    </div>
  </Header>
  <Content id="sheet-content">
    <div class="modal">
      <!-- <div class="modal-header">
        <div class="modal-header-text">Aaadhaar declaration</div>
        <div class="modal-header-icon">
          <IconButton
            on:click={() => (langModalOpen = false)}
            class="material-icons">close</IconButton
          >
        </div>
      </div> -->
      <div class="modal-body">
        <div style="display: flex; width: 100%; gap: 20px; margin-bottom: 20px">
          <span
            style="display: flex; width: 100%; align-items: center; font-weight: 600; "
            >Change language:</span
          >
          <select class="state-select" on:change={setLanguage}>
            {#each Object.entries(declaration) as declarationItem}
              <option value={declarationItem[0]}
                >{declarationItem[1].name}</option
              >
            {/each}
            <!-- <option value="en">English</option>
            <option value="hi">हिंदी</option> -->
          </select>
        </div>

        <div
          class="d-flex"
          style="width: 100; margin-bottom: 20px; font-size: 14px; flex-direction: column"
        >
          <ol
            style=" width: 100%; margin: 0px; padding-left: 10px; text-align: justify;"
          >
            {@html declarationText}
          </ol>
        </div>

        {#if declarationAudio && declarationAudio.length > 0}
          <div style="width: 100; margin-bottom: 20px">
            <!-- <audio controls>
              <source src={declarationAudio} type="audio/mp3" />
              Your browser does not support the audio element.
            </audio> -->
            <AudioPlayer src={declarationAudio} />
          </div>
        {/if}
      </div>
    </div>
  </Content>
  <Actions class="modal-button">
    <div style="justify-content: end; margin: 12px;" class="modal-button">
      <Buttonlnv
        title="reject"
        onclick={() => {
          langModalOpen = false;
        }}
        style="display: flex; justify-content: center;  border: 2px solid #13afe9;  background: #fff; color: #13afe9"
      />

      <Button
        title="Accept"
        onclick={() => {
          langModalOpen = false;

          setTimeout(() => {
            checked = true;
            declarationAccepted = true;
          }, 200);
        }}
        style="display: flex; justify-content: center; border: 2px solid #13afe9"
      />
    </div>
  </Actions>
</Dialog>

<WizardBody title="Give us your Aadhaar Number" back="">
  <div class="row">
    <div class="text-muted">
      We will fetch the details linked to your Aadhaar card
    </div>

    <Input
      type="number"
      style={"width:320px;"}
      placeholder="Enter your Aadhaar Number"
      bind:value={aadhaarValue}
      maxLength={12}
      onKeyPress={(e) => {
        if (!e) e = window.event;
        const keyCode = e.code || e.key;
        if (keyCode == "Enter") {
          buttonClick();
          return false;
        }
      }}
      onchange={(event) => {
        inputValue = event.target.value;
      }}
      validate={(value) => {
        if (value.length !== 12) {
          return "Invalid Aadhaar";
        }
        return "";
      }}
    />
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>

  <div style="margin-left: -12px; margin-bottom: 6px;">
    <Checkbox bind:checked
      ><div
        style="color: #464448; margin-left: -8px; font-weight: 400; font-size: 12px; line-height: 16px;"
      >
        I accept the Aadhaar declaration.
      </div></Checkbox
    >
  </div>

  <Button
    title="verify"
    onclick={buttonClick}
    disabled={inputValue.length != 12 || !checked}
    fade={!checked}
  />
</WizardActionArea>

<style>
  .modal-header-top {
    font-family: "Urbanist", sans-serif !important;
  }
  /* .custom-select {
    position: relative;
    padding: 18px;
    min-width: 180px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
  } */

  .state-select {
    position: relative;
    width: 50%;
    padding: 18px;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 8%);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/images/select-caret-rx.png") no-repeat 100%;
    background-attachment: cover;
    border: 0;
    padding-right: 30px !important;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  }

  .modal-button {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  @media only screen and (max-width: 768px) {
    .text-muted {
      font-size: 12px;
      line-height: 18px;
      margin-top: -8px;
      margin-bottom: 10px;
    }

    .modal-button {
      display: flex;
      width: 100%;
      gap: 14px;
      flex-direction: column-reverse;
    }
    .modal-title-left-margin {
      margin-left: 16px;
    }
  }

  .modal-header {
    width: 100%;
    color: #000;
    display: flex;
  }

  .modal-header-text {
    width: 100%;
    height: 28px;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .modal-header-icon {
    margin-top: -14px;
  }

  @media only screen and (min-width: 768px) {
    .text-muted {
      margin-bottom: 26px;
      margin-left: 5px;
    }
  }
</style>
