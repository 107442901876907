<script>
  import { navigateTo } from "svelte-router-spa";

  export let currentRoute;
  const type = currentRoute.namedParams.type;

  setTimeout(() => {
    if (type === "sa") {
      navigateTo("/sa/success");
    } else if (type === "fd" || type === "sc") {
      navigateTo("/fd/fd-confirm");
    }
  }, 1000);
</script>

Loading...
