<script>
  import Button from "../../components/commons/Button.svelte";
  import WizardSubHeader from "../../components/Wizard/SubHeader.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import Timeline from "../../components/commons/Timeline.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { onMount } from "svelte";
  import { request } from "../../services/network";

  let flow;
  onMount(async () => {
    request("/api/user/flow", "GET").then((response) => {
      flow = response.flow;
    });
  });
</script>

<WizardBody title="Complete your application in just few steps" back="">
  <div class="row sub-heading">
    <div class="col-md-12">
      <span class="text-muted"> This will take only </span><span
        class="badge badge-danger"
        style="border-radius: 2px;">5 minutes</span
      >
    </div>
  </div>
  <div class="row">
    <div class="col-md-8 mt-3">
      {#if flow === "fd"}
        <Timeline
          timelines={[
            {
              description: "Verify your mobile number and email id",
              status: "active",
            },
            {
              description:
                "Complete your online KYC with PAN Card and Aadhaar number",
              status: "warning",
            },
            {
              description: "Complete your Online Application Form",
              status: "",
            },
            {
              description: "Transfer money to fund your Fixed Deposit (FD)",
              status: "",
            },
          ]}
        />
      {:else if flow === "sa"}
        <Timeline
          timelines={[
            {
              description: "Verify your mobile number and email id",
              status: "active",
            },
            {
              description:
                "Complete your online KYC with PAN Card and Aadhaar number",
              status: "warning",
            },
            {
              description: "Complete your Online Application Form",
              status: "",
            },
            {
              description:
                "Enjoy the benefits of SBM Bank Savings Account with Debit card",
              status: "",
            },
          ]}
        />
      {:else if flow === "sc"}
        <Timeline
          timelines={[
            {
              description: "Verify your mobile number and email id",
              status: "active",
            },
            {
              description:
                "Complete your online KYC with PAN Card and Aadhaar number",
              status: "warning",
            },
            {
              description: "Complete your Online Application Form",
              status: "",
            },
            {
              description: "Transfer money to fund your Fixed Deposit (FD)",
              status: "",
            },
            {
              description: "Enjoy using your Freedom Credit Card",
              status: "",
            },
          ]}
        />
      {/if}
    </div>
    <div class="col-md-4">
      <WizardSubHeader title="Be ready with the following things" />
      <div class="requirements mt-3 row">
        <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <div class="requirement-icon">
            <span class="material-symbols-outlined"> badge </span>
          </div>
          <div class="requirement-item-text">PAN Card</div>
        </div>
        <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <div class="requirement-icon">
            <span class="material-symbols-outlined"> pin </span>
          </div>
          <div class="requirement-item-text">Aadhaar Number</div>
        </div>
        <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <div class="requirement-icon">
            <span class="material-symbols-outlined"> camera </span>
          </div>
          <div class="requirement-item-text">Access to device with camera</div>
        </div>
        <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <div class="requirement-icon">
            <span class="material-symbols-outlined"> mic </span>
          </div>
          <div class="requirement-item-text">
            Access to device with microphone
          </div>
        </div>
        <!--<div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" fill="#F8FAFF" />
            <g clip-path="url(#clip0_38_23287)">
              <path
                d="M22 32H17V34H22V36L25 33L22 30V32ZM26 32V34H31V32H26ZM24 20C25.1 20 26 19.1 26 18C26 16.9 25.1 16 24 16C22.9 16 22.01 16.9 22.01 18C22.01 19.1 22.9 20 24 20ZM29 12H19C17.9 12 17 12.9 17 14V28C17 29.1 17.9 30 19 30H29C30.1 30 31 29.1 31 28V14C31 12.9 30.1 12 29 12ZM19 14H29V24.5C29 22.83 25.67 22 24 22C22.33 22 19 22.83 19 24.5V14Z"
                fill="#8D99AB"
              />
            </g>
            <defs>
              <clipPath id="clip0_38_23287">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(12 12)"
                />
              </clipPath>
            </defs>
          </svg>
          <div class="requirement-item-text">
            Face Verification / Liveliness Check
          </div>
        </div>-->
        <!-- <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <svg
            width="35"
            height="35"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" fill="#F8FAFF" />
            <g clip-path="url(#clip0_38_23294)">
              <path
                d="M22 32V26H26V32H31V24H34L24 15L14 24H17V32H22Z"
                fill="#8D99AB"
              />
            </g>
            <defs>
              <clipPath id="clip0_38_23294">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(12 12)"
                />
              </clipPath>
            </defs>
          </svg>
          <div class="requirement-item-text">Delivery Address</div> 
        </div> 
        <div class="requirement-item col-md-12 col-sm-6 col-6 my-3">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" fill="#F8FAFF" />
            <g clip-path="url(#clip0_38_23302)">
              <path
                d="M32 16H16C14.89 16 14.01 16.89 14.01 18L14 30C14 31.11 14.89 32 16 32H21V30H16V24H34V18C34 16.89 33.11 16 32 16ZM32 20H16V18H32V20ZM26.93 31.17L24.1 28.34L22.69 29.75L26.93 34L34 26.93L32.59 25.52L26.93 31.17Z"
                fill="#8D99AB"
              />
            </g>
            <defs>
              <clipPath id="clip0_38_23302">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(12 12)"
                />
              </clipPath>
            </defs>
          </svg>
          <div class="requirement-item-text">Fund your account</div>
        </div>-->
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea>
  <!-- top: -130px -->
  <Button
    title="Next"
    style="position: relative;"
    onclick={() => {
      navigateTo("/onboarding/pan");
    }}
  />
</WizardActionArea>

<style>
  /* .requirements { */
  /* display: flex;
    flex-direction: column; */
  /* gap: 32px; */
  /* } */
  .requirement-item {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .requirement-item-text {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #505869;
  }
  .requirement-icon {
    background-color: #fff;
    border-radius: 36px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (min-width: 768px) {
    .sub-heading {
      margin-top: 19px;
      margin-bottom: 51px;
    }
  }
  @media only screen and (max-width: 768px) {
  }
</style>
