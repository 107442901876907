<script>
  import InputCurrency from "./../../components/commons/InputCurrency.svelte";
  import Button from "../../components/commons/Button.svelte";
  import WizardBody from "../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../components/Wizard/ActionArea.svelte";
  import SubHeader from "../../components/Wizard/SubHeader.svelte";
  import OptionGroup from "../../components/FDCC/OptionGroup.svelte";
  import { navigateTo } from "svelte-router-spa";
  import TenureGroup from "../../components/FDCC/TenureGroup.svelte";
  import { request } from "../../services/network";
  import { decrypt } from "../../services/function";

  import Dialog, { Content } from "@smui/dialog";
  import IconButton from "@smui/icon-button";
  import dayjs from "dayjs";
  import { onMount } from "svelte";
  import { getDurationAndRate } from "../../services/function";
  import Snackbar, { Label } from "@smui/snackbar";

  export let currentRoute;
  const type = currentRoute.namedParams.type;

  let selectedTenureIndex = -1;
  let amount = "₹50,000";
  let open = false;
  let maturityDate = "";
  let maturityDateTemp = "";
  let customMaturityDate = "";
  let customMaturityInterest = "";

  let snackbarOpen;
  let snackbarMessage = "";

  //later these will be used to send in presetup post api
  let daysSelected = "";
  let interest_rate = "";

  let header = "Create your Fixed Deposit";
  let cardHeader = "Fixed Deposit Amount";

  let interestList = [];

  let optionGroup = [];
  let tenureList = [];

  onMount(async () => {
    if (type !== "fd" && type !== "sc" && type !== "sa") {
      navigateTo("/");
    }

    const typeRes = JSON.parse(
      decrypt((await request("/api/user/session?refresh=1", "GET")).data)
    );

    if (
      typeRes.presetup &&
      typeRes.presetup.cc &&
      typeRes.presetup.cc === true
    ) {
      header = "Choose your Credit Card limit";
      cardHeader = "Credit Card Limit";
    }

    const getInterestRatesRes = await request("/api/fd/interest", "GET");

    interestList = getInterestRatesRes.data;

    const templateRes = await request(
      "/api/global_template/PREFILL_DATA", //dont change this
      "GET"
    );

    optionGroup = templateRes.data.data.amount;
    tenureList = templateRes.data.data.tenure;

    // to get the duration and rate
    //const s = getDurationAndRate(interestList, 600);
  });

  $: if (maturityDate) {
    if (maturityDate.length > 0) {
      //open = false;
      const currentDate = new Date();
      const selectDate = new Date(maturityDate);
      const diffTime = Math.abs(selectDate - currentDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 3560) {
        /* window.snackbar(
          "You cannot select date more than 10 years",
          "danger",
          null
        ); */
        snackbarMessage = "You cannot select date more than 10 years";
        snackbarOpen.forceOpen();
      } else if (
        diffDays < 14 ||
        selectDate.getTime() < currentDate.getTime()
      ) {
        /* window.snackbar(
          "Please select a date more than 14 days",
          "danger",
          null
        ); */
        snackbarMessage = "Please select a date more than 14 days";
        snackbarOpen.forceOpen();
        customMaturityDate = "";
        daysSelected = "";
        interest_rate = "";
      } else {
        customMaturityInterest = getDurationAndRate(
          interestList,
          diffDays
        ).interest_rate;

        customMaturityDate =
          "Selected maturity date: " +
          dayjs(maturityDate).format("DD/MM/YYYY") +
          " (" +
          diffDays +
          " days) at " +
          customMaturityInterest +
          "%";

        daysSelected = diffDays.toString();
        interest_rate = customMaturityInterest;
        selectedTenureIndex = -1;
      }
    } else {
      customMaturityDate = "";
    }
  }

  const getRateFromDay = (day) => {
    let interest = 0;
    interestList.forEach((item) => {
      if (day >= item.duration_start && day <= item.duration_end) {
        interest = item.interest;
        return;
      }
    });
    return interest;
  };

  const buttonClick = async () => {
    const amountVal = amount.replace(/[^0-9.]/, "").toString();

    if (amountVal.length < 3) {
      snackbarMessage = "Invalid amount";
      snackbarOpen.forceOpen();
      return;
    }

    if (parseFloat(amountVal.replace(/[^0-9.]/, "")) < 1) {
      snackbarMessage = "Invalid amount";
      snackbarOpen.forceOpen();
      return;
    }

    /* if (selectedTenureIndex === 0) {
      daysSelected = "1825";
      interest_rate = "6.25";
    } else if (selectedTenureIndex === 1) {
      daysSelected = "730";
      interest_rate = "5.75";
    } else if (selectedTenureIndex === 2) {
      daysSelected = "365";
      interest_rate = "5.25";
    } */

    if (selectedTenureIndex > -1 && selectedTenureIndex <= 2) {
      daysSelected = tenureList[selectedTenureIndex].value;
      interest_rate = getRateFromDay(daysSelected);
    }

    if (daysSelected.length < 1 || interest_rate < 1) {
      snackbarMessage = "Please select a tenure";
      snackbarOpen.forceOpen();
      return;
    }

    let session = localStorage.getItem("session");
    if (session) {
      try {
        session = JSON.parse(session);
      } catch (e) {
        session = {};
      }
    }

    window.loading(true);

    const setPresetupRs = await request("/api/presetup", "POST", {
      amount: amountVal.toString(),
      duration: daysSelected.toString(),
      type: type.toString(),
      interest_rate: interest_rate.toString(),
    });

    window.loading(false);

    if (setPresetupRs.type === "success") {
      navigateTo("/");
    } else {
      snackbarMessage = "Invalid data submitted";
      snackbarOpen.forceOpen();
    }

    /* if (session.fd && session.fd.success && session.fd.success > 0) {
      let isCCEnabled = false;
      const isCCReq = await request("/api/user/session?refresh=1", "GET");

      try {
        if (
          isCCReq &&
          isCCReq.presetup &&
          isCCReq.presetup.cc &&
          isCCReq.presetup.cc === true
        ) {
          isCCEnabled = true;
        }
      } catch (e) {
      }

      const req = await request("/api/fd", "POST", {
        cc_enabled: isCCEnabled.toString(),
        amount: amountVal.replace(/,/, "").toString(),
        duration: daysSelected.toString(),
        interest_rate: interest_rate.toString(),
        status: "PENDING",
        nominee_id: null,
        data: "{}",
      });

      navigateTo("/onboarding/address");
    } else {
      const result = await request("/api/presetup", "POST", {
        amount: amountVal,
        duration: daysSelected,
        cc: "false",
        interest_rate,
      });

      if (result.type === "success") {
      } else {
        snackbarMessage = "Something went wrong";
        snackbarOpen.forceOpen();
      }
      navigateTo("/onboarding/phone-verify");
    } */
  };

  const selectTenureCustom = () => {
    open = true;
  };
</script>

<Dialog bind:open aria-describedby="sheet-content">
  <Content id="sheet-content" style="width:480px; max-width:100% ">
    <div class="modal">
      <div class="modal-header">
        <div class="modal-header-text">Select Custom Tenure</div>
        <div class="modal-header-icon">
          <IconButton on:click={() => (open = false)} class="material-icons"
            >close</IconButton
          >
        </div>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-between">
          <span style=" align-items: center; display: flex; ">Select Date:</span
          >
          <div class="col-xs-12 col-md-6 my-3">
            <div class="input-container">
              <input
                class="dob-input-field"
                type="date"
                placeholder="DD/MM/YYYY"
                min={dayjs(new Date()).add(14, "day").format("YYYY-MM-DD")}
                bind:value={maturityDateTemp}
              />
            </div>
          </div>
        </div>
        <Button
          title="Save"
          onclick={() => {
            maturityDate = "";
            maturityDate = maturityDateTemp;
            open = false;
          }}
        />
        <!-- <div class="d-flex justify-content-between">
          <span>Year: 10</span>
          <span>Month: 10</span>
          <span>Day: 10</span>
        </div> -->
      </div>
    </div>
  </Content>
</Dialog>
<WizardBody title={header} back="/fd/list">
  <div class="responsive-scroll p-3">
    <div class="card">
      <div class="top-blue" />

      <div class="card-content">
        <div
          style="display: flex; justify-content: space-between; align-items: center; width: 100%;"
        >
          <i
            style="background-image: url('/assets/images/sbm-logo-inv.png');background-size: 98px; width: 98px; height: 26px;display: block;background-repeat: no-repeat;"
          />
          <i
            style="background-image: url('/assets/images/mastercard.svg');background-size: 40px; width: 40px; height: 40px;display: block;background-repeat: no-repeat;"
          />
        </div>
        <div
          style="margin-top: 18px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          {cardHeader}
        </div>
        <div
          style="margin-top: 8px; font-style: normal; font-weight: 700; font-size: 48px; line-height: 40px; color: #FFFFFF;"
        >
          {amount.replace("₹", "₹ ")}
        </div>
        <div
          style="margin-top: 36px; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: rgba(255, 255, 255, 0.72);"
        >
          FD Amount
        </div>
        <div
          style="margin-top: 4px;font-style: normal; font-weight: 700; font-size: 24px; line-height: 24px;color: #FFFFFF;"
        >
          {amount.replace("₹", "₹ ")}
        </div>
      </div>
    </div>
  </div>
  <SubHeader
    title="Enter FD Amount"
    style="color: #5A5A5A; margin-top: 44px; margin-bottom: 24px;"
  />
  <InputCurrency
    width="320px"
    placeholder="₹ 10,000"
    type="text"
    bind:value={amount}
  />

  {#if optionGroup.length > 0}
    <OptionGroup bind:amount bind:optionGroup />
  {/if}

  <SubHeader
    title="Select Tenure"
    style="color: #5A5A5A; margin-top: 44px; margin-bottom: 24px;"
  />

  {#if tenureList.length > 0}
    <TenureGroup
      bind:selectedTenureIndex
      onclick={selectTenureCustom}
      bind:tenureList
      bind:interestList
      bind:maturityDate
      bind:customMaturityDate
    />
  {/if}

  {#if customMaturityDate.length > 0}
    <h2 style="color: #505869">{customMaturityDate}</h2>
  {/if}
</WizardBody>
<WizardActionArea>
  <div style="position: relative;">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
    <div style="position: relative;">
      <Button title="Next" onclick={buttonClick} />
    </div>
  </div></WizardActionArea
>

<style>
  .card {
    display: flex;
    background: linear-gradient(66.27deg, #13afe9 -10.45%, #083a9a 76.78%);
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    width: 380px;
  }
  .top-blue {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 70%;
    background: rgba(20, 20, 20, 0.39);
    box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    border-radius: 24px 24px 0px 0px;
  }
  .card-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 99;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .modal {
    padding: 0px;
  }

  .modal-header {
    width: 100%;
    color: #000;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-header-text {
    width: 100%;
    height: 28px;
    vertical-align: middle;
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    position: relative;
    display: flex;
    width: 100%;
  }

  .dob-input-field {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
  }
</style>
