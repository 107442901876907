<script>
  import { onMount } from "svelte";
  import { Route } from "svelte-router-spa";
  import SnackbarKitchen, { snackbar } from "../Snackbar/index.svelte";
  export let currentRoute;
  const params = {};
  window.snackbar = snackbar;
  import Loading, { setLoading } from "../../components/commons/Loading.svelte";
  import { request } from "../../services/network";
  window.loading = setLoading;
  let flow = "fd";
  onMount(async () => {
    try {
      if (
        JSON.parse(localStorage.getItem("session")).flow === "fd" ||
        JSON.parse(localStorage.getItem("session")).flow === "sa"
      ) {
        flow = JSON.parse(localStorage.getItem("session")).flow;
      } else {
        let temp_flow = localStorage.getItem("flow");
        if (["fd", "sa"].includes(temp_flow)) {
          flow = temp_flow;
        }
      }
    } catch (e) {
      // console.log(e);
    }
    //if (!localStorage.getItem("csrfkey") || localStorage.getItem("csrfkey").length < 4 ) {
    const { csrfkey } = await request("/api/csrf", "GET");
    localStorage.setItem("csrfkey", csrfkey);

    // }
  });
</script>

<div class="bg-absolute-custom" />
<div class="container">
  <div class="row">
    <div
      class="col-md-8 col-sm-12 col-xs-12"
      style="min-height:100vh; margin: 0px; padding: 0px;"
    >
      <Loading />
      <div
        class="p-3"
        style="min-height:100vh; width:576px; max-width:100%; margin: auto; z-index:10; display:flex; align-items: flex-start; flex-direction: column;"
      >
        <Route {currentRoute} {params} />
      </div>
    </div>
    <div
      class="col-md-4 phone-hide background {flow === 'sa' ? 'bg-sa' : 'bg-fd'}"
    >
      <!-- <i class="bg_img" /> -->

      {#if flow === "fd"}
        <div class="header-banner" style="color: black;">
          <span class="header-banner-text">Maximise</span>
          <span class="header-banner-text"> your savings with </span>
          <span class="header-banner-text"> Attractive FD rates up to </span>
          <span class="header-banner-text"
            ><strong style="font-size: 2.2vw; font-weight: 700">8.75% </strong> p.a.*
          </span>
          <div class="header-banner-underline" />
        </div>
      {:else}
        <div
          class="header-banner"
          style="color: white;
        "
        >
          <span class="header-banner-text">Now earn up to</span>
          <span class="header-banner-text"
            ><strong style="font-size: 2.2vw; font-weight: 700">7.25% </strong> p.a.*
            with monthly</span
          >
          <span class="header-banner-text"> interest pay-out on your </span>
          <span class="header-banner-text"> Savings Account. </span>
          <div class="header-banner-underline" />
        </div>
      {/if}

      {#if flow === "fd"}
        <div class="footer-banner">
          <span>*T&C Apply </span>
          <span
            >Above rates are subject to change without prior notice. Depositors
            may please confirm the rate with the respective branches before the
            investment.</span
          >
          <span
            >0.5% additional benefit for senior citizens (only applicable for
            deposits for Resident Indians)</span
          >
        </div>
      {:else}
        <div class="footer-banner">
          <span>*T&C Apply </span>
          <span>Above rates are subject to change without prior notice. </span>
          <span
            >Depositors may please confirm the rate with the respective branches
            before the investment.</span
          >
        </div>
      {/if}
      <div class="background-gradient-right" />
      <!-- <WizardRightSidebar /> -->
    </div>
  </div>
</div>
<SnackbarKitchen />

<style>
  .header-banner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    width: 88%;
    /* font-family: "Open Sans", sans-serif; */
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 38px;
    left: 12%;
  }

  .header-banner-text {
    letter-spacing: 2px;
    /* white-space: nowrap; Prevent texts from wrapping within themselves */
  }

  .header-banner-underline {
    display: inline-block;
    height: 4px;
    width: 90px;
    background-color: #13afe9;
    border-radius: 5px;
    margin-top: 10px;
  }

  .footer-banner {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: white;
    position: absolute;
    padding: 10px 12px;
    gap: 3px;
    bottom: 0px;
    background-color: #1b3d6b;
  }

  .bg-absolute-custom {
    position: fixed;
    width: 300px;
    height: 100vh;
    left: 0px;
    top: 0px;
    z-index: -1;
    background: linear-gradient(
      90deg,
      #eaf0f9 -14.33%,
      rgba(234, 240, 249, 0) 95.69%
    );
  }
  /*.background-gradient-right {
    position: fixed;
    background-size: cover;
    max-width: 28%;
    width: 300px;
    height: 80vh;
    right: 0px;
    top: 145.68px;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzU2IiBoZWlnaHQ9IjYyMyIgdmlld0JveD0iMCAwIDM1NiA2MjMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIG9wYWNpdHk9IjAuNSIgZD0iTTAuMzQzNzUgMzg5LjIxTDM1NS4wMDMgMC42ODM1OTRWODUxLjU5SDAuMzQzNzVWMzg5LjIxWiIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzM4XzE1NjY2KSIvPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzM4XzE1NjY2IiB4MT0iMTc3LjY3NCIgeTE9IjIxMi42MDciIHgyPSIxNzcuNjc0IiB5Mj0iODI3LjUwNyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRDRFMkY2Ii8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0VDRjNGRCIgc3RvcC1vcGFjaXR5PSIwIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    overflow: hidden;
  }*/
  .background {
    min-height: 100vh;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px;
    padding: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
    /* width: 100%; */
    min-width: 200px;
    overflow: hidden;
    /* background: url(""); */
  }

  .bg-sa {
    background: url("/assets/images/sbm_banner_sa.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
  }

  .bg-fd {
    background: url("/assets/images/sbm_banner_fd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
  }
</style>
