<script>
  import dayjs from "dayjs";
  import Button from "../../../components/commons/Button.svelte";
  import Checkbox from "../../../components/commons/Checkbox.svelte";
  import WizardBody from "../../../components/Wizard/Body.svelte";
  import WizardActionArea from "../../../components/Wizard/ActionArea.svelte";
  import Input from "../../../components/commons/Input.svelte";
  import { navigateTo } from "svelte-router-spa";
  import { request } from "../../../services/network";
  import Snackbar, { Label } from "@smui/snackbar";
  import Dialog, { Content, Header, Title, Actions } from "@smui/dialog";
  import Bullets from "../../../components/commons/Bullets.svelte";
  import Buttonlnv from "../../../components/commons/Buttonlnv.svelte";
  import DatePicker from "../../../components/commons/DatePicker.svelte";
  import { onMount } from "svelte";
  import { routeCheck } from "../../../services/routeCheck";
  import { isPan } from "../../../services/function";
  import customParseFormat from "dayjs/plugin/customParseFormat";
  import { consent } from "./consent.js";

  dayjs.extend(customParseFormat);

  let declarationAccepted = false;
  let panCheckbox = false;
  let panCardNo = "";
  let modalOpen = false;
  let snackbarOpen;
  let snackbarMessage = "";
  let snackbarOpenModal;
  let panDob = "";
  let smsChecked = false;
  let ckycConsentChecked = false;
  let conditions = [
    "there is a change in the information as existing in the records of CKYCR;",
    "the current address is required to be verified;",
    "the Bank considers it necessary to verify the identity or address;",
    "to perform enhanced due diligence or to build an appropriate risk profile;",
    " KYC and periodic KYC process as per the Prevention of Money Laundering Act, 2002 and its rules thereunder and RBI guidelines, or for establishing my identity, carrying out my identification, offline verification demographic or other authentication/verification/identification as may be permitted as per applicable Law, for all accounts, facilities, services and relationships of/through the Bank, existing and future;",
    "collecting, sharing, storing, preserving Information, maintaining records and using the information and authentication/verification/identification records: (a) for the informed purposes above; (b) as well as for regulatory and legal reporting and filings; and/or (c) where required under applicable Law;",
    " producing records and logs of the consent, information or of authentication, identification, verification etc. for evidentiary purposes including before a court of Law, any statutory, administrative, legislative authority or any other authority, any regulator or in arbitration.",
  ];

  let lang = "en";

  let consentText = "";
  //let panInputValue = "";

  let checked = false;

  export let currentRoute;

  onMount(async () => {
    try {
      const localData = localStorage.getItem("pan");
      if (localData) {
        const data = JSON.parse(localData);
        panCardNo = data.pan;
        localStorage.setItem("pan", null);
      }
    } catch (e) {}
    setConsentContent();
    routeCheck("/onboarding/journey");
  });

  function beforeunload(event) {
    event.preventDefault();
    return (event.returnValue = "");
  }

  const isUserMinor = (dob) => {
    //TODO: check
    /* const currentDate = new Date();
    const dobDate = new Date(dob); */

    const currentDate = dayjs();
    const years = currentDate.diff(dayjs(dob, "DD-MM-YYYY"), "year");

    /* console.log(d);

    const years =
      new Date(currentDate - new Date(dobDate)).getFullYear() - 1970;

    console.log(years); */

    if (years < 18) {
      return true;
    }

    return false;
  };

  const verifyButton = async () => {
    if (
      panCardNo.length !== 10 ||
      !panCardNo.match(/[A-Z]{3}P[A-Z][0-9]{4}[A-Z]{1}/)
    ) {
      //window.snackbar("Invalid pan card number", "danger", null);
      snackbarMessage = "Invalid pan card number";
      snackbarOpen.forceOpen();
      return;
    }

    if (!panDob || panDob.length < 4) {
      //window.snackbar("Please select your date of birth", "danger", null);
      snackbarMessage = "Please select your date of birth";
      snackbarOpen.forceOpen();
      return;
    }

    if (isUserMinor(panDob)) {
      //TODO: get the right message from SBM
      snackbarMessage = "User cannot be minor";
      snackbarOpen.forceOpen();
      return;
    }

    // if (!panCheckbox) {
    //   snackbarMessage = "Please accept the declaration";
    //   snackbarOpen.forceOpen();
    //   return;
    // }

    window.loading(true);

    const result = await request("/api/kyc/pan", "POST", {
      pan: panCardNo.toString().trim(),
      panDob: panDob.toString().trim(),
    });

    window.loading(false);

    if (result.pan_number === panCardNo) {
      navigateTo("/onboarding/pan-confirm");
    } else if (result.message) {
      snackbarMessage = result.message;
      snackbarOpen.forceOpen();
    }
  };

  const setLanguage = (e) => {
    lang = e.target.value;
    //langModalOpen = true;

    setConsentContent();
  };

  const panCheck = () => {
    return (
      panCardNo.match(/[A-Z]{3}P[A-Z][0-9]{4}[A-Z]{1}/) &&
      panCardNo.length == 10 &&
      !isUserMinor(panDob)
    );
  };

  $: if (checked) {
    if (declarationAccepted === false) {
      modalOpen = true;
      checked = false;
    }
  } else {
    declarationAccepted = false;
  }

  window.onpopstate = () => {
    alert("Back is disabled");
    history.forward();
  };

  const setConsentContent = () => {
    consentText = consent[lang].value;
  };
</script>

<svelte:window on:beforeunload={beforeunload} />

<Dialog
  fullscreen
  bind:open={modalOpen}
  aria-labelledby="over-fullscreen-title"
  aria-describedby="over-fullscreen-content"
>
  <Header>
    <div style="display: flex; justify-content: space-between; width: 100%">
      <Title id="over-fullscreen-title" class="modal-header-top"
        ><span class="modal-header-top modal-title-left-margin"
          >Pan verification and CKYC consent</span
        ></Title
      >
      <!-- <div style="position: relative; bottom: -6px; ">
        <IconButton
          on:click={() => (modalOpen = false)}
          class="material-icons"
          ripple={false}>close</IconButton
        >
      </div> -->
    </div>
  </Header>
  <Content id="sheet-content">
    <div class="modal">
      <!-- <div class="modal-header">
        <div class="modal-header-text">Aaadhaar declaration</div>
        <div class="modal-header-icon">
          <IconButton
            on:click={() => (langModalOpen = false)}
            class="material-icons">close</IconButton
          >
        </div>
      </div> -->
      <div class="modal-body">
        <div style="display: flex; width: 100%; gap: 20px; margin-bottom: 20px">
          <span
            style="display: flex; width: 100%; align-items: center; font-weight: 600; "
            >Change language:</span
          >
          <select class="state-select" on:change={setLanguage}>
            {#each Object.entries(consent) as consentItem}
              <option value={consentItem[0]}>{consentItem[1].name}</option>
            {/each}
            <!-- <option value="en">English</option>
            <option value="hi">हिंदी</option> -->
          </select>
        </div>
      </div>

      <div
        class="d-flex"
        style="width: 100; margin-bottom: 20px; font-size: 14px; flex-direction: column"
      >
        <ol
          style=" width: 100%; margin: 0px; padding-left: 10px; text-align: justify;"
        >
          {@html consentText}
        </ol>
      </div>
    </div>
  </Content>
  <Actions class="modal-button">
    <div
      class="modal-button"
      style=" margin-left: 14px; margin-right: 14px; margin-bottom: 14px; justify-content: end;"
    >
      <Buttonlnv
        title="reject"
        onclick={() => {
          modalOpen = false;
        }}
        style="display: flex; justify-content: center;  border: 2px solid #13afe9;  background: #fff; color: #13afe9"
      />

      <Button
        title="Accept"
        onclick={() => {
          modalOpen = false;

          setTimeout(() => {
            checked = true;
            declarationAccepted = true;
          }, 200);
        }}
        style="display: flex; justify-content: center; border: 2px solid #13afe9"
      />
    </div>
  </Actions>
</Dialog>

<WizardBody title="Let’s start with your PAN" back="">
  <div class="row">
    <div style="display: flex; gap: 20px;" class="flex-dir">
      <div style="display: flex; flex-direction: column; width: 100%">
        <div class="label">PAN Number</div>
        <div class="row">
          <Input
            type="text"
            placeholder="Enter your PAN number"
            bind:value={panCardNo}
            maxLength={10}
            width="480px"
            onchange={(event) => {
              panCardNo = panCardNo.toUpperCase();
              //panInputValue = event.target.value.toUpperCase();
            }}
            validate={(value) => {
              if (
                !value.match(/[A-Z]{3}P[A-Z][0-9]{4}[A-Z]{1}/) &&
                value.length !== 10
              ) {
                return "Invalid PAN number";
              }
              return "";
            }}
          />
        </div>
      </div>

      <div style="display: flex; flex-direction: column; width: 100%">
        <div class="label">Date of Birth as per PAN</div>
        <DatePicker
          bind:date={panDob}
          placeholder="Enter Date of Birth"
          maxDate={new Date(
            new Date().setFullYear(new Date().getFullYear() - 18)
          )}
          minDate="1900-01-01"
        />
        <!-- 
           initialDate={new Date(
            new Date().setFullYear(new Date().getFullYear() - 18)
          )}
          <input
          class="dob-input-field "
          type="date"
          placeholder="dd-mm-yyyy"
          bind:value={panDob}
        /> -->
      </div>
    </div>
  </div>
</WizardBody>
<WizardActionArea>
  <div style="position: relative; margin-bottom: -10px">
    <Snackbar leading bind:this={snackbarOpen} class="snackbar-warning">
      <Label>{snackbarMessage}</Label>
    </Snackbar>
  </div>

  <div style="margin-left: -12px; margin-bottom: 12px;">
    <Checkbox bind:checked
      ><div
        style="color: #464448; margin-left: -8px; font-weight: 400; font-size: 12px; line-height: 16px;"
      >
        I hereby authorize SBM Bank (India) Ltd. (“Bank”) to fetch/retrieve my
        PAN details from NSDL Registry.
      </div></Checkbox
    >
  </div>

  <Button
    title="verify"
    onclick={verifyButton}
    disabled={!isPan(panCardNo) || !checked}
    fade={!panDob || panDob.length < 1 || !checked}
  />
</WizardActionArea>

<style>
  .modal-header-top {
    font-family: "Urbanist", sans-serif !important;
  }
  .label {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #464448;
    /* margin: 8px; */
    margin-left: 3px;
    margin-bottom: 12px;
  }
  .dob-input-field {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border: 1px solid #eaf0f9;
    border-radius: 8px;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
    padding: 18px;
    margin: 0px !important;
  }

  .checkbox {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .checkbox-ckyc-consent {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  @media only screen and (min-width: 769px) {
    .flex-dir {
      flex-direction: row;
    }

    .span {
      margin-left: 15px;
    }

    .button {
      margin-left: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .flex-dir {
      flex-direction: column;
    }

    .checkbox,
    .checkbox-ckyc-consent {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .modal-title-left-margin {
      margin-left: 16px;
    }
  }

  .modal-button {
    display: flex;
    width: 100%;
    gap: 20px;
  }

  @media only screen and (max-width: 768px) {
    .modal-button {
      display: flex;
      width: 100%;
      gap: 10px;
      flex-direction: column-reverse;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
  }
</style>
