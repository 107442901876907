import NotFound from "./pages/commons/NotFound.svelte";
import PublicLayout from "./components/layouts/PublicLayout.svelte";
import WizardLayout from "./components/layouts/WizardLayout.svelte";
import PhoneInput from "./pages/user/phone/Input.svelte";
import PhoneOtpVerify from "./pages/user/phone/OTPVerify.svelte";
import PreSetup from "./pages/commons/PreSetup.svelte";
import SelectType from "./pages/commons/SelectType.svelte";
import Welcome from "./pages/commons/Welcome.svelte";
import Test from "./pages/commons/Test.svelte";
import Home from "./pages/commons/Home.svelte";
import FdList from "./pages/fd/List.svelte";
import FdDetails from "./pages/fd/Details.svelte";
import FdDepositDetails from "./pages/fd/FdDepositDetails.svelte";
import FdDepositSuccess from "./pages/fd/FdDepositSuccess.svelte";
import PanInput from "./pages/user/pan/Input.svelte";
import PanConfirm from "./pages/user/pan/Confirm.svelte";
import AadhaarInput from "./pages/user/aadhaar/Input.svelte";
import AddressList from "./pages/user/address/List.svelte";
import AddressAdd from "./pages/user/address/Add.svelte";
import AadhaarConfirm from "./pages/user/aadhaar/Confirm.svelte";
import AadhaarOTPVerify from "./pages/user/aadhaar/OTPVerify.svelte";
import Payment from "./pages/commons/Payment.svelte";
import PaymentAccountValidate from "./pages/commons/PaymentAccountValidate.svelte";
import PaymentSuccess from "./pages/commons/PaymentSuccess.svelte";
import Terms from "./pages/commons/Terms.svelte";
import FdConfirmOld from "./pages/commons/Confirm.svelte";
import FdConfirm from "./pages/fd/FdConfirm.svelte";
import Dropoff from "./pages/kyc/Dropoff.svelte";
import Nominee from "./pages/fd/Nominee.svelte";
import Journey from "./pages/commons/Journey.svelte";
import Email from "./pages/user/email/Email.svelte";
import VKYC from "./pages/kyc/VKYC.svelte";
// import ConfirmBooking from "./pages/kyc/ConfirmBooking.svelte";
import VKYCStatus from "./pages/kyc/VKYCStatus.svelte";
import TermsConditions from "./pages/t&c/TermsConditions.svelte";
import FixedDeposit from "./pages/t&c/FixedDeposit.svelte";
import SavingAccount from "./pages/t&c/SavingAccount.svelte";
import DebitCard from "./pages/t&c/DebitCard.svelte";
import EmailAdd from "./pages/user/email/EmailAdd.svelte";
import EmailOTPVerify from "./pages/user/email/OTPVerify.svelte";
import SuccessCC from "./pages/cc/Success.svelte";
import SuccessFD from "./pages/fd/Success.svelte";
import SplashScreen from "./pages/commons/Splash.svelte";
import Logout from "./pages/user/Logout.svelte";
import { session } from "./services/store";
import { get } from "svelte/store";
import Selfie from "./pages/kyc/Selfie.svelte";
import CifConfirm from "./pages/commons/CifConfirm.svelte";
import SASuccess from "./pages/sa/SASuccess.svelte";
import CafInput from "./pages/caf/CafInput.svelte";
import CafAcceptance from "./pages/caf/CafAcceptance.svelte";
import FdCreate from "./pages/fd/FdCreate.svelte";
import UserAccount from "./pages/commons/UserAccount.svelte";
import UserAccountError from "./pages/commons/UserAccountError.svelte";

function isLoggedIn() {
  let userSession = get(session);

  if (
    userSession.user &&
    userSession.user.user_id &&
    userSession.user.user_id > 0
  ) {
    return true;
  } else {
    return false;
  }
}

function isNotLoggedIn() {
  return !isLoggedIn();
}

const routes = [
  {
    name: "/",
    component: SplashScreen,
  },
  {
    name: "/test",
    component: Test,
    layout: WizardLayout,
  },

  {
    name: "/onboarding/welcome/:type",
    component: Welcome,
    layout: WizardLayout,
  },

  {
    name: "/onboarding/presetup/:type",
    component: PreSetup,
    layout: WizardLayout,
  },
  {
    name: "/onboarding/select-type",
    component: SelectType,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },

  {
    name: "/onboarding/phone",
    component: PhoneInput,
    layout: WizardLayout,
    onlyIf: { guard: isNotLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/phone-verify/:verify_id",
    component: PhoneOtpVerify,
    layout: WizardLayout,
    onlyIf: { guard: isNotLoggedIn, redirect: "/" },
  },
  /* {
    name: "/onboarding/email",
    component: Email,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  }, */
  {
    name: "/onboarding/email-add",
    component: EmailAdd,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/email-verify/:verify_id",
    component: EmailOTPVerify,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/journey",
    component: Journey,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/pan",
    component: PanInput,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/pan-confirm",
    component: PanConfirm,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/aadhaar",
    component: AadhaarInput,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/aadhaar-otp",
    component: AadhaarOTPVerify,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/aadhaar-confirm",
    component: AadhaarConfirm,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/selfie",
    component: Selfie,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/vkyc",
    component: VKYC,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/vkyc-confirm/:type",
    component: VKYCStatus,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/vkyc-pending",
    component: VKYCStatus,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/vkyc-status",
    component: VKYCStatus,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/user-account",
    component: UserAccount,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/confirm/:type",
    component: CifConfirm,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/caf",
    component: CafInput,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/caf-confirm",
    component: CafAcceptance,
    layout: WizardLayout,
  },
  {
    name: "/onboarding/terms",
    component: Terms,
    layout: WizardLayout,
  },

  /* {
    name: "/onboarding/cc-journey",
    component: JourneyCC,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  }, */

  {
    name: "/sa/success",
    component: SASuccess,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd/create/:type",
    component: FdCreate,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd/confirm",
    component: FdConfirm,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd/fd-confirm",
    component: FdConfirmOld,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd/list",
    component: FdList,
    layout: WizardLayout,
  },
  {
    name: "/fd-details/:fd_id",
    component: FdDetails,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd-details-deposit/:fd_id",
    component: FdDepositDetails,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/fd-details-deposit-success",
    component: FdDepositSuccess,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/nominee",
    component: Nominee,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },

  {
    name: "/onboarding/add-address/:address_id",
    component: AddressAdd,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/add-address",
    component: AddressAdd,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/address",
    component: AddressList,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },

  {
    name: "/home",
    component: Home,
    layout: WizardLayout,
    /* onlyIf: { guard: isLoggedIn, redirect: "/" }, */
  },
  {
    name: "/onboarding/success-fd",
    component: SuccessFD,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/success-cc",
    component: SuccessCC,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/onboarding/dropoff",
    component: Dropoff,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/terms-conditions",
    component: TermsConditions,
    layout: WizardLayout,
    // onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/terms-conditions/fd",
    component: FixedDeposit,
    layout: WizardLayout,
  },
  {
    name: "/terms-conditions/sa",
    component: SavingAccount,
    layout: WizardLayout,
  },
  {
    name: "/terms-conditions/dc",
    component: DebitCard,
    layout: WizardLayout,
  },
  {
    name: "/payment",
    component: Payment,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/payment/account-validate",
    component: PaymentAccountValidate,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/user-account/error",
    component: UserAccountError,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/user-account/success",
    component: PaymentSuccess,
    layout: WizardLayout,
    onlyIf: { guard: isLoggedIn, redirect: "/" },
  },
  {
    name: "/logout",
    component: Logout,
    layout: WizardLayout,
  },
  {
    name: "404",
    layout: PublicLayout,
    component: NotFound,
  },
  {
    name: "*",
    layout: PublicLayout,
    component: NotFound,
  },

  //template-frontend
  /* { name: 'login', component: Login, layout: PublicLayout },
    {
      name: 'admin',
      component: AdminLayout,
      onlyIf: { guard: userIsAdmin, redirect: '/login' },
      nestedRoutes: [
        { name: 'index', component: AdminIndex },
        {
          name: 'employees',
          component: '',
          nestedRoutes: [
            { name: 'index', component: EmployeesIndex },
            { name: 'show/:id', component: EmployeesShow },
          ],
        },
      ],
    }, */
];

export { routes };
